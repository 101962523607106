import React, { memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const ShowTypes = ({ vodShowTypes, searchQueryToObject, onShowType }) => {
  const renderShowTypes = () => {
    const showTypes = searchQueryToObject["show-types"]
      ? searchQueryToObject["show-types"].split(",").map(Number)
      : [];

    return vodShowTypes.map(showType => {
      return (
        <p
          key={showType.id}
          className={classNames({
            [style.activeShowType]: showTypes.includes(showType.id),
            [style.showType]: !showTypes.includes(showType.id)
          })}
          onClick={() => onShowType(showType.id)}
        >
          {showType.name}
        </p>
      );
    });
  };

  return <div className={style.showTypeWrapper}>{renderShowTypes()}</div>;
};

ShowTypes.propTypes = {
  searchQueryToObject: PropTypes.object.isRequired,
  vodShowTypes: PropTypes.array.isRequired,
  onShowType: PropTypes.func.isRequired
};

export default memo(ShowTypes);
