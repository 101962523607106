import { put, call } from "redux-saga/effects";
import { GET_SUBSCRIPTIONS_SUCCESS } from "reduxStore/reducers/subscriptions";
import {
  CHANGE_SUBSCRIPTION,
  CHECK_SUBSCRIPTION_SUCCESS
} from "reduxStore/reducers/loggedUser";
import { fetchData, postData } from "helper-functions/http-functions";

export const getSubscriptions = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result) yield put({ type: GET_SUBSCRIPTIONS_SUCCESS, payload: result });
  } catch (err) {
    console.info("ERR", err);
    yield put({ type: GET_SUBSCRIPTIONS_SUCCESS, payload: [] });
  }
};

export const getSubscriptionsAnonymous = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (payload.successCallback) payload.successCallback(result);
  } catch (err) {
    console.info("ERR", err);
  }
};

export const updateSubscription = function*({ payload }) {
  try {
    const result = yield call(postData, payload);

    if (
      result &&
      result.data &&
      result.data.data &&
      result.data.data.subscription
    )
      yield put({ type: CHANGE_SUBSCRIPTION, payload: result.data.data });
  } catch (err) {
    console.info("ERR", err);
  }
};

export const checkSubscription = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result) {
      yield put({ type: CHECK_SUBSCRIPTION_SUCCESS, payload: result });

      if (payload.successCallback) {
        payload.successCallback(result);
      }
    }
  } catch (err) {
    console.info("er", err);
  }
};
