import Fingerprint2 from "fingerprintjs2";
import axios from "./axios";
import { appVersion } from "../../config";

export const login = async ({ url, email, password }) => {
  if (url && email && password) {
    const encodedCredentials = btoa(`${email}:${password}`);
    let uid = localStorage.getItem("wecastUID");

    if (!uid) {
      new Fingerprint2().get(result => {
        uid = result;
        localStorage.setItem("wecastUID", result);
      });
    }

    return axios
      .get(url, {
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
          UID: uid,
          Device: window.navigator.platform,
          "Device-Model": window.navigator.userAgent,
          "Content-Type": "application/x-www-form-urlencoded",
          "App-Version": appVersion
        }
      })
      .then(response => {
        const {
          data: { data: responseData }
        } = response;

        if (
          responseData &&
          responseData &&
          responseData.device &&
          responseData.device.token
        ) {
          localStorage.removeItem("wecastRelogin");
          localStorage.setItem(
            "wecastUser",
            JSON.stringify({
              token: responseData.device.token,
              uid: responseData.device.uid,
              currency_code: responseData.subscription.currency_code,
              profile: responseData.profile,
              account: responseData.account,
              device: responseData.device,
              subscription: responseData.subscription
            })
          );
        }
        return response;
      })
      .catch(err => {
        throw err;
      });
  }
};

export default login;
