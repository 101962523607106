import React, { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Popover from "antd/lib/popover";
import NavLinkComponent from "../NavLinkComponent";
import style from "./style.module.scss";

const LiveTvPopover = ({
  epg,
  channels,
  visible,
  onVisibleChange,
  onHandleHidePopover
}) => (
  <Popover
    placement="bottom"
    visible={visible}
    onVisibleChange={onVisibleChange}
    content={
      <div onClick={onHandleHidePopover} className={style.dropDown}>
        {channels && (
          <NavLinkComponent
            exact={true}
            to={"/channel-categories"}
            translationKey={"common.watch"}
          />
        )}
        {epg && (
          <NavLinkComponent
            exact={true}
            to={"/epg-channels"}
            translationKey={"common.epg"}
          />
        )}
      </div>
    }
    trigger="click"
  >
    <p className={style.mainLabel}>
      <FormattedMessage id="common.liveTv" />
    </p>
  </Popover>
);

LiveTvPopover.propTypes = {
  epg: PropTypes.bool.isRequired,
  channels: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,

  onVisibleChange: PropTypes.func.isRequired,
  onHandleHidePopover: PropTypes.func.isRequired
};

export default memo(LiveTvPopover);
