import React, { memo } from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const NextEpisodeCounterView = ({ intl, counter, onClick }) => {
  return (
    <div className={style.outerAutoNextButton} onClick={onClick}>
      <div className={style.autoNextButton}>
        <p>{`${intl.formatMessage({
          id: "common.nextEpisodeIn"
        })} ${counter}`}</p>
      </div>
    </div>
  );
};

NextEpisodeCounterView.propTypes = {
  intl: PropTypes.object.isRequired,
  counter: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default memo(NextEpisodeCounterView);
