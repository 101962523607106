import React, { useEffect, memo, Fragment, useState } from "react";
import { connect } from "react-redux";
import { createPortal } from "react-dom";
import { withRouter } from "react-router";
import { get } from "lodash";
import MoviePlayer from "../../components/Players/MoviePlayer";
import EpisodePlayer from "../../components/Players/EpisodePlayer";
import TrailerPlayer from "../../components/Players/TrailerPlayer";
import * as floatingPlayerActions from "reduxStore/reducers/floatingPlayer";
import style from "./style.module.scss";

const wrapperElement = document.querySelector("#video-root");

const FloatingPlayerHoC = props => {
  const {
    floatingPlayer: { playerSize, isPlayerOpened, selectedPlayer },
    setPlayerSize,
    setSelectedPlayer,
    setOpenPlayer,
    setSelectedVod
  } = props;
  const [playerType, setPlayerType] = useState(null);
  const pathname = get(props, "location.pathname");
  const search = get(props, "location.search");
  let Component = null;

  useEffect(() => {
    if (playerSize === "minimize") {
      wrapperElement.classList.add(style.minimize);
    } else {
      wrapperElement.classList.remove(style.minimize);
    }
  }, [playerSize]);

  useEffect(() => {
    if (isPlayerOpened) {
      setPathname(pathname);
      recognizePlayerType(search);
      setSelectedVod(get(props, "location.state.item"));
      wrapperElement.classList.add(style.container);
    } else if (isPlayerOpened === null) {
      setOpenPlayer(true);
      setPathname(pathname);
      recognizePlayerType(search);
      setSelectedVod(get(props, "location.state.item"));
      wrapperElement.classList.add(style.container);
    } else {
      wrapperElement.classList.remove(style.container);
      setPathname(null);
      setSelectedPlayer(null);
      setSelectedVod(null);
      setPlayerType(null);
      setPlayerSize("maximize");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlayerOpened]);

  const setPathname = pathname => {
    if (pathname === "/") {
      setSelectedPlayer(get(props, "location.state.item.type_name"));
    } else {
      setSelectedPlayer(pathname);
    }
  };

  const recognizePlayerType = search => {
    if (search.includes("play")) {
      setPlayerType("vod");
    } else if (search.includes("trailer")) {
      setPlayerType("trailer");
    }
  };

  const renderPlayer = () => {
    if (selectedPlayer) {
      if (playerType === "vod") {
        if (selectedPlayer.includes("movie")) {
          Component = MoviePlayer;
        } else if (
          selectedPlayer.includes("tv-show") ||
          selectedPlayer.includes("tv_show") ||
          selectedPlayer.includes("episode")
        ) {
          Component = EpisodePlayer;
        }
      } else if (playerType === "trailer") {
        Component = TrailerPlayer;
      }

      if (isPlayerOpened) {
        return createPortal(<Component />, wrapperElement);
      }
    }
  };

  return (
    <Fragment>
      {props.children}
      {renderPlayer()}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  floatingPlayer: state.floatingPlayer
});

const mapDispatchToProps = dispatch => ({
  setPlayerSize: data => dispatch(floatingPlayerActions.setPlayerSize(data)),
  setOpenPlayer: data => dispatch(floatingPlayerActions.setOpenPlayer(data)),
  setSelectedPlayer: data =>
    dispatch(floatingPlayerActions.setSelectedPlayer(data)),
  setSelectedVod: data => dispatch(floatingPlayerActions.setSelectedVod(data))
});

export default withRouter(
  memo(connect(mapStateToProps, mapDispatchToProps)(FloatingPlayerHoC))
);
