export const CHANGE_LANG = "CHANGE_LANG";

const savedLang = localStorage.getItem("wecast-lang");

const lang = (state = savedLang || "en", action) => {
  switch (action.type) {
    case CHANGE_LANG:
      return action.payload;
    default:
      return state;
  }
};

export default lang;

export const changeLang = data => {
  return {
    type: CHANGE_LANG,
    payload: data
  };
};
