import React, { memo } from "react";
import PropTypes from "prop-types";
import { Motion, spring } from "react-motion";
import "./style.scss";

const TogglePlayButton = ({ toggle, onClick }) => {
  return (
    <Motion style={{ scale: spring(toggle ? 1 : 0, [300, 30]) }}>
      {({ scale }) => (
        <button type="button" className="play-pause" onClick={onClick}>
          <span
            className="play-pause__playhead"
            style={{
              transform: `scaleX(${1 - scale})`,
              WebkitTransform: `scaleX(${1 - scale})`
            }}
          />
          <span
            className="play-pause__pausehead"
            style={{
              transform: `scaleX(${scale})`,
              WebkitTransform: `scaleX(${scale})`
            }}
          />
        </button>
      )}
    </Motion>
  );
};

TogglePlayButton.propTypes = {
  toggle: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default memo(TogglePlayButton);
