import update from "immutability-helper";
import { get, uniqBy, findIndex, sortBy } from "lodash";
export const FETCH_CHANNEL = "FETCH_CHANNEL";
export const FETCH_CHANNELS = "FETCH_CHANNELS";
export const APPEND_CHANNELS = "APPEND_CHANNELS";
export const APPEND_CHANNELS_SUCCESS = "APPEND_CHANNELS_SUCCESS";
export const FETCH_CHANNELS_SUCCESS = "FETCH_CHANNELS_SUCCESS";
export const UPDATE_CHANNEL_FAVORITES = "UPDATE_CHANNEL_FAVORITES";

const initialState = {
  items: [],
  pager: null
};

const channels = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHANNELS_SUCCESS:
      return fetchChannelsSuccess(state, action);
    case APPEND_CHANNELS_SUCCESS:
      return appendChannelsSuccess(state, action);
    case UPDATE_CHANNEL_FAVORITES:
      return updateChannelFavorites(state, action);
    default:
      return state;
  }
};

const updateChannelFavorites = (state, action) => {
  const index = findIndex(
    state.items,
    item => item.id === action.payload.model_id
  );

  if (state.items[index]) {
    return update(state, {
      items: { [index]: { $toggle: ["favorite"] } }
    });
  }
  return state;
};

const appendChannelsSuccess = (state, action) => {
  const pager =
    !action.payload.ignorePagerFromResult &&
    get(action, "payload.result.pager.count") &&
    get(action, "payload.result.pager.count") !== 1
      ? get(action, "payload.result.pager")
      : state.pager;

  let channelsList = uniqBy(
    [...state.items, ...action.payload.result.items],
    "id"
  );
  channelsList = action.payload.ignoreSort
    ? channelsList
    : sortBy(channelsList, "channel_number");

  return update(state, {
    items: {
      $set: channelsList
    },
    pager: { $set: pager }
  });
};

const fetchChannelsSuccess = (state, action) => {
  if (action.payload) {
    if (Array.isArray(action.payload)) {
      let channelsList = [];
      action.payload.forEach(o => channelsList.push(...o.items));
      channelsList = uniqBy(channelsList, "id");
      channelsList = sortBy(channelsList, "channel_number");

      return {
        items: channelsList,
        pager: action.payload[0].pager
      };
    }
    return action.payload;
  }
  return state;
};

export default channels;

export const getChannels = data => ({
  type: FETCH_CHANNELS,
  payload: {
    url: data.url,
    successCallback: data.successCallback
  }
});

export const appendChannelsList = data => ({
  type: APPEND_CHANNELS,
  payload: {
    url: data.url,
    ignorePagerFromResult: data.ignorePagerFromResult,
    ignoreSort: data.ignoreSort,
    successCallback: data.successCallback
  }
});

export const getChannel = data => ({
  type: FETCH_CHANNEL,
  payload: {
    url: data.url,
    successCallback: data.successCallback
  }
});
