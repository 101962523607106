import { MOVIE_RATE_URL } from "API-routes";
import { openNotificationWithIcon } from "functions/logic-functions";

//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const rateMovie = function(rate) {
  const { rateVOD, intl } = this.props;
  const vod = this.state.movie || this.state.episode;

  if (vod && vod.id) {
    rateVOD({
      url: `${MOVIE_RATE_URL}/${vod.id}/${rate}`,
      successCallback: response => {
        if (response) {
          openNotificationWithIcon({
            type: "success",
            message: intl.formatMessage({
              id: "notifications.itemSuccessfullyRated"
            })
          });
          return;
        }
        openNotificationWithIcon({
          type: "error",
          message: intl.formatMessage({
            id: "notifications.itemAlreadyRated"
          })
        });
      }
    });
  }
};

export default rateMovie;
