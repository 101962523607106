import { fork, takeLatest, takeEvery } from "redux-saga/effects";
import {
  DO_LOGIN,
  CHECK_AUTHORIZATION,
  GET_MOVIES_NEW_RELEASES,
  GET_SINGLE_MOVIE,
  FETCH_MOVIE_STREAM,
  ADD_TO_FAVOURITES,
  ADD_REMINDER,
  RENT_CHANNEL,
  GET_MVOD_LIST,
  GET_SVOD_LIST,
  RENT_VOD,
  RATE_VOD,
  GET_SUBSCRIPTIONS_ANONYMOUS,
  REGISTER_USER,
  UPDATE_SUBSCRIPTION,
  UPDATE_PROFILE,
  CREATE_PROFILE,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  GET_LANGUAGES,
  GET_VIDEO_CAMPAIGN,
  GET_BANNER,
  DELETE_REMINDER,
  EDIT_REMINDER
} from "./actions/events";
import { GET_CONTINUE_WATCHING_VODS } from "./reducers/cachedContinueWatchingList";
import { GET_HOMEPAGE_HORIZONTAL_LIST } from "./reducers/cachedHorizontalHomepage";
import { HIGHLIGHTED_CONTENT } from "./reducers/highlightedContent";
import { GET_REMINDERS } from "./reducers/remindersList";
import { GET_VOD_GENRES } from "./reducers/genres";
import { GET_SUBSCRIPTIONS } from "./reducers/subscriptions";
import { ADD_VOD_PROFILE } from "./reducers/vodProfile";
import { GET_SEASON_EPISODES } from "./reducers/cachedSingleTvShowsCarousels";
import { GET_COMPOSER_CONFIG } from "./reducers/composerConfig";
import { GET_AVAILABLE_VOD_PROFILES } from "./reducers/vodProfilesList";
import { GET_SIMILAR_MOVIES } from "./reducers/cachedSingleMoviesCarousels";
import { GET_CHANNEL_CATEGORIES } from "./reducers/channelCategories";
import { GET_EPGS_SUCCESS } from "./reducers/EPG";
import { GET_STATIC_PAGES } from "./reducers/staticPages";
import {
  FETCH_CHANNEL,
  FETCH_CHANNELS,
  APPEND_CHANNELS
} from "./reducers/channels";
import { GET_DEVICES, REMOVE_DEVICE } from "./reducers/devices";
import {
  GET_PAYMENT_HISTORY,
  APPEND_PAYMENT_HISTORY
} from "./reducers/paymentHistory";
import {
  GET_TEXT_SEARCH_FILTER_VODS,
  APPEND_TEXT_FILTER_SEARCH_RESULTS
} from "./reducers/textSearchVODS";
import { CHECK_SUBSCRIPTION } from "./reducers/loggedUser";
import { GET_VOD_SHOW_TYPES } from "./reducers/vodShowTypes";
import sagaHandlers from "./sagaHandlers";

const {
  authSaga,
  loginSaga,
  forgotPasswordHandler,
  resetPasswordHandler,
  getRecommended,
  getSingleMovie,
  getMovieStream,
  getChannels,
  getChannelEPG,
  addOrRemoveFavouriteChannel,
  addReminder,
  rentChannel,
  getChannelCategories,
  getShowsGenres,
  getVodShowTypes,
  getMvodList,
  getSvodList,
  getSimilarMovies,
  rentVOD,
  addVodProfile,
  rateVOD,
  getPaymentHistory,
  appendPaymentHistory,
  getSubscriptions,
  getSubscriptionsAnonymous,
  checkSubscription,
  updateSubscription,
  getHighlightedContent,
  getDevices,
  removeDevice,
  appendTextFilterSearchResults,
  getTextSearchFilterVods,
  updateProfile,
  createProfile,
  registerUser,
  getHomepageHorizontalList,
  getSeasonEpisodes,
  getStaticPages,
  getContinueWatchingVods,
  getComposerConfig,
  getAvailableVodProfiles,
  getReminders,
  getAppendChannels,
  getLanguages,
  getVideoCampaign,
  getSingleChannel,
  getBanner,
  deleteReminder,
  editReminder
} = sagaHandlers;

function* watchLoginSaga() {
  yield takeLatest(DO_LOGIN, loginSaga);
}

function* watchAuthCheck() {
  yield takeLatest(CHECK_AUTHORIZATION, authSaga);
}

function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordHandler);
}

function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPasswordHandler);
}

function* watchRecommended() {
  yield takeLatest(GET_MOVIES_NEW_RELEASES, getRecommended);
}

function* watchSingleMovie() {
  yield takeLatest(GET_SINGLE_MOVIE, getSingleMovie);
}

function* watchMovieStream() {
  yield takeLatest(FETCH_MOVIE_STREAM, getMovieStream);
}

function* watchChannels() {
  yield takeLatest(FETCH_CHANNELS, getChannels);
}

function* watchChannelEPG() {
  yield takeLatest(GET_EPGS_SUCCESS, getChannelEPG);
}

function* watchAddFavouriteChannel() {
  yield takeLatest(ADD_TO_FAVOURITES, addOrRemoveFavouriteChannel);
}

function* watchAddReminder() {
  yield takeLatest(ADD_REMINDER, addReminder);
}

function* watchRentChannel() {
  yield takeLatest(RENT_CHANNEL, rentChannel);
}

function* watchGetChannelCategories() {
  yield takeLatest(GET_CHANNEL_CATEGORIES, getChannelCategories);
}

function* watchGetShowsGenres() {
  yield takeLatest(GET_VOD_GENRES, getShowsGenres);
}

function* watchGetVodShowTypes() {
  yield takeLatest(GET_VOD_SHOW_TYPES, getVodShowTypes);
}

function* watchGetMvodList() {
  yield takeEvery(GET_MVOD_LIST, getMvodList);
}

function* watchGetSvodList() {
  yield takeEvery(GET_SVOD_LIST, getSvodList);
}

function* watchRentVOD() {
  yield takeLatest(RENT_VOD, rentVOD);
}

function* watchAddVodProfile() {
  yield takeLatest(ADD_VOD_PROFILE, addVodProfile);
}

function* watchRateVOD() {
  yield takeLatest(RATE_VOD, rateVOD);
}

function* watchGetSimilarMovies() {
  yield takeLatest(GET_SIMILAR_MOVIES, getSimilarMovies);
}

function* watchGetPaymentHistory() {
  yield takeLatest(GET_PAYMENT_HISTORY, getPaymentHistory);
}

function* watchAppendPaymentHistory() {
  yield takeLatest(APPEND_PAYMENT_HISTORY, appendPaymentHistory);
}

function* watchGetSubscriptions() {
  yield takeLatest(GET_SUBSCRIPTIONS, getSubscriptions);
}

function* watchGetDevices() {
  yield takeLatest(GET_DEVICES, getDevices);
}
function* watchRemoveDevice() {
  yield takeLatest(REMOVE_DEVICE, removeDevice);
}

function* watchUpdateSubscription() {
  yield takeLatest(UPDATE_SUBSCRIPTION, updateSubscription);
}

function* watchUpdateProfile() {
  yield takeLatest(UPDATE_PROFILE, updateProfile);
}

function* watchCreateProfile() {
  yield takeLatest(CREATE_PROFILE, createProfile);
}

function* watchRegisterUser() {
  yield takeLatest(REGISTER_USER, registerUser);
}

function* watchGetSubscriptionsAnonymous() {
  yield takeLatest(GET_SUBSCRIPTIONS_ANONYMOUS, getSubscriptionsAnonymous);
}

function* watchGetHighlightedContent() {
  yield takeLatest(HIGHLIGHTED_CONTENT, getHighlightedContent);
}

function* watchAppendTextFilterSearchResults() {
  yield takeLatest(
    APPEND_TEXT_FILTER_SEARCH_RESULTS,
    appendTextFilterSearchResults
  );
}

function* watchGetTextSearchFilterVods() {
  yield takeLatest(GET_TEXT_SEARCH_FILTER_VODS, getTextSearchFilterVods);
}

function* watchGetStaticPages() {
  yield takeLatest(GET_STATIC_PAGES, getStaticPages);
}

function* watchGetSeasonEpisodes() {
  yield takeLatest(GET_SEASON_EPISODES, getSeasonEpisodes);
}

function* watchGetHomepageHorizontalList() {
  yield takeEvery(GET_HOMEPAGE_HORIZONTAL_LIST, getHomepageHorizontalList);
}

function* watchGetContinueWatchingVods() {
  yield takeEvery(GET_CONTINUE_WATCHING_VODS, getContinueWatchingVods);
}

function* watchGetComposerConfig() {
  yield takeEvery(GET_COMPOSER_CONFIG, getComposerConfig);
}

function* watchGetReminders() {
  yield takeEvery(GET_REMINDERS, getReminders);
}

function* watchGetAvailableVodProfiles() {
  yield takeEvery(GET_AVAILABLE_VOD_PROFILES, getAvailableVodProfiles);
}

function* watchGetAppendChannels() {
  yield takeEvery(APPEND_CHANNELS, getAppendChannels);
}

function* watchCheckSubscription() {
  yield takeEvery(CHECK_SUBSCRIPTION, checkSubscription);
}

function* watchGetLanguages() {
  yield takeEvery(GET_LANGUAGES, getLanguages);
}

function* watchGetVideoCampaign() {
  yield takeEvery(GET_VIDEO_CAMPAIGN, getVideoCampaign);
}

function* watchGetSingleChannel() {
  yield takeEvery(FETCH_CHANNEL, getSingleChannel);
}

function* watchGetBanner() {
  yield takeEvery(GET_BANNER, getBanner);
}

function* watchDeleteReminder() {
  yield takeEvery(DELETE_REMINDER, deleteReminder);
}

function* watchEditReminder() {
  yield takeEvery(EDIT_REMINDER, editReminder);
}

export default function* rootSaga() {
  yield [
    fork(watchLoginSaga),
    fork(watchAuthCheck),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchRecommended),
    fork(watchSingleMovie),
    fork(watchMovieStream),
    fork(watchChannels),
    fork(watchChannelEPG),
    fork(watchAddFavouriteChannel),
    fork(watchAddReminder),
    fork(watchRentChannel),
    fork(watchGetChannelCategories),
    fork(watchGetShowsGenres),
    fork(watchGetVodShowTypes),
    fork(watchGetMvodList),
    fork(watchRentVOD),
    fork(watchAddVodProfile),
    fork(watchRateVOD),
    fork(watchGetPaymentHistory),
    fork(watchAppendPaymentHistory),
    fork(watchGetSubscriptions),
    fork(watchGetDevices),
    fork(watchRemoveDevice),
    fork(watchUpdateSubscription),
    fork(watchUpdateProfile),
    fork(watchCreateProfile),
    fork(watchRegisterUser),
    fork(watchGetSubscriptionsAnonymous),
    fork(watchGetSubscriptionsAnonymous),
    fork(watchGetSvodList),
    fork(watchGetSimilarMovies),
    fork(watchGetHighlightedContent),
    fork(watchGetTextSearchFilterVods),
    fork(watchAppendTextFilterSearchResults),
    fork(watchGetSeasonEpisodes),
    fork(watchGetStaticPages),
    fork(watchGetHomepageHorizontalList),
    fork(watchGetContinueWatchingVods),
    fork(watchGetComposerConfig),
    fork(watchGetReminders),
    fork(watchGetAvailableVodProfiles),
    fork(watchGetAppendChannels),
    fork(watchCheckSubscription),
    fork(watchGetLanguages),
    fork(watchGetVideoCampaign),
    fork(watchGetSingleChannel),
    fork(watchGetBanner),
    fork(watchDeleteReminder),
    fork(watchEditReminder)
  ];
}
