import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "reduxStore/reducers/index";
import ComposerFetchHOC from "HOCs/ComposerFetchHOC/ComposerFetchHOC";
import InitialApiRequestsHOC from "HOCs/InitialApiRequestsHOC";
import RemindersLogicWrapper from "HOCs/RemindersLogicWrapper";
import SocketHOC from "HOCs/SocketHOC";
import Routes from "./Routes";
import rootSaga from "reduxStore/sagas";
import config from "./config";
import firebase from "firebase/app";
import "firebase/firebase-analytics";
import "./styles/style.scss";

firebase.initializeApp(config.firebaseConfig);
firebase.analytics();

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(rootSaga);

const App = () => (
  <Provider store={store}>
    <ComposerFetchHOC>
      <SocketHOC>
        <InitialApiRequestsHOC>
          <RemindersLogicWrapper>
            <Routes />
          </RemindersLogicWrapper>
        </InitialApiRequestsHOC>
      </SocketHOC>
    </ComposerFetchHOC>
  </Provider>
);

export default App;
