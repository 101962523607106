import React, { Fragment, memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router";
import { NavLink, Link } from "react-router-dom";
import Popover from "antd/lib/popover";
import { FormattedMessage } from "react-intl";
import { ReactComponent as AvatarIconMale } from "./icons/avatar-male.svg";
import { ReactComponent as AvatarIconFemale } from "./icons/avatar-female.svg";
import SearchIcon from "react-icons/lib/md/search";
import IconHoC from "HOCs/IconHoC/IconHoC";
import NavLinkComponent from "../../../components/NavLinkComponent";
import LiveTvPopover from "../../../components/LiveTvPopover";
import SearchTextComponent from "../../../../SearchTextComponent/SearchTextComponent";
import { BASE_URL } from "API-routes";
import style from "./style.module.scss";

const { bool, func, object } = PropTypes;

const NavbarView = ({
  intl,
  openFilter,
  visible,
  loggedUser,
  composerConfig: {
    web: {
      logos,
      modules: { vod, channels, epg, trending, home }
    }
  },
  onLogout,
  toggleSearch,
  onShowMobileNav,
  onHideNav,
  handleVisibleChangePopover,
  handleHidePopover
}) => {
  const isSomeModuleAvailable = vod || channels || epg || trending || home;

  const _renderSettingsPopover = () => {
    const {
      account: { subscription_expired },
      profile: { sex: gender }
    } = loggedUser;
    const Avatar = IconHoC(gender ? AvatarIconFemale : AvatarIconMale);

    return (
      <Popover
        placement="bottomRight"
        content={
          <ul className={style.dropDown}>
            <NavLink to="/account/info" activeClassName={style.activeNav}>
              <span>
                <FormattedMessage id="common.myAccount" />
              </span>
            </NavLink>
            <p onClick={onLogout}>
              <span>
                <FormattedMessage id="common.logout" />
              </span>
            </p>
          </ul>
        }
        trigger="click"
      >
        <div
          className={classNames(style.avatarIcon, {
            [style.expired]: subscription_expired
          })}
        >
          <Avatar />
        </div>
      </Popover>
    );
  };

  const _renderSearchComponent = () => {
    return (
      <div
        className={classNames({
          activeFilter: openFilter
        })}
      >
        <div
          className={classNames({
            [style.searchIcon]: !openFilter,
            openFilter: openFilter
          })}
        >
          <SearchIcon onClick={toggleSearch} />
        </div>
        {openFilter && (
          <SearchTextComponent onHideNav={onHideNav} intl={intl} />
        )}
      </div>
    );
  };

  const _renderNavLinks = () => {
    return (
      <Fragment>
        {vod && home && (
          <Fragment>
            <NavLinkComponent to={"/movies"} translationKey={"common.movies"} />
            <NavLinkComponent
              to={"/tv-shows"}
              translationKey={"common.tvShows"}
            />
          </Fragment>
        )}
        {trending && home && (
          <NavLinkComponent
            to={"/trending"}
            translationKey={"common.trending"}
          />
        )}
      </Fragment>
    );
  };

  const _renderLiveTvPopover = () => {
    if (vod) {
      return (
        <LiveTvPopover
          epg={epg}
          channels={channels}
          visible={visible}
          onVisibleChange={handleVisibleChangePopover}
          onHandleHidePopover={handleHidePopover}
        />
      );
    } else {
      return _renderIndividualLinks();
    }
  };

  const _renderIndividualLinks = () => {
    return (
      <>
        {channels && (
          <NavLinkComponent
            exact={true}
            to={"/channel-categories"}
            translationKey={"common.liveTv"}
          />
        )}
        {epg && (
          <NavLinkComponent
            exact={true}
            to={"/epg-channels"}
            translationKey={"common.tvGuide"}
          />
        )}
      </>
    );
  };

  const _renderLogo = () => {
    return (
      <div>
        <Link to={"/"}>
          {logos && (
            <img
              src={BASE_URL + "/" + logos.small}
              className={style.logoImg}
              alt={""}
            />
          )}
        </Link>
      </div>
    );
  };

  const _renderHamburgerButton = () => {
    return (
      <div className={classNames(style.hamburgerButton, "themeHamburger")}>
        <button
          onClick={onShowMobileNav}
          className={`hamburger hamburger--slider`}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
    );
  };

  return (
    <div className={classNames(style.navbarWrapper, "nav")}>
      <div>
        {_renderLogo()}
        {_renderHamburgerButton()}
        <div className={style.urlsWrapper}>
          {(epg || channels) && _renderLiveTvPopover()}
          {_renderNavLinks()}
          {isSomeModuleAvailable && _renderSearchComponent()}
          {_renderSettingsPopover()}
        </div>
      </div>
    </div>
  );
};

NavbarView.propTypes = {
  intl: object.isRequired,
  openFilter: bool.isRequired,
  visible: bool.isRequired,
  loggedUser: object.isRequired,
  composerConfig: object.isRequired,
  onLogout: func.isRequired,
  toggleSearch: func.isRequired,
  onShowMobileNav: func,
  onHideNav: func,
  handleVisibleChangePopover: func.isRequired,
  handleHidePopover: func.isRequired
};

export default withRouter(memo(NavbarView));
