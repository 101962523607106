import { get } from "lodash";

const isCatchupAllowed = (channel, epgState, currentProgram) => {
  if (
    epgState &&
    get(channel, "catchup_duration_total") &&
    (get(currentProgram, "start_timestamp") || get(currentProgram, "start"))
  ) {
    const { catchup_duration_total } = channel;
    const { start_timestamp, start } = currentProgram;
    const startTime = start_timestamp || start;

    return !!(
      channel.catchup_enabled &&
      epgState === "past" &&
      startTime * 1000 >=
        new Date().getTime() - catchup_duration_total * 1000 * 60
    );
  }
  return;
};

export default isCatchupAllowed;
