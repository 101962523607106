import { uniqBy } from "lodash";

export const GET_PAYMENT_HISTORY = "GET_PAYMENT_HISTORY";
export const GET_PAYMENT_HISTORY_SUCCESS = "GET_PAYMENT_HISTORY_SUCCESS";
export const APPEND_PAYMENT_HISTORY = "APPEND_PAYMENT_HISTORY";
export const APPEND_PAYMENT_HISTORY_SUCCESS = "APPEND_PAYMENT_HISTORY_SUCCESS";

const paymentHistory = (state = [], action) => {
  switch (action.type) {
    case GET_PAYMENT_HISTORY_SUCCESS:
      if (action.payload && action.payload.items) return action.payload.items;
      return state;
    case APPEND_PAYMENT_HISTORY_SUCCESS:
      return uniqBy([...state, ...action.payload.items], "id");
    default:
      return state;
  }
};

export default paymentHistory;

export const getPaymentHistory = data => {
  return {
    type: GET_PAYMENT_HISTORY,
    payload: {
      url: data.url
    }
  };
};

export const appendPaymentHistory = data => {
  return {
    type: APPEND_PAYMENT_HISTORY,
    payload: {
      url: data.url
    }
  };
};
