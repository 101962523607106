import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Col } from "react-flexbox-grid";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { get, find } from "lodash";
import GridCard from "components/Cards/GridCard";
import ChannelCard from "../Cards/ChannelCard";
import { areImagesTheSame, isCensorshipCard } from "functions/logic-functions";
import style from "./style.module.scss";
import NoContentComponent from "components/NoContentComponent";
import MdTv from "react-icons/lib/md/tv";
import { FormattedMessage } from "react-intl";

class VodsGridList extends PureComponent {
  _renderGridCard = item => {
    const {
      urlRoute,
      useExternalRoute,
      cachedContinueWatchingList,
      intl
    } = this.props;
    const continueWatching = find(
      cachedContinueWatchingList,
      o => item.id === o.id
    );

    return (
      <GridCard
        item={item}
        id={item.id}
        intl={intl}
        urlRoute={(!useExternalRoute && urlRoute) || item.urlRoute}
        title={item.title}
        genres={(item.genres && item.genres.map(genre => genre.name)) || []}
        rating={item.rating}
        attachUrlID={true}
        imgUrl={
          (item.covers && item.covers[0].medium_ar) ||
          (item.covers && item.covers[0].small_ar) ||
          get(item, "cover.medium_ar") ||
          get(item, "cover.small_ar") ||
          (item.logo_url && item.logo_url)
        }
        continueWatching={
          continueWatching && continueWatching.continue_watching
        }
      />
    );
  };

  _renderChannelCard = item => {
    const { urlRoute, useExternalRoute } = this.props;

    return (
      <ChannelCard
        id={item.id}
        title={item.title}
        item={item}
        urlRoute={(!useExternalRoute && urlRoute) || item.urlRoute}
        useObjectContainPropertyImage={areImagesTheSame(
          item.logo_url,
          item.screenshot_url
        )}
        imgUrl={
          isCensorshipCard(item)
            ? item.logo_url
            : (item.covers && item.covers[0].medium_ar) ||
              (item.covers && item.covers[0].small_ar) ||
              get(item, "cover.medium_ar") ||
              get(item, "cover.small_ar") ||
              item.screenshot_url ||
              item.logo_url
        }
      />
    );
  };

  _renderElementsMap = item => {
    const { channelsWithTitle } = this.props;

    return (
      <Col
        key={
          item.channel_number
            ? `${item.channel_number}${item.id}`
            : `${item.id}${item.title && item.title}`
        }
        xl={2}
        lg={4}
        md={4}
        sm={6}
        xs={6}
        className={style.animatedCard}
      >
        {channelsWithTitle
          ? this._renderChannelCard(item)
          : this._renderGridCard(item)}
      </Col>
    );
  };

  render() {
    const { list, children } = this.props;
    const items = list.items || (list.length > 0 && list) || [];

    if (!items.length) {
      return (
        <NoContentComponent
          noContentIcon={<MdTv />}
          noContentText={
            <FormattedMessage id="common.liveTvContentUnavailable" />
          }
        />
      );
    }

    // Expect for children to be ActiveBanner component and it adds banner between cards
    if (children) {
      return (
        <div className={"gridListWrapper"}>
          <div className={style.listWrapper}>
            {items
              .filter((item, index) => index < 6)
              .map(this._renderElementsMap)}
          </div>
          {children}
          <div className={style.listWrapper}>
            {items
              .filter((item, index) => index >= 6)
              .map(this._renderElementsMap)}
          </div>
        </div>
      );
    }

    return (
      <div className={"gridListWrapper"}>
        <div className={style.listWrapper}>
          {items.map(this._renderElementsMap)}
        </div>
      </div>
    );
  }
}

VodsGridList.propTypes = {
  item: PropTypes.object,
  intl: PropTypes.object.isRequired,
  list: PropTypes.oneOfType([
    PropTypes.shape({
      items: PropTypes.array
    }),
    PropTypes.array
  ]),
  cachedContinueWatchingList: PropTypes.array,
  urlRoute: PropTypes.string,
  useExternalRoute: PropTypes.bool, // IF TRUE, IT WILL IGNORE urlRoute PARAM, AND IT WILL LOOK FOR urlRoute IN item IN EVERY MAP LOOP
  channelsWithTitle: PropTypes.bool
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  cachedContinueWatchingList: state.cachedContinueWatchingList
});

export default withRouter(connect(mapStateToProps)(VodsGridList));
