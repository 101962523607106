import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Slider from "react-slick";
import CarouselVerticalCard from "components/Cards/CarouselVerticalCard";
import { SINGLE_VOD } from "API-routes";
import { getMoviesNewReleases } from "reduxStore/actions";
import { generateQuery } from "functions/logic-functions";
import { CarouselWrapper } from "./styledComponents";
import Arrow from "../components/Arrow";
import style from "./style.module.scss";

class FlexibleCarousel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      movies: [],
      showLoader: false
    };
  }

  componentDidMount() {
    const { getMoviesNewReleasesDispatch, query, items } = this.props;

    if (!items || !items.length) {
      getMoviesNewReleasesDispatch({
        url: `${SINGLE_VOD}${generateQuery(query)}`,
        successCallback: this.successCallback
      });
    }
  }

  successCallback = data => {
    if (data && data.length) {
      this.setState({
        movies: [...data]
      });
    }
  };

  renderMovies = () => {
    const { movies } = this.state;
    const { urlRoute, items, intl } = this.props;

    return (
      (movies &&
        movies.length > 0 &&
        movies.map(item => {
          return (
            <div key={item.id}>
              <CarouselVerticalCard
                item={item}
                intl={intl}
                id={item.id}
                title={item.title}
                attachUrlID={true}
                genres={
                  (item.genres && item.genres.map(genre => genre.name)) || []
                }
                rating={item.rating}
                imgUrl={
                  (item.cover && item.cover.medium_ar) ||
                  (item.covers && item.covers[0].medium_ar)
                }
                urlRoute={urlRoute}
              />
            </div>
          );
        })) ||
      (items &&
        items.length > 0 &&
        items.map(item => {
          return (
            <div key={item.id}>
              <CarouselVerticalCard
                item={item}
                intl={intl}
                id={item.id}
                title={item.title}
                attachUrlID={true}
                genres={
                  (item.genres && item.genres.map(genre => genre.name)) || []
                }
                rating={item.rating}
                imgUrl={
                  (item.cover && item.cover.medium_ar) ||
                  (item.covers && item.covers[0].medium_ar)
                }
                urlRoute={urlRoute}
              />
            </div>
          );
        }))
    );
  };

  render() {
    const { title, useInfiniteScroll } = this.props;
    const settings = {
      infinite: useInfiniteScroll,
      speed: 500,
      lazyLoad: true,
      slidesToShow: 8,
      slidesToScroll: 8,
      initialSlide: 0,
      arrows: true,
      nextArrow: (
        <Arrow icon="right" hasInfiniteScroll={useInfiniteScroll || false} />
      ),
      prevArrow: (
        <Arrow icon="left" hasInfiniteScroll={useInfiniteScroll || false} />
      ),
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1399,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            initialSlide: 0
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0
          }
        },
        {
          breakpoint: 479,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0
          }
        }
      ]
    };

    return (
      <div className={style.container}>
        <CarouselWrapper>
          {title && <h2>{title}</h2>}
          <Slider {...settings}>{this.renderMovies()}</Slider>
        </CarouselWrapper>
      </div>
    );
  }
}

FlexibleCarousel.propTypes = {
  intl: propTypes.object.isRequired,
  title: propTypes.string,
  urlRoute: propTypes.string.isRequired,
  items: propTypes.array,
  useInfiniteScroll: propTypes.bool
};

const matchDispatchToProps = dispatch => ({
  getMoviesNewReleasesDispatch: data => dispatch(getMoviesNewReleases(data))
});

const mapStateToProps = state => ({
  cachedHomepage: state.cachedHomepage
});

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(FlexibleCarousel)
);
