import { uniqBy } from "lodash";
import update from "immutability-helper";
export const GET_SEASON_EPISODES = "GET_SEASON_EPISODES";
export const GET_SEASON_EPISODES_SUCCESS = "GET_SEASON_EPISODES_SUCCESS";
export const UPDATE_SEASON_RENTED_EPISODE = "UPDATE_SEASON_RENTED_EPISODE";
export const SET_NEW_SEASON_IN_CAROUSEL = "SET_NEW_SEASON_IN_CAROUSEL";

const cachedSingleTvShowsCarousels = (state = [], action) => {
  switch (action.type) {
    case GET_SEASON_EPISODES_SUCCESS:
      if (action.payload && action.payload.id)
        return uniqBy([...state, action.payload], "id");
      return state;
    case UPDATE_SEASON_RENTED_EPISODE:
      return updateRentedEpisode(state, action);
    case SET_NEW_SEASON_IN_CAROUSEL:
      return setNewSeason(state, action);
    default:
      return state;
  }
};

export default cachedSingleTvShowsCarousels;

export const getSeasonEpisodes = data => {
  return {
    type: GET_SEASON_EPISODES,
    payload: {
      url: data.url,
      id: data.id,
      name: data.name
    }
  };
};

const setNewSeason = (state, action) => {
  if (action.payload) {
    const obj = {
      id: action.payload.items[0].multi_event_vod_season_id,
      items: action.payload.items
    };

    return uniqBy([...state, obj], "id");
  }
  return state;
};

const updateRentedEpisode = (state, action) => {
  if (action.payload && state.length) {
    const {
      id,
      subscribed,
      multi_event_vod_season_id,
      movie_source: { profiles }
    } = action.payload;
    const carouselIndex = state.findIndex(
      o => o.id === multi_event_vod_season_id
    );

    if (carouselIndex !== -1 && state.length) {
      const episodeIndex = state[carouselIndex].items.findIndex(
        o => o.id === id
      );

      if (episodeIndex !== -1) {
        return update(state, {
          [carouselIndex]: {
            items: {
              [episodeIndex]: {
                subscribed: { $set: subscribed },
                movie_source: {
                  profiles: { $set: profiles }
                }
              }
            }
          }
        });
      }
    }
    return state;
  }
  return state;
};
