import React from "react";
import style from "../style.module.scss";
import classNames from "classnames";

const generateProgressBar = (continue_watching, customStyle = "") => {
  if (continue_watching) {
    const { duration, stopped_time } = continue_watching;

    if (duration && stopped_time) {
      return (
        <div
          className={classNames({
            [style.progressWrapper]: style.progressWrapper,
            [customStyle]: customStyle
          })}
        >
          <span
            style={{
              width: `${(stopped_time / duration) * 100}%`
            }}
          />
        </div>
      );
    } else {
      return <div className={style.emptyProgressWrapper} />;
    }
  }
};

export default generateProgressBar;
