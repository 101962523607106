import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Row, Col, Grid } from "react-flexbox-grid";
import RegisterFormDetails from "components/Forms/RegisterFormDetails/RegisterFormDetails";
import RegisterFormPackage from "components/Forms/RegisterFormPackage/RegisterFormPackage";
import RegisterFormCreditCard from "components/Forms/RegisterFormCreditCard/RegisterFormCreditCard";
import { registerUser, getSubscriptionsAnonymous } from "reduxStore/actions";
import { REGISTER_URL, GET_SUBSCRIPTIONS_URL, BASE_URL } from "API-routes";
import { injectIntl } from "react-intl";

import style from "./style.module.scss";

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      showLoader: false,
      formNumb: 0,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      purchasePin: "",
      parentalPin: "",
      age: "",
      gender: "",
      packageID: null,
      cardFirstName: "",
      cardLastName: "",
      creditCardNumber: null,
      expDate: "",
      securityNumberCVV: null,
      paymentType: null,

      subscriptions: []
    };
  }

  componentDidMount() {
    const { subscriptions } = this.state;
    const { getSubscriptionsAnonymousDispatch } = this.props;

    if (!subscriptions.length) {
      getSubscriptionsAnonymousDispatch({
        url: GET_SUBSCRIPTIONS_URL,
        successCallback: response => {
          if (response.length) {
            this.setState({
              subscriptions: response
            });
          }
        }
      });
    }
  }

  updateFormValues = (data, saveEvent) => {
    this.setState(
      {
        ...this.state,
        ...data
      },
      () => {
        if (saveEvent) saveEvent();
      }
    );
  };

  changeFormStep = type => {
    this.setState({
      formNumb:
        type === "next" ? this.state.formNumb + 1 : this.state.formNumb - 1
    });
  };

  onSave = () => {
    const { registerUserDispatch } = this.props;
    const {
      firstName: first_name,
      lastName: last_name,
      email,
      password,
      confirmPassword: confirm_password,
      packageID: subscription_id,
      parentalPin: pin,
      purchasePin: purchase_pin,
      age,
      gender
    } = this.state;

    if (first_name && last_name && email && password && subscription_id) {
      this.setState(
        {
          showLoader: true
        },
        () => {
          registerUserDispatch({
            url: REGISTER_URL,
            body: {
              first_name,
              last_name,
              email,
              password,
              confirm_password,
              subscription_id,
              age,
              pin,
              purchase_pin,
              sex: gender === "true" ? 1 : 0
            },
            successCallback: this.successCallback
          });
        }
      );
    }
  };

  successCallback = result => {
    const { data } = result;

    if (!data) {
      this.setState({
        showLoader: false
      });
      return;
    }

    if (result && result.status !== 0) {
      this.setState(
        {
          showLoader: false
        },
        () => {
          this.props.history.push("/success");
        }
      );
    } else if (result.data) {
      let errorMessage = "";

      if (result.data.email && result.data.email.length)
        errorMessage += result.data.email[0];
      if (result.data.password && result.data.password.length)
        errorMessage += result.data.password[0];
      this.setState({
        errorMessage: errorMessage,
        showLoader: false
      });
    }
  };

  goBack = () => {
    const { formNumb } = this.state;

    if (formNumb > 0) {
      this.setState({
        formNumb: formNumb - 1,
        errorMessage: null,
        showLoader: false
      });
    } else this.props.history.goBack();
  };

  renderForms = () => {
    const { formNumb, subscriptions, showLoader, errorMessage } = this.state;
    const { intl } = this.props;

    switch (formNumb) {
      case 0:
        return (
          <RegisterFormDetails
            updateFormValues={this.updateFormValues}
            changeFormStep={this.changeFormStep}
            intl={intl}
          />
        );
      case 1:
        return (
          <RegisterFormPackage
            updateFormValues={this.updateFormValues}
            changeFormStep={this.changeFormStep}
            subscriptions={subscriptions}
            intl={intl}
          />
        );
      case 2:
        return (
          <RegisterFormCreditCard
            showLoader={showLoader}
            updateFormValues={this.updateFormValues}
            changeFormStep={this.changeFormStep}
            onSave={this.onSave}
            errorMessage={errorMessage}
            intl={intl}
          />
        );
      default:
        break;
    }
  };

  render() {
    const { intl } = this.props;

    return (
      <Grid className={style.mainWrapper}>
        <Row className={style.headerWrapper}>
          {this.props.composerConfig &&
            this.props.composerConfig.web &&
            this.props.composerConfig.web.logos && (
              <img
                src={BASE_URL + "/" + this.props.composerConfig.web.logos.small}
                className={style.logoImg}
                alt={""}
              />
            )}
          <div className={style.headerRightLinks}>
            <Link to={"/login"} style={{ marginRight: 15 }}>
              {intl.formatMessage({
                id: "common.login"
              })}
            </Link>
            <Link to={"/register"}>
              {intl.formatMessage({
                id: "common.register"
              })}
            </Link>
          </div>
        </Row>
        <Row>
          <Col lg={7} className={style.leftBlock}>
            {this.props.composerConfig &&
              this.props.composerConfig.web &&
              this.props.composerConfig.web.logos && (
                <img
                  src={
                    BASE_URL + "/" + this.props.composerConfig.web.logos.medium
                  }
                  className={style.mainImg}
                  alt={""}
                />
              )}
            <h1>
              {intl.formatMessage({
                id: "common.register"
              })}
            </h1>
            <h4>
              <FormattedMessage id="account.createAccountAndEnjoy" />
            </h4>
          </Col>
          <Col lg={4} lgOffset={1} className={style.rightBlock}>
            <h1>
              {intl.formatMessage({
                id: "common.register"
              })}
            </h1>
            <h4>
              <FormattedMessage id="account.createAccountAndEnjoy" />
            </h4>
            {this.renderForms()}
            <div className={style.bottomLinks}>
              <span onClick={this.goBack}>
                <FormattedMessage id="account.goBack" />
              </span>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return {
    registerUserDispatch: data => dispatch(registerUser(data)),
    getSubscriptionsAnonymousDispatch: data =>
      dispatch(getSubscriptionsAnonymous(data))
  };
}

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser,
    composerConfig: state.composerConfig
  };
}

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(injectIntl(RegisterPage))
);
