import { get } from "lodash";

//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onResume = function() {
  if (get(this.continueWatchVOD, "continue_watching.stopped_time")) {
    this.setState(
      {
        openStartResumeModal: false,
        playing: true,
        progress: this.continueWatchVOD.continue_watching.stopped_time
      },
      () => {
        if (this.player)
          this.player.seekTo(
            this.continueWatchVOD.continue_watching.stopped_time
          );
      }
    );
  }
};

export default onResume;
