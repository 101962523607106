import { put, call } from "redux-saga/effects";
import { SET_NEW_SEASON_IN_CAROUSEL } from "reduxStore/reducers/cachedSingleTvShowsCarousels";
import { fetchData } from "helper-functions/http-functions";

const getMvodList = function*({ payload }) {
  const { successCallback, updateSeasonCarouselReducer } = payload;

  try {
    const result = yield call(fetchData, payload);

    if (result) {
      if (successCallback) {
        successCallback(result);
      }

      if (updateSeasonCarouselReducer) {
        yield put({
          type: SET_NEW_SEASON_IN_CAROUSEL,
          payload: result
        });
      }
    }
  } catch (err) {
    if (successCallback) successCallback(false);
    console.info("ERR", err);
  }
};

export default getMvodList;
