import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import LibraryAddIcon from "react-icons/lib/md/library-add";
import DeleteIcon from "react-icons/lib/md/delete";
import { ModalWrapper, ModalStyled } from "./styledComponents";
import BottomControlButtons from "../components/BottomControlButtons";
import { connect } from "react-redux";
import { get } from "lodash";
import style from "./style.module.scss";

class RentOrPlayProfilesModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRent: null
    };
    this.buttonSuccess = React.createRef();
  }

  componentDidMount() {
    const { profiles } = this.props;
    const { selectedRent } = this.state;

    if (
      (get(profiles, "pricing") && profiles.pricing.length === 1) ||
      (profiles &&
        profiles.length === 1 &&
        profiles[0].pricing &&
        profiles[0].pricing.length === 1)
    ) {
      if (!selectedRent) {
        const profile = profiles[0] || profiles;

        this.onSelect({
          profile: profiles[0] || profiles,
          tvod_dtr: profile.business_model === "tvod_dtr" && profile.pricing[0]
        });
      }

      setTimeout(() => {
        this.focusOnButton(true);
      }, 150);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { open } = nextProps;

    if (!open) {
      this.setState({
        selectedRent: null
      });
    }
  }

  onSelect = ({ profile, tvod_dtr }) => {
    this.setState({
      selectedRent: { ...profile, ...tvod_dtr }
    });
  };

  renderList = () => {
    const { profiles } = this.props;
    const { selectedRent } = this.state;

    return profiles.map(item => {
      return (
        <div
          key={item.name}
          className={classNames({
            active:
              (selectedRent && item.id === selectedRent.id) ||
              (get(profiles, "pricing") && profiles.pricing.length === 1)
          })}
          onClick={() => {
            this.focusOnButton(true);
            this.onSelect({
              profile: item
            });
          }}
        >
          <div className={style.selectButton}>{item.name}</div>
          <p className={style.price}>{item.price}</p>
        </div>
      );
    });
  };

  renderSingleProfile = () => {
    const { profiles, loggedUser, intl } = this.props;
    const { selectedRent } = this.state;

    if (
      loggedUser &&
      loggedUser.currency_code &&
      profiles &&
      profiles.pricing.length
    ) {
      return profiles.pricing.map(item => {
        const businessModelText =
          profiles.business_model === "tvod_dtr"
            ? item.duration === 1
              ? intl.formatMessage({ id: "modal.day" })
              : intl.formatMessage({ id: "modal.days" })
            : "";

        return (
          <div
            key={item.price}
            className={classNames({
              active:
                (selectedRent && item.duration === selectedRent.duration) ||
                (get(profiles, "pricing") && profiles.pricing.length === 1)
            })}
            onClick={() => {
              this.focusOnButton(true);
              this.onSelect({
                profile: profiles,
                tvod_dtr: profiles.business_model === "tvod_dtr" && item
              });
            }}
          >
            <div className={style.selectButton}>
              {item.duration || profiles.name} {businessModelText}
            </div>

            <p className={style.price}>
              {loggedUser.currency_code} {item.price}
            </p>
          </div>
        );
      });
    }
  };

  _renderModalTitle = () => {
    const { removeDeviceMessage, profiles } = this.props;

    if (removeDeviceMessage) {
      return (
        <h4 className={classNames(style.textStyle, style.title)}>
          <h1 className={style.space}>
            <FormattedMessage id="modal.deleteDevice" /> ?
          </h1>
          <FormattedMessage id="homepage.areYouSureEraseDevice" />
        </h4>
      );
    } else {
      const titleMessageID =
        profiles.business_model === "tvod_dtr"
          ? "modal.chooseDaysDuration"
          : "modal.chooseTheResolution";
      return (
        <h4 className={style.title}>
          <FormattedMessage id={titleMessageID} />
        </h4>
      );
    }
  };

  focusOnButton = selectedProfileForPlay => {
    if (selectedProfileForPlay && this.buttonSuccess.current) {
      this.buttonSuccess.current.focus();
    }
  };

  _renderBottomButtons = () => {
    const {
      onClose,
      onSuccess,
      selectedProfileForPlay,
      eventType,
      successButtonTitle
    } = this.props;
    const { selectedRent } = this.state;

    return (
      <BottomControlButtons
        ref={this.buttonSuccess}
        successButtonTitle={successButtonTitle}
        closeTranslationID={"common.cancel"}
        onSuccess={() => {
          if (selectedRent || selectedProfileForPlay)
            onSuccess(eventType, selectedRent || selectedProfileForPlay);
        }}
        onClose={() => {
          onClose(eventType);
        }}
      />
    );
  };

  render() {
    const {
      open,
      onClose,
      profiles,
      eventType,
      removeIcon = false
    } = this.props;
    return (
      <ModalStyled
        dimmer={"blurring"}
        size={"tiny"}
        open={open}
        onClose={() => {
          onClose(eventType);
        }}
      >
        <ModalWrapper>
          {removeIcon ? <DeleteIcon /> : <LibraryAddIcon />}
          {this._renderModalTitle()}
          <div
            className={
              profiles && profiles.length > 2
                ? style.buttonsWrapperRentModal
                : "buttonsWrapper"
            }
          >
            {Array.isArray(profiles)
              ? this.renderList()
              : this.renderSingleProfile()}
          </div>
          {this._renderBottomButtons()}
        </ModalWrapper>
      </ModalStyled>
    );
  }
}

RentOrPlayProfilesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  profiles: PropTypes.any,
  selectedProfileForPlay: PropTypes.any,
  eventType: PropTypes.string.isRequired,
  successButtonTitle: PropTypes.any.isRequired
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
});

export default connect(mapStateToProps)(RentOrPlayProfilesModal);
