import React, { memo, useState, useEffect, createRef, useRef } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import CounterButton from "./components/CounterButon";
import style from "./style.module.scss";

const skipButtonRef = createRef();
const wrapperElement = document.querySelector("#video-root");

const AdvertisementPlayer = props => {
  const [counter, setCounter] = useState();
  const counterRef = useRef(counter);
  const {
    floatingPlayer: { playerSize },
    onClose,
    videoCampaignData,
    intl
  } = props;
  const { video_url } = videoCampaignData;
  let interval;

  useEffect(() => {
    counterRef.current = counter;
  });

  useEffect(() => {
    setCounterInterval();
    wrapperElement.addEventListener("click", _disableEvents, true);
    document.addEventListener("keyup", _setKeyboardCloseEvent);

    return () => {
      removeEventsAnyStyles();
      document.removeEventListener("keyup", _setKeyboardCloseEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (videoCampaignData && !counter) {
      setCounter(videoCampaignData.display_duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoCampaignData]);

  const setCounterInterval = () => {
    interval = setInterval(() => {
      setCounter(currentCount => {
        counterRef.current = currentCount;
        if (currentCount > 0) {
          return currentCount - 1;
        }
        clearInterval(interval);
        return currentCount;
      });
    }, 1000);
  };

  const removeEventsAnyStyles = () => {
    clearInterval(interval);
    wrapperElement.classList.remove(style.wrapper);
    wrapperElement.removeEventListener("click", _disableEvents, true);
  };

  const _setKeyboardCloseEvent = e => {
    e.stopPropagation();

    if (e.key === "Enter" && counterRef.current === 0) {
      onClose();
    }
  };

  const _onEndedOrError = () => {
    onClose();
  };

  const _disableEvents = e => {
    if (e.target !== skipButtonRef.current) {
      e.stopPropagation();
    }
  };

  return ReactDOM.createPortal(
    <div className={style.container}>
      <ReactPlayer
        width={playerSize === "minimize" ? "100%" : "100vw"}
        height={playerSize === "minimize" ? "100%" : "100vh"}
        url={video_url}
        playing
        onEnded={_onEndedOrError}
      />
      <CounterButton
        intl={intl}
        ref={skipButtonRef}
        className={classNames(style.skipButton, {
          [style.minimizedButton]: playerSize === "minimize"
        })}
        onClick={onClose}
        disabled={counter === 0}
        skipProgressNumber={counter}
      />
    </div>,
    wrapperElement
  );
};

AdvertisementPlayer.propTypes = {
  intl: PropTypes.object.isRequired,
  videoCampaignData: PropTypes.shape({
    display_duration: PropTypes.number.isRequired,
    mid_roll_time: PropTypes.number,
    video_url: PropTypes.string.isRequired
  }),
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  floatingPlayer: state.floatingPlayer
});

export default memo(withRouter(connect(mapStateToProps)(AdvertisementPlayer)));
