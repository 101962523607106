import React from "react";
import { Redirect } from "react-router-dom";

const DisableWhenLoggedHoC = CustomComponent => props => {
  const token = localStorage.getItem("wecastUser")
    ? JSON.parse(localStorage.getItem("wecastUser")).token
    : null;

  if (!token) return <CustomComponent {...props} />;
  return <Redirect to="/" />;
};

export default DisableWhenLoggedHoC;
