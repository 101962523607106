import axios from "axios";
import Fingerprint2 from "fingerprintjs2";
import { store } from "../../App";
import { get } from "lodash";
import { autoLogin, logout } from "./index";
import {
  openNotificationWithIcon,
  checkEndpointsForNotifications
} from "../logic-functions";
import { CHECK_DEVICE_TOKEN_URL } from "API-routes";
import { DISABLE_SUBSCRIPTION } from "reduxStore/reducers/loggedUser";
import { CHECK_AUTHORIZATION } from "reduxStore/actions/events";

let isLogoutCalled = false;
let timestamp = new Date().getTime();

axios.interceptors.request.use(interceptRequest);
axios.interceptors.response.use(interceptResponse, error => {
  return Promise.reject(error);
});

function interceptRequest(request) {
  let uid = localStorage.getItem("wecastUID");
  const user = JSON.parse(localStorage.getItem("wecastUser"));
  const token = user && user.token;

  if (token) request.headers.Token = token;
  request.headers.Device = "web";
  request.headers.Tenant = "general";
  request.headers["Accept-Language"] =
    localStorage.getItem("wecast-lang") || "en";
  if (!uid) {
    return new Promise(resolve => {
      new Fingerprint2().get(result => {
        localStorage.setItem("wecastUID", result);
        request.headers.UID = result;
        resolve(request);
      });
    });
  } else return request;
}

async function interceptResponse(response) {
  const {
    data: { status, message }
  } = response;

  checkEndpointsForNotifications(response);

  if (status === 2) {
    if ((new Date().getTime() - timestamp) / 1000 > 4) {
      if (message !== "OK") {
        openNotificationWithIcon({
          type: "warning",
          message: message
        });
      }

      store.dispatch({
        type: DISABLE_SUBSCRIPTION
      });

      store.dispatch({
        type: CHECK_AUTHORIZATION,
        payload: {
          url: CHECK_DEVICE_TOKEN_URL
        }
      });
    }
    timestamp = new Date().getTime();
  }

  if (get(response, "data.status") === -1) {
    if (!isLogoutCalled) {
      isLogoutCalled = true;
      localStorage.setItem("wecastRelogin", "true");
      await logout({
        ignoreDeleteCacheAndRedirect: true,
        callback: autoLogin
      });
    }
  } else {
    return response;
  }
}

export default axios;
