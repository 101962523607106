//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
export const onProgress = function(state) {
  const {
    activeVideoCampaign,
    playing,
    openVideoCampaign,
    progress,
    watchedVideoCampaigns,
    showLoader
  } = this.state;
  const obj = {};

  if (showLoader) {
    obj.showLoader = false;
  }

  if (activeVideoCampaign) {
    const { play_rules, mid_roll_time } = activeVideoCampaign;
    const isIncludedAtMiddle = play_rules.map(Number).includes(3);

    if (
      !watchedVideoCampaigns.middle &&
      isIncludedAtMiddle &&
      !openVideoCampaign &&
      (mid_roll_time === Math.round(progress) || progress > mid_roll_time)
    ) {
      obj.openVideoCampaign = true;
      obj.prevStatusPlaying = playing;
      obj.playing = false;
    }
  }

  this.setState({
    progress: state.playedSeconds,
    ...obj
  });
};

export default onProgress;
