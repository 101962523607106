import axios from "./axios";
import { LOGOUT_URL } from "../../API-routes";

export const logout = data => {
  if (localStorage.getItem("wecastUser")) {
    const { ignoreDeleteCacheAndRedirect, callback } = data || {};

    return axios.get(LOGOUT_URL).finally(async () => {
      if (!ignoreDeleteCacheAndRedirect) {
        localStorage.removeItem("wecastUser");
        localStorage.removeItem("wecastCredentials");
        document.location = "/login";
      }
      if (callback) {
        await callback();
      }
    });
  }
};

export default logout;
