export const ADD_SEARCH_TEXT = "ADD_SEARCH_TEXT";

const searchText = (state = "", action) => {
  switch (action.type) {
    case ADD_SEARCH_TEXT:
      if (action.payload) return action.payload.value;
      return "";
    default:
      return state;
  }
};

export default searchText;

export const addSearchText = data => {
  return {
    type: ADD_SEARCH_TEXT,
    payload: {
      value: data.value
    }
  };
};
