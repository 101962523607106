import React, { forwardRef, memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const CounterButton = (props, ref) => {
  const { intl, onClick, className, skipProgressNumber, disabled } = props;

  return (
    <button
      ref={ref}
      disabled={!disabled}
      className={classNames(style.button, className, {
        [style.activeToSkip]: disabled
      })}
      onClick={onClick}
    >
      {intl.formatMessage({ id: "common.skipAd" })}
      {!disabled ? (
        <span>
          {" "}
          {intl.formatMessage({ id: "common.in" })}{" "}
          <span className={style.timer}>{skipProgressNumber}s</span>
        </span>
      ) : (
        ""
      )}
    </button>
  );
};

CounterButton.propTypes = {
  intl: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  skipProgressNumber: PropTypes.number.isRequired
};

export default memo(forwardRef(CounterButton));
