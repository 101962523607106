const onEnded = function(callbackFunction) {
  const { activeVideoCampaign, subscribedEpisodes, episode } = this.state;

  if (activeVideoCampaign) {
    const { play_rules } = activeVideoCampaign;
    const isIncludedAtEnd = play_rules.map(Number).includes(2);

    if (episode && subscribedEpisodes && subscribedEpisodes.length) {
      const episodeIndex = subscribedEpisodes.findIndex(
        item => item.id === episode.id
      );

      if (episodeIndex === subscribedEpisodes.length - 1) {
        this.setState({
          openVideoCampaign: true,
          playing: false,
          prevStatusPlaying: false
        });
      } else if (callbackFunction) {
        callbackFunction(1);
      }

      return;
    }

    if (isIncludedAtEnd) {
      this.setState({
        openVideoCampaign: true,
        playing: false,
        prevStatusPlaying: false
      });
    }
  } else if (callbackFunction) {
    callbackFunction(1);
  } else if (this._onClosePlayer) {
    this._onClosePlayer();
  }
};

export default onEnded;
