import { get } from "lodash";
import config from "../../../config";

function setDrmConfigParams(vod) {
  let obj = {};
  obj = setDrmParam(vod, obj);
  obj.setHlsPolyNet = setHlsPolyNet.bind(this);
  obj.setDashPolyNet = setDashPolyNet.bind(this);
  return obj;
}

const setDrmParam = (movie, obj = {}) => {
  if (get(movie, "primary_drm_license_url")) {
    obj.config = {
      file: {
        drm: {
          servers: {
            "com.widevine.alpha": get(movie, "primary_drm_license_url")
          }
        }
        // dashProtectionData: {
        //   "com.widevine.alpha": {
        //     serverURL: get(movie, "primary_drm_license_url")
        //   }
        // }
      }
    };
  }
  return obj;
};

function setHlsPolyNet(hls) {
  /*eslint-disable */
  if (hls) {
    this.polynetReference = new PolyNetPluginHlsjs({
      polyNetConfig: {
        apiKey: config.POLYNET_API_KEY
      },
      videoElement: document.querySelector("video"),
      hlsjs: hls
    });
  }
  /*eslint-enable */
}

function setDashPolyNet(dash) {
  /*eslint-disable */
  if (dash && window.PolyNetDashjsWrapper) {
    window.PolyNetDashjsWrapper(dash, {
      apiKey: config.POLYNET_API_KEY
    });
  }
  /*eslint-enable */
}

export default setDrmConfigParams;
