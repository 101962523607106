import React, { Component } from "react";
import update from "immutability-helper";
import classNames from "classnames";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import RatingStars from "components/RatingStars";
import RentOrPlayProfilesModal from "components/Modals/RentOrPlayProfilesModal";
import InputPinModal from "components/Modals/InputPinModal";
import FlexibleCarousel from "components/Carousels/FlexibleCarousel";
import ConfirmRentModal from "components/Modals/ConfirmRentModal";
import ScrollTopHOC from "HOCs/ScrollTopHOC";
import { find, isEqual, get } from "lodash";
import { Loader } from "semantic-ui-react";
import ActiveBanner from "components/ActiveBanner";
import MovieDetails from "./components/MovieDetails";
import {
  openNotificationWithIcon,
  redirectToPlay,
  redirectToNotFoundPage
} from "functions/logic-functions";
import {
  RENT_VOD_URL,
  SINGLE_VOD,
  SIMILAR_MOVIES_URL,
  MOVIE_RATE_URL,
  GET_ACTIVE_BANNER
} from "API-routes";
import * as defaultActions from "reduxStore/actions/index";
import * as singleMovieCarouselActions from "reduxStore/reducers/cachedSingleMoviesCarousels";
import * as floatingPlayerActions from "reduxStore/reducers/floatingPlayer";
import { sendAnalyticsData } from "../../helper-functions/logic-functions";
import { injectIntl } from "react-intl";
import style from "./style.module.scss";

class SingleMoviePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movie: null,
      showLoader: true,
      openRentModal: false,
      openConfirmModal: false,
      openPlayModal: false,
      openParentalModal: false,
      openPurchaseModal: false,
      openedRentModalData: null,
      selectedRent: null,
      requirePin: false,
      openStars: false,
      showAnimationClasses: false
    };
    this.DEFAULT_PROFILE =
      localStorage.getItem("wecast-vod-profile") &&
      JSON.parse(localStorage.getItem("wecast-vod-profile"));
  }

  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      location: { state }
    } = this.props;

    if (state && state.item) {
      this.setState(
        {
          movie: state.item,
          showAnimationClasses: true
        },
        () => {
          this._openParentalPin(state.item);
          this._fetchSimilarMoviesIfDoesntExist(this.state.movie.id);
        }
      );
    } else {
      this._fetchMovie(id);
    }

    sendAnalyticsData("single_movie_view");
  }

  componentWillReceiveProps = nextProps => {
    const {
      match: {
        params: { id: nextID }
      },
      location: { state: nextState, search }
    } = nextProps;
    const {
      match: {
        params: { id }
      },
      location: { state },
      setOpenPlayer
    } = this.props;

    if (search && (search.includes("play") || search.includes("trailer"))) {
      setOpenPlayer(true);
    }

    if (id && nextID && parseInt(id) !== parseInt(nextID)) {
      if (get(nextState, "item") && !isEqual(state, nextState)) {
        this.setState(
          {
            showAnimationClasses: false
          },
          () => {
            this._setNewReceivedMovie(nextState.item);
          }
        );
      } else {
        this._fetchMovie(nextID);
      }
    } else {
      this.setState({
        showAnimationClasses: true
      });
    }
  };

  _setNewReceivedMovie = movie => {
    this._fetchSimilarMoviesIfDoesntExist(movie.id);

    setTimeout(() => {
      this.setState({
        movie,
        showAnimationClasses: true
      });
    }, 250);
  };

  _fetchMovie = id => {
    const { getSingleMovie } = this.props;

    if (id) {
      getSingleMovie({
        url: `${SINGLE_VOD}?filter[id]=${id}&config[enable_rate]=1`,
        successCallback: this._successCallback
      });
    }

    this._fetchSimilarMoviesIfDoesntExist(id);
  };

  _fetchSimilarMoviesIfDoesntExist = id => {
    const { getSimilarMovies, cachedSingleMoviesCarousels } = this.props;

    if (
      !find(cachedSingleMoviesCarousels, o => parseInt(o.id) === parseInt(id))
    ) {
      getSimilarMovies({
        url: SIMILAR_MOVIES_URL,
        body: {
          vod_id: id
        }
      });
    }
  };

  _openParentalPin = data => {
    const { history } = this.props;

    if (get(history, "location.state.alreadyEnteredParentalPin")) {
      this.setState({ requirePin: false });
    } else if (get(data, "parental_rating.require_pin")) {
      this.setState({ requirePin: true });
    }
  };

  _successCallback = data => {
    if (data && data.length) {
      this.setState({
        movie: data[0],
        showLoader: false,
        showAnimationClasses: true
      });

      this._openParentalPin(data[0]);
    } else if (data && !Array.isArray(data)) {
      this.setState({
        movie: data,
        showLoader: false,
        showAnimationClasses: true
      });
      this._openParentalPin(data);
    } else {
      redirectToNotFoundPage(this.props.history);
    }
  };

  _setRent = openedRentModalData => {
    const { intl } = this.props;
    const { loggedUser } = this.props;

    if (get(loggedUser, "account.subscription_expired")) {
      openNotificationWithIcon({
        type: "warning",
        message: intl.formatMessage({
          id: "notifications.subscriptionHasExpired"
        })
      });

      return;
    } else {
      this.setState({
        openPurchaseModal: true,
        openedRentModalData,
        selectedRent:
          openedRentModalData.pricing.length === 1
            ? openedRentModalData.pricing[0]
            : null
      });
    }
  };

  _onClose = type => {
    switch (type) {
      case "rent":
        this.setState({
          openRentModal: false,
          selectedRent: null,
          openedRentModalData: null
        });
        break;
      case "confirm":
        this.setState({
          openConfirmModal: false,
          openRentModal: true
        });
        break;
      case "play":
        this.setState({
          openPlayModal: false
        });
        break;
      default:
        break;
    }
  };

  _getSubscribedProfiles = () => {
    const { movie } = this.state;
    return (
      (get(movie, "movie_source.profiles") &&
        get(movie, "movie_source.profiles").filter(item => item.subscribed)) ||
      []
    );
  };

  _isSelectedDefaultProfileInSubscribed = subscribedProfiles => {
    if (subscribedProfiles) {
      return !!subscribedProfiles.find(
        item =>
          get(this.DEFAULT_PROFILE, "id") &&
          item.id === get(this.DEFAULT_PROFILE, "id")
      );
    } else {
      const subProfiles = this._getSubscribedProfiles();
      return !!subProfiles.find(
        item =>
          get(this.DEFAULT_PROFILE, "id") &&
          item.id === get(this.DEFAULT_PROFILE, "id")
      );
    }
  };

  _redirectToPlayWhenSelectedVodProfileIsNotAutoOrNotRequirePin = (
    subscribedProfiles,
    isDefaultProfileIncluded
  ) => {
    const { movie } = this.state;

    if (
      (subscribedProfiles && subscribedProfiles.length === 1) ||
      isDefaultProfileIncluded
    ) {
      redirectToPlay({
        VOD: movie,
        selectedProfile:
          subscribedProfiles.length === 1
            ? subscribedProfiles[0]
            : this.DEFAULT_PROFILE,
        history: this.props.history
      });
    } else {
      this.setState({
        openPlayModal: true
      });
    }
  };

  _redirectToPlayIfOnlyOneSubscribedProfile = () => {
    this.setState({
      openParentalModal: true,
      openPlayModal: false
    });
  };

  _redirectToPlayIfMoreThanOneSubscribedProfile = (
    movie,
    isDefaultProfileIncluded
  ) => {
    if (isDefaultProfileIncluded) {
      redirectToPlay({
        VOD: movie,
        selectedProfile: this.DEFAULT_PROFILE,
        history: this.props.history
      });
    } else {
      this.setState({
        openParentalModal: false,
        openPlayModal: true
      });
    }
  };

  _redirectToPlayIsAlreadyEnteredPin = (
    movie,
    subscribedProfiles,
    isDefaultProfileIncluded
  ) => {
    if (subscribedProfiles) {
      if (subscribedProfiles.length === 1 || isDefaultProfileIncluded) {
        redirectToPlay({
          VOD: movie,
          selectedProfile:
            subscribedProfiles.length === 1
              ? subscribedProfiles[0]
              : this.DEFAULT_PROFILE,
          history: this.props.history,
          alreadyEnteredParentalPin: true
        });
        return;
      }

      this.setState({
        openParentalModal: false,
        openPlayModal: true
      });
    }
  };

  _redirectToPlayIfRequiredPin = (
    subscribedProfiles,
    isDefaultProfileIncluded
  ) => {
    const {
      location: { state }
    } = this.props;
    const { movie, requirePin } = this.state;

    if ((state && state.alreadyEnteredParentalPin) || !requirePin) {
      this._redirectToPlayIsAlreadyEnteredPin(
        movie,
        subscribedProfiles,
        isDefaultProfileIncluded
      );
    } else if (subscribedProfiles.length === 1 || isDefaultProfileIncluded) {
      this._redirectToPlayIfOnlyOneSubscribedProfile();
    } else if (subscribedProfiles.length > 1) {
      this._redirectToPlayIfMoreThanOneSubscribedProfile(
        movie,
        isDefaultProfileIncluded
      );
    }
  };

  _showNotificationWhenNoEpisodeSubscription = () => {
    const { intl } = this.props;

    openNotificationWithIcon({
      type: "info",
      message: intl.formatMessage({
        id: "notifications.movieNotRented"
      }),
      description: intl.formatMessage({
        id: "notifications.rentMovieBeforeWatching"
      })
    });
  };

  _showNotificationWhenSubscriptionIsExpired = () => {
    const { intl } = this.props;

    openNotificationWithIcon({
      type: "warning",
      message: intl.formatMessage({
        id: "notifications.subscriptionHasExpired"
      })
    });
  };

  _onOpenPlayModal = () => {
    const { movie } = this.state;
    const { loggedUser } = this.props;

    if (
      get(loggedUser, "account") &&
      !loggedUser.account.subscription_expired
    ) {
      const subscribedProfiles = this._getSubscribedProfiles();
      const isDefaultProfileIncluded = this._isSelectedDefaultProfileInSubscribed(
        subscribedProfiles
      );

      if (subscribedProfiles && subscribedProfiles.length) {
        if (
          (get(this.DEFAULT_PROFILE, "name") &&
            get(this.DEFAULT_PROFILE, "name") !== "Auto" &&
            isDefaultProfileIncluded &&
            !get(movie, "parental_rating.require_pin")) ||
          (subscribedProfiles.length === 1 &&
            !get(movie, "parental_rating.require_pin"))
        ) {
          this._redirectToPlayWhenSelectedVodProfileIsNotAutoOrNotRequirePin(
            subscribedProfiles,
            isDefaultProfileIncluded
          );
        } else if (get(movie, "parental_rating.require_pin")) {
          this._redirectToPlayIfRequiredPin(
            subscribedProfiles,
            isDefaultProfileIncluded
          );
        } else if (
          subscribedProfiles.length > 1 &&
          !isDefaultProfileIncluded &&
          !get(movie, "parental_rating.require_pin")
        ) {
          this.setState({
            openPlayModal: true
          });
        }
      } else if (!movie.subscribed) {
        this._showNotificationWhenNoEpisodeSubscription();
      }
    } else {
      this._showNotificationWhenSubscriptionIsExpired();
    }
  };

  _onInputParentalPinSuccess = () => {
    this.setState({
      openParentalModal: false,
      openPlayModal: true
    });
  };

  _onSuccess = (type, data) => {
    const { openedRentModalData, selectedRent } = this.state;

    switch (type) {
      case "rent":
        this.setState({
          openRentModal: false,
          openConfirmModal: true,
          selectedRent: data
        });
        break;

      case "confirm":
        this._onConfirm(openedRentModalData, selectedRent);
        break;

      case "play":
        this._onSuccessPlay(data);
        break;
      default:
        break;
    }
  };

  _onConfirm = (openedRentModalData, selectedRent) => {
    const { movie } = this.state;
    const { rentVod } = this.props;

    this.setState(
      {
        openConfirmModal: false
      },
      () => {
        const body = {
          single_event_vod_id: movie.id,
          vod_profile_id: openedRentModalData.id
        };

        if (
          get(openedRentModalData, "business_model") === "tvod_dtr" &&
          get(selectedRent, "duration")
        ) {
          body.duration = selectedRent.duration;
        }

        rentVod({
          url: RENT_VOD_URL,
          body,
          successCallback: this._onConfirmRentSuccessCallback
        });
        sendAnalyticsData("single_movie_rent_profile");
      }
    );
  };

  _onConfirmRentSuccessCallback = success => {
    const { openedRentModalData } = this.state;

    if (success) {
      this.setState(({ movie }) => ({
        selectedRent: null,
        openedRentModalData: null,
        movie: update(movie, {
          movie_source: {
            profiles: {
              $set: movie.movie_source.profiles.map(item => {
                if (item.id === openedRentModalData.id) item.subscribed = true;
                return item;
              })
            }
          }
        })
      }));
    } else {
      this.setState({
        selectedRent: null,
        openedRentModalData: null
      });
    }
  };

  _onSuccessPlay = selectedProfileForPlay => {
    const {
      location: { state }
    } = this.props;
    const { movie } = this.state;

    this.setState(
      {
        openPlayModal: false
      },
      () => {
        this._resetMinimizedPlayer();
        this.props.history.push({
          search: "play",
          state: {
            item: movie,
            alreadyEnteredParentalPin: get(state, "alreadyEnteredParentalPin"),
            cameFrom: this.props.location.pathname,
            selectedProfile: selectedProfileForPlay
          }
        });
      }
    );
  };

  _rateMovie = rate => {
    const { rateVOD } = this.props;
    const { movie } = this.state;

    if (movie && movie.id) {
      rateVOD({
        url: `${MOVIE_RATE_URL}/${movie.id}/${rate}`
      });
      sendAnalyticsData("movie_rate");
    }
  };

  _onRedirectToPlay = () => {
    const { movie } = this.state;
    const selectedVodProfile =
      get(this.DEFAULT_PROFILE, "id") !== 0
        ? this.DEFAULT_PROFILE
        : this._getSubscribedProfiles().length
        ? this._getSubscribedProfiles()
        : null;

    redirectToPlay({
      VOD: movie,
      selectedVodProfile,
      history: this.props.history
    });
  };

  _renderMovieDetails = () => {
    const { showLoader, movie, showAnimationClasses } = this.state;
    const { intl } = this.props;

    if (showLoader && !movie) {
      return (
        <div className={style.loaderWrapper}>
          <Loader active={showLoader} />
        </div>
      );
    } else {
      const breadcrumbsList = [
        {
          title: intl.formatMessage({ id: "common.home" }),
          url: "/"
        },
        {
          title: intl.formatMessage({ id: "common.movies" }),
          url: "/movies"
        },
        {
          title: movie.title
        }
      ];

      return (
        <MovieDetails
          intl={intl}
          movie={movie}
          breadcrumbsList={breadcrumbsList}
          showAnimationClasses={showAnimationClasses}
          setRent={this._setRent}
          onTrailerClick={this._onTrailerClick}
          onOpenPlayModal={this._onOpenPlayModal}
          onOpenRatingStars={this._onOpenRatingStars}
        />
      );
    }
  };

  _onOpenRatingStars = () => this.setState({ openStars: true });

  _onClosePurhasePinModal = () => {
    this.setState({
      openPurchaseModal: false,
      openedRentModalData: null,
      selectedRent: null
    });
  };

  _onPurchasePinModalSuccess = () => {
    this.setState({
      openPurchaseModal: false,
      openRentModal: true
    });
  };

  _renderPurchasePinModal = () => {
    const { intl } = this.props;
    const { openPurchaseModal } = this.state;

    if (openPurchaseModal) {
      return (
        <InputPinModal
          intl={intl}
          open={openPurchaseModal}
          type="purchase"
          onSuccess={this._onPurchasePinModalSuccess}
          onClose={this._onClosePurhasePinModal}
          successButtonTitle={intl.formatMessage({
            id: "common.confirm"
          })}
        />
      );
    }
  };

  _renderRentModal = () => {
    const { intl } = this.props;
    const { openRentModal, openedRentModalData } = this.state;

    if (openRentModal) {
      return (
        <RentOrPlayProfilesModal
          intl={intl}
          open={openRentModal}
          onSuccess={this._onSuccess}
          onClose={this._onClose}
          profiles={openedRentModalData}
          eventType="rent"
          successButtonTitle={intl.formatMessage({
            id: "common.rent"
          })}
        />
      );
    }
  };

  _renderConfirmPinModal = () => {
    const {
      openConfirmModal,
      openRentModal,
      selectedRent,
      openedRentModalData
    } = this.state;
    const { intl } = this.props;

    if (openConfirmModal && !openRentModal) {
      return (
        <ConfirmRentModal
          intl={intl}
          open={openConfirmModal}
          onSuccess={this._onSuccess}
          onClose={this._onClose}
          selectedRent={selectedRent}
          openedRentModalData={openedRentModalData}
          eventType="confirm"
        />
      );
    }
  };

  _renderSimilarMovies = () => {
    const { intl } = this.props;
    const { cachedSingleMoviesCarousels } = this.props;
    const { movie } = this.state;
    const similarMovies =
      get(movie, "id") &&
      find(
        cachedSingleMoviesCarousels,
        o => parseInt(o.id) === parseInt(movie.id)
      );

    if (get(similarMovies, "result") && similarMovies.result.length > 0) {
      return (
        <div className={style.carouselWrapper}>
          <FlexibleCarousel
            intl={intl}
            title={intl.formatMessage({
              id: "vods.similarMovies"
            })}
            urlRoute="/movies"
            items={similarMovies.result}
          />
        </div>
      );
    }
  };

  _renderInitialParentalModal = () => {
    const { intl } = this.props;
    const { requirePin } = this.state;
    const { history } = this.props;
    const doesRequirePin =
      requirePin && !get(history, "location.state.alreadyEnteredParentalPin");

    if (doesRequirePin) {
      return (
        <InputPinModal
          intl={intl}
          open={doesRequirePin}
          type="parental"
          onSuccess={() => {
            this.setState({
              requirePin: false
            });
          }}
          hideCancelButton={true}
          onBack={() => history.push("/movies")}
          successButtonTitle={intl.formatMessage({
            id: "common.confirm"
          })}
        />
      );
    }
  };

  _renderPlayModal = () => {
    const { intl } = this.props;
    const { movie, openPlayModal } = this.state;
    const subscribedProfiles =
      get(movie, "movie_source.profiles") &&
      get(movie, "movie_source.profiles").filter(item => item.subscribed);

    if (openPlayModal) {
      return (
        <RentOrPlayProfilesModal
          intl={intl}
          open={openPlayModal}
          onSuccess={this._onSuccess}
          onClose={this._onClose}
          profiles={subscribedProfiles || []}
          eventType="play"
          successButtonTitle={intl.formatMessage({
            id: "common.play"
          })}
        />
      );
    }
  };

  _onTrailerClick = () => {
    const { movie, requirePin } = this.state;
    const { history } = this.props;

    if (!requirePin) {
      this._resetMinimizedPlayer();
      history.push({
        search: "trailer",
        state: {
          item: movie,
          alreadyEnteredParentalPin: true
        }
      });
    }
  };

  _resetMinimizedPlayer = () => {
    const {
      floatingPlayer: { isPlayerOpened },
      setPlayerSize,
      setOpenPlayer
    } = this.props;

    if (isPlayerOpened) {
      setOpenPlayer(false);
      setPlayerSize("maximize");
    }
  };

  _renderAdvertisementBanner = () => {
    const {
      match: {
        params: { id }
      },
      composerConfig: {
        web: {
          modules: { advertisements }
        }
      }
    } = this.props;

    if (advertisements) {
      return (
        <ActiveBanner
          useParentContainer={true}
          url={`${GET_ACTIVE_BANNER}?filter[box_position]=movie_details_box&filter[ads_type]=image`}
          paramsId={id}
        />
      );
    }
  };

  _renderRatingStars = () => {
    const { openStars, movie } = this.state;
    const { intl } = this.props;

    if (movie) {
      return (
        <RatingStars
          isOpen={openStars}
          vodType={movie.type}
          intl={intl}
          onClose={this._onCloseStarsModal}
          onRate={this._rateMovie}
        />
      );
    }
  };

  _onCloseStarsModal = () => this.setState({ openStars: false });

  render() {
    return (
      <div className={classNames(style.container, "topPadding")}>
        <div className={style.overflowFix}>
          {this._renderMovieDetails()}
          {this._renderSimilarMovies()}
          {this._renderAdvertisementBanner()}
          {this._renderConfirmPinModal()}
          {this._renderRentModal()}
          {this._renderPlayModal()}
          {this._renderPurchasePinModal()}
          {this._renderInitialParentalModal()}
          {this._renderRatingStars()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSingleMovie: data => dispatch(defaultActions.getSingleMovie(data)),
  rentVod: data => dispatch(defaultActions.rentVOD(data)),
  rateVOD: data => dispatch(defaultActions.rateVOD(data)),
  getSimilarMovies: data =>
    dispatch(singleMovieCarouselActions.getSimilarMovies(data)),
  setOpenPlayer: data => dispatch(floatingPlayerActions.setOpenPlayer(data)),
  setPlayerSize: data => dispatch(floatingPlayerActions.setPlayerSize(data))
});

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  cachedSingleMoviesCarousels: state.cachedSingleMoviesCarousels,
  composerConfig: state.composerConfig,
  floatingPlayer: state.floatingPlayer
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScrollTopHOC(injectIntl(SingleMoviePage)))
);
