export const GET_VOD_SHOW_TYPES = "GET_VOD_SHOW_TYPES";
export const GET_VOD_SHOW_TYPES_SUCCESS = "GET_VOD_SHOW_TYPES_SUCCESS";

//reducer
const vodShowTypes = (state = [], action) => {
  switch (action.type) {
    case GET_VOD_SHOW_TYPES_SUCCESS:
      // if (action.payload) return action.payload;
      // return state;
      if (action.payload) return action.payload;
      break;
    default:
      return state;
  }
};
export default vodShowTypes;
//action
export const getVodShowTypes = payload => {
  return {
    type: GET_VOD_SHOW_TYPES,
    payload: {
      url: payload.url
    }
  };
};
