import styledComponents from "styled-components";
import { Modal } from "semantic-ui-react";

export const ModalStyled = styledComponents(Modal)`
	background-color: transparent !important;
	border-radius: 13px !important;
    overflow: hidden;
    animation: startAnimation 150ms ease forwards;
    
    @keyframes startAnimation {
    	0% { transform: scale(0.9); opacity: 0.7; }
    	35% { transform: scale(1.1) }
    	75% { transform: scale(0.95) }
    	100% { transform: scale(1); opacity: 1; }
    }
`;

export const ModalWrapper = styledComponents("div")`
	background-color: rgba(var(--theme_background_modal), 0.95) !important;
	display: flex !important;
	flex-direction: column;
	align-items: center;
	padding: 50px !important;
	
	@media only screen and (max-width: 767px){
	    padding: 50px !important;
	    
		& > .content{
			padding-left: 50px !important;
			padding-right: 50px !important;
			padding-top: 30px !important;
			padding-bottom: 50px !important;
		}
	}
	
	div.input-wrapper{
		position: relative;
		width: 300px;
		
		@media only screen and (max-width: 330px){
			width: 100%;
		}
		
		span.error-message{
			color: red;
			position: absolute;
			display: flex;
			top: 45px;
			font-size: 12px;
			right: 0;
		}
	}
	& > svg{
		font-size: 36px;
		color: var(--theme_text_color);
	}
	& > h4{
		font-size: 18px;
		font-weight: 400;
		margin-bottom: 0px;
		color: var(--theme_reverse_dark);
		margin-bottom: 20px;
		text-transform: capitalize;
	}
`;
