export const GET_HOMEPAGE_HORIZONTAL_LIST_SUCCESS =
  "GET_HOMEPAGE_HORIZONTAL_LIST_SUCCESS";
export const GET_HOMEPAGE_HORIZONTAL_LIST = "GET_HOMEPAGE_HORIZONTAL_LIST";
export const ALIASES = {
  RECOMMENDED_MOVIES: "RECOMMENDED_MOVIES",
  RECOMMENDED_TV_SHOWS: "RECOMMENDED_TV_SHOWS",
  MOVIES_NEW_RELEASES: "MOVIES_NEW_RELEASES",
  TV_SHOWS_NEW_RELEASES: "TV_SHOWS_NEW_RELEASES",
  CHANNELS: "CHANNELS",
  CHANNELS_UPDATE: "CHANNELS_UPDATE",
  TRENDING_CHANNELS: "TRENDING_CHANNELS",
  TRENDING_MOVIES: "TRENDING_MOVIES",
  TRENDING_TV_SHOWS: "TRENDING_TV_SHOWS"
};

const {
  RECOMMENDED_MOVIES,
  RECOMMENDED_TV_SHOWS,
  MOVIES_NEW_RELEASES,
  TV_SHOWS_NEW_RELEASES,
  TRENDING_CHANNELS,
  TRENDING_MOVIES,
  TRENDING_TV_SHOWS
} = ALIASES;

const initialState = {
  recommendedMovies: {
    cached: false,
    items: []
  },
  recommendedTvShows: {
    cached: false,
    items: []
  },
  moviesNewReleases: {
    cached: false,
    items: []
  },
  tvShowsNewReleases: {
    cached: false,
    items: []
  },
  trendingChannels: {
    cached: false,
    items: []
  },
  trendingMovies: {
    cached: false,
    items: []
  },
  trendingTvShows: {
    cached: false,
    items: []
  }
};

const cachedHorizontalHomepage = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOMEPAGE_HORIZONTAL_LIST_SUCCESS:
      switch (action.payload.alias) {
        case RECOMMENDED_MOVIES:
          return Object.assign(
            {},
            {
              ...state,
              recommendedMovies: {
                cached: true,
                items: action.payload.result.items || action.payload.result
              }
            }
          );
        case RECOMMENDED_TV_SHOWS:
          return Object.assign(
            {},
            {
              ...state,
              recommendedTvShows: {
                cached: true,
                items: action.payload.result.items || action.payload.result
              }
            }
          );
        case MOVIES_NEW_RELEASES:
          return Object.assign(
            {},
            {
              ...state,
              moviesNewReleases: {
                cached: true,
                items: action.payload.result.items || action.payload.result
              }
            }
          );
        case TV_SHOWS_NEW_RELEASES:
          return Object.assign(
            {},
            {
              ...state,
              tvShowsNewReleases: {
                cached: true,
                pager: action.payload.result.pager || null,
                items: action.payload.result.items || action.payload.result
              }
            }
          );
        case TRENDING_CHANNELS:
          return Object.assign(
            {},
            {
              ...state,
              trendingChannels: {
                cached: true,
                items: action.payload.result.items || action.payload.result
              }
            }
          );
        case TRENDING_MOVIES:
          return Object.assign(
            {},
            {
              ...state,
              trendingMovies: {
                cached: true,
                items: action.payload.result.items || action.payload.result
              }
            }
          );
        case TRENDING_TV_SHOWS:
          return Object.assign(
            {},
            {
              ...state,
              trendingTvShows: {
                cached: true,
                items: action.payload.result.items || action.payload.result
              }
            }
          );

        default:
          return state;
      }
    default:
      return state;
  }
};

export default cachedHorizontalHomepage;

export const getHomepageHorizontalList = data => {
  return {
    type: GET_HOMEPAGE_HORIZONTAL_LIST,
    payload: {
      alias: data.alias,
      requestMethod: data.requestMethod,
      url: data.url,
      body: data.body
    }
  };
};
