import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import pt from "react-intl/locale-data/pt";
import pt_BR from "react-intl/locale-data/br";

pt_BR[0].locale = "pt_BR";

addLocaleData([...en, ...pt, ...pt_BR]);

const pt_and_br = {
  "common.liveTv": "TV ao vivo",
  "common.movies": "Filmes",
  "common.tvShows": "Programas de televisão",
  "common.trending": "Tendências",
  "common.channels": "Canais",
  "common.register": "Registar",
  "common.login": "Entrar",
  "common.continue": "Continuar",
  "common.back": "Voltar",
  "common.watch": "Assistir",
  "common.epg": "EPG",
  "common.tvGuide": "GUIA DE TV",
  "common.seeAllChannels": "Veja todos canais",
  "common.account": "Conta",
  "common.myAccount": "Minha Conta",
  "common.logout": "Sair",
  "common.next": "Próximo",
  "common.previous": "Anterior",
  "common.search": "Pesquisar",
  "common.settings": "Configurações",
  "common.noSubtitle": "sem legendas",
  "common.noAudioSubtitle": "sem legendas de áudio",
  "common.language": "Idioma",
  "common.parentalCensorshipAllowed": "Censura Parental",
  "common.profiles": "Perfis",
  "common.devices": "Dispositivos",
  "common.subscriptions": "Assinaturas",
  "common.save": "Salvar",
  "common.cancel": "Cancelar",
  "common.moviesAndEpisodes": "Filmes & Episódios",
  "common.gender": "Genero",
  "common.male": "Masculino",
  "common.female": "Feminino",
  "common.parentalPin": "PINO parental",
  "common.purchasePin": "PIN de compra",
  "common.age": "Idade",
  "common.confirmNewPassword": "Confirmar Novo Senha",
  "common.currentPassword": "Atual Senha",
  "common.newPassword": "Nova Senha",
  "common.email": "Email",
  "common.firstName": "Primeiro Nome",
  "common.lastName": "Ultimo Nome",
  "common.moreOptions": "Mais Opções",
  "common.favourites": "Favoritos",
  "common.addReminder": "Adicionar Lembrete",
  "common.rent": "Alugar",
  "common.closeCapition": "CC",
  "common.closeCapitionUnavailable": "Close Caption não disponível",
  "common.closeCapitionSuccess": "Close Caption adicionado com sucesso",
  "common.closeCapitionRemoved": "Close Caption removido com sucesso",
  "common.all": "Tudo",
  "common.genre": "Gênero",
  "common.genres": "Gêneros",
  "common.show_types": "Mostrar Tipos",
  "common.moreInfo": "Mais Info",
  "common.play": "Play",
  "common.playNow": "Reproduzindo agora",
  "common.sound": "Som",

  "common.yes": "Sim",
  "common.no": "Não",
  "common.confirm": "Confimar",
  "common.confirmYourPassword": "Confirme sua senha",
  "common.noInformation": "Sem informação",
  "common.searchTitlesChannels": "Pesquisar títulos, canais...",
  "common.viewAllNumberResults": "Ver todos {resultNumber} os resultados",
  "common.suggestions": "Sugestões",
  "common.viewMore": "Veja mais",
  "common.year": "Ano",
  "common.success": "Sucesso",
  "common.close": "Fechar",
  "common.now": "Agora",
  "common.password": "Senha",
  "common.audio": "Audio",
  "common.subtitles": "Legendas",
  "common.username": "Nome de usuário",
  "common.editReminder": "Editar lembrete",
  "common.removeReminder": "Remover lembrete",
  "common.searchTitles": "Pesquisar títulos...",
  "common.searchChannels": "Canais de pesquisa...",
  "common.favoriteChannels": "Canais Favoritos",
  "common.contentUnavailable": "Conteúdo Indisponível",
  "common.closedCaptionUnavailable": "Closed Caption ndisponível o momento..",
  "common.moviesContentUnavailable":
    "Estamos coletando dados para mostrar o melhor conteúdo.",
  "common.liveTvContentUnavailable":
    "Não podemos mostrar seus resultados para (filtro).",
  "common.trendingContentUnavailable":
    "Estamos coletando dados para mostrar o melhor conteúdo.",
  "common.viewDetails": "Ver Detalhes",
  "common.parentalRating": "Classificação parental",
  "common.channelCategoriesTitle": "Categorias de canais",
  "common.nextEpisodeIn": "Próximo episódio em",
  "common.timeshift": "Mudança de horário",
  "common.movie": "Filme",
  "common.episode": "Episódio",
  "common.tvShow": "Programa de TV",
  "common.create": "Criar",
  "common.skipAd": "Pular Anúncio",
  "common.in": "Em",
  "common.remove": "Remover",
  "common.home": "Home",
  "common.dtl": "Suporte da direita para a esquerda",

  "liveTv.availableIn": "também está disponível em",
  "liveTv.audioTracks": "Faixas de áudio",

  "epg.allChannels": "Todos Os Canais",
  "epg.goToChannel": "Ir ao canal",

  "modal.rentTitleFor": "Alugue este título para",
  "modal.deleteDevice": "Excluir dispositivo",
  "modal.chooseTheResolution": "Escolha a resolução",
  "modal.areYouSureEraseDevice":
    "Tem certeza de que deseja excluir este dispositivo da sua conta? Esta ação não pode ser desfeita.",
  "modal.wrongPurchasePin": "Pino de compra errado!",
  "modal.wrongParentalPin": "Pino parental errado!",
  "modal.enterTypePin": "Entrar {pinType} PIN...",
  "modal.startOver": "Recomeçar",
  "modal.resume": "Continuar de onde parou",
  "modal.playTitle": "Toque {title}",
  "modal.startFromBeginningOrResume":
    "Comece do começo ou continue onde você saiu?",
  "modal.days": "dias",
  "modal.day": "Dia",
  "modal.chooseDaysDuration": "Escolha a duração dos Dias",
  "modal.playProgram": "Reproduzir programa",
  "modal.selectCaption": "Selecione o idioma",

  "vods.resetAllFilters": "Redefinir todos os filtros",
  "vods.choseTheResolution": "Escolha a resolução",
  "vods.rating": "Avaliação",
  "vods.cast": "Fundida",
  "vods.trailer": "Trailer",
  "vods.rate": "Avalie",
  "vods.dislike": "Não gostar",
  "vods.creators": "Criadores",
  "vods.seasons": "Temporadas",
  "vods.similarMovies": "Filmes Semelhantes",
  "vods.resultsFor": "Resultados para",
  "vods.tryDifferentKeywords": "Experimente palavras-chave diferentes",
  "vods.trySearchMoviesSeries":
    "Tente pesquisar títulos de filmes, séries de TV ou canais",
  "vods.noResultsFound": `Não encontramos resultados para "{searchText}"`,
  "vods.rateThisVod": `Classifique isto {vodType}`,

  "homepage.continueWatching": "Continuar Asistindo",
  "homepage.moviesNewReleases": "Filmes Novos Lançamentos",
  "homepage.tvShowsNewReleases": "Programas de televisão novos lançamentos",
  "homepage.areYouSureEraseDevice":
    "Tem certeza de que deseja excluir este dispositivo da sua conta? Essa ação não pode ser desfeita.",
  "homepage.recommendedTvShows": "Programas de TV recomendados",
  "homepage.recommendedMovies": "Filmes recomendados",
  "trending.topChannels": "Principais canais",
  "trending.topMovies": "Melhores filmes",
  "trending.topTvShows": "Principais programas de TV",
  "account.accountInfo": "Informações da conta",
  "account.paymentHistory": "Histórico de pagamento",
  "account.subscription": "Inscrição",
  "account.settings": "Configurações",
  "account.devices": "Dispositivos",
  "account.noDevicesList": "Nenhuma lista de dispositivos",
  "account.chooseNewSubPlan": "Escolha um novo plano de assinatura",
  "account.enterYour5digitPin": "Insira seu PIN de 5 dígitos",
  "account.pinShouldContain5LenNumbers":
    "O PIN deve conter 5 números de comprimento",
  "account.enterFirstName": "Introduza o primeiro nome",
  "account.enterLastName": "Insira o último nome",
  "account.enterEmail": "Insira email",
  "account.enterAge": "Entrar idade",
  "account.enterCurrentPassword": "Insira a senha atual",
  "account.enterPurchasePin": "Insira o PIN de compra (5 dígitos)",
  "account.enterParentalPin": "Insira o PIN do pai",
  "account.enterNewPassword": "Insira a nova senha",
  "account.confirmNewPassword": "Confirme a nova senha",
  "account.currentPasswordIsWrong": "A senha atual está errada",
  "account.purchasePinMustContain5Numbers":
    "O PIN de compra deve conter 5 números",
  "account.parentalPinMustContain4Numbers":
    "O PIN parental deve conter 4 números",
  "account.pleaseCheckEmail":
    "Por favor, verifique seu e-mail para mais instruções.",
  "account.createAccountAndEnjoy": "Crie sua conta e aproveite",
  "account.goBack": "Volte",
  "account.loginHere": "Entre aqui",
  "account.alreadyRegistered": "Já registrado?",
  "account.aboutYou": "Sobre você",
  "account.yourSubscription": "Sua assinatura",
  "account.creditCard": "Cartão de crédito",
  "account.thisFIeldsAreRequired": "Esses campos são obrigatórios",
  "account.creditCardNumber": "Número do Cartão de Crédito",
  "account.expirationDate": "Data de validade (MM/YY)",
  "account.securityNumber": "Número de segurança (CVV)",
  "account.set5DigitPurchasePin":
    "Defina um PIN de 5 dígitos para comprar conteúdo VOD e desbloquear canais.",
  "account.set4DigitPinToRestrictAccess":
    "Defina um PIN de 4 dígitos para restringir o acesso em conteúdo protegido.",
  "account.confirmPassword": "Confirme a Senha",
  "account.resetPassword": "Redefinir senha",
  "account.pleaseInsertEmailToResetPassword":
    "Por favor, insira o seu email para redefinir sua senha.",
  "account.forgotMyPassword": "Esqueci minha senha",
  "account.enterUsername": "Insira nome de usuário",
  "account.themes": "Temas",
  "account.noAvailableSubscriptions": "Nenhuma inscrição disponível",
  "account.pleaseEnterYourCredentialsToContinue":
    "Por favor insira suas credenciais para continuar",
  "account.defaultProfile": "Perfil de vídeo padrão",
  "account.planExpired": "Assinatura expirada",
  "account.planWhenItWillExpire": "Assinatura expira em: {time}",
  "account.addNewAccount": "Adicionar nova conta",
  "account.emailValid": "Digite um email valido",
  "account.passwordDoesntMatch": "A senha não corresponde",
  "account.kid": "É criança?",
  "account.changePassword": "Mudar Senha",
  "account.chooseTheme": "Escolha o tema",

  "notifications.rentMovieBeforeWatching":
    "Você deve primeiro alugar filme antes de assistir!",
  "notifications.subscriptionHasExpired": "Assinatura expirou!",
  "notifications.movieNotRented": "O filme não é alugado!",
  "notifications.pleaseRenewalSubscription":
    "Por favor, renove sua assinatura!",
  "notifications.channelIsNotRented": "Canal não é alugado!",
  "notifications.toWatchPleaseRent": "Para assistir, por favor alugue um canal",
  "notifications.episodeIsNotRented": "Episódio não é alugado!",
  "notifications.rentEpisodeBeforeWatching":
    "Você deve primeiro alugar o episódio antes de assistir!",
  "notifications.wrongPurchasePin": "Pino de compra errado!",
  "notifications.wrongParentalPin": "Pino parental errado!",

  "notifications.successfullySubscribed": "Inscrito com sucesso!",
  "notifications.subscriptionFailed": "Inscrição falhou!",
  "notifications.enterYour5DigitPin": "Digite seu PIN de 5 dígitos",
  "notifications.startsIn": "{title} começa em {time} minutos",
  "notifications.lessThen": "menos que {number}",
  "notifications.profileCreatedSuccessfully": "Perfil criado com sucesso!",
  "notifications.profileCreateFailed": "A criação do perfil falhou!",
  "notifications.alreadyDeclaredEmailOrUsername":
    "Email ou nome de usuario já registrado!"
};

const localization = {
  en: {
    translations: {
      "common.liveTv": "Live TV",
      "common.movies": "Movies",
      "common.tvShows": "TV Shows",
      "common.trending": "Trending",
      "common.channels": "Channels",
      "common.register": "Register",
      "common.login": "Login",
      "common.continue": "Continue",
      "common.back": "Back",
      "common.watch": "Watch",
      "common.epg": "EPG",
      "common.tvGuide": "TV GUIDE",
      "common.seeAllChannels": "See channel list",
      "common.account": "Account",
      "common.myAccount": "My Account",
      "common.logout": "Logout",
      "common.next": "Next",
      "common.previous": "Prev",
      "common.search": "Search",
      "common.settings": "Settings",
      "common.noSubtitle": "No subtitles",
      "common.noAudioSubtitle": "No audio subtitles",
      "common.language": "Language",
      "common.profiles": "Profiles",
      "common.parentalCensorshipAllowed": "Parental Censorship",
      "common.devices": "Devices",
      "common.subscriptions": "Subscriptions",
      "common.save": "Save",
      "common.cancel": "Cancel",
      "common.moviesAndEpisodes": "Movies & Episodes",
      "common.gender": "Gender",
      "common.male": "Male",
      "common.female": "Female",
      "common.parentalPin": "Parental PIN",
      "common.purchasePin": "Purchase PIN",
      "common.age": "Age",
      "common.confirmNewPassword": "Confirm New Password",
      "common.currentPassword": "Current Password",
      "common.newPassword": "New Password",
      "common.email": "Email",
      "common.firstName": "First Name",
      "common.lastName": "Last Name",
      "common.moreOptions": "More Options",
      "common.favourites": "Favourites",
      "common.addReminder": "Add Reminder",
      "common.rent": "Rent",
      "common.closeCapition": "CC",
      "common.closeCapitionUnavailable": "Close Caption unavailable",
      "common.closeCapitionSuccess": "Close Caption successfully added",
      "common.closeCapitionRemoved": "Close Caption successfully removed",
      "common.all": "All",
      "common.genre": "Genre",
      "common.genres": "Genres",
      "common.show_types": "Show Types",
      "common.moreInfo": "More Info",
      "common.play": "Play",
      "common.playNow": "Playing now",
      "common.sound": "Sound",

      "common.yes": "Yes",
      "common.no": "No",
      "common.confirm": "Confirm",
      "common.confirmYourPassword": "Confirm your password",
      "common.noInformation": "No information",
      "common.searchTitlesChannels": "Search titles, channels...",
      "common.viewAllNumberResults": "View all {resultNumber} results",
      "common.suggestions": "Suggestions",
      "common.viewMore": "View More",
      "common.year": "Year",
      "common.success": "Success",
      "common.close": "Close",
      "common.now": "Now",
      "common.password": "Password",
      "common.audio": "Audio",
      "common.subtitles": "Subtitles",
      "common.username": "Username",
      "common.editReminder": "Edit Reminder",
      "common.removeReminder": "Remove Reminder",
      "common.searchTitles": "Search titles...",
      "common.searchChannels": "Search channels...",
      "common.favoriteChannels": "Favorite Channels",
      "common.contentUnavailable": "Content unavailable",
      "common.closedCaptionUnavailable":
        "Closed Caption currently unavailable.",
      "common.moviesContentUnavailable":
        "We’re collecting data to show you the best content.",
      "common.liveTvContentUnavailable":
        "We’re unable to show you results for (filter).",
      "common.trendingContentUnavailable":
        "We’re Collecting Data To Show You The Best Content.",
      "common.viewDetails": "View Details",
      "common.parentalRating": "Parental rating",
      "common.channelCategoriesTitle": "Channel Categories",
      "common.nextEpisodeIn": "Next episode in",
      "common.timeshift": "Timeshift",
      "common.movie": "Movie",
      "common.episode": "Episode",
      "common.tvShow": "Tv Show",
      "common.create": "Create",
      "common.skipAd": "Skip Ad",
      "common.in": "In",
      "common.remove": "Remove",
      "common.home": "Home",
      "common.dtl": "Right to left support",

      "liveTv.availableIn": "is also available in",
      "liveTv.audioTracks": "Audio Tracks",

      "epg.allChannels": "All Channels",
      "epg.goToChannel": "Go to Channel",

      "modal.rentTitleFor": "Rent this title for",
      "modal.deleteDevice": "Delete device",
      "modal.chooseTheResolution": "Choose the Resolution",
      "modal.wrongPurchasePin": "Wrong purchase pin!",
      "modal.wrongParentalPin": "Wrong parental pin!",
      "modal.enterTypePin": "Enter {pinType} PIN...",
      "modal.startOver": "Start Over",
      "modal.resume": "Resume",
      "modal.playTitle": "Play {title}",
      "modal.startFromBeginningOrResume":
        "Start from the beginning or resume  where you left?",
      "modal.days": "days",
      "modal.day": "Day",
      "modal.chooseDaysDuration": "Choose Days duration",
      "modal.playProgram": "Play program",
      "modal.selectCaption": "Select the language",

      "vods.resetAllFilters": "Reset all filters",
      "vods.choseTheResolution": "Chose the resolution",
      "vods.rating": "Rating",
      "vods.cast": "Cast",
      "vods.trailer": "Trailer",
      "vods.rate": "Rate",
      "vods.dislike": "Dislike",
      "vods.creators": "Creators",
      "vods.seasons": "Seasons",
      "vods.similarMovies": "Similar Movies",
      "vods.resultsFor": "Results For",
      "vods.tryDifferentKeywords": "Try different keywords",
      "vods.trySearchMoviesSeries":
        "Try to search for title of movies, tv series or channels",
      "vods.noResultsFound": `We didn't found results for "{searchText}"`,
      "vods.rateThisVod": `Rate this {vodType}`,

      "homepage.continueWatching": "Continue Watching",
      "homepage.moviesNewReleases": "Movies New Releases",
      "homepage.tvShowsNewReleases": "TV Shows New Releases",
      "homepage.areYouSureEraseDevice":
        "Are you sure you want to erase this device from your account? This action can’t be undone.",
      "homepage.recommendedTvShows": "Recommended TV Shows",
      "homepage.recommendedMovies": "Recommended Movies",
      "trending.topChannels": "Top Channels",
      "trending.topMovies": "Top Movies",
      "trending.topTvShows": "Top TV Shows",
      "account.accountInfo": "Account Info",
      "account.paymentHistory": "Payment History",
      "account.subscription": "Subscription",
      "account.settings": "Settings",
      "account.devices": "Devices",
      "account.noDevicesList": "No devices list",
      "account.chooseNewSubPlan": "Choose a new subscription plan",
      "account.enterYour5digitPin": "Enter your 5 digit pin",
      "account.pinShouldContain5LenNumbers":
        "Purchase PIN must contain 5 numbers",
      "account.enterFirstName": "Enter first name",
      "account.enterLastName": "Enter last name",
      "account.enterEmail": "Enter email",
      "account.enterAge": "Enter age",
      "account.enterCurrentPassword": "Enter current password",
      "account.enterPurchasePin": "Enter your purchase PIN (5 digit number)",
      "account.enterParentalPin": "Enter parental PIN",
      "account.enterNewPassword": "Enter new password",
      "account.confirmNewPassword": "Confirm new password",
      "account.currentPasswordIsWrong": "Current password is wrong",
      "account.passwordDoesntMatch": "Password doesn't match",
      "account.purchasePinMustContain5Numbers":
        "Purchase PIN must contain 5 numbers",
      "account.parentalPinMustContain4Numbers":
        "Parental PIN must contain 4 numbers",
      "account.pleaseCheckEmail":
        "Please check your email for further instructions.",
      "account.createAccountAndEnjoy": "Create your account and enjoy",
      "account.goBack": "Go Back",
      "account.loginHere": "Login here",
      "account.alreadyRegistered": "Already registered?",
      "account.aboutYou": "About you",
      "account.yourSubscription": "Your subscription",
      "account.creditCard": "Credit card",
      "account.thisFIeldsAreRequired": "This fields are required",
      "account.creditCardNumber": "Credit card number",
      "account.expirationDate": "Expiration date (MM/YY)",
      "account.securityNumber": "Security number (CVV)",
      "account.set5DigitPurchasePin":
        "Set a 5 digits PIN to purchase VOD content and unlock channels.",
      "account.set4DigitPinToRestrictAccess":
        "Set a 4 digits PIN to restrict access on protected content.",
      "account.confirmPassword": "Confirm password",
      "account.resetPassword": "Reset password",
      "account.pleaseInsertEmailToResetPassword":
        "Please insert your email to reset your password.",
      "account.forgotMyPassword": "Forgot my password",
      "account.enterUsername": "Enter username",
      "account.themes": "Themes",
      "account.noAvailableSubscriptions": "No available subscriptions",
      "account.pleaseEnterYourCredentialsToContinue":
        "Please enter your credentials to continue",
      "account.defaultProfile": "Default video profile",
      "account.planExpired": "Subscription expired",
      "account.planWhenItWillExpire": "Subscription expires: {time}",
      "account.addNewAccount": "Add New Account",
      "account.emailValid": "Enter a valid email address",
      "account.kid": "IS KID?",
      "account.changePassword": "Change Password",
      "account.chooseTheme": "Choose Theme",

      "notifications.rentMovieBeforeWatching":
        "You should first rent movie before watching!",
      "notifications.subscriptionHasExpired": "Subscription has expired!",
      "notifications.movieNotRented": "Movie is not rented!",
      "notifications.pleaseRenewalSubscription":
        "Please renewal your subscription!",
      "notifications.channelIsNotRented": "Channel is not rented!",
      "notifications.toWatchPleaseRent": "To watch, please rent a channel",
      "notifications.episodeIsNotRented": "Episode is not rented!",
      "notifications.rentEpisodeBeforeWatching":
        "You should first rent episode before watching!",
      "notifications.wrongPurchasePin": "Wrong purchase pin!",
      "notifications.wrongParentalPin": "Wrong parental pin!",
      "notifications.enterYour5DigitPin": "Enter your 5 digit PIN",
      "notifications.startsIn": "{title} starts in {time} minutes",
      "notifications.lessThen": "less then {number}",
      "notifications.profileCreatedSuccessfully":
        "Profile successfully created!",
      "notifications.profileCreateFailed": "Profile create failed!",
      "notifications.alreadyDeclaredEmailOrUsername":
        "Already declared email or username!"
    }
  },
  pt: {
    translations: pt_and_br
  },
  pt_BR: {
    translations: pt_and_br
  }
};

export const supportedLanguages = ["en", "pt", "pt_BR"];

export default localization;
