import React from "react";
import { FormattedMessage } from "react-intl";
export const GET_CHANNEL_CATEGORIES = "GET_CHANNEL_CATEGORIES";
export const GET_CHANNEL_CATEGORY_SUCCESS = "GET_CHANNEL_CATEGORY_SUCCESS";

const channelCategories = (state = [], action) => {
  switch (action.type) {
    case GET_CHANNEL_CATEGORY_SUCCESS:
      if (action.payload)
        return [
          {
            id: "all",
            name: <FormattedMessage id="epg.allChannels" />
          },
          {
            id: "favorite_channels",
            name: <FormattedMessage id="common.favoriteChannels" />
          },
          ...action.payload
        ];
      return state;
    default:
      return state;
  }
};

export default channelCategories;

export const getChannelCategories = data => {
  return {
    type: GET_CHANNEL_CATEGORIES,
    payload: {
      url: data.url,
      body: data.body,
      successCallback: data.successCallback
    }
  };
};
