import { put, call } from "redux-saga/effects";
import { GET_CHANNELS_WITH_PAGER } from "API-routes";
import { get, find } from "lodash";
import { GET_EPGS, APPEND_EPG } from "reduxStore/reducers/EPG";
import { fetchData, fetchChannels } from "helper-functions/http-functions";

const getChannelEPG = function*({ payload }) {
  try {
    const result = yield call(fetchChannels, payload);
    if (result) {
      const { getMoreChannelDetails, dontSaveToProps, appendItems } = payload;

      // If this property if provided, get channels more data (catchup, parental_pin), getChannelEPG returns only basic informations
      if (getMoreChannelDetails && get(result, "items")) {
        const idChannelsQuery = result.items.reduce((query, item, index) => {
          return query + `&filter[id][${index}]=${item.id}`;
        }, `${GET_CHANNELS_WITH_PAGER}?sort[channel_number]=asc`);

        const { items: moreDetailsChannels } = yield call(fetchData, {
          url: idChannelsQuery
        });

        result.items = result.items.map(item => {
          const moreDetailChannel = find(
            moreDetailsChannels,
            o => o.id === item.id
          );
          if (moreDetailChannel) {
            const {
              catchup_enabled,
              catchup_duration_total,
              catchup_url_params,
              parental_rating
            } = moreDetailChannel;
            item = Object.assign({}, item, {
              catchup_enabled,
              catchup_duration_total,
              catchup_url_params,
              parental_rating
            });
          }

          return item;
        });
      }

      if (!dontSaveToProps) {
        if (appendItems) {
          yield put({ type: APPEND_EPG, payload: result });
        } else {
          yield put({ type: GET_EPGS, payload: result });
        }
      }

      if (payload.successCallback) {
        payload.successCallback({
          type: "channelEPG",
          result
        });
      }
    }
  } catch (err) {
    if (payload.successCallback) payload.successCallback(false);
    console.info("ERR", err);
  }
};

export default getChannelEPG;
