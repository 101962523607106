import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";

const ScrollTopHOC = (CustomComponent, smoothScroll = false) =>
  withRouter(
    class ScrollTopWrapper extends PureComponent {
      componentWillReceiveProps(nextProps) {
        const {
          location: { pathname, search }
        } = this.props;
        const {
          location: { pathname: nextPathname, search: nextSearch }
        } = nextProps;

        if (pathname === nextPathname && search === nextSearch) {
          return;
        }

        const nextUrl = `${nextPathname}${nextSearch}`;
        const url = `${pathname}${search}`;

        if (
          url &&
          nextUrl &&
          url !== nextUrl &&
          !url.includes("?play") &&
          !url.includes("?trailer")
        ) {
          this.scrollTo();
        }
      }

      componentDidMount() {
        this.scrollTo();
      }

      scrollTo = () => {
        if (smoothScroll) {
          document.body.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        } else if (/Edge\/\d./i.test(navigator.userAgent)) {
          document.getElementById("app").scrollIntoView();
        } else document.body.scrollTop = 0;
      };

      render() {
        return <CustomComponent {...this.props} />;
      }
    }
  );

export default ScrollTopHOC;
