import React, { Component, Fragment } from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import localization from "functions/localization";
import { GET_COMPOSER_CONFIG, BASE_URL } from "API-routes";
import * as composerActions from "reduxStore/reducers/composerConfig";
import {
  rightToLeftDirection,
  leftToRightDirection,
  setDarkTheme,
  removeTheme
} from "functions/logic-functions";

class ComposerFetchHOC extends Component {
  componentWillReceiveProps = nextProps => {
    const { composerConfig } = this.props;
    if (composerConfig !== nextProps.composerConfig) {
      document.title = nextProps.composerConfig.app_name;
      this.changeFavicon(`${BASE_URL}${nextProps.composerConfig.web.favicon}`);
    }
  };

  componentDidMount() {
    const { getComposerConfig, composerConfig } = this.props;
    const html = document.getElementsByTagName("html")[0];

    if (JSON.parse(localStorage.getItem("wecast-direction-right"))) {
      rightToLeftDirection(html);
    } else {
      leftToRightDirection(html);
    }

    if (JSON.parse(localStorage.getItem("wecast-dark-theme"))) {
      setDarkTheme();
    } else {
      removeTheme();
    }

    if (!composerConfig) {
      getComposerConfig({
        url: `${GET_COMPOSER_CONFIG}/web`,
        successCallback: this.successCallback
      });
    }
  }

  changeFavicon = url => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");

    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = url;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  successCallback = response => {
    if (response) {
      const {
        theme_background_color,
        theme_color,
        theme_selection_color
      } = response;
      const root = document.documentElement;

      if (theme_background_color) {
        root.style.setProperty(
          "--theme_background_color",
          theme_background_color
        );
      }
      if (theme_color) {
        root.style.setProperty("--theme_color", theme_color);
      }
      if (theme_selection_color) {
        root.style.setProperty(
          "--theme_selection_color",
          theme_selection_color
        );
      }
    }
  };

  setColors = () => {};

  render() {
    const { lang, composerConfig } = this.props;

    if (!composerConfig) return null;
    return (
      <IntlProvider
        locale={lang}
        messages={localization[lang].translations}
        key={lang}
      >
        <Fragment>{this.props.children}</Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
  composerConfig: state.composerConfig
});

const mapDispatchToProps = dispatch => ({
  getComposerConfig: data => dispatch(composerActions.getComposerConfig(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ComposerFetchHOC);
