const parseObjectToUrl = obj => {
  if (obj) {
    const filteredData = Object.keys(obj).filter(key => obj[key]);

    if (filteredData.length) {
      return (
        "?" +
        filteredData
          .reduce(function(a, k) {
            a.push(k + "=" + obj[k]);
            return a;
          }, [])
          .join("&")
      );
    }
  }
  return;
};

export default parseObjectToUrl;
