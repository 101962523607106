const redirectToNotFoundPage = (history, url = "/404") => {
  history.replace({
    pathname: url,
    state: {
      fromErrorPage: url
    }
  });
};

export default redirectToNotFoundPage;
