import React, { lazy } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DisableWhenLoggedHoC from "HOCs/DisableWhenLoggedHoC";
import LoginComponent from "pages/LoginPage";
import RegisterComponent from "pages/RegisterPage";
import AuthorizationHoC from "HOCs/AuthorizationHoC";
import MainPagesComponentWithNavbar from "pages/MainPagesComponentWithNavbar";
import FloatingPlayerHoC from "./HOCs/FloatingPlayerHoC";
import SuspenseHoC from "./HOCs/SuspenseHoC";

const Routes = props => {
  const {
    composerConfig: {
      web: {
        modules: { vod, channels, registration }
      }
    }
  } = props;

  return (
    <Router>
      <FloatingPlayerHoC>
        <Switch>
          <Route
            path="/login"
            component={DisableWhenLoggedHoC(LoginComponent)}
          />
          {registration && (
            <Route
              path="/register"
              component={DisableWhenLoggedHoC(RegisterComponent)}
            />
          )}
          <Route
            path="/forgot-password"
            component={SuspenseHoC(
              lazy(() => import("./components/ForgotPasswordComponent"))
            )}
          />
          <Route
            path="/reset-password"
            component={SuspenseHoC(
              lazy(() => import("./components/ResetPasswordComponent"))
            )}
          />
          {vod && (
            <Route
              path="/trailer/tv-show/season/:seasonID/episode/:episodeNumber"
              component={SuspenseHoC(
                lazy(() => import("./components/Players/TrailerPlayer"))
              )}
            />
          )}
          {vod && (
            <Route
              path="/trailer/tv-show/:showID/"
              component={SuspenseHoC(
                lazy(() => import("./components/Players/TrailerPlayer"))
              )}
            />
          )}

          {channels && (
            <Route
              path="/channels/:id"
              component={AuthorizationHoC(
                SuspenseHoC(lazy(() => import("pages/LiveTvPage")))
              )}
            />
          )}
          {channels && (
            <Route
              path="/channels"
              component={AuthorizationHoC(
                SuspenseHoC(lazy(() => import("pages/LiveTvPage")))
              )}
            />
          )}
          <Route
            path="/home"
            component={SuspenseHoC(lazy(() => import("pages/LandingPage")))}
          />
          <Route
            path="/page/:slug"
            component={SuspenseHoC(lazy(() => import("pages/StaticPage")))}
          />
          <Route
            path="/success"
            component={SuspenseHoC(lazy(() => import("pages/SuccessPage")))}
          />
          <Route
            path="/"
            component={AuthorizationHoC(MainPagesComponentWithNavbar)}
          />
          <Route
            component={SuspenseHoC(lazy(() => import("pages/NotFoundPage")))}
          />
        </Switch>
      </FloatingPlayerHoC>
    </Router>
  );
};

const mapStateToProps = state => ({
  composerConfig: state.composerConfig
});

export default connect(mapStateToProps)(Routes);
