import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import Slider from "react-slick";
import SingleSlide from "./components/SingleSlide";
import RentOrPlayProfilesModal from "../../Modals/RentOrPlayProfilesModal";
import InputPinModal from "components/Modals/InputPinModal";
import { find, get } from "lodash";
import { redirectToPlay } from "functions/logic-functions";
import { HIGHLIGHTED_CONTENT_URL } from "API-routes";
import { getMvodList, fetchMovieStream } from "reduxStore/actions";
import { getHighlightedContent } from "reduxStore/reducers/highlightedContent";
import { openNotificationWithIcon } from "functions/logic-functions";
import { SliderWrapper } from "./styledComponents";
import style from "./style.module.scss";

class HeaderSlider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openPlayModal: false,
      openParentalModal: false,
      openParentalModalForDetailsPage: false,
      selectedVOD: null,
      selectedVodID: null,
      alreadyEnteredParentalPin: false
    };
    this.includesDetaulfProfile = false;
    this.selectedVodProfile =
      localStorage.getItem("wecast-vod-profile") &&
      JSON.parse(localStorage.getItem("wecast-vod-profile"));
    this.subscribedProfileLength = null;
    this.isTriggeredFetchEndpoint = false;
  }

  componentDidMount() {
    const { getHighlightedContentDispatch, highlightedContent } = this.props;

    if (highlightedContent && !highlightedContent.length) {
      getHighlightedContentDispatch({
        url: `${HIGHLIGHTED_CONTENT_URL}`
      });
    }
    this.isTriggeredFetchEndpoint = true;
  }

  _onClose = type => {
    switch (type) {
      case "rent":
        this.setState({
          openRentModal: false,
          selectedRent: null
        });
        break;
      case "confirm":
        this.setState({
          openConfirmModal: false,
          openRentModal: true
        });
        break;
      case "play":
        this.setState({
          openPlayModal: false
        });
        break;
      default:
        break;
    }
  };

  _getRedirectDataIfSubscriptionExist = (vod, subscribedProfiles) => {
    if (!get(vod, "model.parental_rating.require_pin")) {
      if (
        (get(this.selectedVodProfile, "name") &&
          this.selectedVodProfile.name !== "Auto" &&
          this.includesDetaulfProfile) ||
        subscribedProfiles.length === 1
      ) {
        const selectedProfile =
          subscribedProfiles.length === 1
            ? subscribedProfiles[0]
            : this.selectedVodProfile;
        return {
          vod,
          selectedProfile
        };
      }
      return;
    }
  };

  _pushToPage = (path, item) => {
    this.props.history.push({
      pathname: path,
      state: {
        item: item.model,
        alreadyEnteredParentalPin: true
      }
    });
  };

  _redirectToDetailsPage = item => {
    const { alreadyEnteredParentalPin } = this.state;

    if (
      get(item, "model.parental_rating.require_pin") &&
      !alreadyEnteredParentalPin
    ) {
      this.setState({
        openParentalModalForDetailsPage: true,
        selectedVOD: item
      });

      return;
    }

    this._redirectToDetailsByCase(item);
  };

  _redirectToDetailsByCase = item => {
    if (item) {
      switch (item.type) {
        case 0:
          this._pushToPage(`/movies/${item.model_id}`, item);
          break;
        case 1:
          this._pushToPage(`/tv-shows/episode/${item.model_id}`, item);
          break;
        case 2:
          this._pushToPage(`/tv-shows/${item.model_id}`, item);
          break;
        default:
          break;
      }
    }
  };

  _onOpenPlayModal = id => {
    const { loggedUser, highlightedContent, intl } = this.props;

    if (!get(loggedUser, "account.subscription_expired")) {
      this.setState(
        {
          selectedVodID: id
        },
        () => {
          const vodInHighlightedContent = find(
            highlightedContent,
            item => parseInt(item.model_id) === parseInt(id)
          );

          this._onHighlightedContentFullDataSuccess(
            vodInHighlightedContent.model
          );
        }
      );
    } else {
      openNotificationWithIcon({
        type: "warning",
        message: intl.formatMessage({
          id: "notifications.subscriptionHasExpired"
        }),
        description: intl.formatMessage({
          id: "notifications.pleaseRenewalSubscription"
        })
      });
    }
  };

  _openModalOrShowNotification = profiles => {
    const { intl } = this.props;

    if (profiles && profiles.length) {
      this.setState({
        openPlayModal: true
      });
    } else {
      openNotificationWithIcon({
        type: "info",
        message: intl.formatMessage({
          id: "notifications.movieNotRented"
        }),
        description: intl.formatMessage({
          id: "notifications.rentMovieBeforeWatching"
        })
      });
    }
  };

  _onSuccess = (type, selectedProfileForPlay) => {
    const { highlightedContent } = this.props;
    const { selectedVodID } = this.state;
    const selectedVOD = find(
      highlightedContent,
      o => o.model_id === selectedVodID
    );

    if (type === "play") {
      this.setState(
        {
          openPlayModal: false
        },
        () => {
          this._redirectToPlayer(
            get(selectedVOD, "model"),
            selectedProfileForPlay
          );
        }
      );
    }
  };

  _setIfIncludesDefaultProfile = subscribedProfiles => {
    subscribedProfiles.forEach(item => {
      if (
        get(this.selectedVodProfile, "id") &&
        item.id === this.selectedVodProfile.id
      ) {
        this.includesDetaulfProfile = item;
      }
    });
  };

  _getSubscribedProfiles = result => {
    return (
      get(result, "movie_source.profiles") &&
      result.movie_source.profiles.filter(item => item.subscribed)
    );
  };

  _onHighlightedContentFullDataSuccess = result => {
    if (result) {
      const subscribedProfiles = this._getSubscribedProfiles(result);

      this._setIfIncludesDefaultProfile(subscribedProfiles);
      this.subscribedProfileLength = subscribedProfiles
        ? subscribedProfiles.length
        : null;

      const dataForRedirect = this._getRedirectDataIfSubscriptionExist(
        result,
        subscribedProfiles
      );

      if (dataForRedirect && !get(result, "parental_rating.require_pin")) {
        const { vod: selectedVodID, selectedProfile } = dataForRedirect;

        this._redirectToPlayer(selectedVodID, selectedProfile);
      } else if (
        get(result, "parental_rating.require_pin") &&
        ((subscribedProfiles && subscribedProfiles.length === 1) ||
          (subscribedProfiles && this.includesDetaulfProfile))
      ) {
        this._redirectToPlayerIfSubscribedProfileOneOrDefaultExist(
          result,
          subscribedProfiles,
          dataForRedirect
        );
      } else if (
        get(result, "parental_rating.require_pin") &&
        subscribedProfiles &&
        subscribedProfiles.length > 1
      ) {
        this._redirectToPlayerIfSubscribedMoreThanOne(
          subscribedProfiles,
          dataForRedirect
        );
      } else {
        this._openModalOrShowNotification(subscribedProfiles);
      }
    }
  };

  _redirectToPlayerIfSubscribedMoreThanOne = (
    subscribedProfiles,
    dataForRedirect
  ) => {
    if (dataForRedirect) {
      const { vod: selectedVodID, selectedProfile } = dataForRedirect;

      this._redirectToPlayer(selectedVodID, selectedProfile);
      return;
    }

    this.setState({
      openParentalModal: true,
      openPlayModal: false
    });
  };

  _redirectToPlayerIfSubscribedProfileOneOrDefaultExist = (
    vod,
    subscribedProfiles,
    dataForRedirect
  ) => {
    this.selectedVodProfile =
      this.includesDetaulfProfile || subscribedProfiles[0];

    if (dataForRedirect && !get(vod, "parental_rating.require_pin")) {
      const { vod: selectedVodID, selectedProfile } = dataForRedirect;

      this._redirectToPlayer(selectedVodID, selectedProfile);
      return;
    }

    this.setState({
      openParentalModal: true,
      openPlayModal: false
    });
  };

  _onParentalPinSuccess = () => {
    this.setState({
      openParentalModal: false,
      openPlayModal: true
    });
  };

  _onSlideClickPlay = (itemType, itemModelId) => {
    if (itemType === 3) {
      this.props.history.push(`/channels/${itemModelId}`);
    } else if (itemType === 1 || itemType === 0) {
      this._onOpenPlayModal(itemModelId);
    }
  };

  _redirectToPlayer = (vod, selectedVodProfile) => {
    this.setState(
      {
        selectedVodID: null
      },
      () => {
        redirectToPlay({
          VOD: vod,
          selectedProfile: selectedVodProfile,
          history: this.props.history,
          alreadyEnteredParentalPin: true
        });
      }
    );
  };

  _onRedirectToPlay = () => {
    const { selectedVodID } = this.state;
    const { highlightedContent } = this.props;
    const selectedVodDetails = find(
      highlightedContent,
      o => o.model_id === selectedVodID
    );
    const subscribedProfiles = this._getSubscribedProfiles(
      get(selectedVodDetails, "model")
    );

    this.selectedVodProfile =
      this.includesDetaulfProfile ||
      (subscribedProfiles.length && subscribedProfiles[0]);

    if (this.selectedVodProfile && selectedVodDetails) {
      this._redirectToPlayer(
        get(selectedVodDetails, "model"),
        this.selectedVodProfile
      );
    }
  };

  _renderList = () => {
    const { highlightedContent } = this.props;

    return highlightedContent
      .filter(
        item =>
          item.image ||
          get(item, "model.banners[0].preview_medium") ||
          get(item, "model.gallery[0].preview_medium") ||
          get(item, "model.cover.preview_medium") ||
          (get(item, "model.covers[0].preview_medium") &&
            item.title &&
            item.id) ||
          get(item, "model.screenshot_url")
      )
      .map(item => (
        <div key={item.id} style={{ position: "relative", overflow: "hidden" }}>
          <SingleSlide
            item={item}
            onSlideClickPlay={this._onSlideClickPlay}
            redirectToDetailsPage={this._redirectToDetailsPage}
          />
        </div>
      ));
  };

  _onSuccessParentalModalToDetailsRedirect = () => {
    const { selectedVOD } = this.state;

    this._redirectToDetailsByCase(selectedVOD);
  };

  _renderParentalModalForDetailsPage = () => {
    const { intl } = this.props;
    const { openParentalModalForDetailsPage } = this.state;

    if (openParentalModalForDetailsPage) {
      return (
        <InputPinModal
          open={openParentalModalForDetailsPage}
          type="parental"
          onSuccess={this._onSuccessParentalModalToDetailsRedirect}
          onClose={() =>
            this.setState({
              openParentalModalForDetailsPage: false,
              selectedVOD: null
            })
          }
          successButtonTitle={intl.formatMessage({
            id: "common.confirm"
          })}
          intl={intl}
        />
      );
    }
  };

  render() {
    const { openPlayModal, selectedVodID, openParentalModal } = this.state;
    const { highlightedContent, intl } = this.props;
    const movie = find(highlightedContent, o => o.model_id === selectedVodID);
    const subscribedProfiles =
      get(movie, "model.movie_source.profiles") &&
      movie.model.movie_source.profiles.filter(item => item.subscribed);
    const isSingleHighlightedContent =
      highlightedContent && highlightedContent.length === 1;

    const settings = {
      infinite: true,
      speed: 800,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      arrows: false,
      centerMode: true,
      focusOnSelect: true,
      centerPadding: "300px",
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            centerPadding: "250px"
          }
        },
        {
          breakpoint: 1400,
          settings: {
            centerPadding: "200px"
          }
        },
        {
          breakpoint: 1200,
          settings: {
            centerPadding: "150px"
          }
        },
        {
          breakpoint: 1000,
          settings: {
            centerPadding: "100px"
          }
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: "70px"
          }
        },
        {
          breakpoint: 468,
          settings: {
            centerPadding: "40px"
          }
        }
      ]
    };

    if (this.isTriggeredFetchEndpoint && !highlightedContent.length)
      return null;

    return (
      <div className={style.headerSliderWrapper}>
        <SliderWrapper isSingleHighlightedContent={isSingleHighlightedContent}>
          <Slider {...settings}>{this._renderList()}</Slider>
        </SliderWrapper>
        {openPlayModal && (
          <RentOrPlayProfilesModal
            open={openPlayModal}
            onSuccess={this._onSuccess}
            onClose={this._onClose}
            profiles={subscribedProfiles || []}
            eventType="play"
            successButtonTitle={<FormattedMessage id="common.play" />}
          />
        )}
        {openParentalModal && (
          <InputPinModal
            open={openParentalModal}
            type="parental"
            includes={!!this.includesDetaulfProfile}
            subscribedProfileLength={this.subscribedProfileLength}
            onSuccess={this._onParentalPinSuccess}
            onRedirectToPlay={this._onRedirectToPlay}
            onClose={() => this.setState({ openParentalModal: false })}
            successButtonTitle={intl.formatMessage({
              id: "common.confirm"
            })}
            intl={intl}
          />
        )}
        {this._renderParentalModalForDetailsPage()}
      </div>
    );
  }
}

const matchDispatchToProps = dispatch => ({
  getHighlightedContentDispatch: data => dispatch(getHighlightedContent(data)),
  getMvodListDispatch: data => dispatch(getMvodList(data)),
  fetchMovieStreamDispatch: data => dispatch(fetchMovieStream(data))
});

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  highlightedContent: state.highlightedContent,
  highlightedContentEpisodesFullData: state.highlightedContentEpisodesFullData
});

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(HeaderSlider)
);
