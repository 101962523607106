export const ADD_SELECTED_EPG_CATEGORY = "ADD_SELECTED_EPG_CATEGORY";

const selectedEpgCategory = (state = null, action) => {
  switch (action.type) {
    case ADD_SELECTED_EPG_CATEGORY:
      if (action.payload && action.payload.id !== state)
        return action.payload.id;
      else if (action.payload.id === state) return null;
      return state;
    default:
      return state;
  }
};

export default selectedEpgCategory;

export const addSelectedEpgCategory = data => {
  return {
    type: ADD_SELECTED_EPG_CATEGORY,
    payload: {
      id: data.id
    }
  };
};
