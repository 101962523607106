import React, { PureComponent, lazy } from "react";
import { withRouter, Route, Switch } from "react-router";
import { connect } from "react-redux";
import NavigationWrapper from "./NavigationWrapper";
import NotFoundPage from "../NotFoundPage";
import HomePage from "../HomePage";
import SearchPage from "../SearchPage";
import SuspenseHoC from "../../HOCs/SuspenseHoC";
import ChannelCategoriesPage from "../ChannelCategoriesPage";
import SingleMoviePage from "../SingleMoviePage";
import SingleEpisodePage from "../SingleEpisodePage";
import SingleTvShowPage from "../SingleTvShowPage";
import MoviesListPage from "../MoviesListPage";
import TvShowsListPage from "../TvShowsListPage";
import style from "./style.module.scss";

class MainPagesComponentWithNavbar extends PureComponent {
  componentWillUnmount() {
    document.querySelector("#app").style.overflow = "visible";
  }

  render() {
    const {
      composerConfig: {
        web: {
          modules: { vod, channels, epg, trending, home }
        }
      }
    } = this.props;

    return (
      <div className={style.mainWrapper}>
        <NavigationWrapper />
        <Switch>
          <Route
            exact
            path="/"
            component={home ? HomePage : ChannelCategoriesPage}
          />
          <Route
            path="/account"
            component={SuspenseHoC(lazy(() => import("../AccountPage")))}
          />
          {channels && (
            <Route
              path="/channel-categories/:categoryID"
              component={ChannelCategoriesPage}
            />
          )}
          {channels && (
            <Route
              path="/channel-categories"
              component={ChannelCategoriesPage}
            />
          )}

          {(vod || channels) && <Route path="/search" component={SearchPage} />}

          {vod && (
            <Route path="/tv-shows/episode/:id" component={SingleEpisodePage} />
          )}
          {vod && (
            <Route exact path="/tv-shows/:id" component={SingleTvShowPage} />
          )}
          {vod && <Route path="/tv-shows" component={TvShowsListPage} />}

          {vod && (
            <Route exact path="/movies/:id" component={SingleMoviePage} />
          )}
          {vod && <Route path="/movies" component={MoviesListPage} />}

          {trending && (
            <Route
              path="/trending"
              component={SuspenseHoC(lazy(() => import("../TrendingPage")))}
            />
          )}
          {epg && (
            <Route
              path="/epg-channels"
              component={SuspenseHoC(lazy(() => import("../EpgPage")))}
            />
          )}
          {/*

          {epg && (
            <Route
              path="/epg-channels/page/:pageNumber"
              component={SuspenseHoC(lazy(() => import("../EpgChannelsPage")))}
            />
          )}
          {epg && (
            <Route
              path="/epg-channels"
              component={SuspenseHoC(lazy(() => import("../EpgChannelsPage")))}
            />
          )}
             */}
          <Route path="/404" component={NotFoundPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  composerConfig: state.composerConfig
});

export default withRouter(
  connect(mapStateToProps)(MainPagesComponentWithNavbar)
);
