import { call } from "redux-saga/effects";
import { resetPassword, forgotPassword } from "helper-functions/http-functions";

export const forgotPasswordHandler = function*({ payload }) {
  try {
    const result = yield call(forgotPassword, payload);

    if (result) {
      payload.successCallback(result.data.message);
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export const resetPasswordHandler = function*({ payload }) {
  try {
    const result = yield call(resetPassword, payload);
    if (result && result.data) {
      payload.successCallback(result.data.message);
    } else if (payload.failedCallback) payload.failedCallback();
  } catch (err) {
    console.info("ERR", err);
  }
};
