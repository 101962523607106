export const ADD_VOD_PROFILE = "ADD_VOD_PROFILE";
export const ADD_VOD_PROFILE_SUCCESS = "ADD_VOD_PROFILE_SUCCESS";

const vodProfile = (state = null, action) => {
  switch (action.type) {
    case ADD_VOD_PROFILE_SUCCESS:
      if (action.payload) return action.payload;
      return state;
    default:
      return state;
  }
};

export default vodProfile;

export const addVodProfile = payload => {
  return {
    type: ADD_VOD_PROFILE,
    payload: {
      data: payload.data,
      successCallback: payload.successCallback
    }
  };
};
