import React, { Fragment, memo } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import classNames from "classnames";
import { Col, Row } from "react-flexbox-grid";
import ImageGallery from "components/ImageGallery";
import RoundIconButton from "components/Buttons/RoundIconButton";
import PlayIcon from "react-icons/lib/md/play-arrow";
import MovieIcon from "react-icons/lib/md/movie";
import ThumbUpIcon from "react-icons/lib/md/thumb-up";
import ShopIcon from "react-icons/lib/md/shop";
import Popover from "antd/lib/popover";
import { isCensorshipCard } from "functions/logic-functions";
import imdbImg from "./images/imdb.png";
import style from "./style.module.scss";
import commonStyle from "../../../../styles/commonPagesAnimationStyle.module.scss";

const EpisodeDetails = ({
  intl,
  episode,
  visible,
  showAnimationClasses,
  setRent,
  handleVisibleChangePopover,
  handleHidePopover,
  getSpecificSeason,
  onTrailerClick,
  onOpenPlayModal,
  onOpenRatingStars
}) => {
  const _renderGenres = () => {
    if (episode.genres && episode.genres.length > 0) {
      return (
        <span>{episode.genres.map(genre => genre.name.trim()).join(", ")}</span>
      );
    }
  };

  const _renderRoundIconButton = (onClick, Icon, translationKey) => {
    return (
      <RoundIconButton
        onClick={onClick}
        iconComponent={<Icon />}
        label={intl.formatMessage({
          id: translationKey
        })}
      />
    );
  };

  const _renderRentButtons = () => {
    const { movie_source } = episode;

    if (episode && movie_source && movie_source.profiles) {
      return movie_source.profiles
        .filter(item => !item.subscribed)
        .map(item => {
          return (
            <RoundIconButton
              key={item.id}
              onClick={() => {
                setRent(item);
              }}
              iconComponent={<ShopIcon />}
              label={`${intl.formatMessage({
                id: "common.rent"
              })} ${item.name}`}
            />
          );
        });
    }
  };

  const _renderImageCoverAndGallery = episode => {
    return (
      <Col
        xs={12}
        smOffset={2}
        sm={8}
        md={4}
        mdOffset={0}
        lg={3}
        className={classNames({
          [commonStyle.leftPart]: showAnimationClasses,
          [commonStyle.leftPartReverse]: !showAnimationClasses
        })}
      >
        <img
          className={classNames({
            [style.blurCard]: isCensorshipCard(episode)
          })}
          src={episode.cover.preview_ar || episode.cover.medium_ar}
          alt={""}
        />
        {isCensorshipCard(episode)
          ? ""
          : episode.gallery &&
            episode.gallery.length > 0 && (
              <ImageGallery gallery={episode.gallery} />
            )}
      </Col>
    );
  };

  const _renderRatingBlock = episode => {
    if (episode && episode.rating) {
      return (
        <Fragment>
          <img src={imdbImg} className={style.imdbLogo} alt={""} />
          <span>
            {" "}
            {episode.rating}
            <span className={style.allRating}>/10</span>
          </span>
        </Fragment>
      );
    }
  };

  const _renderSeasonsSelect = episode => {
    if (get(episode, "season_count")) {
      const { seasons } = episode;

      return (
        <div className="popoverArea">
          <div className={style.iconWrapper}>
            <div>
              <Popover
                visible={visible}
                getPopupContainer={() => document.querySelector(".popoverArea")}
                onVisibleChange={handleVisibleChangePopover}
                placement="rightTop"
                content={
                  <div className={style.seasonsPopover}>
                    {seasons.map(item => (
                      <div
                        key={item.name}
                        onClick={() => {
                          handleHidePopover();
                          getSpecificSeason(item.id);
                        }}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                }
                trigger="click"
              />
            </div>
          </div>
          <p>
            {intl.formatMessage({
              id: "vods.seasons"
            })}
          </p>
        </div>
      );
    }
  };

  const _renderButtons = () => {
    const {
      id,
      multi_event_vod_season_id,
      episode_number,
      trailer_source: { url }
    } = episode;

    if (id) {
      return (
        <div className={style.buttonsWrapper}>
          {id && (
            <Fragment>
              {_renderRoundIconButton(onOpenPlayModal, PlayIcon, "common.play")}
              {_renderRoundIconButton(
                onOpenRatingStars,
                ThumbUpIcon,
                "vods.rate"
              )}
            </Fragment>
          )}
          {id && _renderRentButtons()}
          {id && url && (
            <RoundIconButton
              onClick={onTrailerClick}
              url={`/trailer/tv-show/season/${multi_event_vod_season_id}/episode/${episode_number}`}
              iconComponent={<MovieIcon />}
              label={intl.formatMessage({
                id: "vods.trailer"
              })}
            />
          )}
          {_renderSeasonsSelect()}
        </div>
      );
    }
  };

  return (
    <div>
      <div className={style.mainHeadWrapper}>
        <Row>
          {_renderImageCoverAndGallery(episode)}
          <Col
            xs={12}
            sm={12}
            md={7}
            className={classNames(style.rightPart, {
              [commonStyle.rightPartAnimation]: showAnimationClasses,
              [commonStyle.rightPartAnimationReverse]: !showAnimationClasses
            })}
          >
            <h4>{episode.multi_event_vod_season.name}</h4>
            <div className={style.smallerMovieDetails}>
              {episode.year && (
                <span className={"titleYearDescription"}>{episode.year}</span>
              )}
              {_renderGenres()}
              {episode.parental_rating && episode.parental_rating.code && (
                <span>{episode.parental_rating.code}</span>
              )}
              {_renderRatingBlock()}
            </div>
            <h1>
              <span>Episode {episode.episode_number}</span>
              &nbsp;
              {episode.title}
            </h1>
            <p>{episode.description}</p>
            {_renderButtons()}
          </Col>
        </Row>
      </div>
    </div>
  );
};

EpisodeDetails.propTypes = {
  intl: PropTypes.object.isRequired,
  episode: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  showAnimationClasses: PropTypes.bool.isRequired,
  setRent: PropTypes.func.isRequired,
  handleVisibleChangePopover: PropTypes.func.isRequired,
  handleHidePopover: PropTypes.func.isRequired,
  getSpecificSeason: PropTypes.func.isRequired,
  onTrailerClick: PropTypes.func.isRequired,
  onOpenPlayModal: PropTypes.func.isRequired,
  onOpenRatingStars: PropTypes.func.isRequired
};

export default memo(EpisodeDetails);
