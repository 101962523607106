import { call } from "redux-saga/effects";
import { fetchData } from "helper-functions/http-functions";

const getSingleChannel = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result && payload.successCallback) {
      payload.successCallback(result);
    }
  } catch (err) {
    console.info("er", err);
  }
};

export default getSingleChannel;
