import React, { PureComponent, createRef } from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { get } from "lodash";
import Slider from "react-slick";
import RoundCard from "components/Cards/RoundCard";
import { getMoviesNewReleases } from "reduxStore/actions";
import { areImagesTheSame, isCensorshipCard } from "functions/logic-functions";
import { CarouselWrapper } from "./styledComponents";
import Arrow from "../components/Arrow";
import style from "./style.module.scss";

const carouselResponsiveParams = [
  {
    breakpoint: 1599,
    settings: {
      slidesToShow: 6,
      slidesToScroll: 6,
      initialSlide: 0
    }
  },
  {
    breakpoint: 1399,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0
    }
  },
  {
    breakpoint: 1199,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0
    }
  },
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 0
    }
  },
  {
    breakpoint: 479,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0
    }
  }
];

class FlexibleMoreDetailsCarousel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      isLastIndex: false
    };
    this.sliderRef = createRef();
  }

  renderList = () => {
    const {
      items,
      onOpenPlayModal,
      urlRoute: mainUrlRoute,
      showPlayIcon = true,
      continuePlayWithoutResumeModal
    } = this.props;

    return items.map(item => {
      let urlRoute = "";

      switch (item.type) {
        case 0:
          urlRoute = `/play/movie/${item.id}`;
          break;
        case 1:
          urlRoute = `/play/tv-show/episode/${item.id}`;
          break;
        case 3:
          urlRoute = `/channels/${item.id}`;
          break;
        default:
          break;
      }

      return (
        <div key={item.id}>
          <RoundCard
            id={item.id}
            item={item}
            imgUrl={
              isCensorshipCard(item)
                ? item.logo_url
                : item.screenshot_url ||
                  get(item, "cover.medium_ar") ||
                  (item.covers && item.covers[0].medium_ar)
            }
            urlRoute={
              (mainUrlRoute &&
                item.channel_number &&
                `${mainUrlRoute}/${item.id}`) ||
              urlRoute
            }
            title={item.title}
            episodeNumber={item.episode_number}
            showPlayIcon={showPlayIcon}
            onOpenPlayModal={onOpenPlayModal}
            roundedStyle={true}
            continue_watching={item.continue_watching}
            useObjectContainPropertyImage={areImagesTheSame(
              item.logo_url,
              item.screenshot_url
            )}
            continuePlayWithoutResumeModal={continuePlayWithoutResumeModal}
          />
        </div>
      );
    });
  };

  setIsLastIndex = index => {
    const { useInfiniteScroll } = this.props;
    const itemsNumber = this.sliderRef.current.innerSlider.track.props
      .slideCount;
    const showPerSlideNumber = this.sliderRef.current.innerSlider.track.props
      .slidesToShow;
    const maxSlides = index + showPerSlideNumber >= itemsNumber;

    this.setState({ isLastIndex: maxSlides && !useInfiniteScroll });
  };

  render() {
    const { title, useInfiniteScroll, onAppendChannels } = this.props;
    const { isLastIndex } = this.state;
    const settings = {
      infinite: useInfiniteScroll || false,
      speed: 500,
      lazyLoad: true,
      slidesToShow: 7,
      slidesToScroll: 7,
      initialSlide: 0,
      arrows: true,
      nextArrow: (
        <Arrow icon="right" hasInfiniteScroll={useInfiniteScroll || false} />
      ),
      prevArrow: (
        <Arrow icon="left" hasInfiniteScroll={useInfiniteScroll || false} />
      ),
      afterChange: index => {
        if (onAppendChannels) onAppendChannels(index);
        this.setIsLastIndex(index);
      },
      responsive: carouselResponsiveParams
    };

    return (
      <div className={style.container}>
        <CarouselWrapper
          isLastIndex={isLastIndex}
          isInfinite={useInfiniteScroll}
        >
          <h2>{title}</h2>
          <Slider ref={this.sliderRef} {...settings}>
            {this.renderList()}
          </Slider>
        </CarouselWrapper>
      </div>
    );
  }
}

FlexibleMoreDetailsCarousel.propTypes = {
  title: propTypes.string.isRequired,
  urlRoute: propTypes.string,
  items: propTypes.array,
  showPlayIcon: propTypes.bool,
  useInfiniteScroll: propTypes.bool,
  onOpenPlayModal: propTypes.func,
  onAppendChannels: propTypes.func,
  continuePlayWithoutResumeModal: propTypes.bool
};

const matchDispatchToProps = dispatch => ({
  getMoviesNewReleasesDispatch: data => dispatch(getMoviesNewReleases(data))
});

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
});

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(FlexibleMoreDetailsCarousel)
);
