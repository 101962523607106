/*eslint-disable */
import React, { PureComponent } from "react";
/*eslint-enable */
import { connect } from "react-redux";
import * as continueWatchingActions from "reduxStore/reducers/cachedContinueWatchingList";
import { GET_CONTINUE_WATCHING } from "API-routes";

class InitialApiRequestsHOC extends PureComponent {
  componentDidMount() {
    this.getContinueWatching(this.props);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.getContinueWatching(nextProps);
  }

  getContinueWatching = props => {
    const {
      getContinueWatchingVods,
      loggedUser,
      cachedContinueWatchingList
    } = props;

    if (loggedUser && !cachedContinueWatchingList) {
      getContinueWatchingVods({
        url: GET_CONTINUE_WATCHING
      });
    }
  };

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  cachedContinueWatchingList: state.cachedContinueWatchingList
});

const mapDispatchToProps = dispatch => ({
  getContinueWatchingVods: data =>
    dispatch(continueWatchingActions.getContinueWatchingVods(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InitialApiRequestsHOC);
