import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import loggedUser from "./loggedUser";
import pageStructure from "./pageStructure";
import channels from "./channels";
import { EPG, appendEPG } from "./EPG";
import channelCategories from "./channelCategories";
import genres from "./genres";
import vodProfile from "./vodProfile";
import subscriptions from "./subscriptions";
import cachedHorizontalHomepage from "./cachedHorizontalHomepage";
import paymentHistory from "./paymentHistory";
import devices from "./devices";
import cachedSingleMoviesCarousels from "./cachedSingleMoviesCarousels";
import highlightedContent from "./highlightedContent";
import textSearchVODS from "./textSearchVODS";
import searchText from "./searchText";
import filteredGenresForSearchPage from "./filteredGenresForSearchPage";
import cachedSingleTvShowsCarousels from "./cachedSingleTvShowsCarousels";
import staticPages from "./staticPages";
import selectedEpgCategory from "./selectedEpgCategory";
import socket from "./socket";
import cachedContinueWatchingList from "./cachedContinueWatchingList";
import composerConfig from "./composerConfig";
import lang from "./lang";
import vodProfilesList from "./vodProfilesList";
import remindersList from "./remindersList";
import vodShowTypes from "./vodShowTypes";
import floatingPlayer from "./floatingPlayer";

const reducers = combineReducers({
  form: formReducer,
  loggedUser,
  pageStructure,
  channels,
  EPG,
  appendEPG,
  channelCategories,
  genres,
  vodProfile,
  subscriptions,
  cachedHorizontalHomepage,
  paymentHistory,
  devices,
  cachedSingleMoviesCarousels,
  cachedSingleTvShowsCarousels,
  highlightedContent,
  textSearchVODS,
  searchText,
  filteredGenresForSearchPage,
  staticPages,
  selectedEpgCategory,
  socket,
  cachedContinueWatchingList,
  lang,
  composerConfig,
  remindersList,
  vodProfilesList,
  vodShowTypes,
  floatingPlayer
});

export default reducers;
