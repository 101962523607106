import React, { PureComponent } from "react";
import classNames from "classnames";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import RatingStars from "components/RatingStars";
import ShopIcon from "react-icons/lib/md/shop";
import RentOrPlayProfilesModal from "components/Modals/RentOrPlayProfilesModal";
import InputPinModal from "components/Modals/InputPinModal";
import ConfirmRentModal from "components/Modals/ConfirmRentModal";
import SeasonCarousel from "components/Carousels/SeasonCarousel";
import RoundIconButton from "components/Buttons/RoundIconButton";
import ScrollTopHOC from "HOCs/ScrollTopHOC";
import { get, isEqual } from "lodash";
import { Loader } from "semantic-ui-react";
import {
  openNotificationWithIcon,
  redirectToPlay,
  redirectToNotFoundPage
} from "functions/logic-functions";
import * as userActions from "reduxStore/reducers/loggedUser";
import * as vodActions from "reduxStore/actions";
import * as floatingPlayerActions from "reduxStore/reducers/floatingPlayer";
import { addVodProfile } from "reduxStore/reducers/vodProfile";
import { SINGLE_VOD, RENT_VOD_URL, EPISODE_RATE_URL } from "API-routes";
import { sendAnalyticsData } from "../../helper-functions/logic-functions";
import { injectIntl } from "react-intl";
import EpisodeDetails from "./components/EpisodeDetails";
import style from "./style.module.scss";

class SingleEpisodePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      episode: null,
      showLoader: true,
      season: [],
      visible: false,
      openRentSelectModal: false,
      openConfirmModal: false,
      openPlayModal: false,
      validParentalPin: false,
      openParentalModal: false,
      openPurchaseModal: false,
      openedRentModalData: null,
      selectedRent: null,
      requirePin: false,
      openStars: false,
      showAnimationClasses: false
    };
    this.DEFAULT_PROFILE =
      localStorage.getItem("wecast-vod-profile") &&
      JSON.parse(localStorage.getItem("wecast-vod-profile"));
  }

  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      location: { state }
    } = this.props;

    if (state && state.item) {
      this.setState(
        {
          episode: state.item,
          showAnimationClasses: true
        },
        () => {
          const { episode } = this.state;
          const { cachedSingleTvShowsCarousels } = this.props;
          const season = cachedSingleTvShowsCarousels.find(
            item => item.id === episode.multi_event_vod_season_id
          );

          this._openParentalPin(state.item);

          if (!season) {
            this._fetchSeasons();
          }
        }
      );
    } else if (id) {
      this._fetchEpisode(id);
    }
    sendAnalyticsData("single_episode_view");
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { id: nextId }
      },
      location: { state: nextState, search }
    } = nextProps;
    const {
      match: {
        params: { id }
      },
      location: { state },
      setOpenPlayer
    } = this.props;

    if (search && (search.includes("play") || search.includes("trailer"))) {
      setOpenPlayer(true);
    }

    if (get(nextState, "item") && !isEqual(state, nextState)) {
      this.setState(
        {
          showAnimationClasses: false
        },
        () => {
          this._setNewReceivedEpisode(nextState.item);
        }
      );
    } else {
      this.setState(
        {
          showLoader: true
        },
        () => {
          if (nextId !== id && nextId !== undefined) {
            this._fetchEpisode(nextId);
          }
        }
      );
    }
  }

  _setNewReceivedEpisode = episode => {
    const { cachedSingleTvShowsCarousels } = this.props;
    const season = cachedSingleTvShowsCarousels.find(
      item => item.id === episode.multi_event_vod_season_id
    );

    if (!season) {
      this._fetchSeasons();
    }

    setTimeout(() => {
      this.setState({
        episode,
        showAnimationClasses: true
      });
    }, 250);
  };

  _fetchEpisode = id => {
    const { getMvodList } = this.props;

    getMvodList({
      url: `${SINGLE_VOD}?filter[id]=${id}&filter[type]=1&config[enable_rate]=1`,
      successCallback: this._successCallback
    });
  };

  _handleHidePopover = () => {
    this.setState({
      visible: false
    });
  };

  _handleVisibleChangePopover = visible => {
    this.setState({ visible });
  };

  _successCallback = data => {
    if (data && data.items.length) {
      this.setState(
        {
          episode: data.items[0],
          showLoader: false,
          showAnimationClasses: true
        },
        () => this._fetchSeasons()
      );

      this._openParentalPin(data.items[0]);
    } else {
      redirectToNotFoundPage(this.props.history);
    }
  };

  _openParentalPin = data => {
    const { history } = this.props;

    if (get(history, "location.state.alreadyEnteredParentalPin")) {
      this.setState({ requirePin: false });
    } else if (get(data, "parental_rating.require_pin")) {
      this.setState({
        requirePin: true
      });
    }
  };

  _fetchSeasons = () => {
    const { getMvodList } = this.props;
    const { multi_event_vod_season_id } = this.state.episode;

    if (multi_event_vod_season_id) {
      getMvodList({
        url: `${SINGLE_VOD}?filter[multi_event_vod_season_id]=${multi_event_vod_season_id}&filter[type]=1`,
        updateSeasonCarouselReducer: true
      });
    }
  };

  _getSpecificSeason = id => {
    const { getMvodList } = this.props;

    if (id >= 0) {
      getMvodList({
        url: `${SINGLE_VOD}?filter[multi_event_vod_season_id]=${id}&filter[type]=1`
      });
    }
  };

  _setRent = openedRentModalData => {
    const { loggedUser, intl } = this.props;

    if (get(loggedUser, "account.subscription_expired")) {
      openNotificationWithIcon({
        type: "warning",
        message: intl.formatMessage({
          id: "notifications.subscriptionHasExpired"
        })
      });
    } else {
      this.setState({
        openPurchaseModal: true,
        openedRentModalData,
        selectedRent:
          openedRentModalData.pricing.length === 1
            ? openedRentModalData.pricing[0]
            : null
      });
    }
  };

  _onClose = type => {
    switch (type) {
      case "rent":
        this.setState({
          openRentSelectModal: false,
          selectedRent: null,
          openedRentModalData: null
        });
        break;
      case "confirm":
        this.setState({
          openConfirmModal: false,
          openRentSelectModal: true
        });
        break;
      case "play":
        this.setState({
          openPlayModal: false
        });
        break;
      default:
        break;
    }
  };

  _onConfirm = () => {
    const { openedRentModalData, episode, selectedRent } = this.state;
    const TVOD_DTR = "tvod_dtr";

    this.setState(
      {
        openConfirmModal: false
      },
      () => {
        let body = {
          single_event_vod_id: episode.id,
          vod_profile_id: openedRentModalData.id
        };

        if (
          get(openedRentModalData, "business_model") === TVOD_DTR &&
          get(selectedRent, "duration")
        ) {
          body.duration = selectedRent.duration;
        }

        this._rentVod(body);
        sendAnalyticsData("single_episode_rent_profile");
      }
    );
  };

  _rentVod = body => {
    const { rentVOD } = this.props;

    rentVOD({
      url: RENT_VOD_URL,
      body,
      successCallback: this._rentVodSuccessCallback
    });
  };

  _rentVodSuccessCallback = success => {
    const { openedRentModalData, episode } = this.state;

    if (success) {
      let updatedEpisode = Object.assign({}, episode);

      updatedEpisode.movie_source.profiles.forEach(item => {
        if (item.id === openedRentModalData.id) item.subscribed = true;
      });

      this.setState({
        episode: updatedEpisode
      });
    }
  };

  _onPlay = selectedProfile => {
    this.setState(
      {
        openPlayModal: false
      },
      () => {
        const { episode } = this.state;

        this._resetMinimizedPlayer();
        this.props.history.replace({
          search: "play",
          state: {
            item: episode,
            alreadyEnteredParentalPin: true,
            selectedProfile
          }
        });
      }
    );
  };

  _resetMinimizedPlayer = () => {
    const {
      floatingPlayer: { isPlayerOpened },
      setPlayerSize,
      setOpenPlayer
    } = this.props;

    if (isPlayerOpened) {
      setOpenPlayer(false);
      setPlayerSize("maximize");
    }
  };

  _onSuccess = (type, data) => {
    switch (type) {
      case "confirm":
        this._onConfirm();
        break;

      case "play":
        this._onPlay(data);
        break;
      default:
        break;
    }
  };

  _onSuccessRentChoose = (eventType, data) => {
    this.setState({
      openRentSelectModal: false,
      openConfirmModal: true,
      selectedRent: data
    });
  };

  _getSubscribedProfiles = () => {
    const { episode } = this.state;

    if (episode) {
      return (
        (get(episode, "movie_source.profiles") &&
          get(episode, "movie_source.profiles").filter(
            item => item.subscribed
          )) ||
        []
      );
    }
    return [];
  };

  _isSelectedProfileInSubscribed = subscribedProfiles => {
    if (subscribedProfiles) {
      return !!subscribedProfiles.find(
        item =>
          get(this.DEFAULT_PROFILE, "id") &&
          item.id === get(this.DEFAULT_PROFILE, "id")
      );
    } else {
      const subProfiles = this._getSubscribedProfiles();

      return !!subProfiles.find(
        item =>
          get(this.DEFAULT_PROFILE, "id") &&
          item.id === get(this.DEFAULT_PROFILE, "id")
      );
    }
  };

  _onSuccessParentalPin = () => {
    this.setState({
      openParentalModal: false,
      openPlayModal: true
    });
  };

  _redirectToPlayWhenSelectedVodProfileIsNotAutoOrNotRequirePin = (
    subscribedProfiles,
    isDefaultProfileIncluded
  ) => {
    const { addVodProfileDispatch } = this.props;
    const { episode } = this.state;

    if (
      (subscribedProfiles && subscribedProfiles.length === 1) ||
      isDefaultProfileIncluded
    ) {
      redirectToPlay({
        VOD: episode,
        selectedProfile:
          subscribedProfiles.length === 1
            ? subscribedProfiles[0]
            : this.DEFAULT_PROFILE,
        addVodProfileDispatch,
        history: this.props.history
      });
    } else {
      this.setState({
        openPlayModal: true
      });
    }
  };

  _redirectToPlayIfOnlyOneSubscribedProfile = () => {
    this.setState({
      openParentalModal: true,
      openPlayModal: false
    });
  };

  _redirectToPlayIsAlreadyEnteredPin = (
    subscribedProfiles,
    isDefaultProfileIncluded
  ) => {
    const { addVodProfileDispatch } = this.props;
    const { episode } = this.state;

    if (subscribedProfiles.length) {
      if (subscribedProfiles.length === 1 || isDefaultProfileIncluded) {
        redirectToPlay({
          VOD: episode,
          selectedProfile:
            subscribedProfiles.length === 1
              ? subscribedProfiles[0]
              : this.DEFAULT_PROFILE,
          addVodProfileDispatch,
          history: this.props.history,
          alreadyEnteredParentalPin: true
        });
        return;
      }

      this.setState({
        openParentalModal: false,
        openPlayModal: true
      });
    }
  };

  _redirectToPlayIfMoreThanOneSubscribedProfile = () => {
    this.setState({
      openParentalModal: true,
      openPlayModal: false
    });
  };

  _redirectToPlayIfRequiredPin = (
    subscribedProfiles,
    isDefaultProfileIncluded
  ) => {
    const {
      location: { state }
    } = this.props;
    const { requirePin } = this.state;

    if ((state && state.alreadyEnteredParentalPin) || !requirePin) {
      this._redirectToPlayIsAlreadyEnteredPin(
        subscribedProfiles,
        isDefaultProfileIncluded
      );
    } else if (subscribedProfiles) {
      if (subscribedProfiles.length === 1 || isDefaultProfileIncluded) {
        this._redirectToPlayIfOnlyOneSubscribedProfile();
      } else if (subscribedProfiles.length > 1) {
        this._redirectToPlayIfMoreThanOneSubscribedProfile();
      }
    }
  };

  _showNotification = type => {
    const { intl } = this.props;
    let data = {};

    if (type === "EPISODE_NOT_RENTED") {
      data = {
        type: "info",
        message: intl.formatMessage({
          id: "notifications.episodeIsNotRented"
        }),
        description: intl.formatMessage({
          id: "notifications.rentEpisodeBeforeWatching"
        })
      };
    } else if ("SUBSCRIPTION_EXPIRED") {
      data = {
        type: "warning",
        message: intl.formatMessage({
          id: "notifications.subscriptionHasExpired"
        })
      };
    }

    openNotificationWithIcon(data);
  };

  _onOpenPlayModal = () => {
    const { loggedUser } = this.props;
    const { episode } = this.state;

    if (
      get(loggedUser, "account") &&
      !loggedUser.account.subscription_expired
    ) {
      const subscribedProfiles = this._getSubscribedProfiles();
      const isDefaultProfileIncluded = this._isSelectedProfileInSubscribed(
        subscribedProfiles
      );

      if (subscribedProfiles && subscribedProfiles.length) {
        if (
          (get(this.DEFAULT_PROFILE, "name") &&
            this.DEFAULT_PROFILE.name !== "Auto" &&
            isDefaultProfileIncluded &&
            !get(episode, "parental_rating.require_pin")) ||
          (subscribedProfiles.length === 1 &&
            !get(episode, "parental_rating.require_pin"))
        ) {
          this._redirectToPlayWhenSelectedVodProfileIsNotAutoOrNotRequirePin(
            subscribedProfiles,
            isDefaultProfileIncluded
          );
        } else if (get(episode, "parental_rating.require_pin")) {
          this._redirectToPlayIfRequiredPin(
            subscribedProfiles,
            isDefaultProfileIncluded
          );
        } else if (
          subscribedProfiles.length > 1 &&
          !isDefaultProfileIncluded &&
          !get(episode, "parental_rating.require_pin")
        ) {
          this.setState({
            openPlayModal: true
          });
        }
      } else if (!episode.subscribed) {
        this._showNotification("EPISODE_NOT_RENTED");
      }
    } else {
      this._showNotification("SUBSCRIPTION_EXPIRED");
    }
  };

  _rateEpisode = rate => {
    const { rateVOD } = this.props;
    const { episode } = this.state;

    if (episode && episode.id) {
      rateVOD({
        url: `${EPISODE_RATE_URL}/${episode.id}/${rate}`
      });
      sendAnalyticsData("rate_episode");
    }
  };

  _renderRentButtons = () => {
    const { intl } = this.props;
    const { episode } = this.state,
      { movie_source } = episode;

    if (episode && movie_source && movie_source.profiles) {
      return movie_source.profiles
        .filter(item => !item.subscribed)
        .map(item => {
          return (
            <RoundIconButton
              key={item.id}
              onClick={() => {
                this._setRent(item);
              }}
              iconComponent={<ShopIcon />}
              label={`${intl.formatMessage({
                id: "common.rent"
              })} ${item.name}`}
            />
          );
        });
    }
  };

  _onTrailerClick = () => {
    const { episode, requirePin } = this.state;
    const { history } = this.props;

    if (!requirePin) {
      this._resetMinimizedPlayer();
      history.replace({
        search: "trailer",
        state: {
          item: episode,
          alreadyEnteredParentalPin: true
        }
      });
    }
  };

  _onSuccessTrailerParental = () => this._openTrailerPlayer();

  _openTrailerPlayer = () => {
    const { episode } = this.state;
    const { history } = this.props;

    this._resetMinimizedPlayer();
    history.replace({
      search: "trailer",
      state: {
        item: episode,
        alreadyEnteredParentalPin: true
      }
    });
  };

  _onClosePurchasePin = () => this.setState({ openPurchaseModal: false });

  _onSuccessPurchasePin = () => {
    this.setState({
      openPurchaseModal: false,
      openRentSelectModal: true
    });
  };

  _onOpenRatingStars = () => this.setState({ openStars: true });

  _renderEpisode = () => {
    const { episode, showLoader, showAnimationClasses, visible } = this.state;
    const { intl } = this.props;

    if (showLoader && !episode) {
      return (
        <div className={style.loaderWrapper}>
          <Loader active={showLoader} />
        </div>
      );
    } else {
      return (
        <EpisodeDetails
          intl={intl}
          episode={episode}
          visible={visible}
          showAnimationClasses={showAnimationClasses}
          setRent={this._setRent}
          handleVisibleChangePopover={this._handleVisibleChangePopover}
          handleHidePopover={this._handleHidePopover}
          getSpecificSeason={this._getSpecificSeason}
          onTrailerClick={this._onTrailerClick}
          onOpenPlayModal={this._onOpenPlayModal}
          onOpenRatingStars={this._onOpenRatingStars}
        />
      );
    }
  };

  _renderEpisodesCarousel = () => {
    const { intl } = this.props;
    const { episode } = this.state;

    if (episode) {
      const {
        cachedSingleTvShowsCarousels,
        match: {
          params: { id }
        }
      } = this.props;
      const season = cachedSingleTvShowsCarousels.find(
        item => item.id === episode.multi_event_vod_season_id
      );

      if (!season || !season.items) return;

      return (
        <SeasonCarousel
          title={get(episode, "multi_event_vod_season.name") || ""}
          items={season.items}
          urlRoute={`/tv-shows/episode`}
          activeEpisodeID={id}
          intl={intl}
        />
      );
    }
  };

  _renderConfirmRentModal = () => {
    const { openConfirmModal, selectedRent } = this.state;

    return (
      <ConfirmRentModal
        eventType="confirm"
        open={openConfirmModal}
        selectedRent={selectedRent}
        onSuccess={this._onSuccess}
        onClose={this._onClose}
      />
    );
  };

  _renderPurchasePinModal = () => {
    const { intl } = this.props;
    const { openPurchaseModal } = this.state;

    if (openPurchaseModal) {
      return (
        <InputPinModal
          intl={intl}
          open={openPurchaseModal}
          type="purchase"
          successButtonTitle={intl.formatMessage({
            id: "common.confirm"
          })}
          onSuccess={this._onSuccessPurchasePin}
          onClose={this._onClosePurchasePin}
        />
      );
    }
  };

  _renderSelectRentModal = () => {
    const { intl } = this.props;
    const { openRentSelectModal, openedRentModalData } = this.state;

    if (openRentSelectModal) {
      return (
        <RentOrPlayProfilesModal
          intl={intl}
          open={openRentSelectModal}
          profiles={openedRentModalData}
          eventType="rent"
          successButtonTitle={intl.formatMessage({
            id: "common.rent"
          })}
          onSuccess={this._onSuccessRentChoose}
          onClose={this._onClose}
        />
      );
    }
  };

  _renderSelectProfileToPlayModal = () => {
    const { intl } = this.props;
    const { episode, openPlayModal } = this.state;
    const subscribedProfiles =
      get(episode, "movie_source.profiles") &&
      get(episode, "movie_source.profiles").filter(item => item.subscribed);

    if (openPlayModal) {
      return (
        <RentOrPlayProfilesModal
          intl={intl}
          open={openPlayModal}
          profiles={subscribedProfiles || []}
          eventType="play"
          successButtonTitle={intl.formatMessage({
            id: "common.play"
          })}
          onSuccess={this._onSuccess}
          onClose={this._onClose}
        />
      );
    }
  };

  _renderInitialParentalModal = () => {
    const { history, intl } = this.props;
    const { requirePin } = this.state;
    const doesRequirePin =
      requirePin && !get(history, "location.state.alreadyEnteredParentalPin");

    if (doesRequirePin) {
      return (
        <InputPinModal
          intl={intl}
          open={doesRequirePin}
          type="parental"
          successButtonTitle={intl.formatMessage({
            id: "common.confirm"
          })}
          hideCancelButton={true}
          onSuccess={this._onSuccessInitialParentalModal}
          onBack={this._onBackClick}
        />
      );
    }
  };

  _onSuccessInitialParentalModal = () => this.setState({ requirePin: false });

  _onBackClick = () => {
    const { history } = this.props;

    history.push("/tv-shows");
  };

  _renderRatingStars = () => {
    const { openStars, episode } = this.state;
    const { intl } = this.props;

    if (episode) {
      return (
        <RatingStars
          isOpen={openStars}
          vodType={episode.type}
          intl={intl}
          onClose={this._onCloseStarsModal}
          onRate={this._rateEpisode}
        />
      );
    }
  };

  _onCloseStarsModal = () => this.setState({ openStars: false });

  render() {
    return (
      <div className={classNames(style.container, "topPadding")}>
        <div className={style.overflowFix}>
          {this._renderEpisode()}
          {this._renderEpisodesCarousel()}
          {this._renderConfirmRentModal()}
          {this._renderSelectRentModal()}
          {this._renderSelectProfileToPlayModal()}
          {this._renderPurchasePinModal()}
          {this._renderInitialParentalModal()}
          {this._renderRatingStars()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  cachedSingleTvShowsCarousels: state.cachedSingleTvShowsCarousels,
  floatingPlayer: state.floatingPlayer
});

const matchDispatchToProps = dispatch => ({
  getMvodList: data => dispatch(vodActions.getMvodList(data)),
  rentVOD: data => dispatch(vodActions.rentVOD(data)),
  addVodProfileDispatch: data => dispatch(addVodProfile(data)),
  rateVOD: data => dispatch(vodActions.rateVOD(data)),
  checkSubscription: data => dispatch(userActions.checkSubscription(data)),
  setOpenPlayer: data => dispatch(floatingPlayerActions.setOpenPlayer(data)),
  setPlayerSize: data => dispatch(floatingPlayerActions.setPlayerSize(data))
});

export default withRouter(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(ScrollTopHOC(injectIntl(SingleEpisodePage)))
);
