import { put, call } from "redux-saga/effects";
import { GET_VOD_SHOW_TYPES_SUCCESS } from "reduxStore/reducers/vodShowTypes";
import { fetchData } from "helper-functions/http-functions";

const getVodShowTypes = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);
    if (result) {
      yield put({
        type: GET_VOD_SHOW_TYPES_SUCCESS,
        payload: result
      });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export default getVodShowTypes;
