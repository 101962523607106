const pageStructure = () => {
  return {
    HomePage: {
      slots: [
        {
          type: "highlighted",
          urlRoute: "/movies",
          query: {
            filter: [
              {
                type: "type",
                value: 0
              }
            ],
            sort: [
              {
                type: "year",
                value: "desc"
              },
              {
                type: "created",
                value: "desc"
              }
            ]
          }
        },
        {
          type: "carousel",
          title: "Recommended Movies",
          urlRoute: "/movies",
          query: {
            filter: [
              {
                type: "type",
                value: 0
              }
            ],
            sort: [
              {
                type: "year",
                value: "desc"
              },
              {
                type: "created",
                value: "desc"
              }
            ]
          }
        },
        {
          type: "carousel",
          title: "Movies New Releases",
          urlRoute: "/movies",
          query: {
            filter: [
              {
                type: "type",
                value: 0
              }
            ],
            sort: [
              {
                type: "year",
                value: "asc"
              },
              {
                type: "created",
                value: "asc"
              }
            ],
            limit: 15
          }
        },
        {
          type: "carousel",
          title: "Recommended TV Shows",
          urlRoute: "/tv-shows",
          query: {
            filter: [
              {
                type: "type",
                value: 1
              }
            ],
            sort: [
              {
                type: "year",
                value: "desc"
              },
              {
                type: "created",
                value: "desc"
              }
            ],
            limit: 10
          }
        },
        {
          type: "carousel",
          title: "TV Shows New Releases",
          urlRoute: "/tv-shows",
          query: {
            filter: [
              {
                type: "type",
                value: 1
              }
            ],
            sort: [
              {
                type: "year",
                value: "desc"
              },
              {
                type: "created",
                value: "desc"
              }
            ]
          }
        },
        {
          type: "carousel",
          title: "Trending TV Shows",
          urlRoute: "/tv-shows",
          query: {
            filter: [
              {
                type: "type",
                value: 1
              }
            ],
            sort: [
              {
                type: "year",
                value: "asc"
              },
              {
                type: "created",
                value: "asc"
              }
            ]
          }
        }
      ]
    }
  };
};

export default pageStructure;
