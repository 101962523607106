import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Col, Row } from "react-flexbox-grid";
import { FormattedMessage } from "react-intl";
import MovieIcon from "react-icons/lib/md/movie";
import RatingStars from "components/RatingStars";
import ThumbUpIcon from "react-icons/lib/md/thumb-up";
import SeasonCarousel from "components/Carousels/SeasonCarousel";
import ActiveBanner from "components/ActiveBanner";
import ImageGallery from "components/ImageGallery";
import ScrollTopHOC from "HOCs/ScrollTopHOC";
import { find, get } from "lodash";
import { Loader } from "semantic-ui-react";
import Popover from "antd/lib/popover";
import {
  isCensorshipCard,
  redirectToNotFoundPage
} from "functions/logic-functions";
import * as floatingPlayerActions from "reduxStore/reducers/floatingPlayer";
import * as defaultActions from "reduxStore/actions";
import * as singleTvShowActions from "reduxStore/reducers/cachedSingleTvShowsCarousels";
import {
  GET_MVOD_LIST_URL,
  SINGLE_VOD,
  SHOW_RATE_URL,
  GET_ACTIVE_BANNER
} from "API-routes";
import imdbImg from "./images/imdb.png";
import InputPinModal from "components/Modals/InputPinModal";
import RoundIconButton from "components/Buttons/RoundIconButton";
import { sendAnalyticsData } from "functions/logic-functions";
import { injectIntl } from "react-intl";
import style from "./style.module.scss";
import commonStyle from "../../styles/commonPagesAnimationStyle.module.scss";

class SingleTvShowPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: null,
      visible: false,
      showLoader: true,
      activeSeason: null,
      requirePin: false,
      openTrailerParentalModal: false,
      openStars: false,
      showAnimationClasses: false
    };
  }

  componentDidMount() {
    const {
      location: { state }
    } = this.props;

    if (state && state.item) {
      this.setState(
        {
          show: state.item,
          showAnimationClasses: true
        },
        () => {
          this._fetchSeasons(state.item.seasons);
          this._setActiveSeason(state.item.seasons);
        }
      );
    } else {
      this._fetchTvShow();
    }
    sendAnalyticsData("single_tv_show_view");
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      setOpenPlayer,
      location: { search }
    } = nextProps;

    if (search && search.includes("trailer")) {
      setOpenPlayer(true);
    }
  }

  handleHidePopover = () => {
    this.setState({
      visible: false
    });
  };

  handleVisibleChangePopover = visible => {
    this.setState({ visible });
  };

  _fetchTvShow = () => {
    const {
      getMvodList,
      match: {
        params: { id }
      }
    } = this.props;

    if (id) {
      getMvodList({
        url: `${GET_MVOD_LIST_URL}?filter[id]=${id}&config[enable_rate]=1`,
        successCallback: this.successCallback
      });
    }
  };

  _fetchSeasons = seasons => {
    const { getSeasonEpisodes } = this.props;

    getSeasonEpisodes({
      url: `${SINGLE_VOD}?filter[multi_event_vod_season_id]=${
        seasons[seasons.length - 1].id
      }&filter[type]=1`,
      name: seasons[seasons.length - 1].name,
      id: seasons[seasons.length - 1].id
    });
  };

  _setActiveSeason = seasons => {
    this.setState({
      activeSeason: seasons && seasons[seasons.length - 1]
    });
  };

  successCallback = data => {
    const {
      location: { state }
    } = this.props;
    if (data && data.items && data.items.length) {
      this.setShowDataIfItemIsArray(data, state);
    } else if (data && data.id && !Array.isArray(data)) {
      const additionalData = get(data, "parental_rating.require_pin")
        ? { requirePin: true }
        : {};

      this.setState({
        show: data,
        showLoader: false,
        activeSeason: data.seasons && data.seasons[data.seasons.length - 1],
        showAnimationClasses: true,
        ...additionalData
      });
    } else {
      redirectToNotFoundPage(this.props.history);
    }
  };

  setShowDataIfItemIsArray = (data, itemState) => {
    this.setState(
      {
        show: data.items[0],
        showLoader: false,
        showAnimationClasses: true
      },
      () => {
        const {
          show: { seasons }
        } = this.state;
        let stateData = {};

        this._setActiveSeason(seasons);
        this._fetchSeasons(seasons);

        stateData = get(data, "items[0].parental_rating.require_pin")
          ? { requirePin: !get(itemState, "alreadyEnteredParentalPin") }
          : {};
        stateData =
          data.items.parental_rating && data.items.parental_rating.require_pin
            ? { ...stateData, openParentalModal: true }
            : stateData;

        this.setState({
          ...stateData
        });
      }
    );
  };

  rateShow = rate => {
    const { rateVOD } = this.props;
    const { show } = this.state;

    if (show && show.id) {
      rateVOD({
        url: `${SHOW_RATE_URL}/${show.id}/${rate}`
      });
      sendAnalyticsData("rate_tv_show");
    }
  };

  getSpecificSeason = data => {
    const { id, name } = data;
    const { getSeasonEpisodes, cachedSingleTvShowsCarousels } = this.props;
    const existInSeasons = find(cachedSingleTvShowsCarousels, o => o.id === id);

    if (id >= 0) {
      this.setState(
        {
          activeSeason: data
        },
        () => {
          if (!existInSeasons) {
            getSeasonEpisodes({
              url: `${SINGLE_VOD}?filter[multi_event_vod_season_id]=${id}&filter[type]=1`,
              id,
              name
            });
          }
        }
      );
    }
  };

  _onTrailer = () => {
    const { show } = this.state;

    if (get(show, "parental_rating.require_pin")) {
      this.setState({
        openTrailerParentalModal: true
      });
    } else {
      this._openTrailerPlayer();
    }
  };

  _onSuccessTrailerInputParentalPin = () => {
    this._openTrailerPlayer();
  };

  _renderParentalModalForTrailers = () => {
    const { intl } = this.props;
    const { show, openTrailerParentalModal } = this.state;
    const {
      location: { state }
    } = this.props;

    if (openTrailerParentalModal) {
      if (
        state &&
        !state.alreadyEnteredParentalPin &&
        get(show, "parental_rating.require_pin")
      ) {
        return (
          <InputPinModal
            open={true}
            type="parental"
            onSuccess={this._onSuccessTrailerInputParentalPin}
            onClose={() => {
              this.setState({
                openTrailerParentalModal: false
              });
            }}
            successButtonTitle={intl.formatMessage({
              id: "common.confirm"
            })}
            intl={intl}
          />
        );
      }
    }
  };

  _openTrailerPlayer = () => {
    const { show } = this.state;
    const { history } = this.props;

    this._resetMinimizedPlayer();
    history.push({
      search: "trailer",
      state: {
        item: show,
        alreadyEnteredParentalPin: true
      }
    });
  };

  _resetMinimizedPlayer = () => {
    const {
      floatingPlayer: { isPlayerOpened },
      setPlayerSize,
      setOpenPlayer
    } = this.props;

    if (isPlayerOpened) {
      setOpenPlayer(false);
      setPlayerSize("maximize");
    }
  };

  _renderRoundIconButton = (onClick, Icon, translationKey) => {
    const { intl } = this.props;

    return (
      <RoundIconButton
        onClick={onClick}
        iconComponent={<Icon />}
        label={intl.formatMessage({
          id: translationKey
        })}
      />
    );
  };

  renderButtons = show => {
    const {
      id,
      trailer_source: { url }
    } = show;

    if (show) {
      return (
        <div className={style.buttonsWrapper}>
          {id && (
            <Fragment>
              {this._renderRoundIconButton(
                () => this.setState({ openStars: true }),
                ThumbUpIcon,
                "vods.rate"
              )}
              {url &&
                this._renderRoundIconButton(
                  this._onTrailer,
                  MovieIcon,
                  "vods.trailer"
                )}
            </Fragment>
          )}
          {this._renderSeasonsPopover()}
        </div>
      );
    }
  };

  _renderSeasonsPopover = () => {
    const {
      show: { season_count, seasons },
      visible,
      activeSeason
    } = this.state;

    if (season_count) {
      return (
        <div className="popoverArea">
          <div className={style.iconWrapper}>
            <div>
              <Popover
                visible={visible}
                getPopupContainer={() => document.querySelector(".popoverArea")}
                onVisibleChange={this.handleVisibleChangePopover}
                placement="rightTop"
                content={
                  <div className={style.seasonsPopover}>
                    {seasons.map(item => (
                      <div
                        key={item.name}
                        onClick={() => {
                          this.handleHidePopover();
                          this.getSpecificSeason(item);
                        }}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                }
                trigger="click"
              >
                <h4>{(activeSeason && activeSeason.number) || season_count}</h4>
              </Popover>
            </div>
          </div>
          <p>
            <FormattedMessage id="vods.seasons" />
          </p>
        </div>
      );
    }
  };

  _renderShow = () => {
    const { show, showLoader, showAnimationClasses } = this.state;

    if (showLoader && !show) {
      return (
        <div className={style.loaderWrapper}>
          <Loader active={showLoader} />
        </div>
      );
    } else {
      return (
        <div>
          <div className={classNames(style.mainHeadWrapper, "topPadding")}>
            <Row>
              {this._renderCoverImageAndGallery(show)}
              <Col
                xs={12}
                sm={12}
                md={7}
                className={classNames(style.rightPart, {
                  [commonStyle.rightPartAnimation]: showAnimationClasses,
                  [commonStyle.rightPartAnimationReverse]: !showAnimationClasses
                })}
              >
                <h1>{show.title}</h1>
                <div className={style.smallerMovieDetails}>
                  {show.year && (
                    <span className={"titleYearDescription"}>{show.year}</span>
                  )}
                  {this._renderGenres(show)}
                  {this._renderShowTypes(show)}
                  {show.parental_rating && show.parental_rating.code && (
                    <span>{show.parental_rating.code}</span>
                  )}

                  {this._renderRating(show)}
                </div>
                {show.description && (
                  <p className={style.description}>{show.description}</p>
                )}
                {this._renderActors(show)}
                {this._renderWritters(show)}
                {this.renderButtons(show)}
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  };

  _renderCoverImageAndGallery = show => {
    const { showAnimationClasses } = this.state;

    if (show) {
      return (
        <Col
          xs={12}
          smOffset={2}
          sm={8}
          md={4}
          mdOffset={0}
          lg={3}
          className={classNames({
            [commonStyle.leftPart]: showAnimationClasses,
            [commonStyle.leftPartReverse]: !showAnimationClasses
          })}
        >
          <img
            className={classNames(style.mainImage, {
              [style.blurCard]: isCensorshipCard(show)
            })}
            src={
              (show &&
                show.covers &&
                show.covers[0] &&
                show.covers[0].preview_ar) ||
              (show &&
                show.covers &&
                show.covers[0] &&
                show.covers[0].medium_ar) ||
              ""
            }
            alt={""}
          />
          {isCensorshipCard(show)
            ? ""
            : show.gallery &&
              show.gallery.length > 0 && (
                <ImageGallery gallery={show.gallery} />
              )}
        </Col>
      );
    }
  };

  _renderGenres = show => {
    if (show && show.genres && show.genres.length > 0) {
      return (
        <span>{show.genres.map(genre => genre.name.trim()).join(", ")}</span>
      );
    }
  };

  _renderShowTypes = show => {
    if (show && show.show_types && show.show_types.length > 0) {
      return (
        <span>
          {show.show_types.map(show_type => show_type.name.trim()).join(", ")}
        </span>
      );
    }
  };

  _renderRating = show => {
    if (show && show.rating) {
      return (
        <Fragment>
          <img src={imdbImg} className={style.imdbLogo} alt={""} />
          <span>
            {` ${show.rating}`}
            <span className={style.allRating}>/10</span>
          </span>
        </Fragment>
      );
    }
  };

  _renderWritters = show => {
    if (
      show &&
      show.writers &&
      show.writers.length > 0 &&
      show.writers[0].name
    ) {
      return (
        <p>
          <span>
            <FormattedMessage id="vods.creators" />:{" "}
          </span>
          {`${show.writers.map(writer => writer.name.trim()).join(", ")}`}
        </p>
      );
    }
  };

  _renderActors = show => {
    if (show && show.actors && show.actors.length > 0) {
      return (
        <p>
          <span>
            <FormattedMessage id="vods.cast" />:{" "}
          </span>
          {`${show.actors.map(actor => actor.name.trim()).join(", ")}`}
        </p>
      );
    }
  };

  _renderSeasons = () => {
    const { cachedSingleTvShowsCarousels, intl } = this.props;
    const { activeSeason } = this.state;

    if (activeSeason) {
      const existInSeasons = find(
        cachedSingleTvShowsCarousels,
        o => o.id === activeSeason.id
      );

      if (
        existInSeasons &&
        existInSeasons.id &&
        existInSeasons.items &&
        existInSeasons.items.length
      ) {
        return (
          <div className={style.seasonCarouselWrapper}>
            <div className={style.carouselWrapper} key={existInSeasons.id}>
              <SeasonCarousel
                title={(existInSeasons && existInSeasons.name) || ""}
                items={existInSeasons.items}
                urlRoute={`/tv-shows/episode`}
                intl={intl}
              />
            </div>
          </div>
        );
      }
    }
  };

  _renderInitialParentalPin = () => {
    const { requirePin } = this.state;
    const { history, intl } = this.props;
    const doesRequirePin =
      requirePin && !get(history, "location.state.alreadyEnteredParentalPin");

    return (
      <InputPinModal
        open={doesRequirePin}
        type="parental"
        onSuccess={() => {
          this.setState({ requirePin: false });
        }}
        hideCancelButton={true}
        onBack={() => history.push("/tv-shows")}
        onRedirectToPlay={this._onRedirectToPlay}
        successButtonTitle={intl.formatMessage({
          id: "common.confirm"
        })}
        intl={intl}
      />
    );
  };

  _renderParentalModalForPlay = () => {
    const { openParentalModal } = this.state;
    const { intl } = this.props;

    if (openParentalModal) {
      return (
        <InputPinModal
          open={openParentalModal}
          type="parental"
          onSuccess={() =>
            this.setState({
              openParentalModal: false,
              openPlayModal: true
            })
          }
          intl={intl}
          onClose={() => this.setState({ openParentalModal: false })}
          successButtonTitle={<FormattedMessage id="common.confirm" />}
        />
      );
    }
  };

  _renderAdvertisementBanner = () => {
    const {
      composerConfig: {
        web: {
          modules: { advertisements }
        }
      }
    } = this.props;

    if (advertisements) {
      return (
        <ActiveBanner
          useParentContainer={true}
          url={`${GET_ACTIVE_BANNER}?filter[box_position]=tv_show_details_box&filter[ads_type]=image`}
        />
      );
    }
  };

  _renderRatingStars = () => {
    const { openStars, show } = this.state;
    const { intl } = this.props;

    if (show) {
      return (
        <RatingStars
          isOpen={openStars}
          vodType={2}
          intl={intl}
          onClose={this._onCloseStarsModal}
          onRate={this.rateShow}
        />
      );
    }
  };

  _onCloseStarsModal = () => this.setState({ openStars: false });

  render() {
    return (
      <div className={`${style.container} topPadding`}>
        <div className={style.overflowFix}>
          {this._renderShow()}
          {this._renderSeasons()}
          {this._renderAdvertisementBanner()}
          {this._renderParentalModalForPlay()}
          {this._renderParentalModalForTrailers()}
          {this._renderInitialParentalPin()}
          {this._renderRatingStars()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cachedSingleTvShowsCarousels: state.cachedSingleTvShowsCarousels,
  composerConfig: state.composerConfig,
  floatingPlayer: state.floatingPlayer
});

const mapDispatchToProps = dispatch => ({
  getMvodList: data => dispatch(defaultActions.getMvodList(data)),
  rateVOD: data => dispatch(defaultActions.rateVOD(data)),
  getSeasonEpisodes: data =>
    dispatch(singleTvShowActions.getSeasonEpisodes(data)),
  setOpenPlayer: data => dispatch(floatingPlayerActions.setOpenPlayer(data)),
  setPlayerSize: data => dispatch(floatingPlayerActions.setPlayerSize(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScrollTopHOC(injectIntl(SingleTvShowPage))));
