//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onMute = function() {
  const { muted } = this.state;

  this.setState({
    muted: !muted
  });
};

export default onMute;
