import React, { Component } from "react";
import { withRouter } from "react-router";
import { get } from "lodash";
import NotFound from "./images/NotFound.png";
import DefaultButton from "components/Buttons/DefaultButton/DefaultButton";
import StyledComponents from "styled-components";
import style from "./style.module.scss";

class NotFoundPage extends Component {
  goBack() {
    const { location, history } = this.props;

    return get(location, "state.fromErrorPage")
      ? history.push("/")
      : history.goBack();
  }

  render() {
    const errorMessage =
      "Oops! Something went wrong. The page you’re looking for doesn’t exist.";

    return (
      <div className={`topPadding`}>
        <img src={NotFound} className={style.imgClass} alt={""} />
        <div className={style.minHeight}>
          <div className={style.paddingDiv}>
            <h4 className={style.errorMessage}>{errorMessage}</h4>
          </div>
          <br />
          <div className={style.goBackBtn}>
            <GoBackButton
              title="Go back"
              onClick={() => {
                this.goBack();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const GoBackButton = StyledComponents(DefaultButton)`
	button{
		padding: 10px 35px 10px 35px!important;
		font-size: 18px;
	}
`;

export default withRouter(NotFoundPage);
