import { get } from "lodash";

const getURL = movie => {
  let videoURL = "";

  if (get(movie, "movie_source.url")) videoURL = movie.movie_source.url;
  return videoURL;
};

export default getURL;
