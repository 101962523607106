import { put, call } from "redux-saga/effects";
import { GET_PAYMENT_HISTORY_SUCCESS } from "reduxStore/reducers/paymentHistory";
import { fetchData } from "helper-functions/http-functions";

const getPaymentHistory = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result)
      yield put({ type: GET_PAYMENT_HISTORY_SUCCESS, payload: result });
  } catch (err) {
    console.info("ERR", err);
  }
};

export default getPaymentHistory;
