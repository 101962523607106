export const BASE_URL =
  (process.env.REACT_APP_MAIN_BASE_URL &&
    process.env.REACT_APP_MAIN_BASE_URL.trim()) ||
  //"https://api.www.scouttv.tv";
  "https://api.brasilnet.net.br";
  //"https://api2.wecast.tv";
//"https://api.wecastdemo.com";
export const SOCKET_DOMAIN = "wecast.tv:4040";
export const CHECK_DEVICE_TOKEN_URL = BASE_URL + "/account/check-device-token";
export const LOGIN_URL = BASE_URL + "/account/login";
export const LOGOUT_URL = BASE_URL + "/account/logout";
export const FORGOT_PASSWORD_URL = BASE_URL + "/account/forgot-password";
export const SINGLE_VOD = BASE_URL + "/vods/single-event-vods-list";
export const STREAM_VOD = BASE_URL + "/vods/get_movie_source";
export const CHANNEL_EPG = BASE_URL + "/epgs/list";
export const CHANNEL_EPG_WITH_MORE_PROPERTIES = BASE_URL + "/epgs/index";
export const ADD_FAVOURITE_CHANNEL = BASE_URL + "/channels/add-to-favorites";
export const ADD_REMINDERS_URL = BASE_URL + "/epg-reminders/add";
export const RENT_CHANNEL_URL = BASE_URL + "/channels/rent";
export const GET_CHANNEL_CATEGORIES_URL = BASE_URL + "/channels/categories";
export const GET_VOD_GENRES_URL = BASE_URL + "/vods/genres";
export const GET_MVOD_LIST_URL = BASE_URL + "/vods/multi-event-vods-list";
export const RENT_VOD_URL = BASE_URL + "/vods/rent";
export const MOVIE_RATE_URL = BASE_URL + "/rate-items/movie-rate";
export const SHOW_RATE_URL = BASE_URL + "/rate-items/serie-rate";
export const EPISODE_RATE_URL = BASE_URL + "/rate-items/episode-rate";
export const GET_SUBSCRIPTIONS_URL = BASE_URL + "/account/get-subscriptions";
export const SIMILAR_MOVIES_URL =
  BASE_URL + "/recommendations/vod-list-by-vod-id";
export const PAYMENT_HISTORY_URL = BASE_URL + "/account/payment-history-list";
export const GET_DEVICES_URL = BASE_URL + "/account/devices";
export const REMOVE_DEVICE_URL = BASE_URL + "/account/remove-device";
export const UPDATE_SUBSCRIPTION_URL =
  BASE_URL + "/account/update-subscription";
export const UPDATE_PROFILE_URL = BASE_URL + "/account/update-profile";
export const CREATE_PROFILE_URL = BASE_URL + "/account/create-profile";
export const REGISTER_URL = BASE_URL + "/account/registration";
export const HIGHLIGHTED_CONTENT_URL = BASE_URL + "/highlight-contents/index";
export const RECOMMENDED_VODS_BY_VIEW_URL =
  BASE_URL + "/recommendations/vod-list/viewed";
export const STATIC_PAGES = BASE_URL + "/static-pages/index?strip_content=1";
export const RESET_PASSWORD_URL = BASE_URL + "/account/reset-password";
export const GET_CONTINUE_WATCHING = BASE_URL + "/vods/get-continue-watching";
export const GET_COMPOSER_CONFIG = BASE_URL + "/composer/configuration";
export const GET_CHANNELS_WITH_PAGER = BASE_URL + "/channels/list";
export const GET_REMINDERS_URL = BASE_URL + "/epg-reminders/index";
export const EDIT_REMINDER_URL = BASE_URL + "/epg-reminders/edit";
export const DELETE_REMINDER_URL = BASE_URL + "/epg-reminders/delete";
export const TRENDING_CHANNELS_URL = BASE_URL + "/channels/trendings";
export const TRENDING_MOVIES_URL = BASE_URL + "/vods/trending-movies";
export const TRENDING_TV_SHOWS_URL = BASE_URL + "/vods/trending-tv-shows";
export const AVAILABLE_VOD_PROFILES = BASE_URL + "/vods/available-profiles";
export const GET_ACTIVE_BANNER = BASE_URL + "/ads/active-banner-campaigns";
export const REMOVE_CHANNELS_FROM_FAVORITES_URL =
  BASE_URL + "/channels/delete-from-favorites";
export const GET_VOD_SHOW_TYPES = BASE_URL + "/vods/show-types";
export const CHECK_SUBSCRIPTION = BASE_URL + "/account/check-subscription";
export const GET_LANGUAGES = BASE_URL + "/languages";
export const GET_ACTIVE_CAMPAIGN = BASE_URL + "/ads/active-video-campaigns";
export const FAVORITES_LIST = BASE_URL + "/channels/favorites-list";
