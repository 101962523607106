import { put, call } from "redux-saga/effects";
import { get } from "lodash";
import { AUTHORIZE_SUCCESS } from "reduxStore/reducers/loggedUser";
import { login } from "helper-functions/http-functions";

const loginSaga = function*({ payload, callback }) {
  try {
    const result = yield call(login, payload);

    if (callback) {
      if (
        get(result, "data.status") !== 0 &&
        get(result, "data.data.device.token")
      ) {
        const credentials = sessionStorage.getItem("wecastCredentials");
        const wecastUser =
          (localStorage.getItem("wecastUser") &&
            JSON.parse(localStorage.getItem("wecastUser"))) ||
          {};

        if (credentials) {
          localStorage.setItem("wecastCredentials", credentials);
        }

        if (wecastUser) {
          yield put({
            type: AUTHORIZE_SUCCESS,
            payload: Object.assign({}, wecastUser, {
              token: get(result, "data.data.device.token"),
              currency_code: result.data.data.subscription.currency_code
            })
          });
        }

        callback();
      } else {
        callback(get(result, "data.message"));
      }
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export default loginSaga;
