import { put, call } from "redux-saga/effects";
import { get } from "lodash";
import { APPEND_CHANNELS_SUCCESS } from "reduxStore/reducers/channels";
import { fetchData } from "helper-functions/http-functions";

const getAppendChannels = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result) {
      if (payload.successCallback)
        payload.successCallback(get(result, "pager"));

      yield put({
        type: APPEND_CHANNELS_SUCCESS,
        payload: {
          result,
          ignorePagerFromResult: payload.ignorePagerFromResult,
          ignoreSort: payload.ignoreSort
        }
      });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export default getAppendChannels;
