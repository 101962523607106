import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ScrollTopHOC from "HOCs/ScrollTopHOC";
import { withRouter } from "react-router";
import { Loader } from "semantic-ui-react";
import { get } from "lodash";
import { connect } from "react-redux";
import GenresNavList from "components/GenresNavList";
import VodsGridList from "components/VodsGridList";
import VodsPager from "components/VodsPager";
import ShowTypes from "components/ShowTypes";
import SearchFilters from "components/SearchFilters";
import ActiveBanner from "components/ActiveBanner";
import { getSvodList } from "reduxStore/actions";
import { getVodGenres } from "reduxStore/reducers/genres";
import { GET_ACTIVE_BANNER, GET_VOD_GENRES_URL, SINGLE_VOD } from "API-routes";
import NoContentComponent from "components/NoContentComponent";
import MdLocalMovies from "react-icons/lib/md/local-movies";
import { FormattedMessage } from "react-intl";
import {
  parseFromQueryToObject,
  parseObjectToEndpointQuery
} from "functions/logic-functions";
import { injectIntl } from "react-intl";
import style from "./style.module.scss";

class MoviesListPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      showFilter: {
        show: false,
        clicked: false
      },
      movies: []
    };
  }

  componentDidMount() {
    const {
      location: { search }
    } = this.props;
    const searchQueryObject = parseFromQueryToObject(search);

    this._fetchGenres();

    this.setState(
      {
        showLoader: true
      },
      () => {
        this.fetchMovie({
          genres: searchQueryObject.genres,
          page: searchQueryObject.page,
          year: searchQueryObject.year,
          showTypes: searchQueryObject["show-types"]
        });
      }
    );
  }

  componentWillUnmount() {
    clearTimeout(this.fetchImagesTimer);
    clearTimeout(this.loaderTimeout);
  }

  componentWillReceiveProps(nextProps) {
    const { movies } = this.state;
    const {
      location: { search }
    } = this.props;
    const {
      location: { search: nextSearch }
    } = nextProps;
    const searchQueryObject = parseFromQueryToObject(search);
    const nextSearchQueryObject = parseFromQueryToObject(nextSearch);

    this._fetchMoviesIfUrlChanged(searchQueryObject, nextSearchQueryObject);

    if (searchQueryObject.page !== nextSearchQueryObject.page) {
      this.setState(
        {
          showLoader: true
        },
        () => {
          this.fetchMovie({
            genres: nextSearchQueryObject.genres || searchQueryObject.genres,
            page: nextSearchQueryObject.page,
            year: nextSearchQueryObject.year,
            showTypes: nextSearchQueryObject["show-types"]
          });

          // FETCH CONTENT FOR THE NEXT PAGE AND DOWNLOAD COVER IMAGES
          this._fetchNextPageMoviesImages(
            movies,
            searchQueryObject,
            nextSearchQueryObject
          );
        }
      );
    }

    this.loaderTimeout = setTimeout(() => {
      this.setState({
        showLoader: false
      });
    }, 3000);
  }

  _fetchGenres = () => {
    const { getVodGenresDispatch, genres } = this.props;

    if (!genres.length) {
      this.setState(
        {
          showLoader: true
        },
        () => {
          getVodGenresDispatch({
            url: GET_VOD_GENRES_URL
          });
        }
      );
    }
  };

  _fetchNextPageMoviesImages = (
    movies,
    searchQueryObject,
    nextSearchQueryObject
  ) => {
    if (movies && movies.pager) {
      const {
        pager: { pageCount }
      } = movies;

      if (pageCount >= parseInt(nextSearchQueryObject.page) + 1) {
        this.fetchImagesTimer = setTimeout(() => {
          this.fetchMovie({
            genres: nextSearchQueryObject.genres || searchQueryObject.genres,
            page: nextSearchQueryObject.page
              ? parseInt(nextSearchQueryObject.page) + 1
              : 2,
            year: nextSearchQueryObject.year,
            showTypes: nextSearchQueryObject["show-types"],
            downloadImagesFunction: this.downloadNextPageSmallImages
          });
        }, 2000);
      }
    }
  };

  _fetchMoviesIfUrlChanged = (searchQueryObject, nextSearchQueryObject) => {
    if (
      searchQueryObject.genres !== nextSearchQueryObject.genres ||
      searchQueryObject.year !== nextSearchQueryObject.year ||
      searchQueryObject["show-types"] !== nextSearchQueryObject["show-types"]
    ) {
      this.setState(
        {
          showLoader: true
        },
        () => {
          this.fetchMovie({
            genres: nextSearchQueryObject.genres,
            page: nextSearchQueryObject.page,
            year: nextSearchQueryObject.year,
            showTypes: nextSearchQueryObject["show-types"]
          });
        }
      );
    }
  };

  fetchMovie = ({
    genres,
    page = 1,
    year,
    showTypes,
    downloadImagesFunction
  }) => {
    const { getSvodListDispatch } = this.props;
    const queryObject = {};

    queryObject.genres = genres;
    queryObject.page = page;
    queryObject.year = year;
    queryObject.limit = 24;
    queryObject["show-types"] = showTypes;

    let endpointQueryString = parseObjectToEndpointQuery(queryObject);

    //Callback function to only download images for next page
    if (downloadImagesFunction) {
      downloadImagesFunction(endpointQueryString);
    } else {
      getSvodListDispatch({
        url: `${SINGLE_VOD}${endpointQueryString}`,
        successCallback: movies => {
          this.setState({
            showLoader: false,
            movies
          });
        }
      });
    }
  };

  onLoaderShow = showLoader => {
    this.setState({
      showLoader
    });
  };

  downloadNextPageSmallImages = queryString => {
    const { getSvodListDispatch } = this.props;

    getSvodListDispatch({
      url: `${SINGLE_VOD}${queryString}`,
      successCallback: this.nextPageImagesSuccessCallback
    });
  };

  nextPageImagesSuccessCallback = response => {
    const { items } = response || {};
    if (items.length) {
      items.forEach(item => {
        const imageSource =
          (item.covers && item.covers[0].medium_ar) ||
          (item.covers && item.covers[0].small_ar) ||
          (item.cover && item.cover.medium_ar) ||
          (item.cover && item.cover.small_ar) ||
          item.screenshot_url ||
          item.logo_url;

        if (imageSource) {
          const image = new Image();
          image.src = imageSource;
        }
      });
    }
  };

  onShowNavs = () => {
    const { showFilter } = this.state;

    this.setState({
      showFilter: {
        show: !showFilter.show,
        clicked: true
      }
    });
  };

  render() {
    const { movies, showLoader, showFilter } = this.state;
    const {
      match: {
        params: { genreID, pageNumber }
      },
      composerConfig: {
        web: {
          modules: { advertisements }
        }
      },
      location: { search },
      intl,
      genres
    } = this.props;

    const hasContent = get(movies, "items") && movies.items.length;
    const searchQueryObject = parseFromQueryToObject(search);

    return (
      <div
        className={classNames(style.container, "topPadding")}
        style={
          !hasContent
            ? {
                height: "100%"
              }
            : {}
        }
      >
        <GenresNavList
          route="/movies"
          genres={genres}
          selectedGenres={searchQueryObject.genres}
          onShowNavs={this.onShowNavs}
        />
        <SearchFilters
          visible={showFilter.show}
          showYearFilter={true}
          onLoaderShow={this.onLoaderShow}
        >
          <ShowTypes />
        </SearchFilters>
        {hasContent ? (
          <VodsGridList intl={intl} urlRoute="/movies" list={movies}>
            {advertisements && (
              <ActiveBanner
                useParentContainer={true}
                useNestedBanner={true}
                url={`${GET_ACTIVE_BANNER}?filter[box_position]=movies_box&filter[ads_type]=image`}
                paramsId={pageNumber || genreID}
              />
            )}
          </VodsGridList>
        ) : (
          <NoContentComponent
            noContentIcon={<MdLocalMovies />}
            noContentText={
              <FormattedMessage id="common.moviesContentUnavailable" />
            }
          />
        )}

        {showLoader && (
          <div
            className={classNames(style.loaderWrapper, "theme-loader-wrapper")}
          >
            <Loader active={true} size="big" indeterminate />
          </div>
        )}
        {get(movies, "pager") && <VodsPager list={movies} />}
      </div>
    );
  }
}

MoviesListPage.propTypes = {
  loggedUser: PropTypes.object,
  genres: PropTypes.array
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  genres: state.genres,
  composerConfig: state.composerConfig
});

const mapDispatchToProps = dispatch => ({
  getVodGenresDispatch: data => dispatch(getVodGenres(data)),
  getSvodListDispatch: data => dispatch(getSvodList(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(ScrollTopHOC(MoviesListPage))));
