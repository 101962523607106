import React from "react";
import InfoIcon from "react-icons/lib/md/info-outline";
import style from "./style.module.scss";

const InputField = props => {
  let { props: customProps } = props;
  const elementClass = props.error ? style.borderFieldError : style.borderField;

  return (
    <div className={style.inputWrapper}>
      <input className={`${elementClass} themeInput`} {...props} />
      {customProps && customProps.description && <InfoIcon />}
      {customProps && customProps.description && (
        <p>{customProps.description}</p>
      )}
    </div>
  );
};

export default InputField;
