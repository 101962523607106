import FormData from "form-data";
import axios from "./axios";

const fetchFavoritesChannels = ({ url, body }) => {
  if (url && body) {
    const bodyFormData = new FormData();

    for (let key in body) {
      bodyFormData.append(key, body[key]);
    }

    return axios({
      method: "post",
      url: url,
      data: bodyFormData
    })
      .then(response => {
        if (
          response.status === 200 &&
          response.data &&
          response.data.status > 0
        ) {
          const {
            data: { data: responseData }
          } = response;

          return responseData;
        } else throw new Error("Request failed");
      })
      .catch(err => {
        // console.info("FETCH ERROR", err);
        // throw err;
      });
  }
};

export default fetchFavoritesChannels;
