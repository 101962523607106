import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { find } from "lodash";
import { getMvodList } from "reduxStore/actions";
import { getSeasonEpisodes } from "reduxStore/reducers/cachedSingleTvShowsCarousels";
import { GET_MVOD_LIST_URL, SINGLE_VOD } from "API-routes";
import EpisodesSuggestionComponentView from "./components/EpisodesSuggestionComponentView";

class EpisodesSuggestionComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      episodes: []
    };
  }

  componentDidMount() {
    const {
      episode,
      cachedSingleTvShowsCarousels,
      subscribedEpisodes
    } = this.props;

    if (episode) {
      const { multi_event_vod_season_id } = episode;

      if (cachedSingleTvShowsCarousels && cachedSingleTvShowsCarousels.length) {
        if (subscribedEpisodes) {
          this.setState({
            episodes: subscribedEpisodes
          });
          return;
        }

        const season = find(
          cachedSingleTvShowsCarousels,
          item => item.id === multi_event_vod_season_id
        );

        if (season && season.items) {
          this.setState({
            episodes: season.items.filter(item => item.subscribed)
          });
        }
      } else {
        this.getEpisodeContent();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { id: newId }
      }
    } = nextProps;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { episodes } = this.state;

    if (!episodes.length) {
      this.setEpisodes(nextProps);
    }
    if (id !== newId) {
      const { onFocus } = this.props;

      onFocus(false);
    }
  }

  getEpisodeContent = () => {
    const { episode, getMvodListDispatch } = this.props;
    const { multi_event_vod_id } = episode;

    getMvodListDispatch({
      url: `${GET_MVOD_LIST_URL}?filter[id]=${multi_event_vod_id}&config[enable_rate]=1`,
      successCallback: this.successCallback
    });
  };

  setEpisodes = nextProps => {
    const {
      cachedSingleTvShowsCarousels,
      episode: { multi_event_vod_season_id }
    } = nextProps;
    const season = find(
      cachedSingleTvShowsCarousels,
      item => item.id === multi_event_vod_season_id
    );

    if (season && season.items) {
      this.setState({
        episodes: season.items.filter(item => item.subscribed)
      });
    }
  };

  successCallback = data => {
    const { getSeasonEpisodesDispatch, episode } = this.props;
    const { multi_event_vod_season_id } = episode;

    if (data && data.items) {
      if (data.items[0] && data.items[0].seasons) {
        const { seasons } = data.items[0];
        const season = find(
          seasons,
          item => item.id === multi_event_vod_season_id
        );

        if (season) {
          getSeasonEpisodesDispatch({
            url: `${SINGLE_VOD}?filter[multi_event_vod_season_id]=${season.id}&filter[type]=1`,
            name: season.name,
            id: season.id
          });
        }
      }
    }
  };

  render() {
    const { episodes } = this.state;
    const {
      intl,
      onFocus,
      isSuggestionsInFocus,
      episode,
      onChangePlaying,
      isResumePlayerOff,
      onDisplaySelectProfileToPlayModal,
      onDisplayParentalModal,
      arrowsColor
    } = this.props;

    if (episodes.length) {
      return (
        <EpisodesSuggestionComponentView
          intl={intl}
          episode={episode}
          episodes={episodes}
          isSuggestionsInFocus={isSuggestionsInFocus}
          arrowsColor={arrowsColor}
          isResumePlayerOff={isResumePlayerOff}
          onFocus={onFocus}
          onChangePlaying={onChangePlaying}
          onDisplaySelectProfileToPlayModal={onDisplaySelectProfileToPlayModal}
          onDisplayParentalModal={onDisplayParentalModal}
        />
      );
    } else return null;
  }
}

EpisodesSuggestionComponent.propTypes = {
  subscribedEpisodes: PropTypes.array,

  arrowsColor: PropTypes.string,

  episode: PropTypes.object,
  intl: PropTypes.object.isRequired,

  isSuggestionsInFocus: PropTypes.bool,
  isResumePlayerOff: PropTypes.bool,

  onFocus: PropTypes.func.isRequired,
  onChangePlaying: PropTypes.func.isRequired,
  onDisplaySelectProfileToPlayModal: PropTypes.func,
  onDisplayParentalModal: PropTypes.func
};

const mapStateToProps = state => ({
  cachedSingleTvShowsCarousels: state.cachedSingleTvShowsCarousels
});

const mapDispatchToProps = dispatch => ({
  getSeasonEpisodesDispatch: data => dispatch(getSeasonEpisodes(data)),
  getMvodListDispatch: data => dispatch(getMvodList(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EpisodesSuggestionComponent)
);
