import React, { memo } from "react";
import classNames from "classnames";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import style from "./style.module.scss";

const ChannelCategoryItem = props => {
  const {
    name,
    urlRoute,
    match: {
      params: { categoryID }
    },
    id
  } = props;

  let selectedCategory =
    (id === "all" && !categoryID) ||
    (id !== "all" && id === parseInt(categoryID)) ||
    (id === "all" && categoryID === "all") ||
    (categoryID === "favorite_channels" && id === "favorite_channels");

  return (
    <div
      className={classNames(
        style.channelCategoryWrapperBackground,
        "subscription"
      )}
    >
      <Link
        to={urlRoute}
        className={classNames(style.channelCategoryWrapper, "navLink", {
          [style.activeCategory]: selectedCategory
        })}
      >
        {name}
      </Link>
    </div>
  );
};

ChannelCategoryItem.propTypes = {
  name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  urlRoute: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  channelCategories: state.channelCategories
});

export default withRouter(connect(mapStateToProps)(memo(ChannelCategoryItem)));
