import React, { memo } from "react";
import VodsGridList from "components/VodsGridList";
import { Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const MoviesListComponent = (props, context) => {
  const {
    intl,
    showMoviesLoader,
    searchText,
    moviesList,
    fetchMoreMovies
  } = props;
  return (
    <div className={style.sectionBlock}>
      <h2>
        {intl.formatMessage({
          id: "common.movies"
        })}
      </h2>
      <VodsGridList
        intl={intl}
        useExternalRoute={true}
        list={{ items: moviesList }}
      />
      {searchText && moviesList.length >= 20 && (
        <span onClick={fetchMoreMovies}>
          {intl.formatMessage({
            id: "common.viewMore"
          })}
        </span>
      )}
      {showMoviesLoader && <Loader size="mini" active={true} />}
    </div>
  );
};

MoviesListComponent.propTypes = {
  moviesList: PropTypes.array.isRequired,
  showMoviesLoader: PropTypes.bool.isRequired,
  fetchMoreMovies: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired
};

export default memo(MoviesListComponent);
