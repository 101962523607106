export const GET_STATIC_PAGES = "GET_STATIC_PAGES";
export const GET_STATIC_PAGES_SUCCESS = "GET_STATIC_PAGES_SUCCESS";

const staticPages = (state = [], action) => {
  switch (action.type) {
    case GET_STATIC_PAGES_SUCCESS:
      if (action.payload) return action.payload.result;
      return state;
    default:
      return state;
  }
};

export default staticPages;

export const getStaticPages = data => {
  return {
    type: GET_STATIC_PAGES,
    payload: {
      url: data.url
    }
  };
};
