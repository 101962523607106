export const DO_LOGIN = "DO_LOGIN";
export const CHECK_AUTHORIZATION = "CHECK_AUTHORIZATION";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_MOVIES_NEW_RELEASES = "GET_MOVIES_NEW_RELEASES";
export const GET_SINGLE_MOVIE = "GET_SINGLE_MOVIE";
export const FETCH_MOVIE_STREAM = "FETCH_MOVIE_STREAM";
export const ADD_TO_FAVOURITES = "ADD_TO_FAVOURITES";
export const ADD_REMINDER = "ADD_REMINDER";
export const RENT_CHANNEL = "RENT_CHANNEL";
export const GET_MVOD_LIST = "GET_MVOD_LIST";
export const GET_SVOD_LIST = "GET_SVOD_LIST";
export const RENT_VOD = "RENT_VOD";
export const RATE_VOD = "RATE_VOD";
export const GET_SUBSCRIPTIONS_ANONYMOUS = "GET_SUBSCRIPTIONS_ANONYMOUS";
export const REGISTER_USER = "REGISTER_USER";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CREATE_PROFILE = "CREATE_PROFILE";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_VIDEO_CAMPAIGN = "GET_VIDEO_CAMPAIGN";
export const GET_BANNER = "GET_BANNER";
export const DELETE_REMINDER = "DELETE_REMINDER";
export const EDIT_REMINDER = "EDIT_REMINDER";
