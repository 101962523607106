import React, { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import CloseIcon from "react-icons/lib/md/close";
import SearchResultCardList from "../../../SearchResultCardList";
import { Loader } from "semantic-ui-react";
import style from "./style.module.scss";

const { string, object, func, bool } = PropTypes;

const SearchTextComponentView = forwardRef(
  (
    {
      intl,
      showDropdown,
      showLoader,
      filteringPanelID,
      isChannelCategoriesPage,
      location: { pathname },
      composerConfig: {
        web: {
          modules: { vod, channels, epg }
        }
      },
      textSearchVODS,
      searchText,
      onHideNav,
      onClearText,
      onChange,
      onFocus,
      hideDropdown,
      redirectToSearchPage
    },
    inputRef
  ) => {
    const _renderResultsDropdown = () => {
      if (
        !pathname.startsWith("/search") &&
        showDropdown &&
        textSearchVODS &&
        textSearchVODS.all &&
        textSearchVODS.all.length > 0
      ) {
        return (
          <SearchResultCardList
            filteringPanelID={filteringPanelID}
            hasValue={!!searchText}
            hideDropdown={hideDropdown}
            redirectToSearchPage={redirectToSearchPage}
            textSearchVODS={textSearchVODS}
            onHideNav={onHideNav}
            showDropdownOverride={true}
          />
        );
      }
    };

    const _getPlaceholderTranslation = () => {
      return (
        (isChannelCategoriesPage && "common.searchChannels") ||
        (vod && channels && "common.searchTitlesChannels") ||
        (vod && !channels && "common.searchTitles") ||
        (!vod && channels && "common.searchChannels") ||
        (!vod && epg && "common.searchChannels")
      );
    };

    const placeholderTranslation = _getPlaceholderTranslation();

    return (
      <div className={style.searchTextWrapper}>
        {(!!searchText && showLoader && (
          <Loader active={true} size="small" />
        )) ||
          (!!searchText && (
            <CloseIcon className="clearText" onClick={onClearText} />
          ))}
        <input
          ref={inputRef}
          className={"themeInput"}
          id="filteringInput"
          type="text"
          placeholder={intl.formatMessage({
            id: placeholderTranslation
          })}
          autoComplete="off"
          value={searchText}
          onChange={onChange}
          onFocus={onFocus}
        />
        {_renderResultsDropdown()}
      </div>
    );
  }
);

SearchTextComponentView.propTypes = {
  intl: object.isRequired,
  showDropdown: bool.isRequired,
  showLoader: bool.isRequired,
  filteringPanelID: string.isRequired,
  isChannelCategoriesPage: bool.isRequired,
  location: object.isRequired,
  composerConfig: object.isRequired,
  textSearchVODS: object,
  searchText: string,
  onHideNav: func.isRequired,
  onClearText: func.isRequired,
  onChange: func.isRequired,
  onFocus: func.isRequired,
  hideDropdown: func.isRequired,
  redirectToSearchPage: func.isRequired
};

export default memo(SearchTextComponentView);
