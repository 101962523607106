import { store } from "../../App";
import {
  OPEN_PLAYER,
  SET_PLAYER_SIZE
} from "../../redux/reducers/floatingPlayer";

const redirectToPlay = function({
  VOD,
  selectedProfile,
  history,
  continuePlayWithoutResumeModal,
  alreadyEnteredParentalPin
}) {
  const {
    location: { pathname }
  } = history;
  const {
    floatingPlayer: { isPlayerOpened }
  } = store.getState();

  if (isPlayerOpened) {
    maximizePlayer();
    togglePlayer(null);
  } else {
    togglePlayer(true);
  }

  history.push({
    pathname,
    search: "play",
    state: {
      item: VOD,
      cameFrom: pathname,
      continuePlayWithoutResumeModal,
      alreadyEnteredParentalPin,
      selectedProfile: selectedProfile
    }
  });
};

const togglePlayer = value => {
  store.dispatch({
    type: OPEN_PLAYER,
    payload: {
      data: value
    }
  });
};

const maximizePlayer = () => {
  store.dispatch({
    type: SET_PLAYER_SIZE,
    payload: {
      data: "maximize"
    }
  });
};

export default redirectToPlay;
