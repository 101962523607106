import { call } from "redux-saga/effects";
import { fetchMoviesStream } from "helper-functions/http-functions";

const getMovieStream = function*({ payload }) {
  try {
    const result = yield call(fetchMoviesStream, payload);

    if (result) payload.successCallback(result);
    else payload.successCallback(false);
  } catch (err) {
    payload.successCallback(false);
    console.info("ERR", err);
  }
};

export default getMovieStream;
