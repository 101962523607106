const getRequestFullScreenElement = element => {
  if (element) {
    return (
      element.requestFullscreen ||
      element.mozRequestFullScreen ||
      element.webkitRequestFullScreen ||
      element.msRequestFullscreen ||
      element.webkitDisplayingFullscreen
    );
  }
  return;
};

const getRequestCancelFullScreenElement = doc => {
  if (doc && doc.fullscreen) {
    return (
      doc.exitFullscreen ||
      doc.mozCancelFullscreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen
    );
  }
  return;
};

export const onClickFullscreen = function() {
  const doc = window.document;
  const element = doc.documentElement;

  const requestFullScreen = getRequestFullScreenElement(element);
  const cancelFullScreen = getRequestCancelFullScreenElement(doc);

  if (
    !doc.fullscreenElement &&
    !doc.mozFullscreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    this.setState({ isFullscreen: true });
    requestFullScreen.call(element);
  } else if (cancelFullScreen) {
    this.setState({ isFullscreen: false });
    cancelFullScreen.call(doc);
  }
};

export const exitFullScreen = function() {
  const { isFullscreen } = this.state;
  const doc = window.document;
  const cancelFullScreen = getRequestCancelFullScreenElement(doc);

  if (isFullscreen && cancelFullScreen) {
    cancelFullScreen.call(doc);
  }
};
