import update from "immutability-helper";

export const SET_PLAYER_SIZE = "SET_PLAYER_SIZE";
export const SET_SELECTED_PLAYER = "SET_SELECTED_PLAYER";
export const SET_SELECTED_VOD = "SET_SELECTED_VOD";
export const OPEN_PLAYER = "OPEN_PLAYER";

const playerSizes = ["maximize", "minimize"];
const defaultValues = {
  isPlayerOpened: false,
  playerSize: playerSizes[0],
  selectedPlayer: null,
  selectedVod: null
};

const floatingPlayer = (state = defaultValues, action) => {
  switch (action.type) {
    case SET_PLAYER_SIZE:
      return update(state, { playerSize: { $set: action.payload.data } });
    case OPEN_PLAYER:
      return update(state, { isPlayerOpened: { $set: action.payload.data } });
    case SET_SELECTED_PLAYER:
      return update(state, { selectedPlayer: { $set: action.payload.data } });
    case SET_SELECTED_VOD:
      return update(state, { selectedVod: { $set: action.payload.data } });
    default:
      return state;
  }
};

export default floatingPlayer;

export const setPlayerSize = payload => {
  return {
    type: SET_PLAYER_SIZE,
    payload: {
      data: payload
    }
  };
};

export const setOpenPlayer = payload => {
  return {
    type: OPEN_PLAYER,
    payload: {
      data: payload
    }
  };
};

export const setSelectedPlayer = payload => {
  return {
    type: SET_SELECTED_PLAYER,
    payload: {
      data: payload
    }
  };
};

export const setSelectedVod = payload => {
  return {
    type: SET_SELECTED_VOD,
    payload: {
      data: payload
    }
  };
};
