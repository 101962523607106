import { get, uniqBy } from "lodash";
export const GET_EPGS = "GET_EPGS";
export const GET_EPGS_SUCCESS = "GET_EPGS_SUCCESS";
export const GET_EPGS_APPEND = "GET_EPGS_SUCCESS";
export const APPEND_EPG = "APPEND_EPG";
export const APPEND_EPG_SUCCESS = "APPEND_EPG_SUCCESS";

export const EPG = (state = [], action) => {
  switch (action.type) {
    case GET_EPGS:
      if (action.payload) return action.payload;
      return state;
    default:
      return state;
  }
};

export const appendEPG = (state = [], action) => {
  switch (action.type) {
    case APPEND_EPG:
      return {
        pager: action.payload.pager,
        items: uniqBy(
          [...(get(state, "items") || []), ...get(action.payload, "items")],
          "id"
        )
      };
    default:
      return state;
  }
};

export const getChannelEPG = data => {
  return {
    type: GET_EPGS_SUCCESS,
    payload: {
      url: data.url,
      successCallback: data.successCallback,
      getMoreChannelDetails: data.getMoreChannelDetails,
      dontSaveToProps: data.dontSaveToProps,
      appendItems: data.appendItems
    }
  };
};
