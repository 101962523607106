export const CHANGE_VOD_PROFILE = "CHANGE_VOD_PROFILE";
export const GET_AVAILABLE_VOD_PROFILES = "GET_AVAILABLE_VOD_PROFILES";

const savedVodProfile = localStorage.getItem("wecast-vod-profile");

const vodProfilesList = (state = savedVodProfile, action) => {
  switch (action.type) {
    case CHANGE_VOD_PROFILE:
      return action.payload;
    default:
      return state;
  }
};

export default vodProfilesList;

export const changeVodProfile = data => {
  return {
    type: CHANGE_VOD_PROFILE,
    payload: data
  };
};

export const getAvailableVodProfiles = data => {
  return {
    type: GET_AVAILABLE_VOD_PROFILES,
    payload: {
      url: data.url,
      successCallback: data.successCallback
    }
  };
};
