import authSaga from "./authSaga";
import loginSaga from "./loginSaga";
import * as passwordHandlers from "./passwordHandlers";
import getRecommended from "./getRecommended";
import getSingleMovie from "./getSingleMovie";
import getMovieStream from "./getMovieStream";
import getChannels from "./getChannels";
import getChannelEPG from "./getChannelEPG";
import addOrRemoveFavouriteChannel from "./addOrRemoveFavouriteChannel";
import * as reminderHandlers from "./reminderHandlers";
import rentChannel from "./rentChannel";
import getChannelCategories from "./getChannelCategories";
import getShowsGenres from "./getShowsGenres";
import getVodShowTypes from "./getVodShowTypes";
import getMvodList from "./getMvodList";
import getSvodList from "./getSvodList";
import getSimilarMovies from "./getSimilarMovies";
import rentVOD from "./rentVOD";
import addVodProfile from "./addVodProfile";
import rateVOD from "./rateVOD";
import getPaymentHistory from "./getPaymentHistory";
import appendPaymentHistory from "./appendPaymentHistory";
import * as subscriptionHandlers from "./subscriptionHandlers";
import getHighlightedContent from "./getHighlightedContent";
import * as devicesHandlers from "./devicesHandlers";
import * as textFilterHandlers from "./textFilterHandlers";
import * as profileHandlers from "./profileHandlers";
import getHomepageHorizontalList from "./getHomepageHorizontalList";
import getSeasonEpisodes from "./getSeasonEpisodes";
import getStaticPages from "./getStaticPages";
import getContinueWatchingVods from "./getContinueWatchingVods";
import getComposerConfig from "./getComposerConfig";
import getAvailableVodProfiles from "./getAvailableVodProfiles";
import getAppendChannels from "./getAppendChannels";
import getLanguages from "./getLanguages";
import getVideoCampaign from "./getVideoCampaign";
import getSingleChannel from "./getSingleChannel";
import getBanner from "./getBanner";

const forgotPasswordHandler = passwordHandlers.forgotPasswordHandler;
const resetPasswordHandler = passwordHandlers.resetPasswordHandler;
const getSubscriptions = subscriptionHandlers.getSubscriptions;
const updateSubscription = subscriptionHandlers.updateSubscription;
const checkSubscription = subscriptionHandlers.checkSubscription;
const getSubscriptionsAnonymous =
  subscriptionHandlers.getSubscriptionsAnonymous;
const getDevices = devicesHandlers.getDevices;
const removeDevice = devicesHandlers.removeDevice;
const appendTextFilterSearchResults =
  textFilterHandlers.appendTextFilterSearchResults;
const getTextSearchFilterVods = textFilterHandlers.getTextSearchFilterVods;
const updateProfile = profileHandlers.updateProfile;
const createProfile = profileHandlers.createProfile;
const registerUser = profileHandlers.registerUser;
const addReminder = reminderHandlers.addReminder;
const getReminders = reminderHandlers.getReminders;
const deleteReminder = reminderHandlers.deleteReminder;
const editReminder = reminderHandlers.editReminder;

export default {
  authSaga,
  loginSaga,
  forgotPasswordHandler,
  resetPasswordHandler,
  getRecommended,
  getSingleMovie,
  getMovieStream,
  getChannels,
  getChannelEPG,
  addOrRemoveFavouriteChannel,
  addReminder,
  rentChannel,
  getChannelCategories,
  getShowsGenres,
  getVodShowTypes,
  getMvodList,
  getSvodList,
  getSimilarMovies,
  rentVOD,
  addVodProfile,
  rateVOD,
  getPaymentHistory,
  appendPaymentHistory,
  getSubscriptions,
  getSubscriptionsAnonymous,
  checkSubscription,
  updateSubscription,
  getHighlightedContent,
  getDevices,
  removeDevice,
  appendTextFilterSearchResults,
  getTextSearchFilterVods,
  updateProfile,
  createProfile,
  registerUser,
  getHomepageHorizontalList,
  getSeasonEpisodes,
  getStaticPages,
  getContinueWatchingVods,
  getComposerConfig,
  getAvailableVodProfiles,
  getReminders,
  getAppendChannels,
  getLanguages,
  getVideoCampaign,
  getSingleChannel,
  getBanner,
  deleteReminder,
  editReminder
};
