import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FlexibleMoreDetailsCarousel from "components/Carousels/FlexibleMoreDetailsCarousel";
import { isEqual, find, get } from "lodash";
import {
  openNotificationWithIcon,
  redirectToPlay
} from "functions/logic-functions";
import InputPinModal from "components/Modals/InputPinModal";
import RentOrPlayProfilesModal from "components/Modals/RentOrPlayProfilesModal";

class CarouselContinueWatchWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      VOD: null,
      openPlayModal: false,
      openParentalModal: false
    };
    this.selectedVodProfile = localStorage.getItem("wecast-vod-profile")
      ? JSON.parse(localStorage.getItem("wecast-vod-profile"))
      : {};
    this.subscribedProfileLength = null;
    this.includesDefaultProfile = false;
  }

  _onSelect = (type, data) => {
    if (type === "rent") {
      this.setState({
        selectedRent: !isEqual(this.state.selectedRent, data) ? data : null
      });
    }
  };

  _redirectToPlayer = (vod, selectedVodProfile) => {
    redirectToPlay({
      VOD: vod,
      selectedProfile: selectedVodProfile,
      history: this.props.history,
      continuePlayWithoutResumeModal: true,
      alreadyEnteredParentalPin: true
    });
  };

  _getSubscribedProfile = () => {
    const { VOD } = this.state;

    return find(VOD.movie_source.profiles, o => o.subscribed);
  };

  _getSubscribedProfiles = VOD => {
    return (
      get(VOD, "movie_source.profiles") &&
      VOD.movie_source.profiles.filter(item => item.subscribed)
    );
  };

  _setIncludedDefaultProfile = subscribedProfiles => {
    if (subscribedProfiles) {
      subscribedProfiles.forEach(item => {
        if (
          get(this.selectedVodProfile, "id") &&
          item.id === this.selectedVodProfile.id
        ) {
          this.includesDefaultProfile = true;
        }
      });
    }
  };

  _redirectToPlayIfSubscribedOneAndParentalPinNotRequired = VOD => {
    if (VOD) {
      const subscribedProfile = this._getSubscribedProfile();
      this._redirectToPlayer(VOD, subscribedProfile);
    }
  };

  _onOpenPlayModal = id => {
    const { loggedUser, cachedContinueWatchingList, intl } = this.props;

    this.includesDefaultProfile = false;

    if (
      get(loggedUser, "account") &&
      !loggedUser.account.subscription_expired
    ) {
      this.setState(
        {
          VOD: find(cachedContinueWatchingList, o => o.id === id) || null
        },
        () => {
          const { VOD } = this.state;

          if (VOD) {
            const subscribedProfiles = this._getSubscribedProfiles(VOD);
            const isRequiredParentalPin = get(
              VOD,
              "parental_rating.require_pin"
            );

            this._setIncludedDefaultProfile(subscribedProfiles);
            this.subscribedProfileLength = subscribedProfiles
              ? subscribedProfiles.length
              : null;

            if (subscribedProfiles && !subscribedProfiles.length) {
              this._getNotifications();
            } else if (
              this.subscribedProfileLength &&
              get(this.selectedVodProfile, "name") &&
              this.selectedVodProfile.name !== "Auto" &&
              this.includesDefaultProfile &&
              !isRequiredParentalPin
            ) {
              this._redirectToPlayer(VOD, this.selectedVodProfile);
            } else if (
              this.subscribedProfileLength === 1 &&
              !isRequiredParentalPin
            ) {
              this._redirectToPlayIfSubscribedOneAndParentalPinNotRequired(VOD);
            }

            if (
              (isRequiredParentalPin && this.subscribedProfileLength === 1) ||
              (isRequiredParentalPin &&
                subscribedProfiles &&
                this.includesDefaultProfile)
            ) {
              this.setState({
                openParentalModal: true,
                openPlayModal: false
              });
            }

            if (isRequiredParentalPin && this.subscribedProfileLength) {
              this.setState({
                openParentalModal: true,
                openPlayModal: false
              });
            } else if (
              this.subscribedProfileLength > 1 &&
              !isRequiredParentalPin
            ) {
              this.setState({
                openPlayModal: true,
                openParentalModal: false
              });
            } else if (
              this.subscribedProfileLength &&
              this.selectedVodProfile &&
              this.selectedVodProfile.name === "Auto"
            ) {
              this.setState({
                openPlayModal: true
              });
            }
          }
        }
      );
    } else {
      openNotificationWithIcon({
        type: "warning",
        message: intl.formatMessage({
          id: "notifications.subscriptionHasExpired"
        })
      });
    }
  };

  _getNotifications = () => {
    const { VOD } = this.state;
    const { intl } = this.props;

    openNotificationWithIcon({
      type: "info",
      message: intl.formatMessage({
        id: `${
          VOD.type === 0
            ? "notifications.movieNotRented"
            : "notifications.episodeIsNotRented"
        }`
      }),
      description: intl.formatMessage({
        id: `${
          VOD.type === 0
            ? "notifications.rentMovieBeforeWatching"
            : "notifications.rentEpisodeBeforeWatching"
        }`
      })
    });
  };

  _onSuccessProfileModal = (type, selectedProfileForPlay) => {
    const { VOD } = this.state;

    if (type === "play") {
      this.setState(
        {
          openPlayModal: false,
          VOD: null
        },
        () => {
          this._redirectToPlayer(VOD, selectedProfileForPlay);
        }
      );
    }
  };

  _onParentalPinSuccess = () => {
    this.setState({
      openParentalModal: false,
      openPlayModal: true
    });
  };

  _onRedirectToPlay = () => {
    const { VOD } = this.state;
    const subscribedProfile = this._getSubscribedProfile();

    this.setState(
      {
        openParentalModal: false
      },
      () => {
        this._redirectToPlayer(VOD, subscribedProfile);
      }
    );
  };

  _onCloseProfilesModal = type => {
    switch (type) {
      case "rent":
        this.setState({
          openRentModal: false,
          selectedRent: null,
          VOD: null
        });
        break;
      case "confirm":
        this.setState({
          openConfirmModal: false,
          openRentModal: true,
          VOD: null
        });
        break;
      case "play":
        this.setState({
          openPlayModal: false,
          VOD: null
        });
        break;
      default:
        break;
    }
  };

  _onCloseParentalPin = () => {
    this.setState({
      openParentalModal: false,
      VOD: null
    });
  };

  render() {
    const { openParentalModal, openPlayModal, VOD } = this.state;
    const { cachedContinueWatchingList, intl } = this.props;
    let subscribedProfiles =
      (VOD &&
        get(VOD, "movie_source.profiles") &&
        VOD.movie_source.profiles.filter(item => item.subscribed)) ||
      [];

    return (
      <Fragment>
        {cachedContinueWatchingList && cachedContinueWatchingList.length > 0 && (
          <FlexibleMoreDetailsCarousel
            intl={intl}
            title={intl.formatMessage({
              id: "homepage.continueWatching"
            })}
            items={cachedContinueWatchingList}
            onOpenPlayModal={this._onOpenPlayModal}
            continuePlayWithoutResumeModal={true}
            useInfiniteScroll={
              cachedContinueWatchingList &&
              cachedContinueWatchingList.length >= 10
            }
          />
        )}
        {openPlayModal && (
          <RentOrPlayProfilesModal
            intl={intl}
            open={openPlayModal}
            onSuccess={this._onSuccessProfileModal}
            onClose={this._onCloseProfilesModal}
            profiles={subscribedProfiles || []}
            eventType="play"
            successButtonTitle={intl.formatMessage({
              id: "common.play"
            })}
          />
        )}
        {openParentalModal && (
          <InputPinModal
            intl={intl}
            open={openParentalModal}
            type="parental"
            includesDefaultProfile={this.includesDefaultProfile}
            subscribedProfileLength={this.subscribedProfileLength}
            onSuccess={this._onParentalPinSuccess}
            onRedirectToPlay={this._onRedirectToPlay}
            onClose={this._onCloseParentalPin}
            successButtonTitle={intl.formatMessage({
              id: "common.confirm"
            })}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cachedContinueWatchingList: state.cachedContinueWatchingList,
    loggedUser: state.loggedUser
  };
}

export default withRouter(
  connect(mapStateToProps)(CarouselContinueWatchWrapper)
);
