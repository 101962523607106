import React, { Fragment, memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import CloseIcon from "react-icons/lib/md/close";
import { sendAnalyticsData } from "functions/logic-functions";
import style from "./style.module.scss";

const ActiveBannerView = ({
  banner,
  useParentContainer,
  useNestedBanner,
  onClose,
  onClick
}) => {
  return (
    <div
      className={classNames("advertisement-banner", {
        [style.defaultWrapper]: useParentContainer,
        [style.container]: !useParentContainer,
        [style.nestedBanner]: useNestedBanner
      })}
    >
      {banner.ads_file && banner.ads_file.original && (
        <Fragment>
          <div className={style.closeIcon} onClick={onClose}>
            <CloseIcon />
          </div>
          <a
            href={banner.target_url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClick}
          >
            <img
              src={banner.ads_file && banner.ads_file.original}
              alt={""}
              onClick={() => sendAnalyticsData("ad_click")}
            />
          </a>
        </Fragment>
      )}
    </div>
  );
};

ActiveBannerView.propTypes = {
  useParentContainer: PropTypes.bool,
  useNestedBanner: PropTypes.bool, //Property for using banner inside movies/tv-shows grid list
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default memo(ActiveBannerView);
