import { put, call } from "redux-saga/effects";
import { get } from "lodash";
import { GET_HOMEPAGE_HORIZONTAL_LIST_SUCCESS } from "reduxStore/reducers/cachedHorizontalHomepage";
import { fetchData, postData } from "helper-functions/http-functions";

const getHomepageHorizontalList = function*({ payload }) {
  try {
    const callRequest =
      payload.requestMethod && payload.requestMethod === "post"
        ? postData
        : fetchData;
    const result = yield call(callRequest, payload);

    if (result) {
      yield put({
        type: GET_HOMEPAGE_HORIZONTAL_LIST_SUCCESS,
        payload: {
          alias: payload.alias,
          result: get(result, "data.data") || result
        }
      });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export default getHomepageHorizontalList;
