const getParamFromUrlBanner = url => {
  //eslint-disable-next-line
  const regex = /\A?\[box_position]=([^&]+)/g;
  const result = regex.exec(url);

  if (result) return result[1];
  return;
};

const activeBannerIsValid = (url, positionNameBanner) =>
  getParamFromUrlBanner(url) === positionNameBanner;

export default activeBannerIsValid;
