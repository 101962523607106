import { put, call } from "redux-saga/effects";
import { GET_SIMILAR_MOVIES_SUCCESS } from "reduxStore/reducers/cachedSingleMoviesCarousels";
import { postData } from "helper-functions/http-functions";

const getSimilarMovies = function*({ payload }) {
  try {
    const result = yield call(postData, payload);

    if (result && result.data && result.data.data) {
      yield put({
        type: GET_SIMILAR_MOVIES_SUCCESS,
        payload: {
          id: payload.body.vod_id,
          result: result.data.data
        }
      });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export default getSimilarMovies;
