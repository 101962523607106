import React, { PureComponent } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import LightBoxView from "./components/LightBoxView";
import style from "./style.module.scss";

const modalRoot = document.querySelector("#modal-root");

class LightBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imgSource: null
    };
    this.imgWrapperClass = "light-box-image-wrapper";
    this.el = document.createElement("div");
    this.el.classList.add("light-box-wrapper");
  }

  componentWillReceiveProps(nextProps) {
    const { selectedIndex: nextSelectedIndex, imgUrl } = nextProps;
    const { selectedIndex } = this.state;

    if (nextSelectedIndex !== selectedIndex) {
      this.setState(
        {
          imgSource: null
        },
        () => {
          const downloadingImage = new Image();

          downloadingImage.src = imgUrl;
          downloadingImage.onload = () => {
            this.setState({
              imgSource: imgUrl
            });
          };
        }
      );
    }
  }

  componentDidMount() {
    const { imgUrl } = this.props;
    const downloadingImage = new Image();
    const self = this;

    modalRoot.appendChild(this.el);
    modalRoot.classList.add(style.fullHeight);
    downloadingImage.src = imgUrl;
    downloadingImage.onload = function() {
      self.setState({
        imgSource: imgUrl
      });
    };
    window.addEventListener("keyup", this.listenerKeyboard);
  }

  listenerKeyboard = event => {
    const {
      onCloseLightBox,
      selectedIndex,
      length,
      onImageChange
    } = this.props;

    if (event.key === "Escape") {
      onCloseLightBox();
    } else if (event.key === "ArrowLeft" && selectedIndex > 0) {
      onImageChange(selectedIndex - 1);
    } else if (event.key === "ArrowRight" && selectedIndex < length - 1) {
      onImageChange(selectedIndex + 1);
    }
  };

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
    modalRoot.classList.remove(style.fullHeight);
    window.removeEventListener("keyup", this.listenerKeyboard);
  }

  onClose = event => {
    const { onCloseLightBox } = this.props;
    const elementClass = event.target.className;

    if (
      elementClass &&
      typeof elementClass == "string" &&
      elementClass.includes(this.imgWrapperClass)
    )
      onCloseLightBox();
  };

  render() {
    const { imgSource } = this.state;
    const {
      onCloseLightBox,
      selectedIndex,
      length,
      onImageChange
    } = this.props;

    return createPortal(
      <LightBoxView
        imgSource={imgSource}
        selectedIndex={selectedIndex}
        length={length}
        onCloseLightBox={onCloseLightBox}
        onImageChange={onImageChange}
        onClose={this.onClose}
      />,
      this.el
    );
  }
}

LightBox.propTypes = {
  imgUrl: PropTypes.string,
  onCloseLightBox: PropTypes.func,
  selectedIndex: PropTypes.number,
  length: PropTypes.number,
  onImageChange: PropTypes.func
};

export default LightBox;
