export const ADD_SOCKET = "ADD_SOCKET";

const socket = (state = null, action) => {
  switch (action.type) {
    case ADD_SOCKET:
      if (action.payload && action.payload.socket) return action.payload.socket;
      return state;
    default:
      return state;
  }
};

export default socket;

export const addSocket = data => {
  return {
    type: ADD_SOCKET,
    payload: {
      socket: data.socket
    }
  };
};
