import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledComponents from "styled-components";
import FullWidthButton from "../../FormElements/FullWidthButton";
import style from "./style.module.scss";

class RegisterFormPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null
    };
  }

  nextStep = () => {
    const { selected } = this.state;
    const { changeFormStep, updateFormValues } = this.props;

    if (selected) {
      updateFormValues({
        packageID: selected
      });
      changeFormStep("next");
    }
  };

  selectOption = selected => {
    this.setState({
      selected
    });
  };

  renderOptions = () => {
    const { selected } = this.state;
    const { subscriptions } = this.props;

    return subscriptions.map(item => {
      const selectedClass = selected === item.id ? "selected" : "";

      return (
        <SelectOption
          key={item.name}
          className={selectedClass}
          onClick={() => {
            this.selectOption(item.id);
          }}
        >
          <div>
            <h2>{item.name}</h2>
            <p>
              {item.subscription_price} {item.currency_code}
            </p>
          </div>
          <div>
            <p>{item.description}</p>
          </div>
        </SelectOption>
      );
    });
  };

  render() {
    const { selected } = this.state;
    const { intl } = this.props;

    return (
      <div>
        <div className={style.timelineBlock}>
          <div>
            <span />
            <p>{intl.formatMessage({ id: "account.aboutYou" })}</p>
          </div>
          <div>
            <span />
            <p>
              {intl.formatMessage({
                id: "account.yourSubscription"
              })}
            </p>
          </div>
        </div>
        {this.renderOptions()}
        <FullWidthButton onClick={this.nextStep} disabled={!selected}>
          {intl.formatMessage({
            id: "common.continue"
          })}
        </FullWidthButton>
      </div>
    );
  }
}

const SelectOption = StyledComponents.div`
	width: 100%;
	display: flex;
	border: 2px solid var(--theme_text_color);
	box-sizing: border-box;
	padding: 15px;
	margin-bottom: 25px;
	cursor: pointer;
	&:last-child{
		margin-bottom: 0px;
	}
	p{
		margin: 0;
		color: #646464;
		padding-left: 10px;
	}
	div{
		flex-grow: 1;
		flex-basis: 50%;
		h2{
			margin: 0;
			text-transform: uppercase;
		}
	}
	div:last-child{
		margin-left:auto;
		p{
			text-align: right;
		}
	}
	&.selected{
		background-color: black;
		*{
			color: white !important;
		}
	}
`;

RegisterFormPackage.propTypes = {
  changeFormStep: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
  subscriptions: PropTypes.array.isRequired
};

export default RegisterFormPackage;
