import { call } from "redux-saga/effects";
import { fetchData } from "helper-functions/http-functions";

const getSvodList = function*({ payload }) {
  const { successCallback } = payload;

  try {
    const result = yield call(fetchData, payload);

    if (result && successCallback) successCallback(result);
  } catch (err) {
    if (successCallback) successCallback([]);
    console.info("ERR", err);
  }
};

export default getSvodList;
