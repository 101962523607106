import { uniqBy } from "lodash";
export const GET_SIMILAR_MOVIES = "GET_SIMILAR_MOVIES";
export const GET_SIMILAR_MOVIES_SUCCESS = "GET_SIMILAR_MOVIES_SUCCESS";

const cachedSingleMoviesCarousels = (state = [], action) => {
  switch (action.type) {
    case GET_SIMILAR_MOVIES_SUCCESS:
      if (action.payload && action.payload.id)
        return uniqBy([...state, action.payload], "id");
      return state;
    default:
      return state;
  }
};

export default cachedSingleMoviesCarousels;

export const getSimilarMovies = data => {
  return {
    type: GET_SIMILAR_MOVIES,
    payload: {
      url: data.url,
      body: data.body
    }
  };
};
