import { call } from "redux-saga/effects";
import { get } from "lodash";
import { postData } from "helper-functions/http-functions";

const rentChannel = function*({ payload }) {
  try {
    const result = yield call(postData, payload);

    if (result && result.data && payload.successCallback)
      payload.successCallback(get(result, "data.data"));
  } catch (err) {
    console.info("ERR", err);
  }
};

export default rentChannel;
