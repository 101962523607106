import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { get } from "lodash";
import { Link } from "react-router-dom";
import StarIcon from "react-icons/lib/md/star";
import { isCensorshipCard } from "functions/logic-functions";
import DefaultButton from "components/Buttons/DefaultButton";
import InputPinModal from "components/Modals/InputPinModal";
import generateProgressBar from "../../../commonFunctions/generateProgressBar";
import style from "./style.module.scss";

const {
  string,
  number,
  bool,
  func,
  shape,
  array,
  oneOfType,
  object
} = PropTypes;

const CarouselVerticalCardView = ({
  intl,
  title,
  genres,
  rating,
  id,
  urlRoute,
  attachUrlID,
  openParentalModal,
  imgUrl,
  item,
  loggedUser,
  continue_watching,
  onClick,
  onClose,
  onParentalPinSuccess
}) => {
  const goTo = attachUrlID ? `${urlRoute}/${id}` : urlRoute;

  const _renderHoverDetails = () => {
    return (
      <div className={style.hoverDetails}>
        {rating && (
          <div className={style.ratingWrapper}>
            <StarIcon />
            <p>{rating} / 10</p>
          </div>
        )}
        {title && <h4>{title}</h4>}
        {genres && genres.length > 0 && <p>{genres.slice(0, 5).join(", ")}</p>}
        <DefaultButton
          title={intl.formatMessage({
            id: "common.viewDetails"
          })}
        />
      </div>
    );
  };

  const _renderInputPinModal = () => {
    if (openParentalModal) {
      return (
        <InputPinModal
          intl={intl}
          open={openParentalModal}
          type="parental"
          onSuccess={() => onParentalPinSuccess(goTo)}
          onRedirectToPlay={() => this.props.history.push(goTo)}
          onClose={onClose}
          successButtonTitle={intl.formatMessage({
            id: "common.confirm"
          })}
        />
      );
    }
  };

  return (
    <div className={style.mainWrapper}>
      {get(loggedUser, "account.subscription_expired") && (
        <div
          className={classNames(
            style.expiredSubscriptionOverlay,
            "themeExpiredSubscriptionOverlay"
          )}
        />
      )}
      <Link
        to={{
          pathname: goTo,
          state: {
            item,
            alreadyEnteredParentalPin: true
          }
        }}
        className={classNames(style.linkWrapper, {
          [style.censorshipCard]: isCensorshipCard(item)
        })}
        onClick={onClick}
      >
        <img
          src={imgUrl}
          className={classNames({
            [style.imgWithTitleAndProgress]: continue_watching
          })}
          alt={""}
        />
        {generateProgressBar(continue_watching)}
        {_renderHoverDetails()}
      </Link>
      {_renderInputPinModal()}
    </div>
  );
};

CarouselVerticalCardView.defaultProps = {
  attachUrlID: true
};

CarouselVerticalCardView.propTypes = {
  id: oneOfType([string, number]),
  intl: object.isRequired,
  loggedUser: object.isRequired,
  imgUrl: string.isRequired,
  urlRoute: string.isRequired,
  title: string,
  genres: array,
  rating: oneOfType([number, string]),
  attachUrlID: bool,
  item: object,
  openPlayModal: func,
  continue_watching: shape({
    duration: number,
    stopped_time: number
  }),

  onClick: func.isRequired,
  onClose: func.isRequired,
  onParentalPinSuccess: func.isRequired
};

export default memo(CarouselVerticalCardView);
