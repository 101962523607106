import { put, call } from "redux-saga/effects";
import { FETCH_CHANNELS_SUCCESS } from "reduxStore/reducers/channels";
import {
  fetchChannels,
  fetchMultipleGetRequests
} from "helper-functions/http-functions";

const getChannels = function*({ payload }) {
  try {
    const fetchDataFunction = Array.isArray(payload.url)
      ? fetchMultipleGetRequests
      : fetchChannels;
    let result = yield call(fetchDataFunction, payload);

    if (result && result.length) {
      result = result.map(item => item.data.data);
    }

    if (result) {
      yield put({ type: FETCH_CHANNELS_SUCCESS, payload: result });
      if (payload.successCallback) payload.successCallback(result);
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export default getChannels;
