import { put, call } from "redux-saga/effects";
import { get } from "lodash";
import { UPDATE_SUBSCRIBED_PROFILE } from "reduxStore/reducers/highlightedContent";
import { UPDATE_SEASON_RENTED_EPISODE } from "reduxStore/reducers/cachedSingleTvShowsCarousels";
import { postData } from "helper-functions/http-functions";

const rentVOD = function*({ payload }) {
  try {
    const result = yield call(postData, payload);

    if (result) {
      if (payload.successCallback)
        payload.successCallback(get(result, "data.data"));

      yield put({
        type: UPDATE_SUBSCRIBED_PROFILE,
        payload: get(result, "data.data")
      });
      yield put({
        type: UPDATE_SEASON_RENTED_EPISODE,
        payload: get(result, "data.data")
      });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export default rentVOD;
