import axios from "./axios";

const fetchChannels = payload => {
  if (payload && payload.url) {
    const { url } = payload;

    return axios
      .get(url)
      .then(response => {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data &&
          response.data.status > 0 &&
          response.data.data
        ) {
          return response.data.data;
        } else return new Error("Request error, or missing data");
      })
      .catch(err => {
        // console.info("FETCH CHANNELS ERR", err);
        return err;
      });
  }
};

export default fetchChannels;
