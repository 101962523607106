import React, { PureComponent } from "react";

const IconHoC = CustomComponent =>
  class IconWrapper extends PureComponent {
    render() {
      if (!CustomComponent) return null;

      return (
        <div {...this.props}>
          <CustomComponent {...this.props} />
        </div>
      );
    }
  };

export default IconHoC;
