import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { get } from "lodash";
import StarIcon from "react-icons/lib/md/star";
import { isCensorshipCard, redirectToPlay } from "functions/logic-functions";
import DefaultButton from "components/Buttons/DefaultButton";
import InputPinModal from "components/Modals/InputPinModal";
import generateProgressBar from "../../../commonFunctions/generateProgressBar";
import style from "./style.module.scss";

const {
  string,
  number,
  object,
  bool,
  shape,
  oneOfType,
  func,
  array
} = PropTypes;

const GridCardView = ({
  intl,
  history,
  title,
  rating,
  genres,
  id,
  imgUrl,
  item,
  urlRoute,
  attachUrlID,
  loggedUser,
  continueWatching,
  openParentalModal,
  onParentalPinSuccess,
  onCloseParentalPin,
  onClick
}) => {
  const goTo = attachUrlID ? `${urlRoute}/${id}` : urlRoute;
  const doRedirect =
    get(item, "movie_source.profiles") &&
    item.movie_source.profiles.length === 1 &&
    !item.parental_rating &&
    redirectToPlay;

  const _renderParentalPin = goTo => {
    if (goTo && openParentalModal) {
      return (
        <InputPinModal
          open={openParentalModal}
          type="parental"
          onSuccess={() => onParentalPinSuccess(goTo)}
          onRedirectToPlay={() => history.push(goTo)}
          onClose={onCloseParentalPin}
          successButtonTitle={intl.formatMessage({
            id: "common.confirm"
          })}
          intl={intl}
        />
      );
    }
  };

  const _renderHoverDetails = () => {
    return (
      <div className={style.hoverDetails}>
        {rating && (
          <div className={style.ratingWrapper}>
            <StarIcon />
            <p>{rating} / 10</p>
          </div>
        )}
        {title && <h4>{title}</h4>}
        {genres && genres.length > 0 && <p>{genres.slice(0, 5).join(", ")}</p>}
        <DefaultButton
          title={intl.formatMessage({
            id: "common.viewDetails"
          })}
        />
      </div>
    );
  };

  return (
    <div className={style.mainWrapper}>
      {get(loggedUser, "account.subscription_expired") && (
        <div
          className={classNames(
            style.expiredSubscriptionOverlay,
            "themeExpiredSubscriptionOverlay"
          )}
        />
      )}
      <Link
        to={{
          pathname: goTo,
          state: {
            item
          }
        }}
        className={style.linkWrapper}
        onClick={event => onClick(event, doRedirect)}
      >
        <img
          src={imgUrl}
          className={classNames({
            [style.blurCard]: isCensorshipCard(item)
          })}
          alt={""}
        />
        {_renderHoverDetails()}
        {generateProgressBar(continueWatching)}
      </Link>
      {_renderParentalPin(goTo)}
    </div>
  );
};

GridCardView.propTypes = {
  intl: object,
  history: object,
  id: oneOfType([string, number]),
  loggedUser: object.isRequired,
  imgUrl: string.isRequired,
  urlRoute: string.isRequired,
  title: string,
  genres: array,
  rating: oneOfType([number, string]),
  attachUrlID: bool,
  item: object,
  openPlayModal: func,
  continueWatching: shape({
    duration: number,
    stopped_time: number
  }),
  onClick: func.isRequired
};

export default memo(GridCardView);
