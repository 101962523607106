import styledComponents from "styled-components";

export const CarouselWrapper = styledComponents.div`
	margin: 90px 0px;
	min-height: 15vw;
	width: 100%;
	
	.slick-slide{
		overflow: hidden;
		height: 15vw;
		padding: 5px;
		transition: all 150ms ease;
		&:hover{
			padding: 3px;
		}
		
		& > div {
		    height: 100%;
		    
		    & > div {
		        height: 100%;
		    }
		}
	}
	@media (min-width: 1400px) and (max-width: 1599px){
		.slick-slide{
			height: 20vw;
		}
	}
	@media (min-width: 1199px) and (max-width: 1399px){
		.slick-slide{
			height: 24vw;
		}
	}
	@media (min-width: 991px) and (max-width: 1198px){
		.slick-slide{
			height: 24vw;
		}
	}
	@media (min-width: 768px) and (max-width: 990px){
		.slick-slide{
			height: 40vw;
		}
	}
	@media (min-width: 480px) and (max-width: 767px){
		.slick-slide{
			height: 60vw;
		}
	}
	@media (max-width: 479px){
		.slick-slide{
			height: 60vw;
		}
	}
	svg{
		transform: scale(1.5);
		color: var(--theme_text_color);
		transition: color 250ms ease;
		&:hover{
			color: black;
		}
	}
`;
