import React, { memo } from "react";
import PropTypes from "prop-types";
import MdClose from "react-icons/lib/md/close";
import style from "./style.module.scss";

const PlayerCloseIcon = ({ showIcon, onClose }) => (
  <div style={{ display: showIcon ? "block" : "none" }} onClick={onClose}>
    <div className={style.outerClose} id={"closeButton"}>
      <div className={style.close}>
        <MdClose />
      </div>
    </div>
  </div>
);

PlayerCloseIcon.propTypes = {
  showIcon: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default memo(PlayerCloseIcon);
