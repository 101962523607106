import update from "immutability-helper";
import { get } from "lodash";

export const AUTHORIZE_SUCCESS = "AUTHORIZED_SUCCESS";
export const AUTHORIZE_FAILED = "AUTHORIZED_FAILED";
export const CHANGE_SUBSCRIPTION = "CHANGE_SUBSCRIPTION";
export const DISABLE_SUBSCRIPTION = "DISABLE_SUBSCRIPTION";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const CHECK_SUBSCRIPTION = "CHECK_SUBSCRIPTION";
export const CHECK_SUBSCRIPTION_SUCCESS = "CHECK_SUBSCRIPTION_SUCCESS";

const loggedUser = (state = null, action) => {
  switch (action.type) {
    case AUTHORIZE_SUCCESS:
      if (action.payload && action.payload.token) return action.payload;
      else return state;

    case DISABLE_SUBSCRIPTION:
      if (state && state.account && !state.account.subscription_expired) {
        const newState = update(state, {
          account: { subscription_expired: { $set: true } }
        });

        setAccountToLocalStorage(JSON.stringify(newState));

        return newState;
      }
      return state;

    case CHANGE_SUBSCRIPTION:
      return changeSubscription(state, action);

    case UPDATE_PROFILE_SUCCESS:
      return updateProfile(state, action);

    case CHECK_SUBSCRIPTION_SUCCESS:
      return checkSubscriptionSuccess(state, action);

    case AUTHORIZE_FAILED:
      return false;
    default:
      return state;
  }
};

export default loggedUser;

export const checkSubscription = data => {
  return {
    type: CHECK_SUBSCRIPTION,
    payload: {
      url: data.url,
      successCallback: data.successCallback
    }
  };
};

const changeSubscription = (state, action) => {
  if (action.payload) {
    const cached = getAccountFromLocalStorage();
    const device = cached.device;

    device.token = action.payload.device.token;

    setAccountToLocalStorage(
      JSON.stringify(
        Object.assign({}, cached, {
          token: action.payload.device.token,
          device,
          account: action.payload.account,
          subscription: action.payload.subscription
        })
      )
    );

    return Object.assign({}, state, {
      token: action.payload.device.token,
      device,
      account: action.payload.account,
      subscription: action.payload.subscription
    });
  }
  return state;
};

const updateProfile = (state, action) => {
  if (action.payload) {
    const cached = getAccountFromLocalStorage();
    const overwriteProfile = Object.assign({}, cached.profile, action.payload);
    const actionSubscriptionExpired =
      typeof get(action, "payload.account.subscription_expired") === "boolean"
        ? get(action, "payload.account.subscription_expired")
        : get(state, "account.subscription_expired");
    let overwriteAccount = {
      ...cached.account,
      subscription_expired: actionSubscriptionExpired
    };

    if (action.payload.purchase_pin) {
      overwriteAccount = Object.assign({}, overwriteAccount, {
        purchase_pin: action.payload.purchase_pin
      });
    }

    setAccountToLocalStorage(
      JSON.stringify(
        Object.assign({}, cached, {
          profile: overwriteProfile,
          account: overwriteAccount
        })
      )
    );

    return Object.assign({}, state, {
      profile: overwriteProfile,
      account: { ...overwriteAccount, ...action.payload.account }
    });
  }
  return state;
};

const checkSubscriptionSuccess = (state, action) => {
  if (action.payload) {
    const cached = getAccountFromLocalStorage();

    setAccountToLocalStorage(
      JSON.stringify(
        Object.assign({}, cached, {
          account: action.payload.account
        })
      )
    );

    return update(state, {
      account: {
        subscription_expired: {
          $set: get(action, "payload.account.subscription_expired")
        }
      }
    });
  }

  return state;
};

const getAccountFromLocalStorage = () =>
  localStorage.getItem("wecastUser")
    ? JSON.parse(localStorage.getItem("wecastUser"))
    : {};

const setAccountToLocalStorage = data =>
  localStorage.setItem("wecastUser", data);
