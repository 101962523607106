import React, { PureComponent } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { logout } from "functions/http-functions";
import { sendAnalyticsData } from "functions/logic-functions";
import MobileNavbarView from "./components/MobileNavbarView";

const navRoot = document.getElementById("mobile-nav");

class MobileNavbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openFilter: false
    };
    this.el = document.createElement("div");
  }

  componentWillReceiveProps(nextProps) {
    const { isHidden } = nextProps;

    if (isHidden) navRoot.style.pointerEvents = "all";
    else navRoot.style.pointerEvents = "none";
  }

  componentDidMount() {
    const { isHidden } = this.props;

    navRoot.appendChild(this.el);
    if (isHidden) navRoot.style.pointerEvents = "all";
    else navRoot.style.pointerEvents = "none";
  }

  toggleSearch = () => {
    this.setState(state => ({
      openFilter: !state.openFilter
    }));
  };

  onLogout = () => {
    logout();
    sendAnalyticsData("logout");
  };

  render() {
    const {
      intl,
      onHideNav,
      isHidden,
      composerConfig,
      loggedUser
    } = this.props;

    return createPortal(
      <MobileNavbarView
        intl={intl}
        loggedUser={loggedUser}
        composerConfig={composerConfig}
        isHidden={isHidden}
        onHideNav={onHideNav}
        onLogout={this.onLogout}
        toggleSearch={this.toggleSearch}
      />,
      this.el
    );
  }
}

MobileNavbar.propTypes = {
  onHideNav: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  composerConfig: state.composerConfig
});

export default withRouter(connect(mapStateToProps)(MobileNavbar));
