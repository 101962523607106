const areImagesTheSame = (logoUrl, screenshotUrl) => {
  if (logoUrl && screenshotUrl) {
    let logoName = logoUrl.split("/");
    logoName = logoName[logoName.length - 1].split("_");
    logoName = logoName[logoName.length - 1];

    let screenshotName = screenshotUrl.split("/");
    screenshotName = screenshotName[screenshotName.length - 1].split("_");
    screenshotName = screenshotName[screenshotName.length - 1];

    return logoName === screenshotName;
  }
  return;
};

export default areImagesTheSame;
