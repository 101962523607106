import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import SingleSlideView from "./components/SingleSlideView";

class SingleSlide extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      originalImageSource: null
    };
  }

  componentDidMount() {
    const { item } = this.props;
    const downloadingImage = new Image();
    let imgSource =
      item.image ||
      get(item, "model.banners[0].preview_medium") ||
      get(item, "model.gallery[0].preview_medium") ||
      get(item, "model.cover.preview_medium") ||
      get(item, "model.cover.preview_medium") ||
      get(item, "model.covers[0].preview_medium") ||
      get(item, "model.screenshot_url");
    const self = this;

    downloadingImage.src = imgSource;
    downloadingImage.onload = function() {
      self.setState({
        originalImageSource: imgSource
      });
    };
  }

  render() {
    const { item, redirectToDetailsPage, onSlideClickPlay } = this.props;
    const { originalImageSource } = this.state;
    let imgSource =
      item.image ||
      get(item, "model.banners[0].preview_medium") ||
      get(item, "model.gallery[0].preview_medium") ||
      get(item, "model.cover.preview_medium") ||
      get(item, "model.covers[0].preview_medium") ||
      get(item, "model.screenshot_url");
    imgSource = imgSource.split("/");

    if (!imgSource[imgSource.length - 1].includes("_")) {
      imgSource[imgSource.length - 1] = `small_${
        imgSource[imgSource.length - 1]
      }`;
    } else {
      const urlParts = imgSource[imgSource.length - 1].split("_");

      imgSource[imgSource.length - 1] = `small_${
        urlParts[urlParts.length - 1]
      }`;
    }
    imgSource = imgSource.join("/");

    return (
      <SingleSlideView
        item={item}
        imgSource={imgSource}
        originalImageSource={originalImageSource}
        redirectToDetailsPage={redirectToDetailsPage}
        onSlideClickPlay={onSlideClickPlay}
      />
    );
  }
}

SingleSlide.propTypes = {
  item: PropTypes.object.isRequired,
  redirectToDetailsPage: PropTypes.func.isRequired,
  onSlideClickPlay: PropTypes.func.isRequired
};

export default SingleSlide;
