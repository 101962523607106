import { call } from "redux-saga/effects";
import { fetchData } from "helper-functions/http-functions";

const getSingleMovie = function*({ payload }) {
  const { successCallback } = payload;
  try {
    const result = yield call(fetchData, payload);

    if (result && result.items && result.items.length && successCallback) {
      successCallback(result.items);
    } else successCallback(false);
  } catch (err) {
    if (successCallback) successCallback(false);
    console.info("ERR", err);
  }
};

export default getSingleMovie;
