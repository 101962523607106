import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import { withRouter } from "react-router";
import { uniq } from "lodash";
import {
  parseFromQueryToObject,
  parseObjectToUrl
} from "functions/logic-functions";
import SearchFiltersView from "./components/SearchFiltersView";

class SearchFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      year: null
    };

    this.searchQueryToObject = parseFromQueryToObject(props.location.search);
  }

  componentDidMount() {
    const {
      location: { search }
    } = this.props;
    const queryStringObject = parseFromQueryToObject(search);

    if (this.searchQueryToObject.year) {
      this.setState({
        year: queryStringObject.year.split("-")
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { year: stateYear } = this.state;
    const {
      match: {
        params: { year }
      }
    } = nextProps;
    this.searchQueryToObject = parseFromQueryToObject(
      get(nextProps, "location.search")
    );

    if (!this.searchQueryToObject.year) this.setState({ year: null });
    else if (
      stateYear &&
      year &&
      (parseInt(stateYear[0]) !== parseInt(year.split("-")[0]) ||
        parseInt(stateYear[1]) !== parseInt(year.split("-")[1]))
    ) {
      this.setState({
        year: year.split("-").map(Number)
      });
    }
  }

  getUpdatedGenresList = (searchObject, id) => {
    let genresList = [];

    if (searchObject.genres) {
      genresList = searchObject.genres.split(",").map(Number);

      if (genresList.indexOf(id) === -1) {
        genresList = uniq([...genresList, id]);
      } else {
        genresList = genresList.filter(item => item !== id);
      }
    } else genresList.push(id);

    return genresList;
  };

  onChange = event => {
    const {
      location: { pathname }
    } = this.props;
    const id = parseInt(event.target.id);
    const genresList = this.getUpdatedGenresList(this.searchQueryToObject, id);
    const query = {};

    query["show-types"] = this.searchQueryToObject["show-types"];
    query.year = this.searchQueryToObject.year;
    query.genres = genresList.length && genresList.join(",");

    this.props.history.push({
      path: pathname,
      search: parseObjectToUrl(query)
    });
  };

  onYearChange = data => {
    this.setState({
      year: data
    });
  };

  onAfterChange = data => {
    const {
      location: { pathname }
    } = this.props;

    this.searchQueryToObject.year = data && data.join("-");

    this.setState(
      {
        year: data
      },
      () => {
        this.props.history.push({
          path: pathname,
          search: parseObjectToUrl(this.searchQueryToObject)
        });
      }
    );
  };

  resetFilters = () => {
    const {
      location: { pathname }
    } = this.props;

    this.setState(
      {
        year: null
      },
      () => {
        this.props.history.push(pathname);
      }
    );
  };

  render() {
    const {
      genres,
      visible,
      showYearFilter,
      match,
      location,
      children
    } = this.props;
    const { year } = this.state;

    if (!genres || (genres && !genres.length)) return null;

    return (
      <SearchFiltersView
        genres={genres}
        visible={visible}
        children={children}
        showYearFilter={showYearFilter}
        year={year}
        match={match}
        location={location}
        onChange={this.onChange}
        onYearChange={this.onYearChange}
        onAfterChange={this.onAfterChange}
        resetFilters={this.resetFilters}
      />
    );
  }
}

SearchFilters.propTypes = {
  visible: PropTypes.bool.isRequired,
  showYearFilter: PropTypes.bool
};

const mapStateToProps = state => ({
  genres: state.genres
});

export default withRouter(connect(mapStateToProps)(SearchFilters));
