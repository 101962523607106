import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ModalStyled, ModalWrapper } from "./styledComponents";
import classNames from "classnames";
import BottomControlButtons from "../components/BottomControlButtons";
import style from "./style.module.scss";

class InputPinModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pinValue: "",
      wrongPin: null
    };
  }

  componentDidMount() {
    document.addEventListener("keyup", this.listenerKeyboardEnter);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.listenerKeyboardEnter);
  }

  listenerKeyboardEnter = event => {
    const { activeButton } = this.state;
    const {
      key,
      target: { value, maxLength, localName }
    } = event;

    if (localName === "input") {
      this.setState({
        activeButton: value.length === maxLength
      });
    }

    if (activeButton && key === "Enter") {
      this._onSuccess();
    }
  };

  _onSuccess = () => {
    const { pinValue } = this.state;
    const { intl } = this.props;
    const {
      type,
      onSuccess,
      includesDefaultProfile,
      onRedirectToPlay,
      subscribedProfileLength,
      loggedUser: {
        account: { purchase_pin },
        profile: { pin }
      }
    } = this.props;

    if (type === "parental") {
      if (pinValue && pin && pinValue === pin) {
        if (
          (onRedirectToPlay &&
            subscribedProfileLength &&
            subscribedProfileLength === 1) ||
          includesDefaultProfile
        ) {
          onRedirectToPlay();
        } else if (!includesDefaultProfile) {
          onSuccess();
        }
      } else {
        this.setState({
          wrongPin: intl.formatMessage({
            id: "modal.wrongParentalPin"
          })
        });
      }
    } else if (type === "purchase") {
      if (pinValue && purchase_pin && pinValue === purchase_pin) onSuccess();
      else
        this.setState({
          wrongPin: intl.formatMessage({
            id: "modal.wrongPurchasePin"
          })
        });
    }
  };

  _renderParentalTitle = type => {
    const { intl } = this.props;

    if (type) {
      switch (type) {
        case "parental":
          return intl.formatMessage({ id: "common.parentalPin" });
        case "purchase":
          return intl.formatMessage({ id: "common.purchasePin" });
        default:
          break;
      }
    }
  };

  _onChange = event => this.setState({ pinValue: event.target.value });

  _renderBottomControlButtons = () => {
    const {
      onClose,
      successButtonTitle,
      hideCancelButton,
      onBack
    } = this.props;

    return (
      <BottomControlButtons
        successButtonTitle={successButtonTitle}
        closeTranslationID={"common.cancel"}
        hideCancelButton={hideCancelButton}
        onSuccess={this._onSuccess}
        onClose={onClose}
        onBack={onBack}
      />
    );
  };

  render() {
    const { open, onClose, type, intl } = this.props;
    const { pinValue, wrongPin } = this.state;

    if (!open) return null;

    return (
      <ModalStyled
        dimmer={"blurring"}
        size={"tiny"}
        open={open}
        onClose={onClose}
      >
        <ModalWrapper ref={ref => (this.inputWrapper = ref)}>
          <h4>{this._renderParentalTitle(type)}</h4>
          <div className="input-wrapper">
            <input
              autoFocus
              className={classNames(
                style.modalInputField,
                pinValue && style.inputHasValue
              )}
              placeholder={intl.formatMessage(
                { id: "modal.enterTypePin" },
                { pinType: type }
              )}
              maxLength={type === "parental" ? 4 : 5}
              type="text"
              value={pinValue}
              onChange={this._onChange}
            />
            {wrongPin && <span className="error-message">{wrongPin}</span>}
          </div>

          {this._renderBottomControlButtons()}
        </ModalWrapper>
      </ModalStyled>
    );
  }
}

InputPinModal.propTypes = {
  open: PropTypes.bool.isRequired,
  includesDefaultProfile: PropTypes.bool,
  subscribedProfileLength: PropTypes.number,
  onClose: PropTypes.func,
  onRedirectToPlay: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  successButtonTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired // can be parental or purchase
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
});

export default connect(mapStateToProps)(InputPinModal);
