import axios from "./axios";

const fetchData = ({ url }) => {
  if (url) {
    return axios
      .get(url)
      .then(response => {
        if (
          response &&
          response.status === 200 &&
          response.data &&
          response.data.status > 0
        ) {
          const {
            data: { data: responseData }
          } = response;

          if (responseData) return responseData;
          else throw new Error("Missing request data");
        } else throw new Error("Request failed");
      })
      .catch(err => {
        console.info("FETCH ERROR", err);
        throw err;
      });
  }
};

export default fetchData;
