const rentChannelFunction = params => {
  const {
    url,
    streaming_profile_id,
    channel,
    loggedUser,
    rentChannelDispatch,
    successCallback
  } = params;

  if (loggedUser && loggedUser.token && channel && channel.id) {
    rentChannelDispatch({
      url,
      body: {
        channel_id: channel.id,
        streaming_profile_id: streaming_profile_id
      },
      token: loggedUser.token,
      successCallback
    });
  }
};

export default rentChannelFunction;
