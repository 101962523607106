const getInternalMovieSubtitles = player => {
  const internalPlayer =
    player &&
    (player.getInternalPlayer("hls") || player.getInternalPlayer("dash"));

  window.internalPlayer = internalPlayer;

  if (internalPlayer) {
    if (internalPlayer.subtitleTracks) {
      internalPlayer.subtitleTrack = -1;

      return internalPlayer.subtitleTracks;
    } else if (internalPlayer.getVideoElement) {
      let tracks = internalPlayer.getVideoElement().textTracks;

      internalPlayer.setTextTrack(-1);
      return Object.values({ ...tracks }).map(
        ({ label, kind, language }, index) => ({
          id: index,
          label,
          kind,
          lang: language
        })
      );
    }
  }
  return [];
};

export default getInternalMovieSubtitles;
