import { get } from "lodash";

const getExternalSubtitles = movie => {
  if (get(movie, "movie_source.subtitles")) {
    return movie.movie_source.subtitles
      .map(item => {
        if (item.url.indexOf(".srt") !== -1)
          item.url = item.url.replace(".srt", ".vtt");
        return item;
      })
      .map(item => ({
        id: item.id,
        locale: item.locale,
        kind: "subtitles",
        src: item.url,
        srcLang: item.locale
      }));
  }
  return [];
};

export default getExternalSubtitles;
