const parseObjectToEndpointQuery = obj => {
  if (obj && typeof obj === "object") {
    const filteredData = Object.keys(obj).filter(key => obj[key]);

    if (filteredData.length) {
      return (
        "?" +
        filteredData
          .reduce(function(a, k) {
            switch (k) {
              case "genres":
                const genres = obj[k]
                  .split(",")
                  .map((item, index) => `filter[genre_id][${index}]=${item}`)
                  .join("&");

                a.push(genres);
                return a;

              case "year":
                const years = obj[k].split("-");

                a.push(
                  `filter[from_year]=${years[0]}&filter[to_year]=${years[1]}`
                );
                return a;

              case "title":
                a.push(`filter[title]=${obj[k]}`);
                return a;

              case "show-types":
                const showTypes = obj[k]
                  .split(",")
                  .map(
                    (item, index) => `filter[show_type_id][${index}]=${item}`
                  )
                  .join("&");

                a.push(showTypes);
                return a;

              default:
                a.push(`${k}=${obj[k]}`);
                return a;
            }
          }, [])
          .join("&")
      );
    }
  }
  return;
};

export default parseObjectToEndpointQuery;
