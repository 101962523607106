import FormData from "form-data";
import axios from "./axios";

const postData = ({ url, body }) => {
  if (url) {
    const bodyFormData = new FormData();

    if (body) {
      for (let key in body) {
        bodyFormData.append(key, body[key]);
      }
    }

    return axios({
      method: "post",
      url: url,
      data: bodyFormData
    })
      .then(response => {
        if (response && response.status === 200 && response.data.status > 0) {
          return response;
        }
        return;
      })
      .catch(err => {
        return err;
      });
  }
};

export default postData;
