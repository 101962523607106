import FormData from "form-data";
import axios from "./axios";
import { openNotificationWithIcon } from "../logic-functions";

const resetPassword = ({ url, body }) => {
  if (url && body) {
    const bodyFormData = new FormData();

    for (var key in body) {
      bodyFormData.append(key, body[key]);
    }
    return axios({
      method: "post",
      url: url,
      data: bodyFormData
    })
      .then(response => {
        if (response && response.data.status === 1) {
          return response;
        } else if (response.data && response.data.status === -1) {
          openNotificationWithIcon({
            type: "error",
            message: response.data.message
          });
        }
      })
      .catch(err => {
        return err;
      });
  }
};

export default resetPassword;
