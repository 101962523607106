import React, { PureComponent } from "react";
import { connect } from "react-redux";
import FlexibleMoreDetailsCarousel from "components/Carousels/FlexibleMoreDetailsCarousel";
import { getChannels } from "reduxStore/reducers/channels";
import { get, unionWith, uniqBy } from "lodash";
import { injectIntl } from "react-intl";

class CarouselFavoritesWrapper extends PureComponent {
  _onAppendChannels = nextSlide => {
    const { useCacheFrom, pager, onAppendChannels } = this.props;

    if (!pager || get(pager, "count") > useCacheFrom.length) {
      if (nextSlide >= this.slide * 1.5) {
        this.slide = nextSlide;
        this.setState(
          state => ({
            page: state.page + 1
          }),
          () => {
            onAppendChannels(60, this.state.page);
          }
        );
      }
    }
  };

  render() {
    const { channels, intl, favoritesChannels } = this.props;

    if (!channels || (channels && !channels.items.length)) return null;
    const favoviretesChannelFromLoadedChannels = channels.items.filter(
      item => item.favorite
    );

    if (!favoviretesChannelFromLoadedChannels.length) return null;
    const allFavorites = uniqBy(
      unionWith(favoritesChannels, favoviretesChannelFromLoadedChannels),
      "id"
    );

    return (
      <FlexibleMoreDetailsCarousel
        title={intl.formatMessage({
          id: "common.favoriteChannels"
        })}
        intl={intl}
        showPlayIcon={false}
        urlRoute={"/channels"}
        items={allFavorites}
        useInfiniteScroll={allFavorites && allFavorites.length >= 10}
        onAppendChannels={this._onAppendChannels}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getChannelsDispatch: data => dispatch(getChannels(data))
});

const mapStateToProps = state => ({
  channels: state.channels
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CarouselFavoritesWrapper));
