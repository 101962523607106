import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import IconHoC from "HOCs/IconHoC";
import { ReactComponent as Play } from "./icons/play.svg";
import { find, get } from "lodash";
import { isCensorshipCard, redirectToPlay } from "functions/logic-functions";
import InputPinModal from "components/Modals/InputPinModal";
import generateProgressBar from "../commonFunctions/generateProgressBar";
import style from "./style.module.scss";

class EpisodeCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      requirePin: get(props.item, "parental_rating.require_pin"),
      openParentalModal: false
    };
  }

  _onParentalPinSuccess = goTo => {
    const { item, history } = this.props;
    this.setState({
      openParentalModal: false
    });

    history.replace({
      pathname: goTo,
      state: {
        alreadyEnteredParentalPin: true,
        item
      }
    });
  };

  _onClose = () => {
    const { onChangePlaying } = this.props;

    if (onChangePlaying) onChangePlaying(true);
    this.setState({
      openParentalModal: false
    });
  };

  _renderParentalPin = goTo => {
    const { requirePin, openParentalModal } = this.state;
    const { history, intl } = this.props;

    if (goTo && openParentalModal) {
      return (
        <InputPinModal
          intl={intl}
          open={requirePin}
          type="parental"
          onSuccess={() => this._onParentalPinSuccess(goTo)}
          onRedirectToPlay={() => history.replace(goTo)}
          onClose={this._onClose}
          successButtonTitle={intl.formatMessage({
            id: "common.confirm"
          })}
        />
      );
    }
  };

  _redirectIfRequiredPin = () => {
    const { id, urlRoute, attachUrlID, onDisplayParentalModal } = this.props;

    if (onDisplayParentalModal) {
      let goTo = attachUrlID ? `${urlRoute}/${id}` : urlRoute;

      onDisplayParentalModal(goTo);
    } else {
      this.setState({ openParentalModal: true });
    }
  };

  _redirectIfNotRequiredPin = doRedirect => {
    const { item, history, isResumePlayerOff } = this.props;

    doRedirect.call(this, {
      VOD: item,
      selectedProfile: item.movie_source.profiles[0],
      history,
      state: {
        item,
        alreadyEnteredParentalPin: !isResumePlayerOff,
        selectedProfile: item.movie_source.profiles[0],
        subscribedProfile: !this._isProfileSubscribed(item) ? item : null
      }
    });
  };

  _onClick = (event, doRedirect) => {
    const { requirePin } = this.state;
    const {
      id,
      item,
      openPlayModal,
      isResumePlayerOff,
      onDisplaySelectProfileToPlayModal
    } = this.props;

    if (requirePin) {
      event.preventDefault();
      this._redirectIfRequiredPin();
    } else if (openPlayModal && doRedirect) {
      event.preventDefault();
      this._redirectIfNotRequiredPin(doRedirect);
    } else if (openPlayModal) {
      event.preventDefault();
      openPlayModal(id);
    } else if (onDisplaySelectProfileToPlayModal && isResumePlayerOff) {
      event.preventDefault();
      onDisplaySelectProfileToPlayModal(item);
    }
  };

  _isProfileSubscribed = episode => {
    const {
      location: { state }
    } = this.props;
    const selectedProfile = get(state, "selectedProfile");
    const profileSelectedNextEpisode = find(
      episode.movie_source.profiles,
      item => item.code === get(selectedProfile, "code")
    );
    return get(profileSelectedNextEpisode, "subscribed");
  };

  _getRedirectMethod = () => {
    const { item } = this.props;
    return (
      get(item, "movie_source.profiles") &&
      get(item, "movie_source.profiles").length === 1 &&
      !item.parental_rating &&
      redirectToPlay
    );
  };

  render() {
    const {
      id,
      imgUrl,
      item,
      urlRoute,
      title,
      attachUrlID,
      loggedUser,
      showPlayIcon,
      continue_watching,
      activeEpisodeID,
      onDisplayParentalModal,
      episodeNumber
    } = this.props;
    const goTo = attachUrlID ? `${urlRoute}/${id}` : urlRoute;
    const PlayIcon = IconHoC(Play);
    const doRedirect = this._getRedirectMethod();

    return (
      <div className={classNames(style.mainWrapper, style.roundedStyle)}>
        {get(loggedUser, "account.subscription_expired") && (
          <div
            className={classNames(
              style.expiredSubscriptionOverlay,
              "themeExpiredSubscriptionOverlay"
            )}
          />
        )}
        <Link
          to={{
            pathname: goTo,
            state: { item }
          }}
          style={{
            pointerEvents:
              parseInt(activeEpisodeID) === parseInt(id) ? "none" : "all"
          }}
          className={classNames(style.linkWrapper, {
            [style.activeEpisode]: parseInt(activeEpisodeID) === parseInt(id)
          })}
          onClick={event => this._onClick(event, doRedirect)}
        >
          {showPlayIcon && (
            <div className={style.playIcon}>
              <PlayIcon />
            </div>
          )}
          <img
            src={imgUrl}
            className={classNames({
              [style.blurCard]: isCensorshipCard(item),
              [style.imgWithTitleAndProgress]: title && continue_watching,
              [style.imgWithTitle]: title && !continue_watching
            })}
            alt={title && title}
          />
          {generateProgressBar(continue_watching)}
          {title && (
            <h3>
              {episodeNumber && <span>{episodeNumber} - </span>}
              {title}
            </h3>
          )}
        </Link>
        {!onDisplayParentalModal && this._renderParentalPin(goTo)}
      </div>
    );
  }
}

EpisodeCard.propTypes = {
  intl: PropTypes.object,
  item: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeEpisodeID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imgUrl: PropTypes.string.isRequired,
  urlRoute: PropTypes.string.isRequired,
  title: PropTypes.string,

  attachUrlID: PropTypes.bool,
  isResumePlayerOff: PropTypes.bool,
  showPlayIcon: PropTypes.bool,

  continue_watching: PropTypes.shape({
    duration: PropTypes.number,
    stopped_time: PropTypes.number
  }),

  openPlayModal: PropTypes.func,
  onChangePlaying: PropTypes.func,
  onDisplaySelectProfileToPlayModal: PropTypes.func,
  onDisplayParentalModal: PropTypes.func
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  vodProfile: state.vodProfile
});

export default withRouter(connect(mapStateToProps)(EpisodeCard));
