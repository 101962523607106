import { put, call } from "redux-saga/effects";
import { GET_VOD_GENRES_SUCCESS } from "reduxStore/reducers/genres";
import { fetchData } from "helper-functions/http-functions";

const getShowsGenres = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result) {
      if (payload.successCallback) payload.successCallback(result);
      else yield put({ type: GET_VOD_GENRES_SUCCESS, payload: result });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export default getShowsGenres;
