import { put, call } from "redux-saga/effects";
import { HIGHLIGHTED_CONTENT_SUCCESS } from "reduxStore/reducers/highlightedContent";
import { fetchData } from "helper-functions/http-functions";

const getHighlightedContent = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result)
      yield put({ type: HIGHLIGHTED_CONTENT_SUCCESS, payload: result });
  } catch (err) {
    console.info("ERR", err);
  }
};

export default getHighlightedContent;
