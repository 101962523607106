import React, { memo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as vodProfileActions from "reduxStore/reducers/vodProfile";
import { redirectToPlay } from "functions/logic-functions";
import CarouselVerticalCardView from "./components/CarouselVerticalCardView";

const CarouselVerticalCard = ({
  intl,
  id,
  item,
  title,
  genres,
  rating,
  imgUrl,
  urlRoute,
  attachUrlID,
  loggedUser,
  continue_watching,
  openPlayModal,
  history,
  addVodProfile
}) => {
  const [openParentalModal, setOpenParentalModal] = useState(false);
  const requirePin = get(item, "parental_rating.require_pin");

  const _onClick = event => {
    const doRedirect =
      get(item, "movie_source.profiles") &&
      item.movie_source.profiles.length === 1 &&
      !item.parental_rating &&
      redirectToPlay;

    if (requirePin) {
      event.preventDefault();
      setOpenParentalModal(true);
    } else if (openPlayModal && doRedirect) {
      event.preventDefault();
      doRedirect({
        VOD: item,
        selectedProfile: item.movie_source.profiles[0],
        addVodProfileDispatch: addVodProfile,
        history
      });
    } else if (openPlayModal) {
      event.preventDefault();
      openPlayModal(id);
    }
  };

  const _onClose = () => setOpenParentalModal(false);

  const _onParentalPinSuccess = useCallback(
    goTo => {
      setOpenParentalModal(false);
      history.push({
        pathname: goTo,
        state: {
          item,
          alreadyEnteredParentalPin: true
        }
      });
    },
    [item, history]
  );

  return (
    <CarouselVerticalCardView
      intl={intl}
      id={id}
      item={item}
      imgUrl={imgUrl}
      title={title}
      genres={genres}
      rating={rating}
      urlRoute={urlRoute}
      attachUrlID={attachUrlID}
      continue_watching={continue_watching}
      openParentalModal={openParentalModal}
      loggedUser={loggedUser}
      onClick={_onClick}
      onClose={_onClose}
      onParentalPinSuccess={_onParentalPinSuccess}
    />
  );
};

CarouselVerticalCard.defaultProps = {
  attachUrlID: true
};

CarouselVerticalCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgUrl: PropTypes.string.isRequired,
  urlRoute: PropTypes.string.isRequired,
  title: PropTypes.string,
  genres: PropTypes.array,
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  attachUrlID: PropTypes.bool,
  item: PropTypes.object,
  openPlayModal: PropTypes.func,
  continue_watching: PropTypes.shape({
    duration: PropTypes.number,
    stopped_time: PropTypes.number
  })
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
});

const mapDispatchToProps = dispatch => ({
  addVodProfile: data => dispatch(vodProfileActions.addVodProfile(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(CarouselVerticalCard))
);
