import { put, call } from "redux-saga/effects";
import { GET_REMINDERS_SUCCESS } from "reduxStore/reducers/remindersList";
import { fetchData, postData } from "helper-functions/http-functions";

export const addReminder = function*({ payload }) {
  try {
    const result = yield call(postData, payload);

    if (result && result.data && payload.successCallback)
      payload.successCallback(result.data);
  } catch (err) {
    payload.successCallback(false);
    console.info("ERR", err);
  }
};

export const getReminders = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result) {
      yield put({ type: GET_REMINDERS_SUCCESS, payload: result });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export const deleteReminder = function*({ payload }) {
  try {
    const result = yield call(postData, payload);

    if (result && payload.successCallback) {
      payload.successCallback(result);
    }
  } catch (e) {
    //
  }
};

export const editReminder = function*({ payload }) {
  try {
    const result = yield call(postData, payload);

    if (result && payload.successCallback) {
      payload.successCallback(result);
    }
  } catch (e) {
    //
  }
};
