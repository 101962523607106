import React from "react";
import PropTypes from "prop-types";
import { NavLink as NavLinkComponent } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import style from "./style.module.scss";

const NavLink = ({ onClick, translationID, to }) => {
  return (
    <NavLinkComponent
      exact
      to={to}
      activeClassName={style.activeNav}
      onClick={onClick}
    >
      <FormattedMessage id={translationID} />
    </NavLinkComponent>
  );
};

NavLink.propTypes = {
  onClick: PropTypes.func,
  translationID: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

export default NavLink;
