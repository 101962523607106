import React, { PureComponent, Fragment } from "react";
import moment from "moment";
import { find, differenceBy } from "lodash";
/*eslint-disable */
import { interval } from "rxjs";
/*eslint-enable */
import { Observable } from "rxjs/Observable";
import { GET_REMINDERS_URL } from "API-routes";
import { getReminders } from "reduxStore/reducers/remindersList";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
  uniqueCollections,
  openNotificationWithIcon
} from "functions/logic-functions";

class RemindersLogicWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.remindersArray = [];
  }

  componentDidMount() {
    this._getReminders();

    this.reminders15minTimerObserver = Observable.interval(900000);
    this.$reminderGlobalTimerSubscribe = this.reminders15minTimerObserver.subscribe(
      this._getReminders
    );
  }

  componentWillReceiveProps(nextProps) {
    const { remindersList: nextRemindersList } = nextProps;
    const { remindersList } = this.props;

    if (uniqueCollections(nextRemindersList, remindersList)) {
      const {
        moreRemindersList,
        lessRemindersList
      } = this._getPrevAndNextReminders(nextRemindersList, remindersList);
      const remindersDiffList = moreRemindersList.filter(
        e => !find(lessRemindersList, item => item.id === e.id)
      );

      this._unscubscribeReminders(
        remindersDiffList,
        remindersList,
        nextRemindersList
      );

      if (remindersDiffList.length === 1) {
        const shouldDelete = this._checkToDeleteReminder(
          remindersList,
          remindersDiffList
        );
        if (shouldDelete) return;
      } else {
        this._checkToUpdateReminder(nextRemindersList, remindersDiffList);
      }

      this._setNewReminderToList(remindersDiffList);
    }
  }

  componentWillUnmount() {
    if (
      this.$reminderGlobalTimerSubscribe &&
      !this.$reminderGlobalTimerSubscribe.isStopped
    ) {
      this.$reminderGlobalTimerSubscribe.unsubscribe();
    }
  }

  _checkToUpdateReminder = (remindersList, remindersDiffList) => {
    const changedReminder = differenceBy(
      remindersList,
      remindersDiffList,
      "activate_time"
    );

    if (changedReminder.length) {
      const reminder = find(
        this.remindersArray,
        item => item.id === changedReminder[0].id
      );
      this._removeReminder(reminder);
      this._setNewReminderToList(changedReminder);
    }
  };

  _removeReminder = reminder => {
    if (!reminder.subscriber.isStopped) {
      reminder.subscriber.unsubscribe();
    }
    this.remindersArray = this.remindersArray.filter(
      item => item.id !== reminder.id
    );
  };

  _checkToDeleteReminder = (remindersList, remindersDiffList) => {
    return !!find(remindersList, o => o.id === remindersDiffList[0].id);
  };

  _setNewReminderToList = remindersDiffList => {
    remindersDiffList.forEach(
      ({ id, epg_programme_title, epg_programme, activate_time_timestamp }) => {
        if (!find(this.remindersArray, item => item.id === id)) {
          const timeToTrigger = moment(
            moment.utc(activate_time_timestamp * 1000)
          ).diff(new Date().getTime());

          this.reminderTimeObserver = Observable.timer(timeToTrigger);
          this.remindersArray.push({
            id: id,
            title: epg_programme_title,
            time: activate_time_timestamp * 1000,
            subscriber: this.reminderTimeObserver.subscribe(() =>
              this._triggerCallback(epg_programme_title, epg_programme)
            )
          });
        }
      }
    );
  };

  _triggerCallback = (epg_programme_title, epg_programme) => {
    const { intl } = this.props;
    const time =
      moment(epg_programme.start_timestamp * 1000).diff(
        new Date().getTime(),
        "minutes"
      ) + 1;

    openNotificationWithIcon({
      type: "success",
      message: intl.formatMessage(
        {
          id: "notifications.startsIn"
        },
        {
          title: epg_programme_title,
          time
        }
      )
    });
  };

  _unscubscribeReminders = (
    remindersDiffList,
    remindersList,
    nextRemindersList
  ) => {
    if (Array.isArray(this.remindersArray)) {
      this.remindersArray.forEach(({ subscriber }) => {
        if (subscriber && !subscriber.isStopped) {
          subscriber.unsubscribe();
        }
      });

      const listForDelete =
        remindersList.length > nextRemindersList.length
          ? remindersDiffList
          : [];
      this.remindersArray = this.remindersArray.filter(
        e => !find(listForDelete, item => item.id === e.id)
      );
    }
  };

  _getPrevAndNextReminders = (nextRemindersList, remindersList) => {
    const moreRemindersList =
      nextRemindersList.length >= remindersList.length
        ? nextRemindersList
        : remindersList;
    const lessRemindersList =
      nextRemindersList.length < remindersList.length
        ? nextRemindersList
        : remindersList;

    return { moreRemindersList, lessRemindersList };
  };

  _getReminders = () => {
    const { getRemindersDispatch } = this.props;

    getRemindersDispatch({
      url: GET_REMINDERS_URL
    });
  };

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

const mapStateToProps = state => ({
  remindersList: state.remindersList,
  composerConfig: state.composerConfig
});

const mapDispatchToProps = dispatch => ({
  getRemindersDispatch: data => dispatch(getReminders(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RemindersLogicWrapper));
