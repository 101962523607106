import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { logout } from "functions/http-functions";
import { sendAnalyticsData } from "functions/logic-functions";
import NavbarView from "./components/NavbarView";

const navbarRoot = document.getElementById("fixed-nav");

class Navbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openFilter: false,
      visible: false
    };
    this.element = document.createElement("div");
  }

  componentDidMount() {
    navbarRoot.appendChild(this.element);
  }

  handleHidePopover = () => {
    this.setState({
      visible: false
    });
  };

  handleVisibleChangePopover = visible => {
    this.setState({ visible });
  };

  toggleSearch = () => {
    this.setState(state => ({
      openFilter: !state.openFilter
    }));
  };

  onLogout = () => {
    logout();
    sendAnalyticsData("logout");
  };

  render() {
    const {
      intl,
      loggedUser,
      composerConfig,
      onShowMobileNav,
      onHideNav
    } = this.props;
    const { openFilter, visible } = this.state;

    return ReactDOM.createPortal(
      <NavbarView
        intl={intl}
        openFilter={openFilter}
        visible={visible}
        loggedUser={loggedUser}
        composerConfig={composerConfig}
        onLogout={this.onLogout}
        toggleSearch={this.toggleSearch}
        onShowMobileNav={onShowMobileNav}
        onHideNav={onHideNav}
        handleVisibleChangePopover={this.handleVisibleChangePopover}
        handleHidePopover={this.handleHidePopover}
      />,
      this.element
    );
  }
}

Navbar.propTypes = {
  onShowMobileNav: PropTypes.func.isRequired,
  showMobileNav: PropTypes.bool.isRequired,
  onHideNav: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  composerConfig: state.composerConfig
});

export default withRouter(connect(mapStateToProps, null)(Navbar));
