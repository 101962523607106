import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getVodShowTypes } from "reduxStore/reducers/vodShowTypes";
import { GET_VOD_SHOW_TYPES } from "API-routes";
import { withRouter } from "react-router";
import { uniq } from "lodash";
import ShowTypesView from "./components/ShowTypesView";
import {
  parseFromQueryToObject,
  parseObjectToUrl
} from "functions/logic-functions";

class ShowTypes extends PureComponent {
  constructor(props) {
    super(props);
    this.searchQueryToObject = parseFromQueryToObject(props.location.search);
  }

  componentDidMount() {
    const { vodShowTypes, getVodShowTypesDispatch } = this.props;

    if (!vodShowTypes.length) {
      getVodShowTypesDispatch({
        url: GET_VOD_SHOW_TYPES
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { search }
    } = nextProps;

    if (!search) {
      this.searchQueryToObject = parseFromQueryToObject();
      this.forceUpdate();
    }
  }

  onShowType = showTypeId => {
    const {
      location: { pathname, search }
    } = this.props;
    let showTypeList = [];
    this.searchQueryToObject = parseFromQueryToObject(search);

    if (this.searchQueryToObject["show-types"]) {
      showTypeList = this.searchQueryToObject["show-types"]
        .split(",")
        .map(Number);

      if (!showTypeList.includes(showTypeId)) {
        showTypeList = uniq([...showTypeList, showTypeId]);
      } else showTypeList = showTypeList.filter(item => item !== showTypeId);
    } else {
      showTypeList.push(showTypeId);
    }

    this.searchQueryToObject["show-types"] = showTypeList.join(",");
    this.props.history.push({
      path: pathname,
      search: parseObjectToUrl(this.searchQueryToObject)
    });
  };

  render() {
    const { vodShowTypes, location } = this.props;

    return (
      <ShowTypesView
        searchQueryToObject={this.searchQueryToObject}
        vodShowTypes={vodShowTypes}
        location={location}
        onShowType={this.onShowType}
      />
    );
  }
}

const mapStateToProps = state => ({
  vodShowTypes: state.vodShowTypes
});

const mapDispatchToProps = dispatch => ({
  getVodShowTypesDispatch: data => dispatch(getVodShowTypes(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShowTypes)
);
