import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { Loader } from "semantic-ui-react";
import { get, debounce } from "lodash";
import RatingStars from "components/RatingStars";
import MiniProgressBar from "../components/MiniProgressBar";
import MinimizedControls from "../components/MinimizedControls";
import PlayerControls from "components/PlayerControls";
import PlayerCloseIcon from "components/PlayerCloseIcon";
import {
  getCover,
  onReady,
  onMute,
  onThumbs,
  onBuffer,
  playStopPlayer,
  onVolumeChange,
  rateMovie,
  onError,
  onProgress,
  updatePlaybackRate,
  onClickFullscreen,
  exitFullScreen
} from "../commonPlayerFunctions";
import * as floatingPlayerActions from "reduxStore/reducers/floatingPlayer";
import * as defaultActions from "reduxStore/actions";
import { SINGLE_VOD, GET_MVOD_LIST_URL } from "API-routes";
import InputPinModal from "components/Modals/InputPinModal";
import { sendAnalyticsData } from "functions/logic-functions";
import { injectIntl } from "react-intl";
import style from "./style.module.scss";

const CONTROLS_VISIBILITY_DURATION = 4000;

class TrailerPlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      movie: null,
      showLoader: true,
      progress: 0,
      duration: 0,
      volume: 0.8,
      playing: true,
      playbackRate: 1,
      isFullscreen: false,
      muted: false,
      showControls: true,
      showCloseIcon: true,
      showThumbs: false,
      visible: true,
      trailerRequiresPin: false,
      openStars: false,
      prevStatusPlaying: false
    };
    this.animation = "fade left";
    this.playerID = "controlsWrapper";
    this.settingButtonID = "settingBtnID";
    this.nextEpisodes = [];
    this.continueWatchVOD = null;

    this.exitFullScreen = exitFullScreen.bind(this);
    this.onClickFullscreen = onClickFullscreen.bind(this);
    this.onReady = onReady.bind(this);
    this.onMute = onMute.bind(this);
    this.onBuffer = onBuffer.bind(this);
    this.onProgress = onProgress.bind(this);
    this.onError = onError.bind(this);
    this.onVolumeChange = onVolumeChange.bind(this);
    this.updatePlaybackRate = updatePlaybackRate.bind(this);
    this.rateMovie = rateMovie.bind(this);
    this.playStopPlayer = playStopPlayer.bind(this);
    this.onThumbs = onThumbs.bind(this);
    this.alreadyEnteredParentalPin = !!get(
      props.history,
      "location.state.alreadyEnteredParentalPin"
    );
    this.cameFromUrl = props.location.pathname;
    this.isCensorshipActivated =
      localStorage.getItem("censorship-allowed") &&
      JSON.parse(localStorage.getItem("censorship-allowed"));
  }

  componentDidMount() {
    this._setEventListeners();
    this._getTrailerStream();
    sendAnalyticsData("play_trailer");
  }

  componentWillUnmount() {
    this._removeEventListeners();
    this._destroyInitalizedPlayers();
    this._activateDebounce.cancel();
    this.exitFullScreen();

    document.querySelector("body").style.height = "100%";
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { search },
      setOpenPlayer,
      floatingPlayer: { playerSize }
    } = this.props;
    const {
      location: { search: nextSearch }
    } = nextProps;

    if (nextSearch !== search && playerSize === "maximize") {
      setOpenPlayer(false);
    }
  }

  _destroyInitalizedPlayers = () => {
    if (this.player) {
      if (this.player.getInternalPlayer("dash")) {
        this.player.getInternalPlayer("dash").reset();
      }
      if (this.player.getInternalPlayer("hls")) {
        this.player.getInternalPlayer("hls").destroy();
      }
    }
    ReactPlayer.removeCustomPlayers();
  };

  _setEventListeners = () => {
    document.addEventListener("keyup", this._onKeyboardControls);
    document.addEventListener("mousemove", this._activateDebounce);
    document.addEventListener("mousemove", this._activateShowControls);
  };

  _removeEventListeners = () => {
    document.removeEventListener("keyup", this._onKeyboardControls);
    document.removeEventListener("mousemove", this._activateDebounce);
    document.removeEventListener("mousemove", this._activateShowControls);
  };

  _getTrailerStream = () => {
    const {
      location: { state, pathname }
    } = this.props;
    const id = get(state, "item.id");
    const episodeNumber = get(state, "item.episode_number");
    const seasonID = get(state, "item.multi_event_vod_season_id");
    const showID =
      get(state, "item.multi_event_vod_id") || get(state, "item.id");

    if (pathname.includes("tv-show") && seasonID && episodeNumber) {
      this._getEpisodeTrailerStream(seasonID, episodeNumber);
    } else if (pathname.includes("movie") && id) {
      this._getMovieTrailerStream(id);
    } else if (pathname.includes("tv-show") && showID) {
      this._getTvShowTrailerStream(showID);
    }
  };

  _getTvShowTrailerStream = showID => {
    const { getMvodList } = this.props;

    getMvodList({
      url: `${GET_MVOD_LIST_URL}?filter[id]=${showID}`,
      successCallback: this._successTrailerCallback
    });
  };

  _getMovieTrailerStream = id => {
    const { getMvodList } = this.props;

    getMvodList({
      url: `${SINGLE_VOD}?filter[id]=${id}`,
      successCallback: this._successTrailerCallback
    });
  };

  _getEpisodeTrailerStream = (seasonID, episodeNumber) => {
    const { getMvodList } = this.props;

    getMvodList({
      url: `${SINGLE_VOD}?filter[multi_event_vod_season_id]=${seasonID}&filter[episode_number]=${episodeNumber}&filter[type]=1`,
      successCallback: this._successTrailerCallback
    });
  };

  _activateDebounce = debounce(event => {
    const path = event.path || (event.composedPath && event.composedPath());
    let showPlayer = false;

    for (let key in path) {
      if (path[key].id === this.playerID || path[key].id === "closeButton") {
        showPlayer = true;
      }
    }

    if (!showPlayer) {
      this.setState({
        showControls: false
      });
    }
  }, CONTROLS_VISIBILITY_DURATION);

  _activateShowControls = () => {
    if (!this.state.showControls) {
      this.setState({
        showControls: true
      });
    }
  };

  _onPressEscape = () => {
    const { movie, isEpisode } = this.state;
    const {
      history,
      location: { pathname, state },
      setOpenPlayer,
      floatingPlayer: { playerSize }
    } = this.props;

    if (playerSize === "minimize" && this.cameFromUrl !== pathname) {
      return setOpenPlayer(false);
    } else if (get(state, "cameFrom")) {
      this.props.history.replace(get(state, "cameFrom"));
    } else if (isEpisode && movie && movie.multi_event_vod_id) {
      history.replace(`/tv-shows/${movie.multi_event_vod_id}`);
    } else {
      history.replace({
        pathname: this.cameFromUrl || "/",
        alreadyEnteredParentalPin: true
      });
    }

    setOpenPlayer(false);
  };

  _onPressArrowRight = progress => {
    this.RightArrowClickProps = {
      counter: this.RightArrowClickProps
        ? this.RightArrowClickProps.counter + 1
        : 1,
      time: new Date().getTime()
    };

    const seekTimer = setTimeout(() => {
      if (
        this.RightArrowClickProps &&
        new Date().getTime() - this.RightArrowClickProps.time >= 500
      ) {
        this.player.seekTo(progress + this.RightArrowClickProps.counter * 10);
        this.RightArrowClickProps = null;
      } else clearTimeout(seekTimer);
    }, 500);
  };

  _onPressArrowLeft = progress => {
    this.LeftArrowClickProps = {
      counter: this.LeftArrowClickProps
        ? this.LeftArrowClickProps.counter + 1
        : 1,
      time: new Date().getTime()
    };

    const seekTimer = setTimeout(() => {
      if (
        this.LeftArrowClickProps &&
        new Date().getTime() - this.LeftArrowClickProps.time >= 500
      ) {
        this.player.seekTo(
          progress - this.LeftArrowClickProps.counter * 10 >= 0
            ? progress - this.LeftArrowClickProps.counter * 10
            : 0
        );
        this.LeftArrowClickProps = null;
      } else clearTimeout(seekTimer);
    }, 500);
  };

  _onKeyboardControls = e => {
    const { progress } = this.state;

    switch (e.key) {
      case "Escape":
        this._onPressEscape();
        break;

      case "Space":
        this.setState(state => ({ playing: !state.playing }));
        break;

      case " ":
        this.setState(state => ({ playing: !state.playing }));
        e.preventDefault();
        break;

      case "ArrowRight":
        this._onPressArrowRight(progress);
        break;

      case "ArrowLeft":
        this._onPressArrowLeft(progress);
        break;
      default:
        break;
    }
  };

  _successTrailerCallback = data => {
    const shouldRequirePin = this.alreadyEnteredParentalPin
      ? false
      : get(data.items[0], "parental_rating.require_pin");

    if (
      data &&
      data.items &&
      data.items.length &&
      data.items[0] &&
      data.items[0].trailer_source &&
      data.items[0].trailer_source.url
    ) {
      if (data) {
        this.setState({
          movie: { ...data.items[0] },
          playing: !shouldRequirePin,
          trailerRequiresPin: shouldRequirePin
        });
      }
    } else {
      this._redirectToDetailsPage();
    }
  };

  _renderParentalModal = () => {
    const { trailerRequiresPin } = this.state;
    const { history, intl } = this.props;

    if (
      trailerRequiresPin &&
      !get(history, "location.state.alreadyEnteredParentalPin")
    ) {
      return (
        <InputPinModal
          open={trailerRequiresPin}
          intl={intl}
          type="parental"
          onSuccess={() => {
            this.setState({
              trailerRequiresPin: false,
              playing: true
            });
          }}
          hideCancelButton={true}
          onBack={this._redirectToDetailsPage}
          successButtonTitle={intl.formatMessage({
            id: "common.confirm"
          })}
        />
      );
    }
  };

  _redirectToDetailsPage = () => {
    const {
      history,
      match: {
        path,
        params: { seasonID, id, episodeNumber, showID }
      }
    } = this.props;
    const { movie } = this.state;

    if (path.includes("trailer")) {
      if (path.includes("tv-show")) {
        if (seasonID && episodeNumber) {
          history.replace(`/tv-shows/episode/${get(movie, "id")}`);
        } else if (showID) {
          history.replace(`/tv-shows/${showID}`);
        }
      } else if (path.includes("movie") && id) {
        history.replace(`/movies/${id}`);
      }
    }
  };

  ref = player => (this.player = player);

  _onDuration = duration => this.setState({ duration });

  _getURL = () => {
    const { movie } = this.state;
    if (get(movie, "trailer_source.url")) return movie.trailer_source.url;
  };

  _onClosePlayer = e => {
    const {
      history,
      location: { search, pathname },
      setOpenPlayer
    } = this.props;

    if (e) e.stopPropagation();

    if (search) {
      history.replace({
        pathname,
        state: {
          alreadyEnteredParentalPin: true
        }
      });
    }
    setOpenPlayer(false);
  };

  _onDoubleClickFullscreen = e => {
    if (
      e.target.classList.contains(style.wrapperDiv) ||
      e.target.classList.contains(style.reactPlayer) ||
      e.target.tagName === "VIDEO"
    )
      this.onClickFullscreen();
  };

  _renderRatingStars = () => {
    const { movie, openStars } = this.state;
    const { intl } = this.props;

    if (movie) {
      return (
        <RatingStars
          intl={intl}
          isOpen={openStars}
          vodType={movie.type >= 0 ? movie.type : 2}
          onClose={this._onCloseStarsModal}
          onRate={this.rateMovie}
        />
      );
    }
  };

  _renderMinimizeProgress = () => {
    const { progress, duration } = this.state;
    const {
      floatingPlayer: { playerSize }
    } = this.props;

    if (playerSize === "minimize") {
      return <MiniProgressBar progress={progress} duration={duration} />;
    }
  };

  _renderMinimizedControls = () => {
    const { playing, showLoader } = this.state;
    const {
      floatingPlayer: { playerSize }
    } = this.props;

    if (playerSize === "minimize") {
      return (
        <MinimizedControls
          isPlaying={playing}
          showPlayPauseButton={!showLoader}
          onToggleClick={this._onTogglePlayClick}
          onClose={this._onClosePlayer}
          onMaximize={this._onMaximize}
        />
      );
    }
  };

  _onMaximize = e => {
    const { setPlayerSize } = this.props;

    e.stopPropagation();
    setPlayerSize("maximize");
  };

  _onTogglePlayClick = e => {
    e.stopPropagation();
    this.setState(state => ({ playing: !state.playing }));
  };

  _onCloseStarsModal = () => {
    this.setState(prevState => ({
      openStars: false,
      playing: prevState.prevStatusPlaying
    }));
  };

  _onOpenRatingStars = () => {
    this.setState(prevState => ({
      prevStatusPlaying: prevState.playing,
      openStars: true,
      playing: false
    }));
  };

  _showControlsIcons = () => this.setState({ showControls: true });

  render() {
    const {
      intl,
      floatingPlayer: { playerSize }
    } = this.props;
    const {
      movie,
      showLoader,
      duration,
      playing,
      playbackRate,
      isFullscreen,
      muted,
      showControls,
      volume,
      showThumbs
    } = this.state;
    const url = this._getURL(movie);
    const isMinimized = playerSize === "minimize";

    return (
      <div
        className={`${style.wrapperDiv} visible`}
        onDoubleClick={this._onDoubleClickFullscreen}
      >
        {!isMinimized && (
          <PlayerCloseIcon
            showIcon={showControls}
            onClose={this._onClosePlayer}
          />
        )}
        {this._renderParentalModal()}

        <div onClick={this._showControlsIcons} style={{ height: "100%" }}>
          {movie && (
            <ReactPlayer
              className={style.reactPlayer}
              url={url}
              playing={playing}
              controls={false}
              volume={volume}
              muted={muted}
              ref={this.ref}
              playbackRate={playbackRate}
              onReady={this.onReady}
              onProgress={this.onProgress}
              onDuration={this._onDuration}
              onBuffer={this.onBuffer}
              onError={this.onError}
              onEnded={this._onClosePlayer}
              config={{
                file: {
                  attributes: {
                    poster:
                      this.isCensorshipActivated &&
                      get(movie, "censorship_allowed")
                        ? ""
                        : getCover(movie),
                    crossOrigin: "anonymous"
                  }
                }
              }}
            />
          )}
        </div>
        {showLoader && (
          <div className={`${style.loaderWrapper} theme-loader-wrapper`}>
            <Loader active={showLoader} inverted={true} />
          </div>
        )}
        {!isMinimized && (
          <PlayerControls
            id={this.playerID}
            intl={intl}
            title={get(movie, "title") || ""}
            vod={movie || {}}
            nextEpisodesInSeason={this.nextEpisodes}
            currentTime={(this.player && this.player.getCurrentTime()) || 0}
            duration={duration || 0}
            player={this.player}
            playStopPlayer={this.playStopPlayer}
            playing={playing}
            muted={muted}
            volume={volume}
            showControls={showControls}
            playbackRate={playbackRate}
            isFullscreen={isFullscreen}
            settingButtonID={this.settingButtonID}
            updatePlaybackRate={this.updatePlaybackRate}
            onClickFullscreen={this.onClickFullscreen}
            onMute={this.onMute}
            onVolumeChange={this.onVolumeChange}
            onThumbs={this.onThumbs}
            onOpenStars={this._onOpenRatingStars}
            showThumbs={showThumbs}
            onThumbsUp={this.rateMovie}
            onThumbsDown={this.rateMovie}
          />
        )}
        {this._renderRatingStars()}
        {this._renderMinimizeProgress()}
        {this._renderMinimizedControls()}
      </div>
    );
  }
}

const matchDispatchToProps = dispatch => ({
  fetchMovieStream: data => dispatch(defaultActions.fetchMovieStream(data)),
  getMvodList: data => dispatch(defaultActions.getMvodList(data)),
  rateVOD: data => dispatch(defaultActions.rateVOD(data)),
  setPlayerSize: data => dispatch(floatingPlayerActions.setPlayerSize(data)),
  setOpenPlayer: data => dispatch(floatingPlayerActions.setOpenPlayer(data))
});

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  vodProfile: state.vodProfile,
  socket: state.socket,
  cachedContinueWatchingList: state.cachedContinueWatchingList,
  cachedSingleTvShowsCarousels: state.cachedSingleTvShowsCarousels,
  floatingPlayer: state.floatingPlayer
});

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(injectIntl(TrailerPlayer))
);
