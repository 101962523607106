export const GET_COMPOSER_CONFIG = "GET_COMPOSER_CONFIG";
export const GET_COMPOSER_CONFIG_SUCCESS = "GET_COMPOSER_CONFIG_SUCCESS";

const composerConfig = (state = null, action) => {
  switch (action.type) {
    case GET_COMPOSER_CONFIG_SUCCESS:
      if (action.payload) return action.payload;
      return state;
    default:
      return state;
  }
};

export default composerConfig;

export const getComposerConfig = data => {
  return {
    type: GET_COMPOSER_CONFIG,
    payload: {
      url: data.url,
      successCallback: data.successCallback
    }
  };
};
