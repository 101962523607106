import React from "react";
import style from "./style.module.scss";

const SelectField = props => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={`${index}-${key}`} value={key}>
        {props.options[key]}
      </option>
    );
  };

  const elementClass = props.error ? style.selectFieldError : style.selectField;

  if (props && props.options) {
    return (
      <div>
        <select {...props.input} className={`${elementClass} themeInput`}>
          <option value="">{props.label}</option>
          {Object.keys(props.options).map(renderSelectOptions)}
        </select>
      </div>
    );
  }
  return <div />;
};

export default SelectField;
