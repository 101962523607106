import React, { Component, Fragment } from "react";
import Navbar from "components/Navbars/Navbar";
import MobileNavbar from "components/Navbars/MobileNavbar";
import { injectIntl } from "react-intl";
import style from "../style.module.scss";

class NavigationWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileNav: false
    };

    this.dimmerID = "mobile-dimmer";
    this.prism = "prism";
  }

  onShowMobileNav = () => {
    this.setState(state => ({
      showMobileNav: !state.showMobileNav
    }));
  };

  hideMobileNav = event => {
    event.stopPropagation();
    if (event.target.id === this.dimmerID) {
      this.setState({
        showMobileNav: false
      });
      document.querySelector("#app").style.overflow = "visible";
    }
  };

  onHideNav = () => {
    this.setState({ showMobileNav: false });
    document.querySelector("#app").style.overflow = "visible";
  };

  render() {
    const { showMobileNav } = this.state;
    const { intl } = this.props;

    return (
      <Fragment>
        <Navbar
          onShowMobileNav={this.onShowMobileNav}
          showMobileNav={showMobileNav}
          onHideNav={this.onHideNav}
          intl={intl}
        />
        <div
          id={this.dimmerID}
          className={style.mobileNav}
          onClick={this.hideMobileNav}
        >
          <MobileNavbar
            isHidden={showMobileNav}
            onHideNav={this.onHideNav}
            intl={intl}
          />
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(NavigationWrapper);
