import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const Button = props => {
  return (
    <button
      className={`${style.fullWidthButton} themeFullWidthButton`}
      {...props}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool
};

export default Button;
