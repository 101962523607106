import { get } from "lodash";

//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onActiveMovieSubtitle = function(data) {
  const internalPlayer =
    this.player.getInternalPlayer("hls") ||
    this.player.getInternalPlayer("dash") ||
    this.player.getInternalPlayer();

  if (internalPlayer) {
    this.setState(
      {
        activeMovieSubtitle: data.id,
        defaultMovieSubtitle: {
          id: data.id,
          locale: data.locale,
          url: data.url
        }
      },
      () => {
        if (data.id === "MovieOff") {
          turnOffSubtitle.call(this, data, internalPlayer);
        }
        // Case for Dash player
        if (internalPlayer.setTextTrack) {
          activateDashSubtitle.call(this, data, internalPlayer);
        }
        // Case for Hls player
        else {
          activateHlsSubtitle.call(this, data, internalPlayer);
        }
      }
    );
  }
};

const turnOffSubtitle = function(data, internalPlayer) {
  if (internalPlayer.setTextTrack) {
    internalPlayer.setTextTrack(-1);
  } else {
    internalPlayer.subtitleTrack = -1;
  }
  this.setState(state => ({
    externalMovieSubtitle: state.externalMovieSubtitle.map(item => {
      delete item.default;
      return item;
    })
  }));
};

const activateDashSubtitle = function(data, internalPlayer) {
  if (data.internal) {
    activateInternalDashSubtitle.call(this, data, internalPlayer);
  } else {
    activateExternalDashSubtitle.call(this, data, internalPlayer);
  }
};

const activateExternalDashSubtitle = function(data, internalPlayer) {
  if (!internalPlayer.subtitleDisplay) {
    internalPlayer.subtitleDisplay = true;
  }
  internalPlayer.subtitleTrack = -1;
  internalPlayer.setTextTrack(-1);

  setTimeout(() => {
    this.setState(
      state => ({
        externalMovieSubtitle: state.externalMovieSubtitle.map(item => {
          delete item.default;
          return item;
        })
      }),
      () => {
        this.setState(state => ({
          externalMovieSubtitle: state.externalMovieSubtitle.map(item => {
            delete item.default;
            if (item.id === data.id) item.default = true;
            return item;
          })
        }));
      }
    );
  }, 1000);
};

const activateInternalDashSubtitle = function(data, internalPlayer) {
  this.setState(state => ({
    externalMovieSubtitle: state.externalMovieSubtitle.map(item => {
      delete item.default;
      return item;
    })
  }));
  internalPlayer.setTextTrack(data.id);

  const video = internalPlayer.getVideoElement();
  const currentTrack = video.textTracks[data.id];

  if (get(currentTrack, "activeCues.length") > 1) {
    currentTrack.removeCue(currentTrack.activeCues[0]);
  }
};

const activateHlsSubtitle = function(data, internalPlayer) {
  if (data.internal) {
    activateInternalHlsSubtitle.call(this, data, internalPlayer);
  } else {
    activateExternalHlsSubtitle.call(this, data, internalPlayer);
  }
};

const activateInternalHlsSubtitle = function(data, internalPlayer) {
  if (!internalPlayer.subtitleDisplay) {
    internalPlayer.subtitleDisplay = true;
  }
  this.setState(
    state => ({
      externalMovieSubtitle: state.externalMovieSubtitle.map(item => {
        delete item.default;
        return item;
      })
    }),
    () => {
      internalPlayer.subtitleTrack = data.id;
    }
  );
};

const activateExternalHlsSubtitle = function(data, internalPlayer) {
  if (!internalPlayer.subtitleDisplay) {
    internalPlayer.subtitleDisplay = true;
  }
  internalPlayer.subtitleTrack = -1;

  setTimeout(() => {
    this.setState(
      state => ({
        externalMovieSubtitle: state.externalMovieSubtitle.map(item => {
          delete item.default;
          return item;
        })
      }),
      () => {
        this.setState(state => ({
          externalMovieSubtitle: state.externalMovieSubtitle.map(item => {
            delete item.default;
            if (item.id === data.id) item.default = true;
            return item;
          })
        }));
      }
    );
  }, 1000);
};

export default onActiveMovieSubtitle;
