const getRenamedArray = (arr, fieldName) => {
  const renamedArray = [];
  let seq = 1;
  for (let i = 0; i < arr.length; i++) {
    let name = arr[i][fieldName];

    if (i + 1 < arr.length && arr[i][fieldName] === arr[i + 1][fieldName]) {
      name = `${arr[i][fieldName]} - ${seq}`;
      seq += 1;
    } else if (seq > 1) {
      name = `${arr[i][fieldName]} - ${seq}`;
      seq = 1;
    } else {
      seq = 1;
    }
    const arrayItem = { ...arr[i] };
    arrayItem[fieldName] = name;
    renamedArray.push(arrayItem);
  }

  return renamedArray;
};

export default getRenamedArray;
