const generateQuery = query => {
  let queryResult = "",
    queryArray = [];

  for (let key in query) {
    if (query.hasOwnProperty(key)) {
      if (typeof query[key] === "object") {
        if (Array.isArray(query[key])) {
          queryArray = [
            ...queryArray,
            ...query[key].map(item => {
              return `${key}[${item.type}]=${item.value}`;
            })
          ];
        }
      } else {
        queryResult += `&${key}=${query[key]}`;
      }
    }
  }
  return "?" + queryArray.join("&") + queryResult;
};

export default generateQuery;
