import React, { useRef, memo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
import { isArray } from "rxjs/util/isArray";
import ConfirmRentModalView from "./components/ConfirmRentModalView";

const ConfirmRentModal = ({
  open,
  onClose,
  onSuccess,
  selectedRent,
  loggedUser,
  eventType
}) => {
  const buttonRef = useRef(null);

  if (!open) return null;

  const onMouseOver = () => {
    if (document.activeElement === buttonRef.current) {
      buttonRef.current.blur();
    }
  };

  if (selectedRent && get(loggedUser, "currency_code")) {
    const { name, id } = selectedRent;
    const price =
      (get(selectedRent, "price") || get(selectedRent, "pricing")) &&
      isArray(selectedRent.pricing) &&
      selectedRent.pricing[0].price;

    if (name && id && price >= 0) {
      return (
        <ConfirmRentModalView
          ref={buttonRef}
          open={open}
          price={get(selectedRent, "price")}
          selectedRent={selectedRent}
          loggedUser={loggedUser}
          eventType={eventType}
          onClose={onClose}
          onSuccess={onSuccess}
          onMouseOver={onMouseOver}
        />
      );
    }
    return null;
  }
  return null;
};

ConfirmRentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  eventType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  selectedRent: PropTypes.shape({
    price: PropTypes.number
  })
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
});

export default connect(mapStateToProps)(memo(ConfirmRentModal));
