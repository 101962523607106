import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledComponents from "styled-components";
import { Field, reduxForm } from "redux-form";
import InputField from "../../FormElements/InputField";
import FullWidthButton from "../../FormElements/FullWidthButton";
import { Form, Loader } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { registerFormCreditCardValidation } from "functions/formValidations";
import InfoIcon from "react-icons/lib/md/info-outline";
import Cleave from "cleave.js/react";
import paypalImgUrl from "./images/im_paypal.png";
import creditCardImgUrl from "./images/im_credit_card.png";
import config from "../../../config";
import { sendAnalyticsData } from "functions/logic-functions";
import style from "./style.module.scss";

class RegisterFormCreditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardFirstName: null,
      cardLastName: null,
      email: null,
      creditCardNumber: null,
      expDate: null,
      securityNumberCVV: null,
      paymentType: "credit-card"
    };
  }

  onSubmitForm = data => {
    const { updateFormValues, onSave } = this.props;
    const { paymentType } = this.state;

    if (
      data &&
      data.cardFirstName &&
      data.cardLastName &&
      data.creditCardNumber &&
      data.expDate &&
      data.securityNumberCVV &&
      paymentType &&
      data.creditCardNumber.length >= 14 &&
      isNaN(data.cardFirstName) &&
      isNaN(data.cardLastName) &&
      data.securityNumberCVV.length === 3
    ) {
      this.setState(
        {
          showLoader: true
        },
        () => {
          updateFormValues(data, onSave);
        }
      );
      sendAnalyticsData("register_success");
    }
  };

  handleRadioChange = (event, { value }) => {
    this.setState({
      paymentType: value
    });
  };

  maxLenght = max => value => {
    let v;
    let result = value.length > max;

    if (result === false) {
      v = value;
    }
    return v;
  };

  renderFormFields = ({
    input,
    label,
    type,
    meta: { error, submitFailed, submitSucceeded, pristine }
  }) => {
    this.setState({
      [input.name]: error || null
    });

    return (
      <div className={style.inputWrapper}>
        <InputField
          {...input}
          placeholder={label}
          type={type}
          error={(submitFailed || (submitSucceeded && !pristine)) && error}
        />
        {error && <p>{this.state[input.name]}</p>}
      </div>
    );
  };

  renderFormatedFields = ({
    input,
    label,
    meta: { error, submitFailed, submitSucceeded, pristine }
  }) => {
    this.setState({
      [input.name]: error || null
    });
    const elementClass =
      (submitFailed || (submitSucceeded && !pristine)) && error
        ? style.borderFieldError
        : style.borderField;

    return (
      <div className={style.inputWrapper}>
        <Cleave
          {...input}
          className={elementClass}
          placeholder={label}
          options={{ creditCard: true }}
        />
        {error && <p>{this.state[input.name]}</p>}
      </div>
    );
  };

  renderExpDateField = ({
    input,
    label,
    meta: { error, submitFailed, submitSucceeded, pristine }
  }) => {
    this.setState({
      [input.name]: error || null
    });
    const elementClass =
      (submitFailed || (submitSucceeded && !pristine)) && error
        ? style.borderFieldError
        : style.borderField;

    return (
      <div className={style.inputWrapper}>
        <Cleave
          {...input}
          className={elementClass}
          placeholder={label}
          options={{
            date: true,
            datePattern: ["m", "y"]
          }}
          error={(submitFailed || (submitSucceeded && !pristine)) && error}
        />
        {error && <p>{this.state[input.name]}</p>}
      </div>
    );
  };

  render() {
    const {
      handleSubmit,
      submitFailed,
      showLoader,
      errorMessage,
      invalid,
      intl
    } = this.props;
    const { paymentType } = this.state;

    return (
      <div>
        <div className={style.timelineBlock}>
          <div>
            <span />
            <p>{intl.formatMessage({ id: "account.aboutYou" })}</p>
          </div>
          <div>
            <span />
            <p>
              {intl.formatMessage({
                id: "account.yourSubscription"
              })}
            </p>
          </div>
          <div>
            <span />
            <p>{intl.formatMessage({ id: "account.creditCard" })}</p>
          </div>
        </div>
        {submitFailed && (
          <div className={style.errorMessage}>
            <InfoIcon />
            <p>
              <FormattedMessage id="account.thisFIeldsAreRequired" />
            </p>
          </div>
        )}
        {errorMessage && (
          <div className={style.errorMessage}>
            <InfoIcon />
            <p>{errorMessage}</p>
          </div>
        )}
        <form onSubmit={handleSubmit(this.onSubmitForm)}>
          {config && !config.hide_register_payment_methods && (
            <RadiosWrapper inline>
              <Form.Radio
                className={style.paymentImg}
                label={
                  <label>
                    <img src={creditCardImgUrl} alt={""} />
                  </label>
                }
                value="credit-card"
                checked={paymentType === "credit-card"}
                onChange={this.handleRadioChange}
              />
              <Form.Radio
                className={style.paymentImg}
                label={
                  <label>
                    <img src={paypalImgUrl} alt={""} />
                  </label>
                }
                value="paypal"
                checked={paymentType === "paypal"}
                onChange={this.handleRadioChange}
              />
            </RadiosWrapper>
          )}
          <HorizontalFieldsWrapper>
            <Field
              name="cardFirstName"
              component={this.renderFormFields}
              type="text"
              label={intl.formatMessage({
                id: "common.firstName"
              })}
            />
            <Field
              name="cardLastName"
              component={this.renderFormFields}
              type="text"
              label={intl.formatMessage({
                id: "common.lastName"
              })}
            />
          </HorizontalFieldsWrapper>
          <Field
            name="creditCardNumber"
            component={this.renderFormatedFields}
            type="text"
            label={intl.formatMessage({
              id: "account.creditCardNumber"
            })}
          />
          <Field
            name="expDate"
            component={this.renderExpDateField}
            type="text"
            label={intl.formatMessage({
              id: "account.expirationDate"
            })}
          />
          <Field
            name="securityNumberCVV"
            component={this.renderFormFields}
            type="text"
            label={intl.formatMessage({
              id: "account.securityNumber"
            })}
            normalize={this.maxLenght(3)}
          />
          <FullWidthButton type="submit" disabled={invalid}>
            {(!showLoader && (
              <p>
                <FormattedMessage id="common.continue" />
              </p>
            )) || (
              <Loader className={"themeLoader"} active={true} inverted={true} />
            )}
          </FullWidthButton>
        </form>
      </div>
    );
  }
}

const RadiosWrapper = StyledComponents(Form.Group)`
	display:flex;
	margin-bottom: 25px;
	& > div:last-child{
		margin-left: 20px;
	}
`;

const HorizontalFieldsWrapper = StyledComponents.div`
	display: flex;
	&> div{
		width: 50%;
		&:first-child{
			padding-right: 5px;
		}
		&:last-child{
			padding-left: 5px;
		}
	}
	&:last-child{
		display: none !important;
		input {
			margin-bottom: 0px !important;
		}
	}
	@media (max-width: 648px){
		flex-direction: column;
		& > div{
			padding: 0 !important;
			width: 100%;
		}
	}
`;

RegisterFormCreditCard.propTypes = {
  showLoader: PropTypes.bool,
  changeFormStep: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errorMessage: PropTypes.any
};

export default reduxForm({
  form: "credit-card-form",
  validate: registerFormCreditCardValidation
})(RegisterFormCreditCard);
