import { find, get } from "lodash";
import { openNotificationWithIcon } from "functions/logic-functions";

//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const openPlayModal = function(id) {
  const { loggedUser, cachedContinueWatchingList, intl } = this.props;

  if (
    loggedUser &&
    loggedUser.account &&
    !loggedUser.account.subscription_expired
  ) {
    this.setState(
      {
        VOD: find(cachedContinueWatchingList, o => o.id === id) || null
      },
      () => {
        const { VOD } = this.state;

        if (VOD) {
          const subscribedProfiles =
            get(VOD, "movie_source.profiles") &&
            VOD.movie_source.profiles.filter(item => item.subscribed);

          if (
            subscribedProfiles &&
            subscribedProfiles.length &&
            get(VOD, "parental_rating.require_pin")
          ) {
            this.setState({
              openParentalModal: true
            });
          } else if (subscribedProfiles && subscribedProfiles.length) {
            this.setState({
              openPlayModal: true
            });
          }
        }
      }
    );
  } else {
    openNotificationWithIcon({
      type: "warning",
      message: intl.formatMessage({
        id: "notifications.subscriptionHasExpired"
      })
    });
  }
};

export default openPlayModal;
