import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import LightBox from "components/LightBox";
import Arrow from "../Carousels/components/Arrow";
import style from "./style.module.scss";

class ImageGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: null
    };
  }

  onImageChange = index => {
    const { gallery } = this.props;
    const nextImage = gallery[index + 1];
    const prevImage = gallery[index - 1];

    if (nextImage) {
      const downloadingImageNext = new Image();
      const src =
        nextImage.preview_large_ar ||
        nextImage.preview_medium_ar ||
        nextImage.preview_ar;

      downloadingImageNext.src = src;
    }
    if (prevImage) {
      const downloadingImagePrev = new Image();
      const src =
        prevImage.preview_large_ar ||
        prevImage.preview_medium_ar ||
        prevImage.preview_ar;

      downloadingImagePrev.src = src;
    }

    this.setState({
      selected: gallery
        .filter((item, imageIndex) => index === imageIndex)
        .map(item => {
          item.index = index;
          return item;
        })[0]
    });
  };

  onCloseLightBox = () => this.setState({ selected: null });

  renderImages = images => {
    return images.map(({ small_ar, medium_ar }, index) => (
      <div
        key={index}
        className={style.imageWrapper}
        onClick={e => {
          if (!this.dragging) {
            e.preventDefault();
            this.onImageChange(index);
          }
        }}
      >
        <img
          src={small_ar || medium_ar}
          className={style.carouselImg}
          alt={""}
        />
      </div>
    ));
  };

  render() {
    const { selected } = this.state;
    const { gallery } = this.props;
    const settings = {
      dots: false,
      infinite: gallery.length >= 4,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      arrows: true,
      lazyLoad: true,
      nextArrow: <Arrow icon={"right"} />,
      prevArrow: <Arrow icon={"left"} />,
      beforeChange: () => (this.dragging = true),
      afterChange: () => (this.dragging = false),
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            arrows: false,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0,
            arrows: false,
            dots: true
          }
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false,
            dots: true
          }
        }
      ]
    };

    return (
      <Fragment>
        <div className={`${style.carouselWrapper} galleryDotsAndArrowsColor`}>
          <Slider {...settings}>{this.renderImages(gallery)}</Slider>
        </div>
        {selected && (
          <LightBox
            length={gallery.length}
            selectedIndex={selected.index}
            imgUrl={
              selected.preview_large_ar ||
              selected.preview_medium_ar ||
              selected.preview_ar
            }
            onCloseLightBox={this.onCloseLightBox}
            onImageChange={this.onImageChange}
          />
        )}
      </Fragment>
    );
  }
}

ImageGallery.propTypes = {
  gallery: PropTypes.array
};

export default ImageGallery;
