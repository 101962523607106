import React, { Suspense, memo } from "react";

const SuspenseHoC = Component =>
  memo(props => (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  ));

export default SuspenseHoC;
