import styledComponents from "styled-components";
import { Modal } from "semantic-ui-react";

export const ModalStyled = styledComponents(Modal)`
	html[dir='rtl'] & {
		direction: ltr;
	}
	background-color: transparent !important;
	border-radius: 13px !important;
    overflow: hidden;
    animation: startAnimation 150ms ease forwards;
    
    @keyframes startAnimation {
    	0% { transform: scale(0.9); opacity: 0.7; }
    	35% { transform: scale(1.1) }
    	75% { transform: scale(0.95) }
    	100% { transform: scale(1); opacity: 1; }
    }
`;

export const ModalWrapper = styledComponents("div")`
	background-color: rgba(var(--theme_background_modal), 0.95) !important;
	display: flex !important;
	flex-direction: column;
	align-items: center;
	padding: 50px !important;
	
	& > svg{
		font-size: 36px;
		color: var(--theme_text_color);
	}
	
	& > .buttonsWrapper{
		display: flex;
		width: 100%;
		margin: 50px 0;
		justify-content: center;
		
		& > div {
			margin-right: 10px;
			width: 141px;
			
			&.active {  
				& > div {
				  cursor: default;
					background-color: #c1c1c1;
					border: 2px solid transparent;
				}
			}
			
			&:last-child{
					margin-right: 0px;
				}
		}
	}
`;
