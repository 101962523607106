import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import SearchResultCardList from "../SearchResultCardList";
import { debounce } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as textSearchVods from "reduxStore/reducers/textSearchVODS";
import { addSearchText } from "reduxStore/reducers/searchText";
import { uniqueCollections } from "functions/logic-functions";
import SearchTextComponentView from "./components/SearchTextComponentView";
import {
  SINGLE_VOD,
  GET_MVOD_LIST_URL,
  GET_CHANNELS_WITH_PAGER
} from "API-routes";

class SearchTextComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: true,
      showLoader: false,
      isChannelCategoriesPage: this.isChannelCategoriesPage(
        props.location.pathname
      )
    };
    this.filteringPanelID = "filteringPanelID";
    this.timeOut = null;
    this.loaderTimeOut = null;
    this._isMounted = false;
    this.input = createRef();
  }

  componentDidMount() {
    this.timeOut = setTimeout(() => {
      this.input.current.style.visibility = "visible";
      this.input.current.focus();
    }, 200);
    document.addEventListener("click", this.setDropdownClickEvent);
    this._isMounted = true;
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.setDropdownClickEvent);
    this._debounceVodDispatch.cancel();
    clearTimeout(this.timeOut);
    clearTimeout(this.loaderTimeOut);
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    const {
      textSearchVODS,
      match: { path: nextPath },
      location: { pathname: nextPathname }
    } = nextProps;
    const {
      textSearchVODS: oldTextSearchVODS,
      match: { path }
    } = this.props;

    if (this.isChannelCategoriesPage(nextPathname)) {
      this.setState({ isChannelCategoriesPage: true });
    } else this.setState({ isChannelCategoriesPage: false });

    if (
      uniqueCollections(oldTextSearchVODS.all, textSearchVODS.all) ||
      oldTextSearchVODS.all.length !== textSearchVODS.all.length
    ) {
      this.setState({
        showLoader: false
      });
    } else if (oldTextSearchVODS.all.length === textSearchVODS.all.length) {
      this.loaderTimeOut = setTimeout(
        () => this._isMounted && this.setState({ showLoader: false }),
        4000
      );
    }

    if (path !== nextPath) {
      this.setState({
        showDropdown: false
      });
    }
  }

  setDropdownClickEvent = event => {
    const path = event.path || (event.composedPath && event.composedPath());
    let showFilter = false;

    for (let key in path) {
      if (
        path[key].id === this.filteringPanelID ||
        path[key].id === "filteringInput"
      ) {
        showFilter = true;
      }
    }
    if (!showFilter) {
      this.setState({ showDropdown: false });
    }
  };

  isChannelCategoriesPage = pathname =>
    pathname.includes("/channel-categories");

  onFocus = e => {
    const { showDropdown } = this.state;
    const temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;

    if (!showDropdown) this.setState({ showDropdown: true });
  };

  onChange = event => {
    const { addSearchTextDispatch } = this.props;
    const value = event.target.value;

    this.setState(
      {
        showDropdown: true,
        showLoader: value.length >= 2
      },
      () => {
        addSearchTextDispatch({
          value
        });
        this._debounceVodDispatch();
      }
    );
  };

  _debounceVodDispatch = debounce(() => {
    const { isChannelCategoriesPage } = this.state;
    const {
      searchText,
      getTextSearchFilterVods,
      composerConfig: {
        web: {
          modules: { vod, channels }
        }
      }
    } = this.props;

    if (searchText && searchText.length >= 2) {
      const urlsArray = [];

      if (!isChannelCategoriesPage && vod) {
        urlsArray.push(
          `${SINGLE_VOD}?filter[title]=${searchText}&filter[type][all]=true`,
          `${GET_MVOD_LIST_URL}?filter[title]=${searchText}`
        );
      }

      if (channels) {
        urlsArray.push(
          `${GET_CHANNELS_WITH_PAGER}?filter[title]=${searchText}`
        );
      }

      getTextSearchFilterVods({
        urls: urlsArray
      });
    }
  }, 500);

  onClearText = () => {
    const { addSearchTextDispatch, resetTextFilterSearchResults } = this.props;

    addSearchTextDispatch({
      value: ""
    });
    resetTextFilterSearchResults();
  };

  redirectToSearchPage = () => {
    const { onHideNav } = this.props;

    this.setState(
      {
        showDropdown: false
      },
      () => {
        onHideNav();
        this.props.history.push("/search");
      }
    );
  };

  hideDropdown = () => this.setState({ showDropdown: false });

  _renderResultsDropdown = () => {
    const { showDropdown } = this.state;
    const {
      location: { pathname },
      textSearchVODS,
      searchText,
      onHideNav
    } = this.props;

    if (
      !pathname.startsWith("/search") &&
      showDropdown &&
      textSearchVODS &&
      textSearchVODS.all &&
      textSearchVODS.all.length > 0
    ) {
      return (
        <SearchResultCardList
          filteringPanelID={this.filteringPanelID}
          hasValue={!!searchText}
          hideDropdown={this.hideDropdown}
          redirectToSearchPage={this.redirectToSearchPage}
          onHideNav={onHideNav}
          showDropdownOverride={true}
        />
      );
    }
  };

  render() {
    const { showLoader, showDropdown, isChannelCategoriesPage } = this.state;
    const {
      intl,
      location,
      composerConfig,
      textSearchVODS,
      searchText,
      onHideNav
    } = this.props;

    return (
      <SearchTextComponentView
        intl={intl}
        ref={this.input}
        isChannelCategoriesPage={isChannelCategoriesPage}
        composerConfig={composerConfig}
        filteringPanelID={this.filteringPanelID}
        searchText={searchText}
        textSearchVODS={textSearchVODS}
        location={location}
        showLoader={showLoader}
        showDropdown={showDropdown}
        onHideNav={onHideNav}
        hideDropdown={this.hideDropdown}
        redirectToSearchPage={this.redirectToSearchPage}
        onClearText={this.onClearText}
        onFocus={this.onFocus}
        onChange={this.onChange}
      />
    );
  }
}

SearchTextComponent.propTypes = {
  intl: PropTypes.object.isRequired,
  onHideNav: PropTypes.func
};

const matchDispatchToProps = dispatch => ({
  getTextSearchFilterVods: data =>
    dispatch(textSearchVods.getTextSearchFilterVods(data)),
  addSearchTextDispatch: data => dispatch(addSearchText(data)),
  resetTextFilterSearchResults: () =>
    dispatch(textSearchVods.resetTextFilterSearchResults())
});

const mapStateToProps = state => ({
  textSearchVODS: state.textSearchVODS,
  searchText: state.searchText,
  composerConfig: state.composerConfig
});

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(SearchTextComponent)
);
