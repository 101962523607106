import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Slider from "react-slick";
import RoundCard from "components/Cards/RoundCard";
import { getMoviesNewReleases } from "reduxStore/actions";
import { areImagesTheSame, isCensorshipCard } from "functions/logic-functions";
import { CarouselWrapper } from "./styledComponents";
import Arrow from "../components/Arrow";
import style from "./style.module.scss";

class DynamicDataCarousel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false
    };
  }

  renderList = () => {
    const {
      items,
      openPlayModal,
      urlRoute: mainUrlRoute,
      showPlayIcon = true
    } = this.props;

    return items.map(item => {
      let urlRoute = "";

      switch (item.type) {
        case 0:
          urlRoute = `/play/movie/${item.id}`;
          break;
        case 1:
          urlRoute = `/play/tv-show/episode/${item.id}`;
          break;
        case 3:
          urlRoute = `/channels/${item.id}`;
          break;
        default:
          break;
      }

      return (
        <div key={item.id}>
          <RoundCard
            id={item.id}
            item={item}
            imgUrl={
              isCensorshipCard(item)
                ? item.logo_url
                : item.screenshot_url ||
                  (item.cover && item.cover.medium_ar) ||
                  (item.covers && item.covers[0].medium_ar)
            }
            urlRoute={
              (mainUrlRoute &&
                item.channel_number &&
                `${mainUrlRoute}/${item.id}`) ||
              urlRoute
            }
            title={item.title}
            showPlayIcon={showPlayIcon}
            openPlayModal={openPlayModal}
            roundedStyle={true}
            continue_watching={item.continue_watching}
            useObjectContainPropertyImage={areImagesTheSame(
              item.logo_url,
              item.screenshot_url
            )}
          />
        </div>
      );
    });
  };

  render() {
    const { title, useInfiniteScroll, onAfterChange } = this.props;
    const settings = {
      infinite: useInfiniteScroll || false,
      speed: 500,
      // lazyLoad: true,
      slidesToShow: 7,
      slidesToScroll: 7,
      initialSlide: 0,
      arrows: true,
      afterChange: newIndex => {
        if (onAfterChange) onAfterChange(newIndex);
      },
      nextArrow: (
        <Arrow icon="right" hasInfiniteScroll={useInfiniteScroll || false} />
      ),
      prevArrow: (
        <Arrow icon="left" hasInfiniteScroll={useInfiniteScroll || false} />
      ),
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1399,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            initialSlide: 0
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0
          }
        },
        {
          breakpoint: 479,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0
          }
        }
      ]
    };

    return (
      <div className={style.container}>
        <CarouselWrapper>
          <h2>{title}</h2>
          <Slider {...settings}>{this.renderList()}</Slider>
        </CarouselWrapper>
      </div>
    );
  }
}

DynamicDataCarousel.propTypes = {
  title: propTypes.string.isRequired,
  urlRoute: propTypes.string,
  items: propTypes.array,
  showPlayIcon: propTypes.bool,
  useInfiniteScroll: propTypes.bool,
  openPlayModal: propTypes.func,
  onAfterChange: propTypes.func
};

const matchDispatchToProps = dispatch => ({
  getMoviesNewReleasesDispatch: data => dispatch(getMoviesNewReleases(data))
});

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
});

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(DynamicDataCarousel)
);
