/*eslint-disable */
import React, { PureComponent } from "react";
/*eslint-enable */
import { get } from "lodash";
import { connect } from "react-redux";
import socketIO from "socket.io-client";
import { BASE_URL } from "../../API-routes";
import * as socketActions from "reduxStore/reducers/socket";
import { logout } from "functions/http-functions";

class SocketHOC extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      socket: null
    };
  }

  componentDidMount() {
    const { composerConfig } = this.props;
    const { socket } = this.state;

    if (composerConfig && !socket) {
      this._setSocket();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { composerConfig } = nextProps;
    const { socket } = this.state;

    if (composerConfig && !socket) {
      this._setSocket();
    }
  }

  _setSocket = () => {
    const { composerConfig, addSocket } = this.props;
    const token =
      localStorage.getItem("wecastUser") &&
      JSON.parse(localStorage.getItem("wecastUser")).token;

    if (get(composerConfig, "socket_url") && token) {
      const obj = {};

      if (BASE_URL === "https://api-mt.wecast.tv") {
        obj.Tenant = "general";
      }

      const socket = socketIO(get(composerConfig, "socket_url"), {
        query: `token=${token}`,
        ...obj
      });

      this._setSocketEvents(socket);

      this.setState(
        {
          socket
        },
        () => {
          addSocket({ socket });
        }
      );
    }
  };

  _setSocketEvents = socket => {
    socket.on("force-logout", () => {
      if (!localStorage.getItem("wecastRelogin")) {
        logout();
      }
    });
  };

  render() {
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  composerConfig: state.composerConfig,
  token: state.loggedUser
});

const mapDispatchToProps = dispatch => ({
  addSocket: data => dispatch(socketActions.addSocket(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SocketHOC);
