import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter, matchPath } from "react-router";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { Loader } from "semantic-ui-react";
import { find, get, findIndex, debounce } from "lodash";
import PlayerControls from "components/PlayerControls";
import RatingStars from "components/RatingStars";
import MiniProgressBar from "../components/MiniProgressBar";
import MinimizedControls from "../components/MinimizedControls";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/fromEvent";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinct";
import StartOverResumeModal from "components/Modals/StartOverResumeModal";
import SettingsPannel from "components/SettingsPannel";
import AdvertisementPlayer from "components/Players/AdvertisementPlayer";
import InputPinModal from "components/Modals/InputPinModal";
import RentOrPlayProfilesModal from "components/Modals/RentOrPlayProfilesModal";
import ParentalRatingInfoComponent from "components/Players/components/ParentalRatingInfoComponent";
import PlayerCloseIcon from "components/PlayerCloseIcon";
import * as defaultActions from "reduxStore/actions";
import * as playerMethods from "../commonPlayerFunctions";
import * as floatingPlayerActions from "reduxStore/reducers/floatingPlayer";
import { getSeasonEpisodes } from "reduxStore/reducers/cachedSingleTvShowsCarousels";
import { getContinueWatchingVods } from "reduxStore/reducers/cachedContinueWatchingList";
import { GET_ACTIVE_CAMPAIGN, SINGLE_VOD, STREAM_VOD } from "API-routes";
import CountryLanguage from "country-language";
import { injectIntl } from "react-intl";
import config from "../../../config";
import { sendAnalyticsData } from "functions/logic-functions";
import style from "./style.module.scss";

const {
  getInternalAudioSubtitles,
  getInternalMovieSubtitles,
  getExternalSubtitles,
  getCover,
  getURL,
  onReady,
  onResume,
  onMute,
  onSettingsPannel,
  onThumbs,
  onProgress,
  onDuration,
  onBuffer,
  onEnded,
  emitContinueWatchingEvent,
  onError,
  playStopPlayer,
  onVolumeChange,
  updatePlaybackRate,
  onActiveAudioSubtitle,
  onActiveMovieSubtitle,
  rateMovie,
  openPlayModal,
  onClickFullscreen,
  exitFullScreen,
  onCloseVideoCampaign,
  setDrmConfigParams
} = playerMethods;

const CONTROLS_VISIBILITY_DURATION = 4000;
const PRE_ROLL = 1;

class EpisodePlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      episode: null,
      subscribedEpisodes: [],
      showLoader: true,
      progress: 0,
      duration: 0,
      volume: 0.8,
      playing: false,
      playbackRate: 1,
      isFullscreen: false,
      muted: false,
      showControls: true,
      showCloseIcon: true,
      showSettingsPanel: false,
      showThumbs: false,
      externalMovieSubtitle: [],
      defaultMovieSubtitle: {
        id: null,
        locale: null,
        url: null
      },
      defaultAudioSubtitle: {
        id: null,
        locale: null,
        url: null
      },
      activeMovieSubtitle: null,
      activeAudioSubtitle: 0,
      internalAudioTracks: [],
      internalSubtitles: [],
      nextEpisodeData: null,
      openPlayModal: false,
      openStartResumeModal: false,
      displayParentalLockScreen: false,
      openParentalModal: false,
      fromNextEpisode: "",
      prevStatusPlaying: false,
      subscribedProfiles: null,
      openStars: false,
      alreadyEnteredParentalPin: false,

      openVideoCampaign: false,
      activeVideoCampaign: null,
      watchedVideoCampaigns: {}
    };
    this.animation = "fade left";
    this.fadeDuration = 500;
    this.playerID = "controlsWrapper";
    this.settingPanelID = "settingPanelID";
    this.outerSettingPanelClick = Observable.fromEvent(
      document,
      "click"
    ).distinct();
    this.settingButtonID = "settingBtnID";
    this.continueWatchVOD = null;
    this.hasNextEpisode = null;

    this.setDrmConfigParams = setDrmConfigParams.bind(this);
    this.onEnded = onEnded.bind(this);
    this.onCloseVideoCampaign = onCloseVideoCampaign.bind(this);
    this.exitFullScreen = exitFullScreen.bind(this);
    this.onClickFullscreen = onClickFullscreen.bind(this);
    this.emitContinueWatchingEvent = emitContinueWatchingEvent.bind(this);
    this.rateMovie = rateMovie.bind(this);
    this.onProgress = onProgress.bind(this);
    this.onBuffer = onBuffer.bind(this);
    this.onError = onError.bind(this);
    this.onReady = onReady.bind(this);
    this.onResume = onResume.bind(this);
    this.onMute = onMute.bind(this);
    this.onVolumeChange = onVolumeChange.bind(this);
    this.openPlayModal = openPlayModal.bind(this);
    this.getInternalAudioSubtitles = getInternalAudioSubtitles.bind(this);
    this.playStopPlayer = playStopPlayer.bind(this);
    this.onActiveAudioSubtitle = onActiveAudioSubtitle.bind(this);
    this.updatePlaybackRate = updatePlaybackRate.bind(this);
    this.onSettingsPannel = onSettingsPannel.bind(this);
    this.getExternalSubtitles = getExternalSubtitles.bind(this);
    this.getInternalMovieSubtitles = getInternalMovieSubtitles.bind(this);
    this.onThumbs = onThumbs.bind(this);
    this.onDuration = onDuration.bind(this);
    this.onActiveMovieSubtitle = onActiveMovieSubtitle.bind(this);
    this.isCensorshipActivated =
      localStorage.getItem("censorship-allowed") &&
      JSON.parse(localStorage.getItem("censorship-allowed"));
    this._isMounted = false;
  }

  componentDidMount() {
    const {
      location: { pathname, state },
      setOpenPlayer
    } = this.props;
    const selectedProfile = get(state, "selectedProfile");
    const id = get(state, "item.id");

    if (!selectedProfile || !id) {
      setOpenPlayer(false);
      this.props.history.replace(pathname);
    } else {
      const { cachedContinueWatchingList } = this.props;

      this._isMounted = true;
      this._setIfIsInContinueWatching(cachedContinueWatchingList, id);
      this._setSubscribedEpisodes();
      this._getVideoCampaigns();
      this._setEventListeners();
      this._setRxSubscribers();
      this._fetchStream({ id });
      sendAnalyticsData("play_single_episode");
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      cachedContinueWatchingList,
      location: { search, state }
    } = this.props;
    const {
      cachedContinueWatchingList: nextCachedContinueWatchingList,
      floatingPlayer: { selectedPlayer, playerSize },
      location: { search: nextSearch, pathname, state: nextState }
    } = nextProps;
    const selectedProfile = get(nextState, "selectedProfile");

    this._closePlayerOnGoBack(playerSize, nextSearch, search);

    if (!search && pathname !== selectedPlayer) return;

    const nextId = get(nextState, "item.id");
    const id = get(state, "item.id");

    if (parseInt(id) !== parseInt(nextId)) {
      this.hasNextEpisode = this._hasNextEpisode(null, nextProps);
      this._setSubscribedEpisodes(nextProps);

      this.setState(
        {
          activeMovieSubtitle: null,
          activeAudioSubtitle: null,
          playing: false,
          playbackRate: 1,
          openVideoCampaign: false,
          activeVideoCampaign: null,
          watchedVideoCampaigns: {}
        },
        () => {
          this._fetchStream({
            id: nextId,
            videoProfile: selectedProfile
          });

          this.emitContinueWatchingEvent();
          this._getVideoCampaigns(nextId);
        }
      );

      if (
        nextCachedContinueWatchingList.length !==
        cachedContinueWatchingList.length
      ) {
        this._setIfIsInContinueWatching(nextCachedContinueWatchingList, nextId);
      }
    }
  }

  componentDidUpdate() {
    const {
      showLoader,
      episode,
      playing,
      autoplayAlreadySet = false
    } = this.state;
    const { continuePlayWithoutResumeModal } = this.props.location.state || {};

    this._setSubscribedEpisodes(this.props);

    if (
      continuePlayWithoutResumeModal &&
      autoplayAlreadySet !== true &&
      !showLoader &&
      !playing &&
      episode
    ) {
      this.setState(
        {
          autoplayAlreadySet: true
        },
        this.onResume
      );
    }
  }

  componentWillUnmount() {
    const { progress } = this.state;
    const { socket } = this.props;

    this._clearTimers();
    this._removeEventListeners();
    this._unsubscribeRxEvents();
    this._destroyPolyNetReference();
    this._destroyReactPlayers();
    this._isMounted = false;
    this._activateDebounce.cancel();
    this.exitFullScreen();

    if (progress && progress >= 10 && socket) {
      this.emitContinueWatchingEvent();
    }

    document.querySelector("body").style.height = "100%";
  }

  _closePlayerOnGoBack = (playerSize, nextSearch, search) => {
    const { setOpenPlayer } = this.props;

    if (playerSize === "maximize" && nextSearch !== search) {
      setOpenPlayer(false);
    }
  };

  _destroyReactPlayers = () => {
    if (this.player) {
      if (this.player.getInternalPlayer("dash")) {
        this.player.getInternalPlayer("dash").reset();
      }
      if (this.player.getInternalPlayer("hls")) {
        this.player.getInternalPlayer("hls").destroy();
      }
    }
    ReactPlayer.removeCustomPlayers();
  };

  _unsubscribeRxEvents = () => {
    if (this.settingPanelClickHide) this.settingPanelClickHide.unsubscribe();
  };

  _clearTimers = () => {
    clearTimeout(this.playerInitalTimer);
  };

  _setIfIsInContinueWatching = (continueWatchingList, id) => {
    const isInContinueWatching = find(
      continueWatchingList,
      item => item.id === parseInt(id)
    );

    this.continueWatchVOD = isInContinueWatching ? isInContinueWatching : null;
  };

  _getVideoCampaigns = nextId => {
    let {
      getVideoCampaign,
      location: { state }
    } = this.props;
    const id = nextId || get(state, "item.id");

    if (id) {
      getVideoCampaign({
        url: `${GET_ACTIVE_CAMPAIGN}?filter[vod_id]=${id}`,
        successCallback: this._videoCampaignSuccessCallback
      });
    }
  };

  _videoCampaignSuccessCallback = response => {
    if (response) {
      this.setState({
        activeVideoCampaign: response
      });
    }
  };

  _fetchStream = ({ id, videoProfile, url }) => {
    const {
      fetchMovieStream,
      location: { state }
    } = this.props;
    const selectedProfile = videoProfile || get(state, "selectedProfile");

    if (id) {
      fetchMovieStream({
        url: STREAM_VOD,
        body: {
          single_event_vod_id: id,
          vod_profile_id: !videoProfile
            ? get(selectedProfile, "id")
            : get(videoProfile, "id")
        },
        successCallback: data => {
          this._successCallback(data, url);
        }
      });
    }
  };

  _removeEventListeners = () => {
    document.removeEventListener("keyup", this._onKeyboardControls);
    document.removeEventListener("mousemove", this._activateDebounce);
    document.removeEventListener("mousemove", this._activateShowControls);
  };

  _setEventListeners = () => {
    document.addEventListener("keyup", this._onKeyboardControls);
    document.addEventListener("mousemove", this._activateDebounce);
    document.addEventListener("mousemove", this._activateShowControls);
  };

  _activateDebounce = debounce(event => {
    const path = event.path || (event.composedPath && event.composedPath());
    let showPlayer = false;

    for (let key in path) {
      if (path[key].id === this.playerID || path[key].id === "closeButton") {
        showPlayer = true;
      }
    }

    if (!showPlayer && this._isMounted) {
      this.setState({
        showControls: false
      });
    }
  }, CONTROLS_VISIBILITY_DURATION);

  _activateShowControls = () => {
    if (!this.state.showControls) {
      this.setState({
        showControls: true
      });
    }
  };

  _setRxSubscribers = () => {
    this.settingPanelClickHide = this.outerSettingPanelClick.subscribe(
      event => {
        let display = false;
        const path = event.path || (event.composedPath && event.composedPath());

        for (let key in path) {
          if (
            path[key].id === this.settingPanelID ||
            path[key].id === this.settingButtonID
          )
            display = true;
        }
        if (!display) {
          this.setState({
            showSettingsPanel: false
          });
        }
      }
    );
  };

  _onDisplayParentalLockScreen = () => {
    this.setState({
      displayParentalLockScreen: true
    });
  };

  _onDisappearParentalLockScreen = episodeId => {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    if (id !== parseInt(episodeId)) {
      this.setState({
        displayParentalLockScreen: false
      });
    }
  };

  _onEscape = () => {
    const { episode } = this.state;
    const {
      history,
      location: { state }
    } = this.props;

    if (get(state, "cameFrom")) {
      return this.props.history.push(get(state, "cameFrom"));
    } else if (episode && episode.multi_event_vod_id) {
      return history.push({
        pathname: `/tv-shows/${episode.multi_event_vod_id}`,
        state: {
          alreadyEnteredParentalPin: !!get(state, "alreadyEnteredParentalPin")
        }
      });
    } else return history.goBack();
  };

  _onArrowRight = () => {
    const { progress } = this.state;

    this.RightArrowClickProps = {
      counter: this.RightArrowClickProps
        ? this.RightArrowClickProps.counter + 1
        : 1,
      time: new Date().getTime()
    };

    const seekTimer = setTimeout(() => {
      if (
        this.RightArrowClickProps &&
        new Date().getTime() - this.RightArrowClickProps.time >= 500
      ) {
        this.player.seekTo(progress + this.RightArrowClickProps.counter * 10);
        this.RightArrowClickProps = null;
      } else clearTimeout(seekTimer);
    }, 500);
  };

  _onArrowLeft = () => {
    const { progress } = this.state;

    this.LeftArrowClickProps = {
      counter: this.LeftArrowClickProps
        ? this.LeftArrowClickProps.counter + 1
        : 1,
      time: new Date().getTime()
    };

    const seekTimer = setTimeout(() => {
      if (
        this.LeftArrowClickProps &&
        new Date().getTime() - this.LeftArrowClickProps.time >= 500
      ) {
        this.player.seekTo(
          progress - this.LeftArrowClickProps.counter * 10 >= 0
            ? progress - this.LeftArrowClickProps.counter * 10
            : 0
        );
        this.LeftArrowClickProps = null;
      } else clearTimeout(seekTimer);
    }, 500);
  };

  _onKeyboardControls = e => {
    const { displayParentalLockScreen, openParentalModal } = this.state;

    if (!displayParentalLockScreen && !openParentalModal) {
      switch (e.key) {
        case "Escape":
          this._onEscape();
          break;
        case "Space":
          this.setState(state => ({ playing: !state.playing }));
          break;
        case " ":
          this.setState(state => ({ playing: !state.playing }));
          e.preventDefault();
          break;
        case "ArrowRight":
          this._onArrowRight();
          break;
        case "ArrowLeft":
          this._onArrowLeft();
          break;
        default:
          break;
      }
    }
  };

  _successCallback = (data, url) => {
    const {
      location: { state }
    } = this.props;
    const { displayParentalLockScreen, alreadyEnteredParentalPin } = this.state;
    const id = get(state, "item.id");

    if (data) {
      this.setState(
        {
          episode: null,
          progress: 0
        },
        () => {
          this.setState(
            {
              episode: { ...data }
            },
            () => {
              this.hasNextEpisode = this._hasNextEpisode(this.state.episode);
            }
          );

          if (
            !alreadyEnteredParentalPin &&
            get(data, "parental_rating.require_pin") &&
            !get(state, "alreadyEnteredParentalPin")
          ) {
            this._openInitallyParentalPin(data);
          } else if (displayParentalLockScreen) {
            this.setState({
              displayParentalLockScreen: false
            });
          }

          if (get(data, "multi_event_vod_season_id")) {
            this._fetchSeasons(data);
          }

          if (url) {
            this._redirectToNextEpisode(url);
          }
        }
      );
    } else {
      if (
        this.props.match.path.indexOf("episode") !== -1 &&
        this.props.match.path.indexOf("tv-shows") !== -1 &&
        id
      ) {
        this.props.history.push(`/tv-shows/episode/${id}`);
      }
    }
  };

  _setSubscribedEpisodes = nextProps => {
    const { cachedSingleTvShowsCarousels } = nextProps || this.props;
    const { episode, subscribedEpisodes } = this.state;

    if (
      episode &&
      !subscribedEpisodes.length &&
      cachedSingleTvShowsCarousels.length
    ) {
      const season = find(
        cachedSingleTvShowsCarousels,
        item => item.id === episode.multi_event_vod_season_id
      );

      if (season && season.items) {
        this.setState({
          subscribedEpisodes: season.items.filter(item => item.subscribed)
        });
      }
    }
  };

  _redirectToNextEpisode = url => {
    const {
      location: { state }
    } = this.props;

    if (url) {
      if (
        get(state, "fromNextEpisode") ||
        get(state, "alreadyEnteredParentalPin")
      ) {
        this.props.history.replace({
          pathname: url,
          search: "play",
          state: {
            fromNextEpisode: "done",
            alreadyEnteredParentalPin: true
          }
        });
      } else {
        this.setState(
          {
            alreadyEnteredParentalPin: false
          },
          () => {
            this.props.history.replace({
              pathname: url,
              search: "play",
              state: {
                alreadyEnteredParentalPin: true
              }
            });
          }
        );
      }

      this.setState({
        nextEpisodeData: null
      });
    }
  };

  _fetchSeasons = data => {
    const {
      getSeasonEpisodesDispatch,
      cachedSingleTvShowsCarousels
    } = this.props;
    const {
      multi_event_vod_season: { id: seasonId, name }
    } = data;

    if (
      !cachedSingleTvShowsCarousels ||
      (cachedSingleTvShowsCarousels && !cachedSingleTvShowsCarousels.length)
    ) {
      getSeasonEpisodesDispatch({
        url: `${SINGLE_VOD}?filter[multi_event_vod_season_id]=${seasonId}&filter[type]=1`,
        name,
        id: seasonId
      });
    }
  };

  _openInitallyParentalPin = data => {
    const { fromNextEpisode } = this.state;
    const {
      location: { state }
    } = this.props;

    if (get(state, "alreadyEnteredParentalPin") || fromNextEpisode === "done") {
      this.setState({
        openParentalModal: false,
        playing: get(data, "parental_rating.require_pin")
          ? false
          : !this.continueWatchVOD
      });
    } else {
      this.setState(prevState => ({
        prevStatusPlaying: prevState.playing,
        openParentalModal: true,
        playing: false
      }));
    }
  };

  _destroyPolyNetReference = () =>
    this.polynetReference && this.polynetReference.destroy();

  _setPolyNetCdn = player => {
    /*eslint-disable */
    if (player && player.getInternalPlayer("hls")) {
      const hls = player.getInternalPlayer("hls");

      this.polynetReference = new PolyNetPluginHlsjs({
        polyNetConfig: {
          apiKey: config.POLYNET_API_KEY
        },
        videoElement: document.querySelector("video"),
        hlsjs: hls
      });
    }
    /*eslint-enable */
  };

  _ref = player => {
    const { episode } = this.state;
    this.player = player;
    window.reactPlayer = this.player;
    this.playerInitalTimer = setTimeout(() => {
      const internalAudioTracks = this.getInternalAudioSubtitles(player);
      const internalSubtitles = this.getInternalMovieSubtitles(player);
      const externalMovieSubtitle = this.getExternalSubtitles(episode);
      const defaultAudio =
        internalAudioTracks &&
        internalAudioTracks.length &&
        internalAudioTracks.filter(item => item.default);
      const defaultMovie =
        internalSubtitles &&
        internalSubtitles.length &&
        internalSubtitles.filter(item => item.default);

      if (
        this.player &&
        this.player.getInternalPlayer &&
        this.player.getInternalPlayer("hls")
      ) {
        this.player.getInternalPlayer("hls").subtitleDisplay = true;
      }

      this._setPolyNetCdn(this.player);
      this._isMounted &&
        this._setSubtitles({
          internalAudioTracks,
          internalSubtitles,
          externalMovieSubtitle,
          defaultAudio,
          defaultMovie
        });
    }, 1000);
  };

  _setSubtitles = ({
    internalAudioTracks,
    internalSubtitles,
    externalMovieSubtitle,
    defaultAudio,
    defaultMovie
  }) => {
    this.setState({
      internalAudioTracks,
      internalSubtitles,
      externalMovieSubtitle,
      defaultAudioSubtitle: defaultAudio && {
        id: defaultAudio.id,
        locale: CountryLanguage.getLanguage(defaultAudio.lang).name
          ? CountryLanguage.getLanguage(defaultAudio.lang).name[0]
          : defaultAudio.lang,
        url: "#"
      },
      defaultMovieSubtitle: defaultMovie && {
        id: defaultMovie.id,
        locale: CountryLanguage.getLanguage(defaultMovie.lang).name
          ? CountryLanguage.getLanguage(defaultMovie.lang).name[0]
          : defaultMovie.lang,
        url: "#"
      }
    });
  };

  // Index can be 1 for next, -1 for previous
  _onNextOrPreviousEpisode = index => {
    const { episode, subscribedEpisodes } = this.state;
    const {
      location: { state }
    } = this.props;
    const selectedProfile = get(state, "selectedProfile");

    if (subscribedEpisodes.length) {
      let currentEpisodeIndex = subscribedEpisodes.findIndex(
        item => item.id === episode.id
      );

      if (currentEpisodeIndex !== -1) {
        currentEpisodeIndex =
          index === 1 ? currentEpisodeIndex + 1 : currentEpisodeIndex - 1;
        const nextEpisode = subscribedEpisodes[currentEpisodeIndex];

        if (nextEpisode) {
          const urlNextEpisode = `/tv-shows/episode/${nextEpisode.id}`;
          const profileSelectedNextEpisode = find(
            nextEpisode.movie_source.profiles,
            item => item.code === get(selectedProfile, "code")
          );

          if (
            get(nextEpisode, "parental_rating.require_pin") &&
            !get(profileSelectedNextEpisode, "subscribed")
          ) {
            this._onDisplayParentalModal(urlNextEpisode);
            this.setState({
              nextEpisodeData: nextEpisode
            });
          } else if (get(nextEpisode, "parental_rating.require_pin")) {
            this._onDisplayParentalModal(urlNextEpisode);
          } else if (get(profileSelectedNextEpisode, "subscribed")) {
            this._redirectToNextEpisodeWithSameProfile(
              nextEpisode,
              urlNextEpisode,
              profileSelectedNextEpisode
            );
          } else {
            this._onDisplaySelectProfileToPlayModal(nextEpisode);
          }
          this.setState({
            showLoader: true
          });
        } else {
          this._onClosePlayer();
        }
      }
    }
  };

  _redirectToNextEpisodeWithSameProfile = (
    nextEpisode,
    urlNextEpisode,
    profileSelectedNextEpisode
  ) => {
    this.props.history.replace({
      pathname: urlNextEpisode,
      search: "play",
      state: {
        item: nextEpisode,
        selectedProfile: profileSelectedNextEpisode
      }
    });
  };

  _onBackToZeroProgress = (shouldStop = false) => {
    this.setState(
      {
        playing: shouldStop ? !shouldStop : true,
        progress: 0
      },
      () => {
        if (this.player) this.player.seekTo(0);
      }
    );
  };

  _onLockClick = () => this.setState({ openParentalModal: true });

  _onChangePlaying = value => {
    this.setState(prevState => ({
      playing: value ? prevState.prevStatusPlaying : false,
      prevStatusPlaying: prevState.playing,
      showLoader: prevState.progress < 1.7
    }));
  };

  _onClosePlayer = e => {
    const {
      history,
      location: { pathname },
      setOpenPlayer
    } = this.props;

    if (e && e.stopPropagation) e.stopPropagation();
    setOpenPlayer(false);

    history.replace({
      pathname,
      state: {
        alreadyEnteredParentalPin: true
      }
    });
  };

  _hasNextEpisode = (newEpisode, nextProps) => {
    const episode = newEpisode || this.state.episode;
    const { cachedSingleTvShowsCarousels } = nextProps || this.props;

    if (
      episode &&
      cachedSingleTvShowsCarousels &&
      cachedSingleTvShowsCarousels[0] &&
      cachedSingleTvShowsCarousels[0].items
    ) {
      const season = find(
        cachedSingleTvShowsCarousels,
        o => o.id === episode.multi_event_vod_season_id
      );
      this.setState({
        showLoader: true
      });

      if (season && season.items) {
        const currentEpisodeIndex = findIndex(
          season.items,
          o => o.id === episode.id
        );
        const subscribedEpisodes = season.items
          .slice(currentEpisodeIndex + 1, season.items.length)
          .filter(item => item.subscribed);

        return !!subscribedEpisodes.length;
      }
      return;
    }
  };

  _onDoubleClickFullscreen = e => {
    if (
      e.target.classList.contains(style.wrapperDiv) ||
      e.target.classList.contains(style.reactPlayer) ||
      e.target.tagName === "VIDEO"
    )
      this.onClickFullscreen();
  };

  _onInputPinSuccess = () => {
    const { fromNextEpisode } = this.state;
    const {
      location: { state }
    } = this.props;
    const nextEpisodeData = this._setNextEpisodeData(fromNextEpisode);

    if (this.continueWatchVOD) {
      this.setState({
        openStartResumeModal: fromNextEpisode === "done" || !fromNextEpisode,
        openParentalModal: false,
        displayParentalLockScreen: false,
        alreadyEnteredParentalPin: true
      });
    } else {
      this.setState({
        openStartResumeModal: false,
        openParentalModal: false,
        displayParentalLockScreen: false,
        progress: 0,
        alreadyEnteredParentalPin: true
      });
    }

    if (nextEpisodeData || get(state, "subscribedProfile")) {
      this._redirectToPlayIfOnlyOneSubscribedProfile(nextEpisodeData, state);
    } else if (fromNextEpisode) {
      this._onInputSuccessRedirect(fromNextEpisode);
    } else {
      this.setState({
        playing: true
      });
    }
  };

  _redirectToPlayIfOnlyOneSubscribedProfile = (nextEpisodeData, state) => {
    const subscribedProfile = nextEpisodeData.movie_source.profiles.filter(
      item => item.subscribed
    );

    if (subscribedProfile.length === 1) {
      this.setState(
        {
          nextEpisodeData
        },
        () => {
          this._onSuccessPlayModal("play", subscribedProfile[0]);
        }
      );
    } else {
      this._onDisplaySelectProfileToPlayModal(
        nextEpisodeData || get(state, "subscribedProfile")
      );
    }
  };

  _setNextEpisodeData = (fromNextEpisode, callback) => {
    const nextVodParams = this._getEpisodeDataFromPathname(fromNextEpisode);
    const nextEpisode = this._getEpisodeDataFromSubscribedEpisodes(
      get(nextVodParams, "params.id")
    );

    this.setState(
      {
        nextEpisodeData: nextEpisode
      },
      callback
    );

    return nextEpisode;
  };

  _getEpisodeDataFromPathname = pathname => {
    return matchPath(pathname, {
      path: "/tv-shows/episode/:id"
    });
  };

  _getEpisodeDataFromSubscribedEpisodes = id => {
    const { subscribedEpisodes } = this.state;
    return subscribedEpisodes.find(item => item.id === parseInt(id));
  };

  _onInputSuccessRedirect = episode => {
    const { fromNextEpisode } = this.state;
    const {
      location: { state }
    } = this.props;
    const selectedProfile = get(state, "selectedProfile");
    const nextEpisode = this._setNextEpisodeData(fromNextEpisode);
    let isSubscribed = false;

    if (nextEpisode) {
      isSubscribed = !!nextEpisode.movie_source.profiles.filter(
        item => item.code === selectedProfile.code && item.subscribed
      ).length;
    }

    if (episode !== "done") {
      this.setState(
        {
          fromNextEpisode: "done"
        },
        () => {
          if (isSubscribed) {
            this.props.history.replace({
              pathname: episode,
              search: "play",
              state: {
                item: nextEpisode,
                fromNextEpisode: "done",
                alreadyEnteredParentalPin: true
              }
            });
          } else {
            const nextEpisodeProfilesSubscribed =
              (get(nextEpisode, "movie_source.profiles") &&
                get(nextEpisode, "movie_source.profiles").filter(
                  item => item.subscribed
                )) ||
              [];

            this.setState(state => ({
              openPlayModal: true,
              subscribedProfiles: nextEpisodeProfilesSubscribed,
              alreadyEnteredParentalPin: true
            }));
          }
        }
      );
    }
  };

  _onDisplayParentalModal = url => {
    this.setState(prevState => ({
      prevStatusPlaying: prevState.playing,
      openParentalModal: true,
      playing: false,
      fromNextEpisode: url,
      showLoader: false
    }));
  };

  _onDisplaySelectProfileToPlayModal = nextEpisode => {
    const nextEpisodeProfilesSubscribed =
      (get(nextEpisode, "movie_source.profiles") &&
        get(nextEpisode, "movie_source.profiles").filter(
          item => item.subscribed
        )) ||
      [];

    this.setState(state => ({
      openPlayModal: true,
      openStartResumeModal: false,
      subscribedProfiles: nextEpisodeProfilesSubscribed,
      playing: false,
      prevStatusPlaying: state.prevStatusPlaying || state.playing,
      nextEpisodeData: nextEpisode
    }));
  };

  _onCloseParentalModal = () => {
    const { fromNextEpisode } = this.state;

    if (!fromNextEpisode) {
      this._onDisplayParentalLockScreen();
      this.setState({
        openParentalModal: false,
        playing: false,
        progress: 0
      });
    } else {
      this.setState(prevState => ({
        openParentalModal: false,
        playing: prevState.prevStatusPlaying
      }));
    }
  };

  _onStartOverOrClose = () => {
    const { activeVideoCampaign } = this.state;
    const obj = {};
    let shouldStop = false;

    if (activeVideoCampaign) {
      const { play_rules } = activeVideoCampaign;
      const isIncludedAtStart = play_rules.map(Number).includes(PRE_ROLL);

      if (isIncludedAtStart) {
        obj.openVideoCampaign = true;
        obj.playing = false;
        obj.prevStatusPlaying = true;
        shouldStop = true;
      }
    }

    this.setState({ ...obj, openStartResumeModal: false });
    this._onBackToZeroProgress(shouldStop);
  };

  _onSuccessPlayModal = (eventType, selectedProfileForPlay) => {
    let { nextEpisodeData, fromNextEpisode } = this.state;

    if (!nextEpisodeData) {
      nextEpisodeData = this._getEpisodeDataFromSubscribedEpisodes(
        fromNextEpisode
      );
    }
    const urlNextEpisode = `/tv-shows/episode/${nextEpisodeData.id}`;

    this.setState(
      {
        openPlayModal: false,
        fromNextEpisode: "done"
      },
      () => {
        this.props.history.replace({
          pathname: urlNextEpisode,
          search: "play",
          state: {
            item: nextEpisodeData,
            selectedProfile: selectedProfileForPlay,
            alreadyEnteredParentalPin: true
          }
        });
      }
    );
  };

  _onClosePlayModal = type => {
    if (type === "play") {
      this.setState(state => ({
        openPlayModal: false,
        playing: state.prevStatusPlaying
      }));
    }
  };

  _renderRatingStars = () => {
    const { episode, openStars } = this.state;
    const { intl } = this.props;

    if (episode) {
      return (
        <RatingStars
          isOpen={openStars}
          vodType={episode.type}
          intl={intl}
          onClose={this._onCloseStarsModal}
          onRate={this.rateMovie}
        />
      );
    }
  };

  _onEndedWithCallback = () => {
    this.onEnded(this._onNextOrPreviousEpisode);
  };

  _onCloseStarsModal = () => {
    this.setState(prevState => ({
      openStars: false,
      playing: prevState.prevStatusPlaying
    }));
  };

  _onOpenRatingStars = () => {
    this.setState(prevState => ({
      prevStatusPlaying: prevState.playing,
      openStars: true,
      playing: false
    }));
  };

  _showControlsIcons = () => this.setState({ showControls: true });

  _onTogglePlayClick = e => {
    e.stopPropagation();
    this.setState(state => ({ playing: !state.playing }));
  };

  _onMaximize = e => {
    const { setPlayerSize } = this.props;
    e.stopPropagation();

    setPlayerSize("maximize");
  };

  _renderMinimizedControls = () => {
    const { playing, showLoader } = this.state;
    const {
      floatingPlayer: { playerSize }
    } = this.props;

    if (playerSize === "minimize") {
      return (
        <MinimizedControls
          isPlaying={playing}
          showPlayPauseButton={!showLoader}
          onToggleClick={this._onTogglePlayClick}
          onClose={this._onClosePlayer}
          onMaximize={this._onMaximize}
        />
      );
    }
  };

  _renderMinimizedProgress = () => {
    const { progress, duration } = this.state;
    const {
      floatingPlayer: { playerSize }
    } = this.props;

    if (playerSize === "minimize") {
      return <MiniProgressBar progress={progress} duration={duration} />;
    }
  };

  render() {
    const {
      intl,
      floatingPlayer: { playerSize }
    } = this.props;
    const {
      episode,
      showLoader,
      duration,
      playing,
      playbackRate,
      isFullscreen,
      muted,
      progress,
      showControls,
      volume,
      showSettingsPanel,
      showThumbs,
      externalMovieSubtitle,
      activeMovieSubtitle,
      activeAudioSubtitle,
      internalAudioTracks,
      internalSubtitles,
      openStartResumeModal,
      openParentalModal,
      openPlayModal,
      subscribedProfiles,
      activeVideoCampaign,
      openVideoCampaign,
      subscribedEpisodes
    } = this.state;
    const subtitles =
      externalMovieSubtitle &&
      externalMovieSubtitle.length > 0 &&
      externalMovieSubtitle &&
      externalMovieSubtitle.filter(item => item.default);
    const url = episode && getURL(episode);
    const forceShowControls = duration - progress <= 5.0;
    const isMinimized = playerSize === "minimize";
    let config = this.setDrmConfigParams(episode);

    return (
      <div
        className={classNames(style.wrapperDiv, "visible")}
        onDoubleClick={this._onDoubleClickFullscreen}
      >
        {openVideoCampaign && activeVideoCampaign && (
          <AdvertisementPlayer
            intl={intl}
            videoCampaignData={activeVideoCampaign}
            onClose={this.onCloseVideoCampaign}
          />
        )}
        {!isMinimized && (
          <Fragment>
            <PlayerCloseIcon
              showIcon={showControls}
              onClose={this._onClosePlayer}
            />
            <ParentalRatingInfoComponent
              intl={intl}
              vodItem={episode}
              progress={progress}
            />
          </Fragment>
        )}
        <div className={style.playerWrapper} onClick={this._showControlsIcons}>
          {episode && (
            <ReactPlayer
              className={style.reactPlayer}
              url={url}
              playing={playing}
              controls={false}
              volume={volume}
              muted={muted}
              ref={this._ref}
              playbackRate={playbackRate}
              onReady={this.onReady}
              onProgress={this.onProgress}
              onDuration={this.onDuration}
              onBuffer={this.onBuffer}
              onError={this.onError}
              onEnded={this._onEndedWithCallback}
              config={{
                file: {
                  attributes: {
                    poster:
                      this.isCensorshipActivated &&
                      get(episode, "censorship_allowed")
                        ? ""
                        : getCover(episode),
                    crossOrigin: "anonymous"
                  },
                  tracks: subtitles && subtitles.length ? subtitles : [],
                  ...get(config, "config.file")
                }
              }}
              setHlsPolyNet={config.setHlsPolyNet}
              setDashPolyNet={config.setDashPolyNet}
            />
          )}
        </div>
        {showLoader && (
          <div
            className={classNames(style.loaderWrapper, "theme-loader-wrapper", {
              [style.disablePointerEvents]: isMinimized
            })}
          >
            <Loader active={showLoader} inverted={true} />
          </div>
        )}
        {!isMinimized && (
          <PlayerControls
            id={this.playerID}
            intl={intl}
            title={get(episode, "title") || ""}
            vod={episode || {}}
            isEpisode={true}
            currentTime={(this.player && this.player.getCurrentTime()) || 0}
            duration={duration || 0}
            player={this.player}
            playStopPlayer={this.playStopPlayer}
            playing={playing}
            playbackRate={playbackRate}
            isFullscreen={isFullscreen}
            muted={muted}
            showControls={showControls}
            volume={volume}
            isResumePlayerOff={!openStartResumeModal}
            showThumbs={showThumbs}
            settingButtonID={this.settingButtonID}
            forceShowControls={forceShowControls}
            subscribedEpisodes={subscribedEpisodes}
            displayParentalLockScreen={this.state.displayParentalLockScreen}
            hasNextSubscribedEpisode={this.hasNextEpisode}
            updatePlaybackRate={this.updatePlaybackRate}
            onThumbsUp={this.rateMovie}
            onThumbsDown={this.rateMovie}
            onOpenStars={this._onOpenRatingStars}
            onNextOrPreviousEpisode={this._onNextOrPreviousEpisode}
            onDisplayParentalLockScreen={this._onDisplayParentalLockScreen}
            onDisappearParentalLockScreen={this._onDisappearParentalLockScreen}
            onSettingsPannel={this.onSettingsPannel}
            onThumbs={this.onThumbs}
            onVolumeChange={this.onVolumeChange}
            onMute={this.onMute}
            onClickFullscreen={this.onClickFullscreen}
            onLockClick={this._onLockClick}
            onChangePlaying={this._onChangePlaying}
            onDisplaySelectProfileToPlayModal={
              this._onDisplaySelectProfileToPlayModal
            }
            onDisplayParentalModal={this._onDisplayParentalModal}
          />
        )}
        <SettingsPannel
          settingPanelID={this.settingPanelID}
          showSettingsPanel={showSettingsPanel}
          externalMovieSubtitles={externalMovieSubtitle}
          internalMovieSubtitles={internalSubtitles}
          internalAudioSubtitles={internalAudioTracks}
          onActiveMovieSubtitle={this.onActiveMovieSubtitle}
          onActiveAudioSubtitle={this.onActiveAudioSubtitle}
          activeMovieSubtitle={activeMovieSubtitle}
          activeAudioSubtitle={activeAudioSubtitle}
          animation={this.animation}
          fadeDuration={this.fadeDuration}
        />
        {episode && openStartResumeModal && !openVideoCampaign && (
          <StartOverResumeModal
            open={openStartResumeModal}
            vodTitle={episode.title}
            intl={intl}
            onClose={this._onStartOverOrClose}
            onStartOver={this._onStartOverOrClose}
            onResume={this.onResume}
          />
        )}
        {openPlayModal && (
          <RentOrPlayProfilesModal
            intl={intl}
            open={openPlayModal}
            onSuccess={this._onSuccessPlayModal}
            onClose={this._onClosePlayModal}
            profiles={subscribedProfiles || []}
            eventType="play"
            successButtonTitle={intl.formatMessage({
              id: "common.play"
            })}
          />
        )}
        {openParentalModal && !openVideoCampaign && !isMinimized && (
          <InputPinModal
            open={openParentalModal}
            intl={intl}
            type="parental"
            onSuccess={this._onInputPinSuccess}
            onClose={this._onCloseParentalModal}
            successButtonTitle={intl.formatMessage({
              id: "common.confirm"
            })}
          />
        )}
        {this._renderRatingStars()}
        {this._renderMinimizedProgress()}
        {this._renderMinimizedControls()}
      </div>
    );
  }
}

EpisodePlayer.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedProfile: PropTypes.object.isRequired,
      item: PropTypes.shape({
        id: PropTypes.number.isRequired
      }).isRequired
    })
  })
};

const mapDispatchToProps = dispatch => ({
  fetchMovieStream: data => dispatch(defaultActions.fetchMovieStream(data)),
  getSeasonEpisodesDispatch: data => dispatch(getSeasonEpisodes(data)),
  getMvodList: data => dispatch(defaultActions.getMvodList(data)),
  rateVOD: data => dispatch(defaultActions.rateVOD(data)),
  getContinueWatchingVodsDispatch: data =>
    dispatch(getContinueWatchingVods(data)),
  getVideoCampaign: data => dispatch(defaultActions.getVideoCampaign(data)),
  setOpenPlayer: data => dispatch(floatingPlayerActions.setOpenPlayer(data)),
  setPlayerSize: data => dispatch(floatingPlayerActions.setPlayerSize(data))
});

const mapStateToProps = state => ({
  socket: state.socket,
  cachedContinueWatchingList: state.cachedContinueWatchingList,
  cachedSingleTvShowsCarousels: state.cachedSingleTvShowsCarousels,
  floatingPlayer: state.floatingPlayer
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(EpisodePlayer))
);
