//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onReady = function() {
  if (!this.props.location.search.includes("trailer") && this._setPolyNetCdn) {
    this._setPolyNetCdn(this.player);
  }

  this.setState({ showLoader: false });
};

export default onReady;
