//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const emitContinueWatchingEvent = function() {
  const otherProps = {};
  const { socket } = this.props;
  const { progress, duration } = this.state;
  const movie = this.state.movie || this.state.episode;

  if (movie) {
    if (duration) otherProps.duration = duration;
    socket.emit("vod-plays", {
      model_id: movie.id,
      model_type: movie.type,
      type: "SVOD",
      retention_time: progress,
      m_vod_id: movie.multi_event_vod_id || null,
      m_season: movie.multi_event_vod_season_id || null,
      genre_ids: movie.genres,
      show_type_ids: movie.show_types,
      ...otherProps
    });
  }
};

export default emitContinueWatchingEvent;
