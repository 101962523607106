import React, { Fragment, memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import CloseIcon from "react-icons/lib/md/close";
import LeftArrow from "react-icons/lib/md/keyboard-arrow-left";
import RightArrow from "react-icons/lib/md/keyboard-arrow-right";
import style from "./style.module.scss";
import { Loader } from "semantic-ui-react";

const imgWrapperClass = "light-box-image-wrapper";

const LightBoxView = ({
  imgSource,
  onClose,
  onCloseLightBox,
  selectedIndex,
  length,
  onImageChange
}) => {
  return (
    <Fragment>
      <span onClick={onCloseLightBox}>
        <CloseIcon />
      </span>
      <div
        className={classNames(style.lightBoxImageWrapper, imgWrapperClass)}
        onClick={onClose}
      >
        {!imgSource && (
          <div className={classNames(style.centerBlock, imgWrapperClass)}>
            <Loader className={"themeLoader"} active={true} inverted={true} />
          </div>
        )}
        {imgSource && (
          <div className={classNames(style.centerBlock, imgWrapperClass)}>
            {selectedIndex > 0 && (
              <button
                onClick={() => {
                  onImageChange(selectedIndex - 1);
                }}
              >
                <LeftArrow />
              </button>
            )}
            <img src={imgSource} alt={""} />
            {selectedIndex < length - 1 && (
              <button
                onClick={() => {
                  onImageChange(selectedIndex + 1);
                }}
              >
                <RightArrow />
              </button>
            )}
            {selectedIndex >= 0 && length && (
              <div className={style.status}>
                <span>{`${selectedIndex + 1} / ${length}`}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

LightBoxView.propTypes = {
  imgSource: PropTypes.string,
  imgUrl: PropTypes.string,
  onCloseLightBox: PropTypes.func,
  selectedIndex: PropTypes.number,
  length: PropTypes.number,
  onImageChange: PropTypes.func,
  onClick: PropTypes.func
};

export default memo(LightBoxView);
