import React, { PureComponent, Fragment } from "react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { Row, Col, Grid } from "react-flexbox-grid";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import aesjs from "aes-js";
import Fingerprint2 from "fingerprintjs2";
import InputField from "components/FormElements/InputField/InputField";
import FullWidthButton from "components/FormElements/FullWidthButton/FullWidthButton";
import { loginValidation } from "functions/formValidations";
import * as actions from "reduxStore/actions/index";
import { Loader } from "semantic-ui-react";
import { BASE_URL, LOGIN_URL } from "API-routes";
import InfoIcon from "react-icons/lib/md/info-outline";
import { sendAnalyticsData } from "../../helper-functions/logic-functions";

import { injectIntl } from "react-intl";
import style from "./style.module.scss";

class LoginPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      loading: false,
      loginErrorMessage: null
    };
  }

  componentDidMount() {
    let uid = localStorage.getItem("wecastUID");

    if (!uid) {
      new Fingerprint2().get(result => {
        localStorage.setItem("wecastUID", result);
      });
    }
  }

  onSubmitForm = data => {
    const { loginAction } = this.props;

    sessionStorage.setItem(
      "wecastCredentials",
      aesjs.utils.utf8.toBytes(JSON.stringify(data))
    );

    if (data && data.email && data.password) {
      sendAnalyticsData("login");
      this.setState(
        {
          loading: true
        },
        () => {
          loginAction({
            url: LOGIN_URL,
            email: data.email,
            password: data.password,
            callback: errorMessage => {
              if (!errorMessage) {
                this.props.history.replace("/");
              } else {
                this.setState({
                  loading: false,
                  loginErrorMessage: errorMessage
                });
              }
            }
          });
        }
      );
    }
  };

  renderFormFields = ({
    input,
    label,
    custom,
    type,
    meta: { error, submitFailed, submitSucceeded, pristine }
  }) => {
    this.setState({
      [input.name]: error || null
    });
    return (
      <div className={style.inputWrapper}>
        <InputField
          {...input}
          placeholder={label}
          type={type}
          props={custom}
          error={(submitFailed || (submitSucceeded && !pristine)) && error}
        />
        {error && <p>{this.state[input.name]}</p>}
      </div>
    );
  };

  render() {
    const {
      handleSubmit,
      composerConfig: {
        web: {
          modules: { registration }
        }
      }
    } = this.props;
    const { loading, loginErrorMessage } = this.state;
    const { intl } = this.props;

    return (
      <Grid className={style.mainWrapper}>
        <Row className={style.headerWrapper}>
          {get(this.props, "composerConfig.web.logos.small") && (
            <img
              className={style.logoImg}
              src={
                BASE_URL +
                "/" +
                get(this.props, "composerConfig.web.logos.small")
              }
              alt={""}
            />
          )}
          <div className={style.headerRightLinks}>
            {registration && (
              <Fragment>
                <Link to={"/login"} style={{ marginRight: 15 }}>
                  {intl.formatMessage({
                    id: "common.login"
                  })}
                </Link>
                <Link to={"/register"}>
                  {intl.formatMessage({
                    id: "common.register"
                  })}
                </Link>
              </Fragment>
            )}
          </div>
        </Row>
        <Row>
          <Col lg={7} className={style.leftBlock}>
            {get(this.props, "composerConfig.web.logos.medium") && (
              <img
                className={style.mainImg}
                src={
                  BASE_URL +
                  "/" +
                  get(this.props, "composerConfig.web.logos.medium")
                }
                alt={""}
              />
            )}
            <h1>
              {intl.formatMessage({
                id: "common.login"
              })}
            </h1>
            <h4>
              {intl.formatMessage({
                id: "account.pleaseEnterYourCredentialsToContinue"
              })}
            </h4>
          </Col>
          <Col lg={4} lgOffset={1} className={style.rightBlock}>
            <h1>
              {intl.formatMessage({
                id: "common.login"
              })}
            </h1>
            <h4>
              {intl.formatMessage({
                id: "account.pleaseEnterYourCredentialsToContinue"
              })}
            </h4>
            {loginErrorMessage && (
              <div className={style.errorMessage}>
                <InfoIcon />
                <p>{loginErrorMessage}</p>
              </div>
            )}
            <form onSubmit={handleSubmit(this.onSubmitForm)}>
              <Field
                name="email"
                component={this.renderFormFields}
                type="text"
                label="EMAIL OR USERNAME"
              />
              <Field
                name="password"
                component={this.renderFormFields}
                type="password"
                label="PASSWORD"
              />
              <FullWidthButton type="submit">
                {loading ? (
                  <Loader
                    className={"themeLoader"}
                    active={true}
                    inverted={true}
                  />
                ) : (
                  intl.formatMessage({
                    id: "common.login"
                  })
                )}
              </FullWidthButton>
              <div className={style.bottomLinks}>
                <p>
                  <Link to="/forgot-password">
                    {intl.formatMessage({
                      id: "account.forgotMyPassword"
                    })}
                  </Link>
                </p>
              </div>
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return {
    loginAction: data => dispatch(actions.loginAction(data))
  };
}

const mapStateToProps = state => ({
  composerConfig: state.composerConfig
});

export default reduxForm({
  form: "login-form",
  validate: loginValidation,
  fields: ["email", "password"]
})(
  withRouter(
    connect(mapStateToProps, matchDispatchToProps)(injectIntl(LoginPage))
  )
);
