import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { get } from "lodash";
import IconHoC from "HOCs/IconHoC";
import { ReactComponent as Play } from "./icons/play.svg";
import generateProgressBar from "../../../commonFunctions/generateProgressBar";
import style from "./style.module.scss";

const { string, number, func, bool, oneOfType, shape } = PropTypes;

const RoundCard = ({
  id,
  imgUrl,
  item,
  urlRoute,
  title,
  attachUrlID,
  loggedUser,
  showPlayIcon,
  roundedStyle,
  continue_watching,
  activeEpisodeID,
  useObjectContainPropertyImage,
  useCoverImageSize,
  useResponsiveMinHeight,
  episodeNumber,
  onClick
}) => {
  let imgClass =
    title &&
    ((continue_watching && style.imgWithTitleAndProgress) ||
      style.imgWithTitle);

  const goTo = attachUrlID ? `${urlRoute}/${id}` : urlRoute;
  const PlayIcon = IconHoC(Play);

  const renderSubscribedProfiles = () => {
    if (item && item.profiles) {
      return item.profiles
        .filter(item => item.subscribed)
        .map(profile => (
          <span key={profile.id} className={style.subscribedProfilesNames}>
            {profile.name}
          </span>
        ));
    }
  };

  return (
    <div
      className={classNames(
        style.mainWrapper,
        roundedStyle,
        style.roundedStyle
      )}
    >
      {get(loggedUser, "account.subscription_expired") && (
        <div
          className={classNames(
            style.expiredSubscriptionOverlay,
            "themeExpiredSubscriptionOverlay"
          )}
        />
      )}
      <div className={style.subscribedProfiles}>
        {renderSubscribedProfiles()}
      </div>
      <Link
        to={goTo}
        className={classNames(style.linkWrapper, {
          [style.activeEpisode]: activeEpisodeID === id
        })}
        onClick={onClick}
      >
        {showPlayIcon && (
          <div className={style.playIcon}>
            <PlayIcon />
          </div>
        )}
        <img
          src={imgUrl || ""}
          className={classNames(imgClass, {
            channelImg: useObjectContainPropertyImage,
            originalAspectRatioImage: useObjectContainPropertyImage,
            [style.responsiveMinHeight]: useResponsiveMinHeight
          })}
          style={
            useCoverImageSize
              ? {
                  objectFit: "cover"
                }
              : {}
          }
          alt={title}
        />
        {continue_watching && generateProgressBar(continue_watching)}
        <h3>
          {episodeNumber && <span>{episodeNumber} - </span>}
          {title}
        </h3>
      </Link>
    </div>
  );
};

RoundCard.propTypes = {
  id: oneOfType([string, number]),
  loggedUser: PropTypes.object.isRequired,
  activeEpisodeID: oneOfType([number, string]),
  imgUrl: string,
  urlRoute: string.isRequired,
  title: string,
  attachUrlID: bool,
  useCoverImageSize: bool,
  item: PropTypes.object,
  useObjectContainPropertyImage: bool,
  showPlayIcon: bool,
  roundedStyle: bool,
  useResponsiveMinHeight: bool,
  continue_watching: shape({
    duration: number,
    stopped_time: number
  }),
  onClick: func.isRequired
};

export default memo(RoundCard);
