import { get } from "lodash";
import { openNotificationWithIcon } from "functions/logic-functions";

const toggleCloseCaption = function(item) {
  this.setState(
    {
      openSelectCloseCaptionModal: false
    },
    () => {
      setTimeout(() => {
        if (this.player.getInternalPlayer("hls")) {
          showOrHideSubtitle.call(
            this,
            this.player.getInternalPlayer("hls").media,
            item
          );
        } else if (this.player.getInternalPlayer("dash")) {
          showOrHideSubtitle.call(
            this,
            this.player.getInternalPlayer("dash").getVideoElement(),
            item
          );
        }
      }, 250);
    }
  );
};

const showOrHideSubtitle = function(playerHtml, item) {
  const { intl } = this.props;

  if (get(playerHtml, "textTracks.length")) {
    hiddenAllTracks(playerHtml.textTracks);

    this.setState(
      {
        closeCaptionActivate: item
      },
      () => {
        if (item.id !== "MovieOff")
          playerHtml.textTracks[item.id].mode = "showing";

        openNotificationWithIcon({
          type: "success",
          message: intl.formatMessage({
            id:
              item.id === "MovieOff"
                ? "common.closeCapitionRemoved"
                : "common.closeCapitionSuccess"
          })
        });
      }
    );
  }
};

const hiddenAllTracks = function(playerHtml) {
  for (let i = 0, L = playerHtml.length; i < L; i++) {
    playerHtml[i].mode = "hidden";
  }
};

export default toggleCloseCaption;
