import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import PropTypes from "prop-types";
import FlexibleCarousel from "components/Carousels/FlexibleCarousel";
import { getHomepageHorizontalList } from "reduxStore/reducers/cachedHorizontalHomepage";

class CarouselFetchWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      visible: false
    };
    this.slide = 0;
  }

  componentDidMount() {
    const {
      getHomepageHorizontalListDispatch,
      url,
      alias,
      type,
      useCacheFrom,
      requestMethod,
      limit,
      onAppendChannels
    } = this.props;

    if (onAppendChannels && useCacheFrom && !useCacheFrom.length) {
      onAppendChannels();
    } else if (
      alias &&
      url &&
      requestMethod &&
      useCacheFrom &&
      !useCacheFrom.length
    ) {
      getHomepageHorizontalListDispatch({
        alias,
        requestMethod,
        url: `${url}${limit ? `?limit=${limit}` : ""}`,
        body: {
          type
        }
      });
    }
  }

  _onAppendChannels = nextSlide => {
    const { useCacheFrom, pager, onAppendChannels } = this.props;

    if (!pager || get(pager, "count") > useCacheFrom.length) {
      if (nextSlide >= 12 && nextSlide >= this.slide * 1.5) {
        this.slide = nextSlide;
        this.setState(
          state => ({
            page: state.page + 1
          }),
          () => {
            onAppendChannels(40, this.state.page);
          }
        );
      }
    }
  };

  render() {
    const { useCacheFrom, title, urlRoute, children, intl } = this.props;

    return (
      <div>
        {(!children && useCacheFrom && useCacheFrom.length > 0 && (
          <FlexibleCarousel
            title={title}
            urlRoute={urlRoute}
            items={useCacheFrom}
            useInfiniteScroll={useCacheFrom && useCacheFrom.length >= 10}
            intl={intl}
          />
        )) ||
          (useCacheFrom &&
            useCacheFrom.length > 0 &&
            React.cloneElement(this.props.children, {
              onAfterChange: this._onAppendChannels
            }))}
      </div>
    );
  }
}

CarouselFetchWrapper.propTypes = {
  type: PropTypes.number,
  url: PropTypes.string,
  alias: PropTypes.string,
  useCacheFrom: PropTypes.array.isRequired,
  title: PropTypes.string,
  urlRoute: PropTypes.string.isRequired,
  requestMethod: PropTypes.string,
  onAppendChannels: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    getHomepageHorizontalListDispatch: data =>
      dispatch(getHomepageHorizontalList(data))
  };
}

function mapStateToProps(state) {
  return {
    cachedHorizontalHomepage: state.cachedHorizontalHomepage
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CarouselFetchWrapper);
