import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NextEpisodeCounterView from "./components/NestEpisodeCounterView";

class NextEpisodeCounter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      counter:
        this.props.initialCounterTime >= 5 ? 5 : this.props.initialCounterTime,
      movie: null,
      openParentalModal: false
    };
    this.pausePlaying = true;
    this.interval = null;
  }

  componentWillReceiveProps(nextProps) {
    const { playing } = nextProps;
    this.pausePlaying = playing;
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.pausePlaying) {
        this.setState({ counter: this.state.counter - 1 }, () => {
          if (this.state.counter <= 1) {
            clearInterval(this.interval);
          }
        });
      }
      if (!this.pausePlaying) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  componentWillUnmount = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
  };

  onClick = () => {
    const { onNextOrPreviousEpisode } = this.props;

    if (onNextOrPreviousEpisode) {
      onNextOrPreviousEpisode(1);
    }
  };

  render() {
    const { intl } = this.props;
    const { counter } = this.state;

    return (
      <NextEpisodeCounterView
        intl={intl}
        counter={counter}
        onClick={this.onClick}
      />
    );
  }
}

NextEpisodeCounter.propTypes = {
  playing: PropTypes.bool.isRequired,
  onNextOrPreviousEpisode: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser,
  cachedSingleTvShowsCarousels: state.cachedSingleTvShowsCarousels
});

export default withRouter(connect(mapStateToProps)(NextEpisodeCounter));
