import notification from "antd/lib/notification";

const openNotificationWithIcon = ({ type, message, description }) => {
  if (type && message) {
    notification[type]({
      message,
      description
    });
  }
};

export default openNotificationWithIcon;
