import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { get } from "lodash";
import * as vodProfileActions from "reduxStore/reducers/vodProfile";
import GridCardView from "./components/GridCardView";

const GridCard = ({
  id,
  imgUrl,
  item,
  urlRoute,
  attachUrlID,
  loggedUser,
  continueWatching,
  intl,
  history,
  title,
  rating,
  genres,
  openPlayModal,
  addVodProfile
}) => {
  const [openParentalModal, setOpenParentalModal] = useState(false);
  const requirePin = get(item, "parental_rating.require_pin");

  const _onParentalPinSuccess = goTo => {
    _onCloseParentalPin();
    history.push({
      pathname: goTo,
      state: {
        item,
        alreadyEnteredParentalPin: true
      }
    });
  };

  const _onCloseParentalPin = () => setOpenParentalModal(false);

  const _onClick = (event, doRedirect) => {
    if (requirePin || (openPlayModal && doRedirect) || openPlayModal) {
      event.preventDefault();
    }

    if (requirePin) {
      setOpenParentalModal(true);
    } else if (openPlayModal && doRedirect) {
      doRedirect({
        VOD: item,
        selectedProfile: item.movie_source.profiles[0],
        addVodProfileDispatch: addVodProfile,
        history
      });
    } else if (openPlayModal) {
      openPlayModal(id);
    }
  };

  return (
    <GridCardView
      id={id}
      intl={intl}
      history={history}
      title={title}
      rating={rating}
      genres={genres}
      imgUrl={imgUrl}
      item={item}
      urlRoute={urlRoute}
      attachUrlID={attachUrlID}
      loggedUser={loggedUser}
      continueWatching={continueWatching}
      openParentalModal={openParentalModal}
      onParentalPinSuccess={_onParentalPinSuccess}
      onCloseParentalPin={_onCloseParentalPin}
      onClick={_onClick}
    />
  );
};

GridCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgUrl: PropTypes.string.isRequired,
  urlRoute: PropTypes.string.isRequired,
  title: PropTypes.string,
  genres: PropTypes.array,
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  attachUrlID: PropTypes.bool,
  item: PropTypes.object,
  openPlayModal: PropTypes.func,
  intl: PropTypes.object,
  continueWatching: PropTypes.shape({
    duration: PropTypes.number,
    stopped_time: PropTypes.number
  })
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
});

const mapDispatchToProps = dispatch => ({
  addVodProfile: data => dispatch(vodProfileActions.addVodProfile(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(GridCard))
);
