import { put, call } from "redux-saga/effects";
import { GET_CONTINUE_WATCHING_VODS_SUCCESS } from "reduxStore/reducers/cachedContinueWatchingList";
import { fetchData } from "helper-functions/http-functions";

const getContinueWatchingVods = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result) {
      yield put({
        type: GET_CONTINUE_WATCHING_VODS_SUCCESS,
        payload: result
      });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export default getContinueWatchingVods;
