import { put, call } from "redux-saga/effects";
import { GET_CHANNEL_CATEGORY_SUCCESS } from "reduxStore/reducers/channelCategories";
import { fetchData } from "helper-functions/http-functions";

const getChannelCategories = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result)
      yield put({
        type: GET_CHANNEL_CATEGORY_SUCCESS,
        payload: result
      });
  } catch (err) {
    console.info("ERR", err);
  }
};

export default getChannelCategories;
