export const GET_DEVICES = "GET_DEVICES";
export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS";
export const REMOVE_DEVICE = "REMOVE_DEVICES";
export const REMOVE_DEVICE_SUCCESS = "REMOVE_DEVICES_SUCCESS";

const devices = (state = [], action) => {
  switch (action.type) {
    case GET_DEVICES_SUCCESS:
      if (action.payload) return action.payload;
      return state;
    case REMOVE_DEVICE_SUCCESS:
      if (action.payload)
        return state.filter(item => item.id !== action.payload);
      return state;
    default:
      return state;
  }
};

export default devices;

export const getDevices = data => {
  return {
    type: GET_DEVICES,
    payload: {
      url: data.url
    }
  };
};

export const removeDevice = data => {
  return {
    type: REMOVE_DEVICE,
    payload: {
      url: data.url,
      body: data.body
    }
  };
};
