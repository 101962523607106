import { put, call } from "redux-saga/effects";
import {
  GET_TEXT_SEARCH_FILTER_VODS_SUCCESS,
  APPEND_TEXT_FILTER_SEARCH_RESULTS_SUCCESS
} from "reduxStore/reducers/textSearchVODS";
import {
  fetchData,
  fetchMultipleGetRequests
} from "helper-functions/http-functions";

export const appendTextFilterSearchResults = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result && result.items)
      yield put({
        type: APPEND_TEXT_FILTER_SEARCH_RESULTS_SUCCESS,
        payload: {
          results: result.items,
          type: payload.type
        }
      });
  } catch (err) {
    console.info("ERR", err);
  }
};

export const getTextSearchFilterVods = function*({ payload }) {
  try {
    const result = yield call(fetchMultipleGetRequests, payload);

    if (result) {
      yield put({
        type: GET_TEXT_SEARCH_FILTER_VODS_SUCCESS,
        payload: result
      });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};
