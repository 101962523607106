import { login } from "./login";
import aesjs from "aes-js";
import { store } from "../../App";
import { get } from "lodash";
import { LOGIN_URL } from "API-routes";
import { AUTHORIZE_SUCCESS } from "reduxStore/reducers/loggedUser";

const autoLogin = async () => {
  if (localStorage.getItem("wecastCredentials")) {
    const { email, password } = JSON.parse(
      aesjs.utils.utf8.fromBytes(
        localStorage.getItem("wecastCredentials").split(",")
      )
    );

    if (email && password) {
      const result = await login({
        url: LOGIN_URL,
        email,
        password
      });
      const device = get(result, "device") || get(result, "data.data.device");
      const subscription =
        get(result, "subscription") || get(result, "data.data.subscription");

      if (get(device, "token")) {
        store.dispatch({
          type: AUTHORIZE_SUCCESS,
          payload: Object.assign(
            {},
            JSON.parse(localStorage.getItem("wecastUser")),
            {
              token: device.token,
              currency_code: subscription && subscription.currency_code
            }
          )
        });

        setTimeout(() => {
          localStorage.removeItem("wecastRelogin");
          document.location.reload();
        }, 1000);
      }
    }
  }
};

export default autoLogin;
