import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router";
import { ProgressBar } from "react-player-controls";
import ReactTooltip from "react-tooltip";
import PlayerControlsButtons from "./PlayerControlsButtons";
import EpisodesSuggestionComponent from "components/EpisodesSuggestionComponent";
import NextEpisodeCounter from "components/NextEpisodeCounter";
import style from "./style.module.scss";
import "./style/style.scss";

class PlayerControls extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSuggestionsInFocus: false,
      hoverTime: null
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { vod } = this.props;
    const { vod: nextVod } = nextProps;
    const { isSuggestionsInFocus } = this.state;

    if (vod && nextVod && vod.id !== nextVod.id && isSuggestionsInFocus) {
      this.setState({
        isSuggestionsInFocus: false
      });
    }
  }

  secondsToMin = s => {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + Math.floor(s);
  };

  seekForward = () => {
    const { updatePlaybackRate, playbackRate } = this.props;

    if (playbackRate !== 8) {
      updatePlaybackRate(playbackRate * 2);
    } else updatePlaybackRate(1);
  };

  seekBackwards = () => {
    const {
      player,
      updatePlaybackRate,
      playbackRate,
      currentTime = 0
    } = this.props;

    if (playbackRate > 1) {
      updatePlaybackRate(playbackRate / 2);
    } else if (player) player.seekTo(currentTime - 10);
  };

  playPause = () => {
    const { updatePlaybackRate, playing, playStopPlayer } = this.props;
    if (playing) updatePlaybackRate(1);
    playStopPlayer(!playing);
  };

  onIntent = hoverTime => this.setState({ hoverTime });

  onFocus = value => {
    if (value === false) {
      this.setState({
        isSuggestionsInFocus: false
      });
      return;
    }
    this.setState(state => ({
      isSuggestionsInFocus: !state.isSuggestionsInFocus
    }));
  };

  onSeek = time => {
    const { player, displayParentalLockScreen } = this.props;

    if (player && !displayParentalLockScreen) {
      player.seekTo(Math.round(time));
    }
  };

  render() {
    const { isSuggestionsInFocus, hoverTime } = this.state;
    const {
      intl,
      vod,
      title,
      isEpisode,
      currentTime = 0,
      duration = 0,
      player,
      playing,
      playbackRate,
      onClickFullscreen,
      isFullscreen,
      onMute,
      muted,
      showControls,
      onVolumeChange,
      volume,
      id,
      onSettingsPannel,
      onThumbs,
      onOpenStars,
      settingButtonID,
      onNextOrPreviousEpisode,
      forceShowControls,
      onDisappearParentalLockScreen,
      displayParentalLockScreen,
      hasNextSubscribedEpisode,
      onLockClick,
      onChangePlaying,
      isResumePlayerOff,
      onDisplaySelectProfileToPlayModal,
      onDisplayParentalModal,
      subscribedEpisodes
    } = this.props;
    const showControlsClass =
      forceShowControls || showControls ? style.activeControls : style.controls;
    const isLastFiveSeconds =
      parseInt(duration - currentTime) <= 5 && duration - currentTime > 0;

    return (
      <div id={id} className={showControlsClass}>
        <div
          className={classNames(style.controlsWrapper, {
            [style.hiddenControls]: isSuggestionsInFocus
          })}
        >
          <div className={style.description}>
            <h2>{title}</h2>
            <p>{this.secondsToMin(duration)} mins</p>
            {isEpisode &&
              isLastFiveSeconds &&
              !displayParentalLockScreen &&
              hasNextSubscribedEpisode && (
                <NextEpisodeCounter
                  intl={intl}
                  onNextOrPreviousEpisode={onNextOrPreviousEpisode}
                  playing={playing}
                  initialCounterTime={parseInt(duration - currentTime)}
                />
              )}
          </div>
          <div className={style.progressWrapper}>
            <p>{this.secondsToMin(currentTime)}</p>
            <ReactTooltip
              place="top"
              type="dark"
              effect="float"
              id="tooltip-hover"
            >
              {this.secondsToMin(hoverTime)}
            </ReactTooltip>
            <div
              className={style.progressLineWrapper}
              data-tip
              data-for="tooltip-hover"
            >
              <ProgressBar
                totalTime={duration}
                currentTime={currentTime}
                isSeekable={true}
                onIntent={this.onIntent}
                onSeek={this.onSeek}
              />
            </div>
            <p>{this.secondsToMin(duration)}</p>
          </div>
          <div className={style.controlButtons}>
            <PlayerControlsButtons
              player={player}
              settingButtonID={settingButtonID}
              isFullscreen={isFullscreen}
              muted={muted}
              volume={volume}
              playbackRate={playbackRate}
              playing={playing}
              isEpisode={isEpisode}
              isLastFiveSeconds={isLastFiveSeconds}
              displayParentalLockScreen={displayParentalLockScreen}
              hasNextSubscribedEpisode={hasNextSubscribedEpisode}
              onClickFullscreen={onClickFullscreen}
              onMute={onMute}
              onVolumeChange={onVolumeChange}
              seekForward={this.seekForward}
              seekBackwards={this.seekBackwards}
              playPause={this.playPause}
              onSettingsPannel={onSettingsPannel}
              onThumbs={onThumbs}
              onOpenStars={onOpenStars}
              onNextOrPreviousEpisode={onNextOrPreviousEpisode}
              onLockClick={onLockClick}
              showControls={showControls}
            />
          </div>
        </div>
        {isEpisode && (
          <EpisodesSuggestionComponent
            intl={intl}
            episode={vod}
            arrowsColor="white"
            subscribedEpisodes={subscribedEpisodes}
            isResumePlayerOff={isResumePlayerOff}
            isSuggestionsInFocus={isSuggestionsInFocus}
            onFocus={this.onFocus}
            displayParentalLockScreen={displayParentalLockScreen}
            onDisappearParentalLockScreen={onDisappearParentalLockScreen}
            onChangePlaying={onChangePlaying}
            onDisplaySelectProfileToPlayModal={
              onDisplaySelectProfileToPlayModal
            }
            onDisplayParentalModal={onDisplayParentalModal}
          />
        )}
      </div>
    );
  }
}

PlayerControls.propTypes = {
  vod: PropTypes.object,
  player: PropTypes.object,
  intl: PropTypes.object.isRequired,

  subscribedEpisodes: PropTypes.array,

  title: PropTypes.any.isRequired,
  id: PropTypes.any.isRequired,

  settingButtonID: PropTypes.string.isRequired,

  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  volume: PropTypes.number.isRequired,
  playbackRate: PropTypes.number.isRequired,

  isEpisode: PropTypes.bool,
  muted: PropTypes.bool.isRequired,
  hasNextSubscribedEpisode: PropTypes.bool,
  showControls: PropTypes.bool.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  playing: PropTypes.bool.isRequired,
  displayParentalLockScreen: PropTypes.bool,
  forceShowControls: PropTypes.bool,
  isResumePlayerOff: PropTypes.bool,

  onLockClick: PropTypes.func,
  playStopPlayer: PropTypes.func.isRequired,
  updatePlaybackRate: PropTypes.func.isRequired,
  onClickFullscreen: PropTypes.func.isRequired,
  onMute: PropTypes.func.isRequired,
  onVolumeChange: PropTypes.func.isRequired,
  onThumbs: PropTypes.func.isRequired,
  onNextOrPreviousEpisode: PropTypes.func,
  onChangePlaying: PropTypes.func,
  onDisplaySelectProfileToPlayModal: PropTypes.func,
  onDisplayParentalModal: PropTypes.func
};

export default withRouter(PlayerControls);
