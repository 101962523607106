//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onVolumeChange = function(volume) {
  const { muted } = this.state;

  if (volume === 0) {
    this.setState({
      volume,
      muted: true
    });
  } else if (volume > 0 && muted) {
    this.setState({
      volume,
      muted: false
    });
  } else {
    this.setState({
      volume
    });
  }

  sessionStorage.setItem("lastVolume", volume);
};

export default onVolumeChange;
