import FormData from "form-data";
import Fingerprint2 from "fingerprintjs2";
import axios from "./axios";

const register = ({ url, body }) => {
  if (url && body) {
    const encodedCredentials = btoa(`${body.email}:${body.password}`);
    const bodyFormData = new FormData();

    for (let key in body) {
      bodyFormData.append(key, body[key]);
    }

    let uid = localStorage.getItem("wecastUID");

    if (!uid) {
      new Fingerprint2().get(result => {
        uid = result;
        localStorage.setItem("wecastUID", result);
      });
    }

    return axios({
      method: "post",
      url: url,
      data: bodyFormData,
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        UID: uid,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(response => response.data)
      .catch(err => {
        throw err;
      });
  }
};

export default register;
