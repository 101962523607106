import { put, call } from "redux-saga/effects";
import {
  GET_DEVICES_SUCCESS,
  REMOVE_DEVICE_SUCCESS
} from "reduxStore/reducers/devices";
import { fetchData, postData } from "helper-functions/http-functions";

export const getDevices = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result) yield put({ type: GET_DEVICES_SUCCESS, payload: result });
  } catch (err) {
    console.info("ERR", err);
  }
};

export const removeDevice = function*({ payload }) {
  try {
    const result = yield call(postData, payload);

    if (result)
      yield put({
        type: REMOVE_DEVICE_SUCCESS,
        payload: payload.body.id
      });
  } catch (err) {
    console.info("ERR", err);
  }
};
