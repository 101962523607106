import React, { memo } from "react";
import PropTypes from "prop-types";
import TogglePlayButton from "../TogglePlayButton";
import CloseIcon from "react-icons/lib/md/close";
import Maximize from "react-icons/lib/md/open-in-new";
import style from "./style.module.scss";

const MinimizedControls = props => {
  const {
    isPlaying,
    onToggleClick,
    onClose,
    onMaximize,
    showPlayPauseButton
  } = props;

  return (
    <div className={style.container} onClick={onToggleClick}>
      <div className={style.maximize} onClick={onMaximize}>
        <Maximize />
      </div>
      <div className={style.close} onClick={onClose}>
        <CloseIcon />
      </div>
      {showPlayPauseButton && (
        <TogglePlayButton toggle={isPlaying} onClick={onToggleClick} />
      )}
    </div>
  );
};

MinimizedControls.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  showPlayPauseButton: PropTypes.bool.isRequired,
  onToggleClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onMaximize: PropTypes.func.isRequired
};

export default memo(MinimizedControls);
