import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledComponents from "styled-components";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import InfoIcon from "react-icons/lib/md/info-outline";
import InputField from "../../FormElements/InputField";
import SelectField from "../../FormElements/SelectField";
import FullWidthButton from "../../FormElements/FullWidthButton";
import { registerFormDetailsValidation } from "functions/formValidations";

import style from "./style.module.scss";

class RegisterFormDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      confirmPassword: null,
      purchasePin: null,
      parentalPin: null,
      age: null,
      gender: null
    };
  }

  onSubmitForm = data => {
    const { changeFormStep, updateFormValues } = this.props;

    if (
      data &&
      data.email &&
      data.firstName &&
      data.lastName &&
      data.password &&
      data.confirmPassword &&
      data.purchasePin &&
      data.parentalPin &&
      data.age &&
      data.gender &&
      data.password === data.confirmPassword &&
      data.parentalPin.length === 4 &&
      data.purchasePin.length === 5 &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
    ) {
      updateFormValues({
        ...data
      });
      changeFormStep("next");
    }
  };

  maxLenght = max => value => {
    let v;
    let result = value.length > max;

    if (result === false) {
      v = value;
    }
    return v;
  };

  renderFormFields = ({
    input,
    label,
    custom,
    type,
    meta: { error, submitFailed, submitSucceeded, pristine }
  }) => {
    this.setState({
      [input.name]: error || null
    });

    return (
      <div className={style.inputWrapper}>
        <InputField
          {...input}
          placeholder={label}
          type={type}
          props={custom}
          error={(submitFailed || (submitSucceeded && !pristine)) && error}
        />
        {error && <p>{this.state[input.name]}</p>}
      </div>
    );
  };

  renderSelectField = ({
    input,
    label,
    options,
    meta: { error, submitFailed, submitSucceeded, pristine }
  }) => {
    this.setState({
      [input.name]: error || null
    });

    return (
      <div className={style.inputWrapper}>
        <SelectField
          input={input}
          label={label}
          options={options}
          error={(submitFailed || (submitSucceeded && !pristine)) && error}
        />
        {error && <p>{this.state[input.name]}</p>}
      </div>
    );
  };

  render() {
    const { handleSubmit, submitFailed, invalid, intl } = this.props;

    return (
      <div>
        <div className={style.timelineBlock}>
          <div>
            <span />
            <p>{intl.formatMessage({ id: "account.aboutYou" })}</p>
          </div>
          <div>
            <span />
            <p>
              {intl.formatMessage({
                id: "account.yourSubscription"
              })}
            </p>
          </div>
        </div>
        {submitFailed && (
          <div className={style.errorMessage}>
            <InfoIcon />
            <p>
              <FormattedMessage id="account.thisFIeldsAreRequired" />
            </p>
          </div>
        )}
        <form onSubmit={handleSubmit(this.onSubmitForm)}>
          <FleixbleFieldWrapper>
            <Field
              name="firstName"
              component={this.renderFormFields}
              type="text"
              label={intl.formatMessage({
                id: "common.firstName"
              })}
            />
            <Field
              name="lastName"
              component={this.renderFormFields}
              type="text"
              label={intl.formatMessage({
                id: "common.lastName"
              })}
            />
          </FleixbleFieldWrapper>
          <Field
            name="email"
            component={this.renderFormFields}
            type="text"
            label={intl.formatMessage({ id: "common.email" })}
          />
          <Field
            name="password"
            component={this.renderFormFields}
            type="password"
            label={intl.formatMessage({ id: "common.password" })}
          />
          <Field
            name="confirmPassword"
            component={this.renderFormFields}
            type="password"
            label={intl.formatMessage({
              id: "account.confirmPassword"
            })}
          />
          <Field
            name="purchasePin"
            component={this.renderFormFields}
            type="text"
            label={intl.formatMessage({ id: "common.purchasePin" })}
            normalize={this.maxLenght(5)}
            custom={{
              description: intl.formatMessage({
                id: "account.set5DigitPurchasePin"
              })
            }}
          />
          <Field
            name="parentalPin"
            component={this.renderFormFields}
            type="text"
            label={intl.formatMessage({ id: "common.parentalPin" })}
            normalize={this.maxLenght(4)}
            custom={{
              description: intl.formatMessage({
                id: "account.set4DigitPinToRestrictAccess"
              })
            }}
          />
          <FleixbleFieldWrapper>
            <Field
              name="age"
              component={this.renderFormFields}
              type="text"
              label={intl.formatMessage({ id: "common.age" })}
              normalize={this.maxLenght(3)}
            />
            <Field
              name="gender"
              label={intl.formatMessage({ id: "common.gender" })}
              component={this.renderSelectField}
              options={{
                false: intl.formatMessage({
                  id: "common.male"
                }),
                true: intl.formatMessage({
                  id: "common.female"
                })
              }}
            />
          </FleixbleFieldWrapper>
          <FullWidthButton type="submit" disabled={invalid}>
            <FormattedMessage id="common.continue" />
          </FullWidthButton>
        </form>
      </div>
    );
  }
}

const FleixbleFieldWrapper = StyledComponents.div`
	display: flex;
	&:last-child{
		display: none !important;
		input, select {
			margin-bottom: 0px !important;
		}
	}
	& > div{
		width: 50%;
		&:first-child{
			margin-right: 10px;
		}
		&:last-child{
			margin-left: 10px;
		}
	}
	input{
		width: 100%;
	}
	@media (max-width: 648px){
		flex-direction: column;
		& > div{
			margin-left: 0;
			margin-right: 10px;
			width: 100%;
			&:last-child{
				margin-left: 0;
			}
		}
	}
`;

RegisterFormDetails.propTypes = {
  changeFormStep: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired
};

export default reduxForm({
  form: "register-details-form",
  validate: registerFormDetailsValidation
})(RegisterFormDetails);
