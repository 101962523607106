import { get } from "lodash";

//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onError = function(err, data) {
  const { movie, episode } = this.state;
  const { socket } = this.props;
  const id = get(movie, "id") || get(episode, "id");

  this.setState(
    {
      showLoader: true
    },
    () => {
      if (typeof err === "string" && socket && socket.connected && id) {
        socket.emit("play-issues", {
          buffer: false,
          crash: true,
          record_model: "SingleEventVod",
          model_id: id,
          model_type: 0
        });
      }
    }
  );
};

export default onError;
