import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getChannelCategories } from "reduxStore/reducers/channelCategories";
import { GET_CHANNEL_CATEGORIES_URL } from "API-routes";
import ChannelCategoryItem from "components/Carousels/ChannelCategoriesCarousel/components/ChannelCategoryItem";
import Slider from "react-slick/lib";
import { FormattedMessage } from "react-intl";
import Arrow from "../components/Arrow";
import style from "./style.module.scss";

export class ChannelCategoriesCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channels: null
    };
  }

  componentDidMount() {
    const { channelCategories, getChannelCategoriesDispatch } = this.props;

    if (
      (channelCategories && channelCategories.length > 0) ||
      !channelCategories ||
      (channelCategories && !channelCategories.length)
    ) {
      getChannelCategoriesDispatch({
        url: GET_CHANNEL_CATEGORIES_URL
      });

      const categoriesList = this.getCategoriesList(channelCategories);

      setTimeout(() => {
        if (this.slider) {
          this.goToSlickIndex(categoriesList);
        }
      }, 500);
    }
  }

  getCategoriesList = channelCategories => {
    const categoriesList = [];
    channelCategories.forEach(({ id, name, children }) => {
      categoriesList.push({
        id,
        name
      });
      if (children && children.length) categoriesList.push(...children);
    });
    return categoriesList;
  };

  goToSlickIndex = categoriesList => {
    const {
      match: {
        params: { categoryID }
      }
    } = this.props;
    categoriesList.forEach(item => {
      if (item.id === parseInt(categoryID)) {
        this.slickIndex = categoriesList.indexOf(item);
      }
    });
    this.slider.slickGoTo(this.slickIndex);
  };

  componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { categoryID: nextCategoryID }
      },
      channelCategories: nextChannelCategories
    } = nextProps;
    const {
      match: {
        params: { categoryID }
      }
    } = this.props;

    const categoriesList = this.getCategoriesList(nextChannelCategories);

    setTimeout(() => {
      if (this.slider && categoryID === nextCategoryID) {
        this.goToSlickIndex(categoriesList);
      }
    }, 500);

    if (parseInt(categoryID) !== parseInt(nextCategoryID)) {
      if (nextCategoryID) {
        nextChannelCategories.forEach(item => {
          if (item.id === parseInt(nextCategoryID)) {
            this.selectedChannelCategory = item.name;
          }
          if (item.id === "all") {
            this.selectedChannelCategory = "All Channels";
          } else if (item.id === "favorite_channels") {
            this.selectedChannelCategory = "Favorite Channels";
          }
        });
      } else {
        this.selectedChannelCategory = "All Channels";
      }
    }
  }

  renderChannelCategories = channelCategories => {
    if (Array.isArray(channelCategories)) {
      const categoriesList = [];

      channelCategories.forEach(({ id, name, children }) => {
        categoriesList.push({
          id,
          name
        });
        if (children && children.length) categoriesList.push(...children);
      });

      return categoriesList
        .filter(item => item.id && item.name)
        .map(({ id, name }) => {
          return (
            <ChannelCategoryItem
              key={id}
              id={id}
              name={name}
              urlRoute={`/channel-categories/${id !== "all" ? id : ""}`}
            />
          );
        });
    }
    return [];
  };

  render() {
    const {
      channelCategories,
      match: {
        params: { categoryID }
      }
    } = this.props;

    const settings = {
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 6,
      initialSlide: 0,
      arrows: true,
      infinite: channelCategories.length > 6,
      nextArrow: (
        <Arrow icon="right" hasInfiniteScroll={channelCategories.length > 6} />
      ),
      prevArrow: (
        <Arrow icon="left" hasInfiniteScroll={channelCategories.length > 6} />
      ),
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1399,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
            initialSlide: 0
          }
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0
          }
        },
        {
          breakpoint: 479,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 0
          }
        }
      ]
    };

    if (channelCategories && channelCategories.length) {
      return (
        <div className={style.sliderWrapper}>
          <p className={"navLink"}>
            <FormattedMessage id="common.channelCategoriesTitle" />
          </p>
          <Slider ref={slider => (this.slider = slider)} {...settings}>
            {this.renderChannelCategories(channelCategories, categoryID)}
          </Slider>
        </div>
      );
    }
    return <div className={style.placeholderHeight}></div>;
  }
}

const mapStateToProps = state => ({
  channelCategories: state.channelCategories,
  composerConfig: state.composerConfig
});

const mapDispatchToProps = dispatch => ({
  getChannelCategoriesDispatch: data => dispatch(getChannelCategories(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChannelCategoriesCarousel)
);
