export const loginValidation = values => {
  const errors = {};

  if (!values.email) errors.email = "Email or username required";
  if (!values.password) errors.password = "Password required";
  return errors;
};

export const resetValidation = values => {
  const errors = {};

  if (!values.email) errors.email = "Email required";
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export const resetPasswordValidation = values => {
  const errors = {};

  if (!values.newPassword) errors.newPassword = "Password required";
  if (!values.newPassword && values.confirmNewPassword)
    errors.newPassword = "Password required";
  if (!values.newPassword && !values.confirmNewPassword)
    errors.newPassword = "Password required";
  if (!values.confirmNewPassword && values.newPassword)
    errors.newPassword = "Confirm password required";
  if (values.newPassword && values.newPassword.length < 4)
    errors.newPassword = "Password must contain minimum 4 characters";
  if (values.confirmNewPassword && values.confirmNewPassword.length < 4)
    errors.confirmPassword = "Password must contain minimum 4 characters";
  if (
    values.newPassword &&
    values.confirmNewPassword &&
    values.newPassword !== values.confirmNewPassword
  )
    errors.confirmNewPassword = "Password don't match";

  return errors;
};

export const registerFormDetailsValidation = values => {
  const errors = {};

  if (!values.firstName) errors.firstName = "First name required";
  else if (!isNaN(values.firstName))
    errors.cardFirstName = "First name can't be a number";
  if (!values.lastName) errors.lastName = "Last name required";
  else if (!isNaN(values.lastName))
    errors.cardLastName = "Last name can't be a number";
  if (!values.email) errors.email = "Email required";
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
    errors.email = "Invalid email address";
  if (!values.confirmPassword)
    errors.confirmPassword = "Confirm password required";
  if (!values.purchasePin) errors.purchasePin = "Purchase pin required";
  if (!values.parentalPin) errors.parentalPin = "Parental pin required";
  if (!values.age) errors.age = "Age required";
  if (!values.gender) errors.gender = "Gender required";

  if (!values.password) errors.password = "Password required";
  if (values.password && values.password.length < 4)
    errors.password = "Password must contain minimum 4 characters";
  if (values.confirmPassword && values.confirmPassword.length < 4)
    errors.confirmPassword = "Password must contain minimum 4 characters";
  if (
    values.password &&
    values.confirmPassword &&
    values.password !== values.confirmPassword
  )
    errors.confirmPassword = "Password don't match";
  if (
    (values.age && values.age < 1) ||
    values.age > 130 ||
    isNaN(parseInt(values.age))
  )
    errors.age = "Invalid age";
  if (values.purchasePin && values.purchasePin.length !== 5)
    errors.purchasePin = "Purhase pin must contain 5 characters";
  if (values.parentalPin && values.parentalPin.length !== 4)
    errors.parentalPin = "Parental pin must contain 4 characters";
  return errors;
};

export const registerFormCreditCardValidation = values => {
  const re = /[0-9 ]+/;
  const errors = {};

  if (!values.cardFirstName) errors.cardFirstName = "First name required";
  else if (!isNaN(values.cardFirstName))
    errors.cardFirstName = "First name can't be a number";
  if (!values.cardLastName) errors.cardLastName = "Last name required";
  else if (!isNaN(values.cardLastName))
    errors.cardLastName = "Last name can't be a number";
  if (!values.creditCardNumber)
    errors.creditCardNumber = "Credit card required";
  else if (values.creditCardNumber && !re.exec(values.creditCardNumber))
    errors.creditCardNumber = "Credit card should contain only numbers";
  else if (values.creditCardNumber && values.creditCardNumber.length < 16)
    errors.creditCardNumber = "Credit card should contain minimum 16 numbers";
  if (!values.expDate) errors.expDate = "Exp date required";
  if (!values.securityNumberCVV) errors.securityNumberCVV = "CVV required";
  else if (isNaN(values.securityNumberCVV))
    errors.securityNumberCVV = "CVV wrong characters";
  else if (values.securityNumberCVV.length !== 3)
    errors.securityNumberCVV = "Incorrect CVV";
  return errors;
};
