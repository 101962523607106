import { call } from "redux-saga/effects";
import { fetchData } from "helper-functions/http-functions";

const getAvailableVodProfiles = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (payload.successCallback) payload.successCallback(result);
  } catch (err) {
    if (payload.successCallback) payload.successCallback();
  }
};

export default getAvailableVodProfiles;
