//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onSettingsPannel = function() {
  const { showSettingsPanel } = this.state;

  this.setState({
    showSettingsPanel: !showSettingsPanel
  });
};

export default onSettingsPannel;
