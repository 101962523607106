import axios from "./axios";

const isAuthorized = ({ url }) => {
  const userDetails = JSON.parse(localStorage.getItem("wecastUser"));

  if (userDetails && userDetails.token) {
    return axios
      .get(url)
      .then(response => {
        const {
          data: { data: responseData }
        } = response;

        if (responseData && responseData.status === 1 && responseData.token) {
          const userDetails =
            JSON.parse(localStorage.getItem("wecastUser")) || {};

          userDetails.token = response.data.token;
          localStorage.setItem("wecastUser", JSON.stringify(userDetails));
        }
        return responseData;
      })
      .catch(err => {
        localStorage.removeItem("wecastUser");
        throw err;
      });
  } else throw new Error("User doesn't exist in localstorage");
};

export default isAuthorized;
