import React, { Fragment, memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router";
import StyledComponents from "styled-components";
import { ReactComponent as AvatarIconMale } from "./icons/avatar-male.svg";
import { ReactComponent as AvatarIconFemale } from "./icons/avatar-female.svg";
import SearchIcon from "react-icons/lib/md/search";
import IconHoC from "HOCs/IconHoC/IconHoC";
import SearchTextComponent from "../../../../SearchTextComponent/SearchTextComponent";
import ArrowBack from "react-icons/lib/md/arrow-back";
import NavLink from "../NavLink";
import style from "./style.module.scss";

const { object, func, bool } = PropTypes;

const MobileNavbarView = ({
  intl,
  openFilter,
  isHidden,
  loggedUser,
  composerConfig: {
    web: {
      modules: { vod, channels, epg, trending, home }
    }
  },
  toggleSearch,
  onHideNav,
  onLogout
}) => {
  const _renderNavLink = (to, translationID, useLiWrapper) => {
    if (to && translationID) {
      if (useLiWrapper) {
        return (
          <li>
            <NavLink
              to={to}
              onClick={onHideNav}
              translationID={translationID}
            />
          </li>
        );
      }

      return (
        <NavLink to={to} onClick={onHideNav} translationID={translationID} />
      );
    }
  };

  const _renderAccountDropdown = () => {
    const {
      profile: { sex: gender }
    } = loggedUser;
    const Avatar = IconHoC(gender ? AvatarIconFemale : AvatarIconMale);

    return (
      <div className={style.dropDownList}>
        <label htmlFor="account-check">
          <div className={style.avatarIcon}>
            <Avatar />
          </div>
        </label>
        <input type="checkbox" id="account-check" />
        <ul className={style.dropDown}>
          {_renderNavLink("/account/info", "common.myAccount", true)}
          <li>
            <p onClick={onLogout}>
              {intl.formatMessage({
                id: "common.logout"
              })}
            </p>
          </li>
        </ul>
      </div>
    );
  };

  const _renderSearchComponent = () => {
    const isSomeModuleAvailable = vod || channels || epg || trending || home;

    if (isSomeModuleAvailable) {
      return (
        <div
          className={classNames({
            activeFilter: openFilter
          })}
        >
          <div className="filterFieldWrapper">
            {openFilter && (
              <div className={style.searchWrapper}>
                <SearchTextComponent onHideNav={onHideNav} intl={intl} />
              </div>
            )}
            <div className={style.searchIcon}>
              <SearchIcon onClick={toggleSearch} />
            </div>
          </div>
        </div>
      );
    }
  };

  const _renderLiveTvComponent = () => {
    if ((channels || epg) && vod) {
      return (
        <div className={style.dropDownList}>
          <label htmlFor="livetv-check">
            {intl.formatMessage({ id: "common.liveTv" })}
          </label>
          <input type="checkbox" id="livetv-check" />
          <ul className={style.dropDown}>
            {channels &&
              _renderNavLink("/channel-categories", "common.watch", true)}
            {epg && _renderNavLink("/epg-channels", "common.epg", true)}
          </ul>
        </div>
      );
    } else if ((channels || epg) && !vod) {
      return (
        <>
          {channels && _renderNavLink("/channel-categories", "common.liveTv")}
          {epg && _renderNavLink("/epg-channels", "common.tvGuide")}
        </>
      );
    }
  };

  return (
    <Fragment>
      <SideBar isHidden={isHidden} className={style.navbarWrapper}>
        <ArrowBack className={"themeArrowBack"} onClick={onHideNav} />
        <div className={style.urlsWrapper}>
          {_renderLiveTvComponent()}
          {vod && home && _renderNavLink("/movies", "common.movies")}
          {vod && home && _renderNavLink("/tv-shows", "common.tvShows")}
          {trending && home && _renderNavLink("/trending", "common.trending")}
          {_renderSearchComponent()}
          {_renderAccountDropdown()}
        </div>
      </SideBar>
      <DimmerWrapper
        onClick={onHideNav}
        isHidden={isHidden}
        className={style.mobileNavDimmerBack}
      />
    </Fragment>
  );
};

const DimmerWrapper = StyledComponents.div`
	transition: background-color 350ms ease;
	background-color: ${props =>
    !props.isHidden ? "transparent" : "rgba(0,0,0,0.6)"}
`;

const SideBar = StyledComponents.div`
	transition: right 350ms ease;
	right: ${props => (!props.isHidden ? "-256px" : "0px")}
`;

MobileNavbarView.propTypes = {
  intl: object.isRequired,
  openFilter: bool,
  isHidden: bool.isRequired,
  composerConfig: object.isRequired,
  toggleSearch: func.isRequired,
  onHideNav: func.isRequired
};

export default withRouter(memo(MobileNavbarView));
