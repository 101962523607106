import React, { Component } from "react";
import PropTypes from "prop-types";
import PlayIcon from "react-icons/lib/md/play-circle-filled";
import { connect } from "react-redux";
import { ModalStyled, ModalWrapper } from "./styledComponents";
import style from "./style.module.scss";

class StartOverResumeModal extends Component {
  render() {
    const { open, onClose, onStartOver, onResume, vodTitle, intl } = this.props;

    return (
      <ModalStyled
        dimmer={"blurring"}
        size={"tiny"}
        open={open}
        onClose={onClose}
      >
        <ModalWrapper>
          <PlayIcon />
          <h1>
            {intl.formatMessage(
              {
                id: "modal.playTitle"
              },
              {
                title: vodTitle
              }
            )}
          </h1>
          <h4>
            {intl.formatMessage({
              id: "modal.startFromBeginningOrResume"
            })}
          </h4>

          <div className={"bottomButtons"}>
            <div>
              <button
                autoFocus
                className={style.controlsMoreBtnWrapper}
                onClick={onStartOver}
              >
                {intl.formatMessage({
                  id: "modal.startOver"
                })}
              </button>
            </div>

            <div>
              <button
                className={style.controlsMoreBtnWrapper}
                onClick={onResume}
              >
                {intl.formatMessage({
                  id: "modal.resume"
                })}
              </button>
            </div>
          </div>
        </ModalWrapper>
      </ModalStyled>
    );
  }
}

StartOverResumeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  vodTitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onStartOver: PropTypes.func.isRequired,
  onResume: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser
  };
}

export default connect(mapStateToProps)(StartOverResumeModal);
