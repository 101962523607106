import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import GenresNavListView from "./components/GenresNavListView";

const GenresNavList = props => {
  const {
    genres,
    selectedGenres,
    route,
    onShowNavs,
    location,
    history
  } = props;
  const clearFiltersAndRedirect = useCallback(() => {
    history.push(route);
  }, [route, history]);

  if (!genres || (genres && !genres.length)) return null;

  return (
    <GenresNavListView
      genres={genres}
      selectedGenres={selectedGenres}
      location={location}
      clearFiltersAndRedirect={clearFiltersAndRedirect}
      onShowNavs={onShowNavs}
    />
  );
};

GenresNavList.propTypes = {
  genres: PropTypes.array.isRequired,
  route: PropTypes.string.isRequired,
  onShowNavs: PropTypes.func.isRequired,
  selectedGenres: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default withRouter(memo(GenresNavList));
