import styledComponents from "styled-components";
import { Modal } from "semantic-ui-react";

export const ModalStyled = styledComponents(Modal)`
	background-color: transparent !important;
	border-radius: 13px !important;
    overflow: hidden;
    animation: startAnimation 150ms ease forwards;
    
    @keyframes startAnimation {
    	0% { transform: scale(0.9); opacity: 0.7; }
    	35% { transform: scale(1.1) }
    	75% { transform: scale(0.95) }
    	100% { transform: scale(1); opacity: 1; }
    }
`;

export const ModalWrapper = styledComponents("div")`
	background-color: rgba(var(--theme_background_modal), 0.95) !important;
	display: flex !important;
	flex-direction: column;
	align-items: center;
	padding: 50px !important;
	
	& > h1{
		font-size: 24px;
		text-align: center;
		margin-top: 20px;
		margin-bottom: 15px;
		color: var(--theme_text_color);
	}
	& > svg{
		font-size: 36px;
		color: var(--theme_text_color);
	}
	& > h4{
		font-size: 16px;
    	font-weight: 400;
    	margin-bottom: 25px;
    	color: var(--theme_text_color);;
	}
	
	& > .bottomButtons{
		display: flex;
		width: 100%;
		justify-content: center;
		
		@media only screen and (max-width: 340px) {
            flex-direction: column;
            
            & > div:first-child {
                margin: 0;
                margin-bottom: 15px;
            }
        }
		
		div{
			cursor: pointer;
			display: flex;
			justify-content: center;
			height: 41px;
			margin-right: 25px;
			&:last-child{
				margin-right: 0px;
			}
		}
	}
`;
