//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onThumbs = function() {
  const { showThumbs } = this.state;

  this.setState({
    showThumbs: !showThumbs
  });
};

export default onThumbs;
