import React, { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import CheckIcon from "react-icons/lib/md/check";
import CloseIcon from "react-icons/lib/md/close";
import { ModalStyled, ModalWrapper } from "./styledComponents";
import style from "./style.module.scss";

const ConfirmRentModalView = forwardRef(
  (
    { open, price, loggedUser, eventType, onClose, onSuccess, onMouseOver },
    buttonRef
  ) => {
    return (
      <ModalStyled
        dimmer={"blurring"}
        size={"tiny"}
        open={open}
        onClose={() => {
          onClose(eventType);
        }}
      >
        <ModalWrapper>
          <h4>
            <FormattedMessage id="modal.rentTitleFor" />{" "}
            {loggedUser.currency_code} {price}?
          </h4>
          <div className={style.buttonsWrapper} />
          <div className={style.buttonsWrapper}>
            <div>
              <div
                className={style.iconWrapper}
                onClick={() => {
                  onSuccess(eventType);
                }}
              >
                <button autoFocus ref={buttonRef}>
                  <div>
                    <CheckIcon />
                  </div>
                </button>
              </div>
              <p>
                <FormattedMessage id="common.yes" />
              </p>
            </div>
            <div>
              <div
                className={style.iconWrapper}
                onClick={() => {
                  onClose(eventType);
                }}
                onMouseOver={onMouseOver}
              >
                <div>
                  <CloseIcon />
                </div>
              </div>
              <p>
                <FormattedMessage id="common.no" />
              </p>
            </div>
          </div>
        </ModalWrapper>
      </ModalStyled>
    );
  }
);

ConfirmRentModalView.propTypes = {
  open: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  loggedUser: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired
};

export default memo(ConfirmRentModalView);
