import React, { memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const DefaultButton = ({
  title,
  active,
  onClick,
  className,
  style: propStyle
}) => {
  return (
    <div
      className={classNames(className, {
        [style.activeButton]: active,
        [style.mainWrapper]: !active
      })}
      style={propStyle}
    >
      <div className={style.buttonWrapper} onClick={onClick}>
        <button>{title}</button>
      </div>
    </div>
  );
};

DefaultButton.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default memo(DefaultButton);
