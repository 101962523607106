import React, { PureComponent } from "react";
import { withRouter, Redirect } from "react-router";
import { connect } from "react-redux";
import * as defaultActions from "reduxStore/actions";
import { CHECK_DEVICE_TOKEN_URL } from "API-routes";
import CheckSubscriptionValidationHoC from "../CheckSubscriptionValidationHoC";

const AuthorizationHoC = CustomComponent => {
  return connect(
    mapStateToProps,
    matchDispatchToProps
  )(
    withRouter(
      class extends PureComponent {
        componentDidMount() {
          const { authorizeAction, loggedUser } = this.props;

          if (!loggedUser) {
            authorizeAction({
              url: CHECK_DEVICE_TOKEN_URL
            });
          }
        }

        renderComponents = () => {
          const { loggedUser } = this.props;

          switch (loggedUser) {
            case null:
              return null;
            case false:
              return <Redirect to="/login" />;
            default:
              return (
                <CheckSubscriptionValidationHoC>
                  <CustomComponent {...this.props} />
                </CheckSubscriptionValidationHoC>
              );
          }
        };

        render() {
          return this.renderComponents();
        }
      }
    )
  );
};

function matchDispatchToProps(dispatch) {
  return {
    authorizeAction: data => dispatch(defaultActions.authorizeAction(data))
  };
}

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser
  };
}

export default AuthorizationHoC;
