import React, { memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import NotFoundImageUrl from "./images/search_not_found.png";
import style from "./style.module.scss";

const NotFoundResultsComponent = ({ searchText }) => (
  <div className={classNames(style.notFoundResults, "topPadding")}>
    {searchText && (
      <p>
        <span>
          <FormattedMessage id="vods.noResultsFound" values={{ searchText }} />
        </span>
      </p>
    )}
    <img src={NotFoundImageUrl} alt={""} />
    <p>
      <FormattedMessage id="common.suggestions" />:
    </p>
    <ul>
      <li>
        <span>
          <FormattedMessage id="vods.tryDifferentKeywords" />
        </span>
      </li>
      <li>
        <span>
          <FormattedMessage id="vods.trySearchMoviesSeries" />
        </span>
      </li>
    </ul>
  </div>
);

NotFoundResultsComponent.propTypes = {
  searchText: PropTypes.string.isRequired
};

export default memo(NotFoundResultsComponent);
