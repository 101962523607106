import styledComponents from "styled-components";
import { Modal } from "semantic-ui-react";

export const ModalStyled = styledComponents(Modal)`
	background-color: transparent !important;
	border-radius: 13px !important;
    overflow: hidden;
    animation: 150ms 200ms ease forwards;
    
    @keyframes startAnimation {
    	0% { transform: scale(0.9); opacity: 0.7; }
    	35% { transform: scale(1.1) }
    	75% { transform: scale(0.95) }
    	100% { transform: scale(1); opacity: 1; }
    }
`;

export const ModalWrapper = styledComponents(Modal.Content)`
	background-color: rgba(var(--theme_background_modal), 0.95) !important;
	display: flex !important;
	flex-direction: column;
	align-items: center;
	padding: 50px !important;
	& > svg{
		font-size: 36px;
	}
	& > h4{
		font-size: 26px;
    	font-weight: 400;
    	margin-bottom: 0px;
	}
	
	& > .bottomButtons{
		display: flex;
		width: 100%;
		div{
			cursor: pointer;
			display: flex;
			justify-content: center;
			flex-grow: 1;
			padding: 10px;
			border-radius: 5px;
			border: 2px solid var(--theme_text_color);
			margin-right: 20px;
			&:last-child{
				margin-right: 0px;
			}
		}
	}
	
	& > .buttonsWrapper{
		display: flex;
		width: 100%;
		margin: 50px 0;
		& > div {
			margin-right: 10px;
			flex-grow: 1;
			
			&.active{
				& > div{
					background-color: #c1c1c1;
					border: 2px solid transparent;
				}
			}
			
			&:last-child{
					margin-right: 0px;
				}
				
			& > div{
				cursor: pointer;
				display: flex;
				justify-content: center;
				flex-grow: 1;
				padding: 10px;
				border-radius: 5px;
				border: 2px solid var(--theme_text_color);
				margin-bottom: 8px;
			}
			
			& > p {
				text-align: center;
			}
		}
	}
`;
