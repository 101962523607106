import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router";
import classNames from "classnames";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { Loader } from "semantic-ui-react";
import { find, get, debounce } from "lodash";
import PlayerControls from "components/PlayerControls";
import RatingStars from "components/RatingStars";
import MiniProgressBar from "../components/MiniProgressBar";
import MinimizedControls from "../components/MinimizedControls";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/fromEvent";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinct";
import StartOverResumeModal from "components/Modals/StartOverResumeModal";
import SettingsPannel from "components/SettingsPannel";
import PlayerCloseIcon from "components/PlayerCloseIcon";
import ParentalRatingInfoComponent from "components/Players/components/ParentalRatingInfoComponent";
import AdvertisementPlayer from "components/Players/AdvertisementPlayer";
import * as playerMethods from "../commonPlayerFunctions/index";
import * as defaultActions from "reduxStore/actions";
import * as continueWatchingActions from "reduxStore/reducers/cachedContinueWatchingList";
import * as floatingPlayerActions from "reduxStore/reducers/floatingPlayer";
import { STREAM_VOD, GET_ACTIVE_CAMPAIGN } from "API-routes";
import CountryLanguage from "country-language";
import { injectIntl } from "react-intl";
import { sendAnalyticsData } from "functions/logic-functions";
import style from "./style.module.scss";

const {
  getInternalAudioSubtitles,
  getInternalMovieSubtitles,
  getExternalSubtitles,
  getCover,
  getURL,
  onReady,
  onResume,
  onMute,
  onSettingsPannel,
  onThumbs,
  onProgress,
  onDuration,
  onBuffer,
  emitContinueWatchingEvent,
  onError,
  playStopPlayer,
  onVolumeChange,
  updatePlaybackRate,
  onActiveAudioSubtitle,
  onActiveMovieSubtitle,
  rateMovie,
  openPlayModal,
  onEnded,
  onCloseVideoCampaign,
  onClickFullscreen,
  exitFullScreen,
  onBufferEnd,
  setDrmConfigParams
} = playerMethods;

const CONTROLS_VISIBILITY_DURATION = 4000;

class MoviePlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      movie: null,
      showLoader: true,
      autoplayAlreadySet: false,
      progress: 0,
      duration: 0,
      volume: 0.8,
      playing: false,
      playbackRate: 1,
      isFullscreen: false,
      muted: false,
      showControls: true,
      showCloseIcon: true,
      showSettingsPanel: false,
      externalMovieSubtitle: [],
      defaultMovieSubtitle: {
        id: null,
        locale: null,
        url: null
      },
      defaultAudioSubtitle: {
        id: null,
        locale: null,
        url: null
      },
      activeMovieSubtitle: null,
      activeAudioSubtitle: 0,
      internalAudioTracks: [],
      internalSubtitles: [],

      openPlayModal: false,
      openStartResumeModal: false,
      displayParentalLockScreen: false,
      openParentalModal: false,
      openStars: false,
      prevStatusPlaying: false,

      openVideoCampaign: false,
      activeVideoCampaign: null,
      watchedVideoCampaigns: {}
    };

    this.animation = "fade left";
    this.fadeDuration = 500;
    this.playerID = "controlsWrapper";
    this.settingPanelID = "settingPanelID";
    this.outerSettingPanelClick = Observable.fromEvent(
      document,
      "click"
    ).distinct();
    this.settingButtonID = "settingBtnID";
    this.continueWatchVOD = null;
    this.isCensorshipActivated =
      localStorage.getItem("censorship-allowed") &&
      JSON.parse(localStorage.getItem("censorship-allowed"));

    this.setDrmConfigParams = setDrmConfigParams.bind(this);
    this.onBufferEnd = onBufferEnd.bind(this);
    this.exitFullScreen = exitFullScreen.bind(this);
    this.onClickFullscreen = onClickFullscreen.bind(this);
    this.onCloseVideoCampaign = onCloseVideoCampaign.bind(this);
    this.onReady = onReady.bind(this);
    this.onEnded = onEnded.bind(this);
    this.onResume = onResume.bind(this);
    this.onMute = onMute.bind(this);
    this.onSettingsPannel = onSettingsPannel.bind(this);
    this.onThumbs = onThumbs.bind(this);
    this.onProgress = onProgress.bind(this);
    this.onDuration = onDuration.bind(this);
    this.onBuffer = onBuffer.bind(this);
    this.emitContinueWatchingEvent = emitContinueWatchingEvent.bind(this);
    this.onError = onError.bind(this);
    this.playStopPlayer = playStopPlayer.bind(this);
    this.onVolumeChange = onVolumeChange.bind(this);
    this.updatePlaybackRate = updatePlaybackRate.bind(this);
    this.onActiveAudioSubtitle = onActiveAudioSubtitle.bind(this);
    this.onActiveMovieSubtitle = onActiveMovieSubtitle.bind(this);
    this.rateMovie = rateMovie.bind(this);
    this.openPlayModal = openPlayModal.bind(this);
    this.getExternalSubtitles = getExternalSubtitles.bind(this);
    this.getInternalAudioSubtitles = getInternalAudioSubtitles.bind(this);
    this.getInternalMovieSubtitles = getInternalMovieSubtitles.bind(this);
    this.cameFromUrl = props.location.pathname;
    this._isMounted = false;
  }

  componentDidMount() {
    const {
      history,
      location: { pathname, state },
      setOpenPlayer
    } = this.props;
    const id = get(state, "item.id");
    const selectedProfile = get(state, "selectedProfile");
    const { cachedContinueWatchingList } = this.props;
    const isInContinueWatching = find(
      cachedContinueWatchingList,
      item => item.id === parseInt(id)
    );

    if (isInContinueWatching) {
      this.continueWatchVOD = isInContinueWatching;
    }

    //GET STREAM FOR MOVIE
    if (!selectedProfile) {
      setOpenPlayer(false);
      history.replace({ pathname });
    } else {
      this._isMounted = true;
      this._setEventListeners();
      this._setRxSubscribers();
      this._getVideoCampaigns();
      this._getVodStream(id, selectedProfile.id);
      sendAnalyticsData("play_single_movie");
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { search: nextSearch, state: nextState },
      floatingPlayer: { playerSize }
    } = nextProps;
    const {
      location: { search }
    } = this.props;
    const { movie } = this.state;
    const selectedProfile = get(nextState, "selectedProfile");

    this._closePlayerOnGoBack(playerSize, nextSearch, search);

    if (
      search &&
      nextState &&
      movie &&
      get(movie, "id") !== get(nextState, "item.id") &&
      selectedProfile
    ) {
      this._getVideoCampaigns();
      this._getVodStream(get(nextState, "item.id"), selectedProfile.id);
    }
  }

  componentWillUnmount() {
    const { progress } = this.state;
    const { socket } = this.props;

    this._activateDebounce.cancel();
    this._isMounted = false;
    this._clearTimers();
    this._removeEventListeners();
    this._unsubscribeRxEvents();
    this._destroyPolyNetReference();
    this._destroyReactPlayers();
    this.exitFullScreen();

    if (progress && progress >= 10 && socket) {
      this.emitContinueWatchingEvent();
    }

    document.querySelector("body").style.height = "100%";
  }

  componentDidUpdate() {
    const {
      showLoader,
      movie,
      playing,
      autoplayAlreadySet,
      openVideoCampaign
    } = this.state;
    const { continuePlayWithoutResumeModal } = this.props.location.state || {};

    if (
      !openVideoCampaign &&
      continuePlayWithoutResumeModal &&
      !autoplayAlreadySet &&
      !showLoader &&
      !playing &&
      movie
    ) {
      this.setState(
        {
          autoplayAlreadySet: true
        },
        this.onResume
      );
    }
  }

  _closePlayerOnGoBack = (playerSize, nextSearch, search) => {
    const { setOpenPlayer } = this.props;

    if (playerSize === "maximize" && nextSearch !== search) {
      setOpenPlayer(false);
    }
  };

  _getVideoCampaigns = () => {
    const { getVideoCampaign, location } = this.props;

    getVideoCampaign({
      url: `${GET_ACTIVE_CAMPAIGN}?filter[vod_id]=${get(
        location,
        "state.item.id"
      )}`,
      successCallback: this._videoCampaignSuccessCallback
    });
  };

  _getVodStream = (id, vodProfileID) => {
    const { fetchMovieStream } = this.props;

    fetchMovieStream({
      url: STREAM_VOD,
      body: {
        single_event_vod_id: id,
        vod_profile_id: vodProfileID
      },
      successCallback: this._successCallback
    });
  };

  _destroyReactPlayers = () => {
    if (this.player) {
      if (this.player.getInternalPlayer("dash")) {
        this.player.getInternalPlayer("dash").reset();
      }
      if (this.player.getInternalPlayer("hls")) {
        this.player.getInternalPlayer("hls").destroy();
      }
    }
    ReactPlayer.removeCustomPlayers();
  };

  _removeEventListeners = () => {
    document.removeEventListener("keyup", this._onKeyboardControls);
    document.removeEventListener("mousemove", this._activateDebounce);
    document.removeEventListener("mousemove", this._activateShowControls);
  };

  _unsubscribeRxEvents = () => {
    if (this.settingPanelClickHide) this.settingPanelClickHide.unsubscribe();
  };

  _clearTimers = () => {
    clearTimeout(this.playerInitalTimer);
  };

  _onClosePlayer = e => {
    const {
      history,
      location: { pathname, search, state },
      setOpenPlayer
    } = this.props;

    if (e) e.stopPropagation();

    if (search) {
      history.replace({
        pathname,
        state: {
          alreadyEnteredParentalPin: get(state, "alreadyEnteredParentalPin")
        }
      });
    }
    setOpenPlayer(false);
  };

  _videoCampaignSuccessCallback = response => {
    if (response) {
      this.setState({
        activeVideoCampaign: response
      });
    }
  };

  _setEventListeners = () => {
    document.addEventListener("keyup", this._onKeyboardControls);
    document.addEventListener("mousemove", this._activateDebounce);
    document.addEventListener("mousemove", this._activateShowControls);
  };

  _activateDebounce = debounce(event => {
    const path = event.path || (event.composedPath && event.composedPath());
    let showPlayer = false;

    for (let key in path) {
      if (path[key].id === this.playerID || path[key].id === "closeButton") {
        showPlayer = true;
      }
    }

    if (!showPlayer && this._isMounted) {
      this.setState({
        showControls: false
      });
    }
  }, CONTROLS_VISIBILITY_DURATION);

  _activateShowControls = () => {
    if (!this.state.showControls) {
      this.setState({
        showControls: true
      });
    }
  };

  _setRxSubscribers = () => {
    this.settingPanelClickHide = this.outerSettingPanelClick.subscribe(
      event => {
        let display = false;
        const path = event.path || (event.composedPath && event.composedPath());

        for (let key in path) {
          if (
            path[key].id === this.settingPanelID ||
            path[key].id === this.settingButtonID
          )
            display = true;
        }
        if (!display) {
          this.setState({
            showSettingsPanel: false
          });
        }
      }
    );
  };

  _onArrowLeft = () => {
    const { progress } = this.state;

    this.LeftArrowClickProps = {
      counter: this.LeftArrowClickProps
        ? this.LeftArrowClickProps.counter + 1
        : 1,
      time: new Date().getTime()
    };

    const seekTimer = setTimeout(() => {
      if (
        this.LeftArrowClickProps &&
        new Date().getTime() - this.LeftArrowClickProps.time >= 500
      ) {
        this.player.seekTo(
          progress - this.LeftArrowClickProps.counter * 10 >= 0
            ? progress - this.LeftArrowClickProps.counter * 10
            : 0
        );
        this.LeftArrowClickProps = null;
      } else clearTimeout(seekTimer);
    }, 500);
  };

  _onArrowRight = () => {
    const { progress } = this.state;

    this.RightArrowClickProps = {
      counter: this.RightArrowClickProps
        ? this.RightArrowClickProps.counter + 1
        : 1,
      time: new Date().getTime()
    };

    const seekTimer = setTimeout(() => {
      if (
        this.RightArrowClickProps &&
        new Date().getTime() - this.RightArrowClickProps.time >= 500
      ) {
        this.player.seekTo(progress + this.RightArrowClickProps.counter * 10);
        this.RightArrowClickProps = null;
      } else clearTimeout(seekTimer);
    }, 500);
  };

  _onKeyboardControls = e => {
    const { openParentalModal } = this.state;

    if (!openParentalModal) {
      switch (e.key) {
        case "Escape":
          this._onPressEscape();
          break;
        case "Space":
          this.setState(state => ({ playing: !state.playing }));
          break;
        case " ":
          this.setState(state => ({ playing: !state.playing }));
          e.preventDefault();
          break;
        case "ArrowRight":
          this._onArrowRight();
          break;
        case "ArrowLeft":
          this._onArrowLeft();
          break;
        default:
          break;
      }
    }
  };

  _onPressEscape = () => {
    const {
      history,
      setOpenPlayer,
      location: { pathname, state },
      floatingPlayer: { playerSize }
    } = this.props;

    if (playerSize === "minimize" && get(state, "cameFrom") !== pathname) {
      return setOpenPlayer(false);
    }

    history.replace({
      pathname: get(state, "cameFrom") || "/",
      state: {
        alreadyEnteredParentalPin: get(state, "alreadyEnteredParentalPin")
      }
    });
    setOpenPlayer(false);
  };

  _destroyPolyNetReference = () =>
    this.polynetReference && this.polynetReference.destroy();

  _setRef = player => {
    const { movie } = this.state;
    this.player = player;

    this.playerInitalTimer = setTimeout(() => {
      const internalAudioTracks = this.getInternalAudioSubtitles(player);
      const internalSubtitles = this.getInternalMovieSubtitles(player);
      const externalMovieSubtitle = this.getExternalSubtitles(movie);
      const defaultAudio =
        internalAudioTracks &&
        internalAudioTracks.length &&
        internalAudioTracks.filter(item => item.default);
      const defaultMovie =
        internalSubtitles &&
        internalSubtitles.length &&
        internalSubtitles.filter(item => item.default);

      if (
        this.player &&
        this.player.getInternalPlayer &&
        this.player.getInternalPlayer("hls")
      )
        this.player.getInternalPlayer("hls").subtitleDisplay = true;

      this._isMounted &&
        this.setState({
          internalAudioTracks,
          internalSubtitles,
          externalMovieSubtitle,
          defaultAudioSubtitle: defaultAudio && {
            id: defaultAudio.id,
            locale: CountryLanguage.getLanguage(defaultAudio.lang).name
              ? CountryLanguage.getLanguage(defaultAudio.lang).name[0]
              : defaultAudio.lang,
            url: "#"
          },
          defaultMovieSubtitle: defaultMovie && {
            id: defaultMovie.id,
            locale: CountryLanguage.getLanguage(defaultMovie.lang).name
              ? CountryLanguage.getLanguage(defaultMovie.lang).name[0]
              : defaultMovie.lang,
            url: "#"
          }
        });
    }, 1000);
  };

  _successCallback = data => {
    const {
      location: {
        state: { item }
      },
      history
    } = this.props;

    if (data) {
      this.setState(
        {
          movie: null,
          progress: 0
        },
        () => {
          this.setState({
            movie: { ...data }
          });
        }
      );
    } else {
      history.replace(`/movies/${get(item, "id")}`);
    }
  };

  _onDoubleClickFullscreen = e => {
    if (
      e.target.classList.contains(style.wrapperDiv) ||
      e.target.classList.contains(style.reactPlayer) ||
      e.target.tagName === "VIDEO"
    )
      this.onClickFullscreen();
  };

  _onCloseStartOverResumeModal = () => {
    this.setState(
      {
        openStartResumeModal: false,
        playing: true,
        progress: 0
      },
      () => {
        if (this.player) this.player.seekTo(0);
      }
    );
  };

  _onStartOver = () => {
    const { activeVideoCampaign } = this.state;
    const isCampaignIncludedAtStart = this._isVideoCampaignIncludedAtStart(
      activeVideoCampaign
    );

    this.setState(
      {
        openStartResumeModal: false,
        playing: !isCampaignIncludedAtStart,
        prevStatusPlaying: true,
        openVideoCampaign: isCampaignIncludedAtStart,
        progress: 0
      },
      () => {
        if (this.player) this.player.seekTo(0);
      }
    );
  };

  _isVideoCampaignIncludedAtStart = activeVideoCampaign => {
    return (
      activeVideoCampaign && get(activeVideoCampaign, "play_rules").includes(1)
    );
  };

  _onCloseStarsModal = () => {
    this.setState(prevState => ({
      openStars: false,
      playing: prevState.prevStatusPlaying
    }));
  };

  _onOpenRatingStars = () => {
    this.setState(prevState => ({
      prevStatusPlaying: prevState.playing,
      openStars: true,
      playing: false
    }));
  };

  _showControlsIcons = () => this.setState({ showControls: true });

  _renderStartOverResumeModal = () => {
    const { movie, openStartResumeModal, openVideoCampaign } = this.state;
    const { intl } = this.props;

    if (movie && openStartResumeModal && !openVideoCampaign) {
      return (
        <StartOverResumeModal
          intl={intl}
          open={openStartResumeModal}
          vodTitle={movie.title}
          onClose={this._onCloseStartOverResumeModal}
          onStartOver={this._onStartOver}
          onResume={this.onResume}
        />
      );
    }
  };

  _renderMinimizeProgress = () => {
    const { progress, duration } = this.state;
    const {
      floatingPlayer: { playerSize }
    } = this.props;

    if (playerSize === "minimize") {
      return <MiniProgressBar progress={progress} duration={duration} />;
    }
  };

  _renderRatingStars = () => {
    const { openStars, movie } = this.state;
    const { intl } = this.props;

    if (movie) {
      return (
        <RatingStars
          isOpen={openStars}
          vodType={movie.type}
          intl={intl}
          onClose={this._onCloseStarsModal}
          onRate={this.rateMovie}
        />
      );
    }
  };

  _onTogglePlayClick = e => {
    e.stopPropagation();
    this.setState(state => ({ playing: !state.playing }));
  };

  _onMaximize = e => {
    const { setPlayerSize } = this.props;

    e.stopPropagation();
    setPlayerSize("maximize");
  };

  _renderMinimizedControls = () => {
    const { playing, showLoader } = this.state;
    const {
      floatingPlayer: { playerSize }
    } = this.props;

    if (playerSize === "minimize") {
      return (
        <MinimizedControls
          isPlaying={playing}
          showPlayPauseButton={!showLoader}
          onToggleClick={this._onTogglePlayClick}
          onClose={this._onClosePlayer}
          onMaximize={this._onMaximize}
        />
      );
    }
  };

  render() {
    const {
      intl,
      floatingPlayer: { playerSize }
    } = this.props;
    const {
      movie,
      showLoader,
      duration,
      playing,
      playbackRate,
      isFullscreen,
      muted,
      showControls,
      volume,
      showSettingsPanel,
      externalMovieSubtitle,
      activeMovieSubtitle,
      activeAudioSubtitle,
      internalAudioTracks,
      internalSubtitles,
      progress,
      openVideoCampaign,
      activeVideoCampaign
    } = this.state;
    const subtitles =
      externalMovieSubtitle &&
      externalMovieSubtitle.length > 0 &&
      externalMovieSubtitle &&
      externalMovieSubtitle.filter(item => item.default);
    const url = movie && getURL(movie);
    const isMinimized = playerSize === "minimize";
    const config = this.setDrmConfigParams(movie);

    return (
      <Fragment>
        {openVideoCampaign && activeVideoCampaign && (
          <AdvertisementPlayer
            intl={intl}
            videoCampaignData={activeVideoCampaign}
            open={openVideoCampaign}
            onClose={this.onCloseVideoCampaign}
          />
        )}
        <div
          className={classNames(style.wrapperDiv, "visible", "videoWrapper")}
          onDoubleClick={this._onDoubleClickFullscreen}
        >
          {!isMinimized && (
            <Fragment>
              <PlayerCloseIcon
                showIcon={showControls}
                onClose={this._onClosePlayer}
              />
              <ParentalRatingInfoComponent
                intl={intl}
                vodItem={movie}
                progress={progress}
              />
            </Fragment>
          )}
          <div onClick={this._showControlsIcons} style={{ height: "100%" }}>
            {movie && (
              <ReactPlayer
                className={style.reactPlayer}
                url={url}
                playing={playing}
                controls={false}
                volume={volume}
                muted={muted}
                ref={this._setRef}
                playbackRate={playbackRate}
                onReady={this.onReady}
                onProgress={this.onProgress}
                onDuration={this.onDuration}
                onBuffer={this.onBuffer}
                onError={this.onError}
                onEnded={this.onEnded}
                onBufferEnd={this.onBufferEnd}
                config={{
                  file: {
                    attributes: {
                      poster:
                        this.isCensorshipActivated &&
                        get(movie, "censorship_allowed")
                          ? ""
                          : getCover(movie),
                      crossOrigin: "anonymous"
                    },
                    tracks: subtitles && subtitles.length ? subtitles : [],
                    ...get(config, "config.file")
                  }
                }}
                setHlsPolyNet={config.setHlsPolyNet}
                setDashPolyNet={config.setDashPolyNet}
              />
            )}
          </div>

          {showLoader && (
            <div className={`${style.loaderWrapper} theme-loader-wrapper`}>
              <Loader active={showLoader} inverted={true} />
            </div>
          )}
          {!isMinimized && (
            <PlayerControls
              id={this.playerID}
              intl={intl}
              title={get(movie, "title") || ""}
              vod={movie || {}}
              currentTime={(this.player && this.player.getCurrentTime()) || 0}
              duration={duration || 0}
              player={this.player}
              playStopPlayer={this.playStopPlayer}
              playing={playing}
              muted={muted}
              volume={volume}
              playbackRate={playbackRate}
              showControls={showControls}
              settingButtonID={this.settingButtonID}
              isFullscreen={isFullscreen}
              updatePlaybackRate={this.updatePlaybackRate}
              onMute={this.onMute}
              onClickFullscreen={this.onClickFullscreen}
              onVolumeChange={this.onVolumeChange}
              onSettingsPannel={this.onSettingsPannel}
              onThumbs={this.onThumbs}
              onOpenStars={this._onOpenRatingStars}
            />
          )}
          <SettingsPannel
            settingPanelID={this.settingPanelID}
            showSettingsPanel={showSettingsPanel}
            externalMovieSubtitles={externalMovieSubtitle}
            internalMovieSubtitles={internalSubtitles}
            internalAudioSubtitles={internalAudioTracks}
            onActiveMovieSubtitle={this.onActiveMovieSubtitle}
            onActiveAudioSubtitle={this.onActiveAudioSubtitle}
            activeMovieSubtitle={activeMovieSubtitle}
            activeAudioSubtitle={activeAudioSubtitle}
            animation={this.animation}
            fadeDuration={this.fadeDuration}
          />
          {this._renderStartOverResumeModal()}
          {this._renderRatingStars()}
          {this._renderMinimizeProgress()}
        </div>
        {this._renderMinimizedControls()}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchMovieStream: data => dispatch(defaultActions.fetchMovieStream(data)),
  rateVOD: data => dispatch(defaultActions.rateVOD(data)),
  getVideoCampaign: data => dispatch(defaultActions.getVideoCampaign(data)),
  getContinueWatchingVods: data =>
    dispatch(continueWatchingActions.getContinueWatchingVods(data)),
  setPlayerSize: data => dispatch(floatingPlayerActions.setPlayerSize(data)),
  setOpenPlayer: data => dispatch(floatingPlayerActions.setOpenPlayer(data))
});

const mapStateToProps = state => ({
  socket: state.socket,
  cachedContinueWatchingList: state.cachedContinueWatchingList,
  cachedSingleTvShowsCarousels: state.cachedSingleTvShowsCarousels,
  floatingPlayer: state.floatingPlayer
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(MoviePlayer))
);
