//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onActiveAudioSubtitle = function(data) {
  const internalPlayer =
    this.player.getInternalPlayer("hls") ||
    this.player.getInternalPlayer("dash");

  this.setState(
    {
      activeAudioSubtitle: data.id,
      defaultAudioSubtitle: {
        id: data.id,
        locale: data.locale,
        url: data.url
      }
    },
    () => {
      if (internalPlayer && internalPlayer.audioTrack) {
        if (data.id === "AudioOff") {
          internalPlayer.audioTrack = 0;
        } else if (data.internal) {
          internalPlayer.audioTrack = data.id;
        }
      }
    }
  );
};

export default onActiveAudioSubtitle;
