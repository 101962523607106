import update from "immutability-helper";
import { get } from "lodash";
export const HIGHLIGHTED_CONTENT = "HIGHLIGHTED_CONTENT";
export const HIGHLIGHTED_CONTENT_SUCCESS = "HIGHLIGHTED_CONTENT_SUCCESS";
export const UPDATE_SUBSCRIBED_PROFILE = "UPDATE_SUBSCRIBED_PROFILE";

const highlightedContent = (state = [], action) => {
  switch (action.type) {
    case HIGHLIGHTED_CONTENT_SUCCESS:
      if (action.payload) return action.payload;
      return state;

    case UPDATE_SUBSCRIBED_PROFILE:
      return updateSubscribedProfile(state, action);
    default:
      return state;
  }
};

export default highlightedContent;

export const getHighlightedContent = data => {
  return {
    type: HIGHLIGHTED_CONTENT,
    payload: {
      url: data.url
    }
  };
};

const updateSubscribedProfile = (state, action) => {
  if (action.payload && state.length) {
    const itemIndex = state.findIndex(
      item => item.model_id === action.payload.id
    );

    if (itemIndex !== -1) {
      return update(state, {
        [itemIndex]: {
          model: {
            movie_source: {
              $set: {
                profiles: get(action, "payload.movie_source.profiles")
              }
            }
          }
        }
      });
    }
    return state;
  }
  return state;
};
