import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as accountActions from "reduxStore/reducers/loggedUser";
import { CHECK_SUBSCRIPTION } from "API-routes";

class CheckSubscriptionValidationHoC extends React.Component {
  componentDidMount() {
    const { checkSubscription, loggedUser } = this.props;

    if (loggedUser) {
      checkSubscription({
        url: CHECK_SUBSCRIPTION
      });
    }
  }

  render() {
    return this.props.children;
  }
}

const mapDispatchToProps = dispatch => ({
  checkSubscription: data => dispatch(accountActions.checkSubscription(data))
});

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckSubscriptionValidationHoC));
