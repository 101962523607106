import { put, call } from "redux-saga/effects";
import { UPDATE_PROFILE_SUCCESS } from "reduxStore/reducers/loggedUser";
import { postData, register } from "helper-functions/http-functions";

export const updateProfile = function*({ payload }) {
  try {
    const result = yield call(postData, payload);

    if (result && result.data && result.data.data) {
      if (payload.successCallback) payload.successCallback(result);
      yield put({
        type: UPDATE_PROFILE_SUCCESS,
        payload: result.data.data
      });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export const createProfile = function*({ payload }) {
  try {
    const result = yield call(register, payload);
    if (result && payload.successCallback) {
      payload.successCallback(result);
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export const registerUser = function*({ payload }) {
  try {
    const result = yield call(register, payload);

    if (result && payload.successCallback) {
      payload.successCallback(result);
    }
  } catch (err) {
    console.info("ERR", err);
  }
};
