import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { get } from "lodash";
import * as vodProfileActions from "reduxStore/reducers/vodProfile";
import { redirectToPlay } from "functions/logic-functions";
import RoundCardView from "./components/RoundCardView";

const RoundCard = ({
  id,
  imgUrl,
  item,
  urlRoute,
  title,
  attachUrlID,
  loggedUser,
  history,
  showPlayIcon,
  onOpenPlayModal,
  roundedStyle,
  continue_watching,
  addVodProfile,
  activeEpisodeID,
  useObjectContainPropertyImage,
  useCoverImageSize,
  useResponsiveMinHeight,
  displayParentalLockScreen,
  onDisappearParentalLockScreen,
  continuePlayWithoutResumeModal,
  episodeNumber
}) => {
  const doRedirect =
    get(item, "movie_source.profiles") &&
    item.movie_source.profiles.length === 1 &&
    !item.parental_rating &&
    redirectToPlay;

  const onClick = event => {
    if (displayParentalLockScreen) {
      onDisappearParentalLockScreen();
    }
    if (onOpenPlayModal && doRedirect) {
      event.preventDefault();
      doRedirect({
        VOD: item,
        selectedProfile: item.movie_source.profiles[0],
        addVodProfileDispatch: addVodProfile,
        history,
        continuePlayWithoutResumeModal,
        alreadyEnteredParentalPin: true
      });
    } else if (onOpenPlayModal) {
      event.preventDefault();
      onOpenPlayModal(id);
    }
  };

  return (
    <RoundCardView
      id={id}
      attachUrlID={attachUrlID}
      urlRoute={urlRoute}
      activeEpisodeID={activeEpisodeID}
      loggedUser={loggedUser}
      imgUrl={imgUrl}
      title={title}
      showPlayIcon={showPlayIcon}
      episodeNumber={episodeNumber}
      roundedStyle={roundedStyle}
      useObjectContainPropertyImage={useObjectContainPropertyImage}
      useResponsiveMinHeight={useResponsiveMinHeight}
      useCoverImageSize={useCoverImageSize}
      continue_watching={continue_watching}
      onClick={onClick}
    />
  );
};

RoundCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeEpisodeID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imgUrl: PropTypes.string,
  urlRoute: PropTypes.string.isRequired,
  title: PropTypes.string,
  attachUrlID: PropTypes.bool,
  useCoverImageSize: PropTypes.bool,
  item: PropTypes.object,
  useObjectContainPropertyImage: PropTypes.bool,
  showPlayIcon: PropTypes.bool,
  roundedStyle: PropTypes.bool,
  useResponsiveMinHeight: PropTypes.bool,
  onOpenPlayModal: PropTypes.func,
  continue_watching: PropTypes.shape({
    duration: PropTypes.number,
    stopped_time: PropTypes.number
  })
};

const mapStateToProps = state => ({
  loggedUser: state.loggedUser
});

const mapDispatchToProps = dispatch => ({
  addVodProfile: data => dispatch(vodProfileActions.addVodProfile(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(memo(RoundCard))
);
