import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import style from "./style.module.scss";

const NavLinkComponent = ({ to, translationKey, exact = false }) => (
  <NavLink
    exact={exact}
    to={to}
    className={style.link}
    activeClassName={style.activeNav}
  >
    <FormattedMessage id={translationKey} />
  </NavLink>
);

NavLinkComponent.propTypes = {
  to: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
  exact: PropTypes.bool
};

export default NavLinkComponent;
