const addOrRemoveFromFavouritesFunction = params => {
  const {
    url,
    channel,
    addOrRemoveFromFavouritesDispatch,
    successCallback
  } = params;

  if (channel && channel.id) {
    addOrRemoveFromFavouritesDispatch({
      url,
      body: {
        channel_id: channel.id
      },
      successCallback
    });
  }
};

export default addOrRemoveFromFavouritesFunction;
