import { get } from "lodash";

const getCover = movie => {
  return (
    get(movie, "cover.preview_large") ||
    (movie &&
      movie.covers &&
      movie.covers.length &&
      movie.covers[0].preview_large)
  );
};

export default getCover;
