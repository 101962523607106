import React, { Fragment, memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-flexbox-grid";
import ImageGallery from "components/ImageGallery";
import RoundIconButton from "components/Buttons/RoundIconButton";
import Breadcrumbs from "components/Breadcrumbs";
import PlayIcon from "react-icons/lib/md/play-arrow";
import MovieIcon from "react-icons/lib/md/movie";
import ThumbUpIcon from "react-icons/lib/md/thumb-up";
import ShopIcon from "react-icons/lib/md/shop";
import { isCensorshipCard } from "functions/logic-functions";
import imdbImg from "./images/imdb.png";
import style from "./style.module.scss";
import commonStyle from "../../../../styles/commonPagesAnimationStyle.module.scss";

const MovieDetails = ({
  intl,
  movie,
  breadcrumbsList,
  showAnimationClasses,
  setRent,
  onTrailerClick,
  onOpenPlayModal,
  onOpenRatingStars
}) => {
  const _renderGenres = movie => {
    if (movie.genres && movie.genres.length > 0) {
      return (
        <p>
          <span>
            <FormattedMessage id="common.genres" />:{" "}
          </span>
          {movie.genres.map(item => item.name.trim()).join(", ")}
        </p>
      );
    }
  };

  const _renderShowTypes = movie => {
    if (movie.show_types && movie.show_types.length > 0) {
      return (
        <p>
          <span>
            <FormattedMessage id="common.show_types" />:{" "}
          </span>
          {movie.show_types.map(item => item.name.trim()).join(", ")}
        </p>
      );
    }
  };

  const _renderRatingInformations = movie => {
    return (
      <div className={style.smallerMovieDetails}>
        {movie.rate && movie.rate.rate_average && (
          <p>
            <span>
              <FormattedMessage id="vods.rating" />:
            </span>{" "}
            {movie.rate.rate_average.toString().substring(0, 3)}
          </p>
        )}
        {movie.rating && (
          <Fragment>
            <img src={imdbImg} className={style.imdbLogo} alt={""} />
            <span>
              {" "}
              {movie.rating}
              <span className={style.allRating}>/10</span>
            </span>
          </Fragment>
        )}
      </div>
    );
  };

  const _renderActors = movie => {
    if (movie.actors.length > 0) {
      return (
        <p>
          <span>
            <FormattedMessage id="vods.cast" />:
          </span>{" "}
          {movie.actors.map(item => item.name.trim()).join(", ")}
        </p>
      );
    }
  };

  const _renderRoundIconButton = (onClick, Icon, translationKey) => {
    return (
      <RoundIconButton
        onClick={onClick}
        iconComponent={<Icon />}
        label={intl.formatMessage({
          id: translationKey
        })}
      />
    );
  };

  const _renderRentButtons = () => {
    const { movie_source } = movie;

    if (movie_source && movie_source.profiles) {
      return movie_source.profiles
        .filter(item => !item.subscribed)
        .map(item => (
          <RoundIconButton
            key={item.id}
            onClick={() => {
              setRent(item);
            }}
            iconComponent={<ShopIcon />}
            label={`${intl.formatMessage({
              id: "common.rent"
            })} ${item.name}`}
          />
        ));
    }
  };

  const _renderButtons = movie => {
    const {
      id,
      trailer_source: { url }
    } = movie;

    if (id) {
      return (
        <div className={style.buttonsWrapper}>
          {id && (
            <Fragment>
              {_renderRoundIconButton(onOpenPlayModal, PlayIcon, "common.play")}
              {_renderRoundIconButton(
                onOpenRatingStars,
                ThumbUpIcon,
                "vods.rate"
              )}
            </Fragment>
          )}
          {id && _renderRentButtons()}
          {id && url && (
            <RoundIconButton
              onClick={onTrailerClick}
              iconComponent={<MovieIcon />}
              label={intl.formatMessage({
                id: "vods.trailer"
              })}
            />
          )}
        </div>
      );
    }
  };

  const _renderImageCoverAndGallery = movie => {
    return (
      <Col
        xs={12}
        smOffset={2}
        sm={8}
        md={4}
        mdOffset={0}
        lg={3}
        className={classNames({
          [commonStyle.leftPart]: showAnimationClasses,
          [commonStyle.leftPartReverse]: !showAnimationClasses
        })}
      >
        <img
          className={classNames(style.mainImage, {
            [style.blurCard]: isCensorshipCard(movie)
          })}
          src={movie.cover.preview_ar || movie.cover.medium_ar || ""}
          alt={""}
        />

        {isCensorshipCard(movie)
          ? ""
          : movie &&
            movie.gallery &&
            movie.gallery.length > 0 && (
              <ImageGallery gallery={movie.gallery} />
            )}
      </Col>
    );
  };

  return (
    <div>
      <div className={style.mainHeadWrapper}>
        <Breadcrumbs list={breadcrumbsList} />
        <Row>
          {_renderImageCoverAndGallery(movie)}
          <Col
            xs={12}
            sm={12}
            md={8}
            lg={7}
            className={classNames(style.rightPart, {
              [commonStyle.rightPartAnimation]: showAnimationClasses,
              [commonStyle.rightPartAnimationReverse]: !showAnimationClasses
            })}
          >
            <h1>{movie.title}</h1>
            <div className={style.smallerMovieDetails}>
              <div>
                {movie.year && (
                  <span className={"titleYearDescription"}>{movie.year}</span>
                )}
                {movie.parental_rating !== null && (
                  <span>{movie.parental_rating.code}</span>
                )}
                {movie.runtime && <span>{movie.runtime} min.</span>}
              </div>
              {_renderGenres(movie)}
              {_renderShowTypes(movie)}
              {_renderRatingInformations(movie)}
            </div>
            <p className={style.description}>{movie.description}</p>
            {_renderActors(movie)}
            {_renderButtons(movie)}
          </Col>
        </Row>
      </div>
    </div>
  );
};

MovieDetails.propTypes = {
  intl: PropTypes.object.isRequired,
  movie: PropTypes.object.isRequired,
  breadcrumbsList: PropTypes.array.isRequired,
  showAnimationClasses: PropTypes.bool,
  isCensorshipCard: PropTypes.bool
};

export default memo(MovieDetails);
