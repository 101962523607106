import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import FilterListIcon from "react-icons/lib/md/filter-list";
import {
  parseFromQueryToObject,
  parseObjectToUrl
} from "functions/logic-functions";
import style from "./style.module.scss";

const GenresNavList = ({
  genres,
  selectedGenres,
  onShowNavs,
  location: { pathname, search },
  clearFiltersAndRedirect
}) => {
  const queryStringObject = parseFromQueryToObject(search);

  return (
    <div className={style.navWrapper}>
      <p onClick={clearFiltersAndRedirect}>
        {" "}
        <FormattedMessage id="common.all" />
      </p>
      {genres.map(item => {
        queryStringObject.genres = item.id;

        return (
          <Link
            to={{
              path: pathname,
              search: parseObjectToUrl(queryStringObject)
            }}
            key={item.id}
            className={classNames({
              [style.active]: parseInt(selectedGenres) === parseInt(item.id)
            })}
          >
            {item.name}
          </Link>
        );
      })}
      <button onClick={onShowNavs}>
        <FilterListIcon />
      </button>
    </div>
  );
};

GenresNavList.propTypes = {
  genres: PropTypes.array.isRequired,
  selectedGenres: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string
  }).isRequired,
  onShowNavs: PropTypes.func.isRequired,
  clearFiltersAndRedirect: PropTypes.func.isRequired
};

export default memo(GenresNavList);
