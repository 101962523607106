import axios from "./axios";

const fetchMultipleGetRequests = ({ url, urls }) => {
  urls = urls || url;

  if (urls && urls.length) {
    return axios
      .all([
        ...urls.map(item => {
          return axios.get(item);
        })
      ])
      .then(response => {
        if (response) return response;
        else throw new Error();
      })
      .catch(err => {
        console.info("FETCH ERROR", err);
        throw err;
      });
  }
};

export default fetchMultipleGetRequests;
