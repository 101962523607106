import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import StyledComponents from "styled-components";
import { FormattedMessage } from "react-intl";
import PlayIcon from "react-icons/lib/md/play-arrow";
import DotsHoriz from "react-icons/lib/md/more-horiz";
import { isCensorshipCard } from "functions/logic-functions";
import style from "./style.module.scss";

const SingleSlideView = ({
  item,
  originalImageSource,
  imgSource,
  redirectToDetailsPage,
  onSlideClickPlay
}) => {
  return (
    <SlideWrapper imgSource={originalImageSource || imgSource}>
      <img
        src={originalImageSource || imgSource}
        style={{
          transition: "filter 500ms ease",
          filter: originalImageSource ? "blur(0px)" : "blur(10px)"
        }}
        className={classNames({
          [style.blurCard]: isCensorshipCard(item)
        })}
        alt={""}
      />
      <div className={style.content}>
        <div className="support-rtl">
          <h1>{item.title}</h1>
          {item.description && (
            <p className={style.description}>{item.description}</p>
          )}
          <div className={style.buttonsWrapper}>
            {item.id && parseInt(item.type) !== 2 && (
              <div
                onClick={() => {
                  onSlideClickPlay(item.type, item.model_id);
                }}
              >
                <div className={style.iconWrapper}>
                  <div>
                    <PlayIcon />
                  </div>
                </div>
                <p>
                  <FormattedMessage id="common.play" />
                </p>
              </div>
            )}
            {item.id && item.type !== 3 && (
              <div onClick={() => redirectToDetailsPage(item)}>
                <div className={style.iconWrapper}>
                  <div>
                    <DotsHoriz />
                  </div>
                </div>
                <p>
                  <FormattedMessage id="common.moreInfo" />
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </SlideWrapper>
  );
};

const SlideWrapper = StyledComponents.div`
	height: 100%;
`;

SingleSlideView.propTypes = {
  item: PropTypes.object.isRequired,
  imgSource: PropTypes.string,
  originalImageSource: PropTypes.string,
  redirectToDetailsPage: PropTypes.func.isRequired,
  onSlideClickPlay: PropTypes.func.isRequired
};

export default memo(SingleSlideView);
