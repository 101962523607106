import { put, call } from "redux-saga/effects";
import {
  AUTHORIZE_SUCCESS,
  AUTHORIZE_FAILED
} from "reduxStore/reducers/loggedUser";
import { isAuthorized } from "helper-functions/http-functions";

const authSaga = function*({ payload }) {
  try {
    const result = yield call(isAuthorized, payload);

    if (result && result.token) {
      const cachedData =
        (localStorage.getItem("wecastUser") &&
          JSON.parse(localStorage.getItem("wecastUser"))) ||
        {};

      yield put({
        type: AUTHORIZE_SUCCESS,
        payload: Object.assign({}, cachedData, { token: result.token })
      });
      console.info("AUTH DONE");
    } else yield put({ type: AUTHORIZE_FAILED });
  } catch (err) {
    yield put({ type: AUTHORIZE_FAILED });
    console.info("ERR", err);
  }
};

export default authSaga;
