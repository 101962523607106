import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import style from "./style.module.scss";

const ChannelCard = ({
  item,
  id,
  imgUrl,
  urlRoute,
  title,
  attachUrlID,
  loggedUser,
  match,
  useObjectContainPropertyImage
}) => {
  let imgClass = title ? style.imgWithTitle : style.img;
  const goTo = attachUrlID ? `${urlRoute}/${id}` : urlRoute;

  imgClass = useObjectContainPropertyImage
    ? `${imgClass} channelImg`
    : imgClass;

  const renderSubscribedProfiles = () => {
    if (item && item.profiles) {
      return item.profiles
        .filter(item => item.subscribed)
        .map(profile => (
          <span key={profile.id} className={style.subscribedProfilesNames}>
            {profile.name}
          </span>
        ));
    }
  };

  return (
    <div className={style.mainWrapper}>
      {loggedUser &&
        loggedUser.account &&
        loggedUser.account.subscription_expired && (
          <div className={style.expiredSubscriptionOverlay} />
        )}
      <div className={style.subscribedProfiles}>
        {renderSubscribedProfiles()}
      </div>
      <Link
        to={{
          pathname: goTo,
          state: {
            cameFrom: match.url
          }
        }}
      >
        <img src={imgUrl} className={imgClass} alt={""} />
        {title && <h3>{title}</h3>}
      </Link>
    </div>
  );
};

ChannelCard.defaultProps = {
  attachUrlID: true
};

ChannelCard.propTypes = {
  item: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgUrl: PropTypes.string.isRequired,
  urlRoute: PropTypes.string.isRequired,
  title: PropTypes.string,
  attachUrlID: PropTypes.bool,
  match: PropTypes.object.isRequired,
  useObjectContainPropertyImage: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser
  };
}

export default withRouter(connect(mapStateToProps)(ChannelCard));
