import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import MdSettings from "react-icons/lib/md/settings";
import MdThumbUp from "react-icons/lib/md/thumb-up";
import MuteOff from "react-icons/lib/md/volume-up";
import MuteOn from "react-icons/lib/md/volume-off";
import FullScreenIcon from "react-icons/lib/md/fullscreen";
import FullScreenExitIcon from "react-icons/lib/md/fullscreen-exit";
import PlayIcon from "react-icons/lib/md/play-arrow";
import PauseIcon from "react-icons/lib/md/pause";
import FastWRewindIcon from "react-icons/lib/md/fast-rewind";
import FastWForwardIcon from "react-icons/lib/md/fast-forward";
import MdSkipNext from "react-icons/lib/md/skip-next";
import MdSkipPrevious from "react-icons/lib/md/skip-previous";
import MdLock from "react-icons/lib/md/lock";
import PictureInPicture from "react-icons/lib/md/picture-in-picture-alt";
import * as floatingPlayerActions from "reduxStore/reducers/floatingPlayer";
import Popover from "antd/lib/popover";
import Slider from "antd/lib/slider";

import style from "../style.module.scss";
import "../style/style.scss";

class PlayerControlsButtons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openParentalModal: false
    };
  }

  _onMinimize = () => {
    const { setPlayerSize } = this.props;
    setPlayerSize("minimize");
  };

  render() {
    const {
      // playbackRate,
      seekForward,
      playing,
      playPause,
      seekBackwards,
      onClickFullscreen,
      isFullscreen,
      onMute,
      muted,
      onSettingsPannel,
      onOpenStars,
      settingButtonID,
      isEpisode,
      onNextOrPreviousEpisode,
      displayParentalLockScreen,
      onLockClick,
      volume,
      onVolumeChange,
      showControls
    } = this.props;

    return (
      <div className={style.buttonContainer}>
        <div
          className={style.playerIcon}
          style={{
            justifyContent: displayParentalLockScreen
              ? "center"
              : "space-between"
          }}
        >
          {
            <div
              id={settingButtonID}
              className={classNames({
                [style.outerDiv]:
                  !isEpisode || (isEpisode && !displayParentalLockScreen),
                [style.hiddenControls]: !onSettingsPannel,
                [style.noneDisplay]: isEpisode && displayParentalLockScreen
              })}
            >
              <div
                className={classNames({
                  [style.innerDiv]:
                    !isEpisode || (isEpisode && !displayParentalLockScreen)
                })}
                onClick={onSettingsPannel}
              >
                <MdSettings />
              </div>
            </div>
          }
          <div
            className={classNames({
              [style.outerDiv]:
                (isEpisode && !displayParentalLockScreen) || !isEpisode,
              [style.noneDisplay]: isEpisode && displayParentalLockScreen
            })}
          >
            <div
              className={classNames({
                [style.innerDiv]:
                  (isEpisode && !displayParentalLockScreen) || !isEpisode
              })}
              onClick={onOpenStars}
            >
              <MdThumbUp />
            </div>
          </div>
          <div
            className={classNames({
              [style.outerDiv]:
                (isEpisode && !displayParentalLockScreen) || !isEpisode,
              [style.noneDisplay]: isEpisode && displayParentalLockScreen
            })}
          >
            <div
              className={classNames({
                [style.innerDiv]:
                  (isEpisode && !displayParentalLockScreen) || !isEpisode
              })}
              onClick={seekBackwards}
            >
              <FastWRewindIcon />
            </div>
          </div>
          <div
            className={classNames({
              [style.outerDiv]: isEpisode,
              [style.noneDisplay]: !isEpisode
            })}
          >
            <div
              className={classNames({
                [style.innerDiv]: isEpisode
              })}
              onClick={() => {
                if (onNextOrPreviousEpisode) onNextOrPreviousEpisode(-1);
              }}
            >
              <MdSkipPrevious />
            </div>
          </div>

          {isEpisode && displayParentalLockScreen && (
            <div className={style.outerDiv}>
              <div className={style.innerDiv} onClick={onLockClick}>
                <MdLock isEnabled={true} style={{ color: "white" }} />
              </div>
            </div>
          )}
          <div
            className={classNames({
              [style.episodeOuterPlayButton]:
                isEpisode && !displayParentalLockScreen,
              [style.outerPlayButton]: !isEpisode,
              [style.noneDisplay]: isEpisode && displayParentalLockScreen
            })}
          >
            <div
              className={classNames({
                [style.episodeInnerPlayButton]:
                  isEpisode && !displayParentalLockScreen,
                [style.innerPlayButton]: !isEpisode
              })}
              onClick={playPause}
              style={{ color: "white" }}
            >
              {playing ? <PauseIcon /> : <PlayIcon />}
            </div>
          </div>
          <div
            className={classNames({
              [style.outerDiv]: isEpisode,
              [style.noneDisplay]: !isEpisode
            })}
          >
            <div
              className={classNames({
                [style.innerDiv]: isEpisode
              })}
              onClick={() => {
                if (onNextOrPreviousEpisode) {
                  onNextOrPreviousEpisode(1);
                }
              }}
            >
              <MdSkipNext />
            </div>
          </div>

          <div
            className={classNames({
              [style.outerDiv]:
                (isEpisode && !displayParentalLockScreen) || !isEpisode,
              [style.noneDisplay]: isEpisode && displayParentalLockScreen
            })}
          >
            <div
              className={`${
                isEpisode && !displayParentalLockScreen ? style.innerDiv : ""
              } ${!isEpisode ? style.innerDiv : ""}`}
              onClick={seekForward}
            >
              <FastWForwardIcon />
              {/*{playbackRate != 1 && (*/}
              {/*<p className={style.controlText}>*/}
              {/*{playbackRate}*/}
              {/*</p>*/}
              {/*)}*/}
            </div>
          </div>
          <div
            className={classNames({
              [style.outerDiv]:
                (isEpisode && !displayParentalLockScreen) || !isEpisode,
              [style.noneDisplay]: isEpisode && displayParentalLockScreen
            })}
          >
            {showControls && (
              <Popover
                placement="top"
                content={
                  <div>
                    <div className={style.fakeArea}></div>
                    <div className={style.sliderWrap}>
                      <Slider
                        vertical
                        step={10}
                        defaultValue={parseInt(volume * 100)}
                        onChange={value => onVolumeChange(0.01 * value)}
                      />
                    </div>
                  </div>
                }
                trigger="hover"
              >
                <div
                  className={classNames({
                    [style.innerDiv]:
                      (isEpisode && !displayParentalLockScreen) || !isEpisode
                  })}
                  onClick={onMute}
                >
                  {muted ? <MuteOn /> : <MuteOff />}
                </div>
              </Popover>
            )}
          </div>

          <div className={style.flexWrapper}>
            <div
              className={classNames({
                [style.outerDiv]:
                  (isEpisode && !displayParentalLockScreen) || !isEpisode,
                [style.noneDisplay]: isEpisode && displayParentalLockScreen
              })}
            >
              <div
                className={classNames({
                  [style.innerDiv]:
                    (isEpisode && !displayParentalLockScreen) || !isEpisode
                })}
                onClick={this._onMinimize}
              >
                <PictureInPicture />
              </div>
            </div>

            <div
              className={classNames({
                [style.outerDiv]:
                  (isEpisode && !displayParentalLockScreen) || !isEpisode,
                [style.noneDisplay]: isEpisode && displayParentalLockScreen
              })}
            >
              <div
                className={classNames({
                  [style.innerDiv]:
                    (isEpisode && !displayParentalLockScreen) || !isEpisode
                })}
                onClick={onClickFullscreen}
              >
                {isFullscreen ? <FullScreenExitIcon /> : <FullScreenIcon />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PlayerControlsButtons.propTypes = {
  player: PropTypes.object,
  volume: PropTypes.number.isRequired,
  playbackRate: PropTypes.number.isRequired,

  muted: PropTypes.bool.isRequired,
  playing: PropTypes.bool.isRequired,
  displayParentalLockScreen: PropTypes.bool,
  hasNextSubscribedEpisode: PropTypes.bool,
  isEpisode: PropTypes.bool,
  isLastFiveSeconds: PropTypes.bool,
  isFullscreen: PropTypes.bool.isRequired,

  onMute: PropTypes.func.isRequired,
  onVolumeChange: PropTypes.func.isRequired,
  seekForward: PropTypes.func.isRequired,
  playPause: PropTypes.func.isRequired,
  seekBackwards: PropTypes.func.isRequired,
  onSettingsPannel: PropTypes.func,
  onThumbs: PropTypes.func.isRequired,
  onLockClick: PropTypes.func,
  onNextOrPreviousEpisode: PropTypes.func
};

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser,
    cachedSingleTvShowsCarousels: state.cachedSingleTvShowsCarousels
  };
}

const mapDispatchToProps = dispatch => ({
  setPlayerSize: data => dispatch(floatingPlayerActions.setPlayerSize(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PlayerControlsButtons)
);
