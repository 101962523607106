import React, { useEffect, memo, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import StarIcon from "react-icons/lib/md/star";
import StarBorderIcon from "react-icons/lib/md/star-border";
import ReactDOM from "react-dom";
import style from "./style.module.scss";

const modalRoot = document.getElementById("modal-root");
const body = document.querySelector("body");

const RatingStars = props => {
  const { isOpen, onClose, onRate, vodType, intl } = props;
  const [selectedStar, setSelectedStar] = useState(0);

  const setAttributes = () => {
    body.classList.add("dimmable", "dimmed", "blurring");
    modalRoot.classList.add(style.portalRoot);
  };
  const removeAttributes = () => {
    body.classList.remove("dimmable", "dimmed", "blurring");
    modalRoot.classList.remove(style.portalRoot);
  };

  useEffect(() => {
    if (isOpen) {
      setAttributes();
    } else {
      removeAttributes();
      document.removeEventListener("keyup", setKeyboardEvent);
      setSelectedStar(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("keyup", setKeyboardEvent);
    return () => {
      document.removeEventListener("keyup", setKeyboardEvent);
      removeAttributes();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setKeyboardEvent = e => {
    e.stopPropagation();

    if (e.code === "Escape") {
      onClose();
    }
  };

  const renderStars = () => {
    const starsArray = [];
    for (let i = 1; i < 6; i++) {
      const StarComponent = selectedStar <= i ? StarBorderIcon : StarIcon;

      starsArray.push(
        <StarComponent
          key={i}
          onClick={e => {
            rateContent(e, i);
          }}
        />
      );
    }
    return starsArray;
  };

  const rateContent = (e, i) => {
    e.stopPropagation();
    setSelectedStar(i + 1);
    setTimeout(() => {
      onRate(i);
      onClose();
    }, 800);
  };

  const getVodTranslationValue = () => {
    switch (vodType) {
      case 0:
        return intl.formatMessage({ id: "common.movie" });
      case 1:
        return intl.formatMessage({ id: "common.episode" });
      case 2:
        return intl.formatMessage({ id: "common.tvShow" });
      default:
        return;
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={style.portal} onClick={onClose}>
      <div className={style.portalWrapper}>
        <div className={style.starsWrapper}>
          <h2 className={style.title}>
            <FormattedMessage
              id="vods.rateThisVod"
              values={{ vodType: getVodTranslationValue() }}
            />
          </h2>
          {renderStars()}
        </div>
      </div>
    </div>,
    modalRoot
  );
};

RatingStars.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  vodType: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onRate: PropTypes.func.isRequired
};

export default memo(RatingStars);
