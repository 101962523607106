const parseFromQueryToObject = query => {
  if (query) {
    if (query.startsWith("?")) {
      query = query.slice(1);
    }

    try {
      const result = JSON.parse(
        '{"' +
          decodeURI(query)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );

      return result;
    } catch (e) {
      return {};
    }
  }
  return {};
};

export default parseFromQueryToObject;
