import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { get } from "lodash";
import * as actions from "reduxStore/actions";
import ActiveBannerView from "./components/ActiveBannerView";
import { activeBannerIsValid } from "functions/logic-functions";

class ActiveBanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      banner: null
    };
  }

  async componentDidMount() {
    const { url, getBanner } = this.props;

    getBanner({
      url: url,
      successCallback: this.successCallback
    });
  }

  async componentWillReceiveProps(nextProps) {
    const { paramsId, url, getBanner } = this.props;
    const { paramsId: nextParamsId } = nextProps;

    if (paramsId && nextParamsId && paramsId !== nextParamsId) {
      getBanner({
        url: url,
        successCallback: this.successCallback
      });
    }
  }

  successCallback = response => {
    const { url } = this.props;

    if (response) {
      this.setState({
        banner: activeBannerIsValid(url, get(response, "box_position"))
          ? response
          : false
      });
    }
  };

  onClose = event => {
    const { socket } = this.props;
    const {
      banner: { mongo_id }
    } = this.state;

    event.stopPropagation();
    if (socket && socket.connected && mongo_id) {
      socket.emit("ads-banner", {
        mongo_id,
        clicked: true,
        closed: true
      });
    }
    this.setState({
      banner: null
    });
  };

  onClick = e => {
    const { banner } = this.state;

    if (!banner.target_url) {
      e.preventDefault();
    }
  };

  render() {
    const { banner } = this.state;
    const { useParentContainer, useNestedBanner } = this.props;

    if (!banner) return null;
    return (
      <ActiveBannerView
        banner={banner}
        useParentContainer={useParentContainer}
        useNestedBanner={useNestedBanner}
        onClick={this.onClick}
        onClose={this.onClose}
      />
    );
  }
}

ActiveBanner.propTypes = {
  url: PropTypes.string,
  useParentContainer: PropTypes.bool,
  paramsId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  useNestedBanner: PropTypes.bool //Property for using banner inside movies/tv-shows grid list
};

const mapStateToProps = state => ({
  socket: state.socket
});

const mapDispatchToProps = dispatch => ({
  getBanner: data => dispatch(actions.getBanner(data))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActiveBanner)
);
