import {
  ADD_FAVOURITE_CHANNEL,
  ADD_REMINDERS_URL,
  DELETE_REMINDER_URL,
  EDIT_REMINDER_URL,
  EPISODE_RATE_URL,
  MOVIE_RATE_URL,
  REMOVE_CHANNELS_FROM_FAVORITES_URL,
  RENT_CHANNEL_URL,
  RENT_VOD_URL,
  SHOW_RATE_URL,
  UPDATE_PROFILE_URL,
  UPDATE_SUBSCRIPTION_URL
} from "../../API-routes";
import openNotificationWithIcon from "./openNotificationWithIcon";

const checkEndpointsForNotifications = response => {
  const {
    data: { status, message }
  } = response;

  const endpointsToCheck = [
    SHOW_RATE_URL,
    MOVIE_RATE_URL,
    EPISODE_RATE_URL,
    RENT_VOD_URL,
    RENT_CHANNEL_URL,
    ADD_FAVOURITE_CHANNEL,
    UPDATE_PROFILE_URL,
    REMOVE_CHANNELS_FROM_FAVORITES_URL,
    UPDATE_SUBSCRIPTION_URL,
    ADD_REMINDERS_URL,
    DELETE_REMINDER_URL,
    EDIT_REMINDER_URL
  ];

  endpointsToCheck.forEach(item => {
    if (response.request.responseURL.includes(item) && message) {
      openNotificationWithIcon({
        type: status ? "success" : "error",
        message: message
      });
    }
  });
};

export default checkEndpointsForNotifications;
