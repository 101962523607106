import { call } from "redux-saga/effects";
import { fetchData } from "helper-functions/http-functions";

const rateVOD = function*({ payload }) {
  try {
    yield call(fetchData, payload);
  } catch (err) {
    console.log(err);
  }
};

export default rateVOD;
