import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import style from "./style.module.scss";

class Breadcrumbs extends PureComponent {
  render() {
    return (
      <div>
        <ul className={`${style.breadcrumbsList} singlePageBreadcrumbs`}>
          {this.props.list.map(item => {
            return (
              <li key={item.title}>
                {(item.url && <Link to={item.url}>{item.title}</Link>) || (
                  <span>{item.title}</span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

Breadcrumbs.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

export default Breadcrumbs;
