import { get } from "lodash";

//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onBuffer = function(err) {
  const { movie } = this.state;
  const { socket } = this.props;

  this.setState({
    showLoader: true
  });

  if (
    typeof err === "string" &&
    socket &&
    socket.connected &&
    get(movie, "id")
  ) {
    socket.emit("play-issues", {
      buffer: true,
      crash: false,
      record_model: "SingleEventVod",
      model_id: get(movie, "id"),
      model_type: 0
    });
  }
};

export default onBuffer;
