import { put, call } from "redux-saga/effects";
import { GET_SEASON_EPISODES_SUCCESS } from "reduxStore/reducers/cachedSingleTvShowsCarousels";
import { fetchData } from "helper-functions/http-functions";

const getSeasonEpisodes = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result) {
      yield put({
        type: GET_SEASON_EPISODES_SUCCESS,
        payload: {
          id: payload.id,
          name: payload.name,
          items: result.items
        }
      });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export default getSeasonEpisodes;
