export const ADD_FILTERED_GENRES_FOR_SEARCH_PAGE =
  "ADD_FILTERED_GENRES_FOR_SEARCH_PAGE";

const filteredGenresForSearchPage = (state = [], action) => {
  switch (action.type) {
    case ADD_FILTERED_GENRES_FOR_SEARCH_PAGE:
      if (action.payload) {
        if (action.payload.data && action.payload.data === "clear_list")
          return [];
        if (state.length) {
          if (state.includes(action.payload.data))
            return state.filter(item => item !== action.payload.data);
          else return [...state, action.payload.data];
        } else return [...state, action.payload.data];
      }
      return [];
    default:
      return state;
  }
};

export default filteredGenresForSearchPage;

export const addFilteredGenresForSearchPage = payload => {
  return {
    type: ADD_FILTERED_GENRES_FOR_SEARCH_PAGE,
    payload: {
      data: payload.data
    }
  };
};
