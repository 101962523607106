export const GET_REMINDERS = "GET_REMINDERS";
export const GET_REMINDERS_SUCCESS = "GET_REMINDERS_SUCCESS";

const remindersList = (state = [], action) => {
  switch (action.type) {
    case GET_REMINDERS_SUCCESS:
      if (action.payload) return action.payload;
      return state;
    default:
      return state;
  }
};

export default remindersList;

export const getReminders = data => ({
  type: GET_REMINDERS,
  payload: {
    url: data.url
  }
});
