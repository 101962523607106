import React, { memo, useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Slider from "react-slick";
import { get, debounce } from "lodash";
import EpisodeCard from "../../Cards/EpisodeCard";
import { getMoviesNewReleases } from "reduxStore/actions";
import { CarouselWrapper } from "./styledComponents";
import Arrow from "../components/Arrow";

const SeasonCarousel = props => {
  const { title, items, useWhiteControlArrows, activeEpisodeID } = props;
  const [isLastIndex, setIsLastIndex] = useState(null);
  const [isInfinite, setIsInfinite] = useState(false);
  const sliderRef = useRef(null);

  const onAfterChange = index => {
    const isInfinite = sliderRef.current.props.infinite;
    const itemsNumber = sliderRef.current.innerSlider.track.props.slideCount;
    const showPerSlideNumber =
      sliderRef.current.innerSlider.track.props.slidesToShow;
    const maxSlides = index + showPerSlideNumber >= itemsNumber;

    setIsLastIndex(maxSlides && !isInfinite);
  };

  const settings = {
    infinite: items.length > 5,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    arrows: true,
    nextArrow: <Arrow icon="right" color={props.arrowsColor} />,
    prevArrow: <Arrow icon="left" color={props.arrowsColor} />,
    afterChange: onAfterChange,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const _moveSlide = () => {
    const index = items.findIndex(
      item => item.id === parseInt(activeEpisodeID)
    );

    if (index !== -1) {
      sliderRef.current.slickGoTo(index);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResized);
    setIsInfinite(items.length > 6 || get(sliderRef, "current.props.infinite"));
    return () => {
      onWindowResized.cancel();
      window.removeEventListener("resize", onWindowResized);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(_moveSlide, []);

  useEffect(_moveSlide, [activeEpisodeID, items]);

  const onWindowResized = debounce(() => {
    sliderRef.current.slickGoTo(0);
    setIsLastIndex(false);
  }, 250);

  const _renderEpisodes = () => {
    const {
      urlRoute,
      onChangePlaying,
      isResumePlayerOff,
      onDisplaySelectProfileToPlayModal,
      onDisplayParentalModal,
      intl
    } = props;

    if (items && Array.isArray(items)) {
      return items.map(item => {
        return (
          <div key={item.id}>
            <EpisodeCard
              intl={intl}
              item={item}
              id={item.id}
              imgUrl={
                get(item, "cover.medium_ar") || get(item, "covers[0].medium_ar")
              }
              urlRoute={`${urlRoute}/${item.id}`}
              title={item.title}
              episodeNumber={item.episode_number}
              activeEpisodeID={activeEpisodeID}
              isResumePlayerOff={isResumePlayerOff}
              onChangePlaying={onChangePlaying}
              onDisplaySelectProfileToPlayModal={
                onDisplaySelectProfileToPlayModal
              }
              onDisplayParentalModal={onDisplayParentalModal}
            />
          </div>
        );
      });
    }
    return [];
  };

  return (
    <div>
      <CarouselWrapper
        useWhiteControlArrows={useWhiteControlArrows}
        isLastIndex={isLastIndex}
        isInfinite={isInfinite}
      >
        {title && <h2>{title}</h2>}
        <Slider ref={sliderRef} {...settings}>
          {_renderEpisodes()}
        </Slider>
      </CarouselWrapper>
    </div>
  );
};

SeasonCarousel.propTypes = {
  title: propTypes.string,
  urlRoute: propTypes.string.isRequired,
  arrowsColor: propTypes.string,
  items: propTypes.array.isRequired,
  activeEpisodeID: propTypes.oneOfType([propTypes.number, propTypes.string]),
  useWhiteControlArrows: propTypes.bool,
  isResumePlayerOff: propTypes.bool,
  onChangePlaying: propTypes.func,
  intl: propTypes.object.isRequired,
  onDisplaySelectProfileToPlayModal: propTypes.func,
  onDisplayParentalModal: propTypes.func
};

function matchDispatchToProps(dispatch) {
  return {
    getMoviesNewReleasesDispatch: data => dispatch(getMoviesNewReleases(data))
  };
}

function mapStateToProps(state) {
  return {
    loggedUser: state.loggedUser
  };
}

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(memo(SeasonCarousel))
);
