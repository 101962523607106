import React, { Component } from "react";
import { withRouter } from "react-router";
import { get } from "lodash";
import classNames from "classnames";
import ScrollTopHOC from "HOCs/ScrollTopHOC";
import { connect } from "react-redux";
import VodsGridList from "components/VodsGridList";
import SearchFilters from "components/SearchFilters";
import { Loader } from "semantic-ui-react";
import { getVodGenres } from "reduxStore/reducers/genres";
import NotFoundResultsComponent from "./components/NotFoundResultsComponent";
import MoviesListComponent from "./components/MoviesListComponent";
import {
  appendTextFilterSearchResults,
  getTextSearchFilterVods
} from "reduxStore/reducers/textSearchVODS";
import {
  SINGLE_VOD,
  GET_MVOD_LIST_URL,
  GET_VOD_GENRES_URL,
  GET_CHANNELS_WITH_PAGER
} from "API-routes";
import ShowTypes from "components/ShowTypes";
import {
  parseFromQueryToObject,
  parseObjectToEndpointQuery
} from "functions/logic-functions";
import { injectIntl } from "react-intl";
import style from "./style.module.scss";

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoviesLoader: false,
      showTvShowsLoader: false
    };
  }

  componentDidMount() {
    const { getVodGenresDispatch, genres } = this.props;

    if (!genres.length) {
      getVodGenresDispatch({
        url: GET_VOD_GENRES_URL
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { search }
    } = this.props;
    const {
      location: { search: nextSearch }
    } = nextProps;
    const searchQueryObject = parseFromQueryToObject(search);
    const nextSearchQueryObject = parseFromQueryToObject(nextSearch);

    if (
      searchQueryObject.genres !== nextSearchQueryObject.genres ||
      searchQueryObject["show-types"] !== nextSearchQueryObject["show-types"] ||
      (searchQueryObject.genres !== nextSearchQueryObject["show-types"] &&
        searchQueryObject["show-types"] !== nextSearchQueryObject["show-types"])
    ) {
      this.setState(
        {
          showLoader: true
        },
        () => {
          this.fetchData(
            nextSearchQueryObject.genres,
            nextSearchQueryObject["show-types"]
          );
        }
      );
    }
  }

  _getEndpointQueryFromObject = (object, type) => {
    return `${parseObjectToEndpointQuery(object)}${
      type !== undefined ? `&filter[type]=${type}` : ""
    }`;
  };

  fetchData = (genres, showTypes) => {
    const { searchText, getTextSearchFilterVodsDispatch } = this.props;
    const queryObject = {
      genres,
      title: searchText,
      "show-types": showTypes
    };

    getTextSearchFilterVodsDispatch({
      urls: [
        `${SINGLE_VOD}${this._getEndpointQueryFromObject(queryObject, 0)}`,
        `${GET_MVOD_LIST_URL}${this._getEndpointQueryFromObject(queryObject)}`,
        `${GET_CHANNELS_WITH_PAGER}?filter[title]=${searchText}`
      ]
    });
  };

  fetchMoreMovies = () => {
    const {
      appendTextFilterSearchResultsDispatch,
      searchText,
      filteredGenresForSearchPage
    } = this.props;
    const query = filteredGenresForSearchPage
      .map((item, index) => `filter[genre_id][${index}]=${item}`)
      .join("&");

    if (searchText) {
      this.setState(
        {
          showMoviesLoader: true
        },
        () => {
          appendTextFilterSearchResultsDispatch({
            url: `${SINGLE_VOD}?filter[title]=${searchText}&filter[type]=0&limit=200&${query}`,
            type: "movies"
          });
        }
      );
    }
  };

  fetchMoreTvShows = () => {
    const {
      appendTextFilterSearchResultsDispatch,
      searchText,
      filteredGenresForSearchPage
    } = this.props;
    const query = filteredGenresForSearchPage
      .map((item, index) => `filter[genre_id][${index}]=${item}`)
      .join("&");

    if (searchText) {
      appendTextFilterSearchResultsDispatch({
        url: `${GET_MVOD_LIST_URL}?filter[title]=${searchText}&limit=200&${query}`,
        type: "tvShows"
      });
    }
  };

  resultsNotFound = () => {
    const { searchText } = this.props;

    return <NotFoundResultsComponent searchText={searchText} />;
  };

  _renderChannels = intl => {
    const { textSearchVODS } = this.props;

    if (get(textSearchVODS, "channels") && textSearchVODS.channels.length > 0) {
      return (
        <div className={style.sectionBlock}>
          <h2>
            {intl.formatMessage({
              id: "common.channels"
            })}
          </h2>
          <VodsGridList
            intl={intl}
            useExternalRoute={true}
            list={
              {
                items: textSearchVODS.channels
              } || {}
            }
            channelsWithTitle={true}
          />
        </div>
      );
    }
  };

  _renderTvShows = intl => {
    const { textSearchVODS, searchText } = this.props;
    const { showTvShowsLoader } = this.state;

    if (get(textSearchVODS, "tvShows") && textSearchVODS.tvShows.length > 0) {
      return (
        <div className={style.sectionBlock}>
          <h2>
            {intl.formatMessage({
              id: "common.tvShows"
            })}
          </h2>
          <VodsGridList
            intl={intl}
            useExternalRoute={true}
            list={{ items: textSearchVODS.tvShows }}
          />
          {searchText && textSearchVODS.tvShows.length >= 20 && (
            <span onClick={this.fetchMoreTvShows}>
              {intl.formatMessage({
                id: "common.viewMore"
              })}
            </span>
          )}
          {showTvShowsLoader && <Loader size="mini" active={true} />}
        </div>
      );
    }
  };

  _renderMovies = () => {
    const { showMoviesLoader } = this.state;
    const { textSearchVODS, searchText, intl } = this.props;

    if (get(textSearchVODS, "movies") && textSearchVODS.movies.length > 0) {
      return (
        <MoviesListComponent
          intl={intl}
          fetchMoreMovies={this.fetchMoreMovies}
          showMoviesLoader={showMoviesLoader}
          moviesList={textSearchVODS.movies}
          searchText={searchText}
        />
      );
    }
  };

  onLoaderShow = showLoader => {
    this.setState({
      showLoader
    });
  };

  render() {
    const { textSearchVODS, searchText } = this.props;
    const { intl } = this.props;

    return (
      <div className={classNames(style.container, "topPadding")}>
        {(textSearchVODS.all.length > 0 && (
          <div>
            {searchText && (
              <h2 className={style.title}>
                {`${intl.formatMessage({
                  id: "vods.resultsFor"
                })} ${searchText}`}
              </h2>
            )}
            <SearchFilters visible={true} onLoaderShow={this.onLoaderShow}>
              <ShowTypes />
            </SearchFilters>
            {this._renderMovies()}
            {this._renderTvShows(intl)}
            {this._renderChannels(intl)}
          </div>
        )) ||
          this.resultsNotFound()}
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return {
    appendTextFilterSearchResultsDispatch: data =>
      dispatch(appendTextFilterSearchResults(data)),
    getVodGenresDispatch: data => dispatch(getVodGenres(data)),
    getTextSearchFilterVodsDispatch: data =>
      dispatch(getTextSearchFilterVods(data))
  };
}

function mapStateToProps(state) {
  return {
    textSearchVODS: state.textSearchVODS,
    searchText: state.searchText,
    genres: state.genres,
    filteredGenresForSearchPage: state.filteredGenresForSearchPage,
    vodShowTypes: state.vodShowTypes
  };
}

export default ScrollTopHOC(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(withRouter(injectIntl(SearchPage)))
);
