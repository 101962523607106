import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { get, find } from "lodash";
import classNames from "classnames";
import ChannelCategoriesCarousel from "components/Carousels/ChannelCategoriesCarousel";
import VodsGridList from "components/VodsGridList";
import * as channelsActions from "reduxStore/reducers/channels";
import { GET_CHANNELS_WITH_PAGER, GET_ACTIVE_BANNER } from "API-routes";
import ScrollTopHOC from "HOCs/ScrollTopHOC";
import { generateQuery } from "functions/logic-functions";
import ActiveBanner from "components/ActiveBanner";
import NoContentComponent from "components/NoContentComponent";
import MdTv from "react-icons/lib/md/tv";
import { FormattedMessage, injectIntl } from "react-intl";
import style from "./style.module.scss";

export class ChannelCategoriesPage extends PureComponent {
  constructor(props) {
    super(props);

    this.observer = new IntersectionObserver(this.observerCallback, {
      rootMargin: "0px",
      threshold: 0
    });
    this.currentLastElement = null;
    this.isObservePaused = false;
    this.selectedCategoryTemporaryPager = null;
  }

  componentDidMount() {
    const {
      channels,
      getChannels,
      match: {
        params: { categoryID }
      }
    } = this.props;
    this.currentLastElement = this.getLastElement();

    if (get(channels, "items") && !get(channels, "items").length) {
      let urls = [];
      const defaultFirstRequest = `${GET_CHANNELS_WITH_PAGER}?config[enable_favorites]=1&limit=40`;

      if (categoryID) {
        urls = [
          defaultFirstRequest,
          `${GET_CHANNELS_WITH_PAGER}?config[enable_favorites]=1&filter[category_id]=${categoryID}&limit=40`
        ];
      }

      getChannels({
        url: urls.length ? urls : defaultFirstRequest
      });
    }

    if (this.currentLastElement) {
      this.observer.observe(this.currentLastElement);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      channels: { items },
      location: { pathname }
    } = this.props;
    const {
      location: { pathname: prevPathname }
    } = prevProps;

    if (
      (items &&
        items.length !== get(prevProps, "channels.items") &&
        get(prevProps, "channels.items").length) ||
      pathname !== prevPathname
    ) {
      if (pathname !== prevPathname) {
        this.removeLastElementObserver();
        this.selectedCategoryTemporaryPager = null;
      }

      if (this.currentLastElement !== this.getLastElement()) {
        this.currentLastElement = this.getLastElement();

        if (this.currentLastElement) {
          if (this.isElementInVewport(this.currentLastElement)) {
            this.isObservePaused = true;
            this.appendChannels();
          }

          this.observer.observe(this.currentLastElement);
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.currentLastElement) {
      this.observer.unobserve(this.currentLastElement);
    }
  }

  removeLastElementObserver = () => {
    if (this.currentLastElement) {
      this.observer.unobserve(this.currentLastElement);
      this.currentLastElement = null;
    }
  };

  getLastElement = () => {
    let lastElement = document.querySelector(
      ".gridListWrapper > div:last-child > div:nth-last-child(6)"
    );
    lastElement =
      lastElement ||
      document.querySelector(
        ".gridListWrapper > div:last-child > div:last-child"
      );
    lastElement =
      lastElement ||
      document.querySelector(
        ".gridListWrapper > div:first-child > div:nth-last-child(6)"
      );
    lastElement =
      lastElement ||
      document.querySelector(
        ".gridListWrapper > div:first-child > div:last-child"
      );
    return lastElement;
  };

  isElementInVewport = element => {
    const bounding = element.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth) &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  observerCallback = elements => {
    const {
      channels: { pager }
    } = this.props;

    if (
      elements &&
      elements.length &&
      pager &&
      pager.pageCount >= pager.page + 1
    ) {
      if (elements[0].isIntersecting) {
        this.removeLastElementObserver();

        if (!this.isObservePaused) {
          if (get(this.selectedCategoryTemporaryPager, "pageCount") !== 1) {
            this.appendChannels();
          }
        }
      }
    }
  };

  appendChannels = () => {
    const {
      appendChannelsList,
      channels: { pager },
      match: {
        params: { categoryID }
      }
    } = this.props;

    let queryData = {
      "config[enable_favorites]": 1,
      limit: !categoryID ? 40 : 200,
      page: !categoryID && pager.page ? pager.page + 1 : null,
      "filter[category_id]": categoryID ? categoryID : null
    };

    queryData = generateQuery(queryData);

    appendChannelsList({
      url: `${GET_CHANNELS_WITH_PAGER}${queryData}`,
      ignorePagerFromResult: !!categoryID,
      ignoreSort: true,
      successCallback: pager => {
        this.isObservePaused = false;
        this.selectedCategoryTemporaryPager = categoryID ? pager : null;
      }
    });
  };

  filterChannelsList = (channels, categoryID) => {
    if (Array.isArray(channels.items)) {
      if (!categoryID || categoryID === "all") {
        return channels.items.map(item => {
          item.urlRoute = `/channels`;
          return item;
        });
      } else if (categoryID === "favorite_channels") {
        return channels.items.filter(item => item.favorite);
      } else {
        return channels.items
          .filter(({ channel_categories }) =>
            find(
              channel_categories,
              item => parseInt(item.id) === parseInt(categoryID)
            )
          )
          .map(item => {
            item.urlRoute = `/channels`;
            return item;
          });
      }
    }
    return [];
  };

  render() {
    const {
      intl,
      channels,
      match: {
        params: { categoryID }
      },
      composerConfig: {
        web: {
          modules: { advertisements, channels: channelsModule }
        }
      }
    } = this.props;
    const list = this.filterChannelsList(channels, categoryID);

    return (
      <div
        className={classNames(style.container, "topPadding", {
          [style.fullHeight]:
            !channels ||
            (get(channels, "items") && !get(channels, "items").length)
        })}
      >
        {channelsModule &&
        get(channels, "items") &&
        get(channels, "items").length ? (
          <Fragment>
            <ChannelCategoriesCarousel />
            <VodsGridList intl={intl} list={list} channelsWithTitle={true}>
              {advertisements && (
                <ActiveBanner
                  useParentContainer={true}
                  useNestedBanner={true}
                  url={`${GET_ACTIVE_BANNER}?filter[box_position]=live_tv_box&filter[ads_type]=image`}
                />
              )}
            </VodsGridList>
          </Fragment>
        ) : (
          <NoContentComponent
            noContentIcon={<MdTv />}
            noContentText={
              <FormattedMessage id="common.liveTvContentUnavailable" />
            }
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  channelCategories: state.channelCategories,
  channels: state.channels,
  composerConfig: state.composerConfig
});

const mapDispatchToProps = dispatch => ({
  getChannels: data => dispatch(channelsActions.getChannels(data)),
  appendChannelsList: data => dispatch(channelsActions.appendChannelsList(data))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScrollTopHOC(injectIntl(ChannelCategoriesPage)))
);
