import { put, call } from "redux-saga/effects";
import { APPEND_PAYMENT_HISTORY_SUCCESS } from "reduxStore/reducers/paymentHistory";
import { fetchData } from "helper-functions/http-functions";

const appendPaymentHistory = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result)
      yield put({
        type: APPEND_PAYMENT_HISTORY_SUCCESS,
        payload: result
      });
  } catch (err) {
    console.info("ERR", err);
  }
};

export default appendPaymentHistory;
