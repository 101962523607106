const getInternalAudioSubtitles = player => {
  if (
    player &&
    player.getInternalPlayer("hls") &&
    player.getInternalPlayer("hls").audioTracks
  ) {
    let hlsPlayer = player.getInternalPlayer("hls");
    let audioTracks = hlsPlayer.audioTracks;

    return audioTracks.filter(item => item.lang !== "dubbing");
  }
  return [];
};

export default getInternalAudioSubtitles;
