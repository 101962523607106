import React, { memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { get } from "lodash";
import style from "./style.module.scss";

const ParentalRatingInfoComponent = props => {
  const { vodItem, intl, progress } = props;
  if (!vodItem) return null;

  return (
    <div
      className={classNames(style.parentalRatingWrapper, {
        [style.active]:
          get(vodItem, "parental_rating.code") &&
          progress >= 10 &&
          progress <= 15
      })}
    >
      <div className={style.outerParentalCode}>
        <div className={style.parentalCode}>
          <p>{get(vodItem, "parental_rating.code")}</p>
        </div>
      </div>
      <p className={style.parentalRating}>
        {intl.formatMessage({
          id: "common.parentalRating"
        })}
      </p>
    </div>
  );
};

ParentalRatingInfoComponent.propTypes = {
  progress: PropTypes.number.isRequired,
  vodItem: PropTypes.object,
  intl: PropTypes.object.isRequired
};

export default memo(ParentalRatingInfoComponent);
