import {
  DO_LOGIN,
  CHECK_AUTHORIZATION,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  GET_MOVIES_NEW_RELEASES,
  GET_SINGLE_MOVIE,
  FETCH_MOVIE_STREAM,
  ADD_TO_FAVOURITES,
  ADD_REMINDER,
  RENT_CHANNEL,
  GET_MVOD_LIST,
  RENT_VOD,
  RATE_VOD,
  GET_SUBSCRIPTIONS_ANONYMOUS,
  REGISTER_USER,
  GET_SVOD_LIST,
  UPDATE_SUBSCRIPTION,
  UPDATE_PROFILE,
  CREATE_PROFILE,
  GET_LANGUAGES,
  GET_VIDEO_CAMPAIGN,
  GET_BANNER,
  DELETE_REMINDER,
  EDIT_REMINDER
} from "./events";

export const loginAction = data => {
  return {
    type: DO_LOGIN,
    payload: {
      url: data.url,
      email: data.email,
      password: data.password
    },
    callback: data.callback
  };
};

export const authorizeAction = data => {
  return {
    type: CHECK_AUTHORIZATION,
    payload: {
      url: data.url
    },
    successCallback: data.successCallback,
    failedCallback: data.failedCallback
  };
};

export const forgotPassword = data => {
  return {
    type: FORGOT_PASSWORD,
    payload: {
      url: data.url,
      email: data.email,
      successCallback: data.successCallback
    }
  };
};

export const resetPassword = data => {
  return {
    type: RESET_PASSWORD,
    payload: {
      url: data.url,
      body: data.body,
      successCallback: data.successCallback
    }
  };
};

export const getMoviesNewReleases = data => {
  return {
    type: GET_MOVIES_NEW_RELEASES,
    payload: {
      url: data.url,
      successCallback: data.successCallback
    }
  };
};

export const getSingleMovie = data => {
  return {
    type: GET_SINGLE_MOVIE,
    payload: {
      url: data.url,
      successCallback: data.successCallback
    }
  };
};

export const fetchMovieStream = data => {
  return {
    type: FETCH_MOVIE_STREAM,
    payload: {
      url: data.url,
      successCallback: data.successCallback,
      body: data.body
    }
  };
};

export const addOrRemoveFromFavourites = data => {
  return {
    type: ADD_TO_FAVOURITES,
    payload: {
      url: data.url,
      body: data.body
    }
  };
};

export const addReminder = data => {
  return {
    type: ADD_REMINDER,
    payload: {
      url: data.url,
      body: data.body,
      successCallback: data.successCallback
    }
  };
};

export const rentChannel = data => {
  return {
    type: RENT_CHANNEL,
    payload: {
      url: data.url,
      body: data.body,
      successCallback: data.successCallback
    }
  };
};

export const getMvodList = data => {
  return {
    type: GET_MVOD_LIST,
    payload: {
      url: data.url,
      successCallback: data.successCallback,
      updateSeasonCarouselReducer: data.updateSeasonCarouselReducer
    }
  };
};

export const getSvodList = data => {
  return {
    type: GET_SVOD_LIST,
    payload: {
      url: data.url,
      successCallback: data.successCallback
    }
  };
};

export const rentVOD = data => {
  return {
    type: RENT_VOD,
    payload: {
      url: data.url,
      body: data.body,
      successCallback: data.successCallback
    }
  };
};

export const rateVOD = data => {
  return {
    type: RATE_VOD,
    payload: {
      url: data.url
    }
  };
};

export const getSubscriptionsAnonymous = data => {
  return {
    type: GET_SUBSCRIPTIONS_ANONYMOUS,
    payload: {
      url: data.url,
      successCallback: data.successCallback
    }
  };
};

export const updateSubscription = data => {
  return {
    type: UPDATE_SUBSCRIPTION,
    payload: {
      url: data.url,
      body: data.body
    }
  };
};

export const updateProfile = data => {
  return {
    type: UPDATE_PROFILE,
    payload: {
      url: data.url,
      body: data.body,
      successCallback: data.successCallback
    }
  };
};

export const createProfile = data => {
  return {
    type: CREATE_PROFILE,
    payload: {
      url: data.url,
      body: data.body,
      successCallback: data.successCallback
    }
  };
};

export const registerUser = data => {
  return {
    type: REGISTER_USER,
    payload: {
      url: data.url,
      body: data.body,
      successCallback: data.successCallback
    }
  };
};

export const getLanguages = data => {
  return {
    type: GET_LANGUAGES,
    payload: {
      url: data.url,
      successCallback: data.successCallback
    }
  };
};

export const getVideoCampaign = data => {
  return {
    type: GET_VIDEO_CAMPAIGN,
    payload: {
      url: data.url,
      successCallback: data.successCallback
    }
  };
};

export const getBanner = data => {
  return {
    type: GET_BANNER,
    payload: {
      url: data.url,
      successCallback: data.successCallback
    }
  };
};

export const deleteReminder = data => {
  return {
    type: DELETE_REMINDER,
    payload: {
      url: data.url,
      body: data.body,
      successCallback: data.successCallback
    }
  };
};

export const editReminder = data => {
  return {
    type: EDIT_REMINDER,
    payload: {
      url: data.url,
      body: data.body,
      successCallback: data.successCallback
    }
  };
};
