import { get } from "lodash";

//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onDuration = function(duration) {
  const {
    continuePlayWithoutResumeModal,
    alreadyEnteredParentalPin,
    fromNextEpisode
  } = this.props.location.state || {};
  const {
    openParentalModal,
    movie,
    episode,
    openPlayModal,
    openVideoCampaign,
    activeVideoCampaign
  } = this.state;
  const VOD = episode || movie;

  //If vod is in a continue watching, show on start / resume modal
  if (this.continueWatchVOD && !openPlayModal) {
    const stateData = setStateIfInContinueWatching.call(
      this,
      continuePlayWithoutResumeModal,
      openParentalModal,
      openVideoCampaign
    );
    this.setState(stateData);
  } else if (alreadyEnteredParentalPin || fromNextEpisode) {
    const stateData = setStateIfAlreadyEnteredParentalPin.call(
      this,
      VOD,
      openPlayModal,
      openVideoCampaign,
      activeVideoCampaign
    );
    this.setState(stateData);
  } else {
    this.setState(state => ({
      openParentalModal: get(VOD, "parental_rating.require_pin"),
      playing: !openVideoCampaign
    }));
  }

  this.setState({ duration });
};

const setStateIfAlreadyEnteredParentalPin = function(
  VOD,
  openPlayModal,
  openVideoCampaign,
  activeVideoCampaign
) {
  if (activeVideoCampaign) {
    const PRE_ROLL = 1;
    const { play_rules } = activeVideoCampaign;
    const isIncludedAtStart = play_rules.map(Number).includes(PRE_ROLL);

    return {
      openVideoCampaign: isIncludedAtStart,
      openParentalModal: false,
      playing: openPlayModal || isIncludedAtStart ? false : true,
      prevStatusPlaying: isIncludedAtStart
    };
  }
  return {
    openParentalModal: false,
    playing: !openPlayModal
  };
};

const setStateIfInContinueWatching = function(
  continuePlayWithoutResumeModal,
  openParentalModal,
  openVideoCampaign
) {
  const hasContinueWatching =
    !continuePlayWithoutResumeModal &&
    !openParentalModal &&
    !!get(this.continueWatchVOD, "continue_watching.stopped_time");

  return {
    openVideoCampaign: openVideoCampaign && !hasContinueWatching,
    openStartResumeModal: hasContinueWatching,
    playing: openParentalModal
      ? false
      : continuePlayWithoutResumeModal === true
      ? true
      : !openParentalModal &&
        !!get(this.continueWatchVOD, "continue_watching.stopped_time")
      ? false
      : true,
    openPlayModal: false
  };
};

export default onDuration;
