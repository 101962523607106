import { get } from "lodash";

//Use function instead of arrow functions to get context of state which provides setState which is appended with bind function
const onCloseVideoCampaign = function(e) {
  const {
    prevStatusPlaying,
    openStartResumeModal,
    progress,
    duration,
    watchedVideoCampaigns,
    movie,
    episode
  } = this.state;
  let obj = {};
  const VOD = movie || episode;

  if (e) e.stopPropagation();

  if (prevStatusPlaying && !openStartResumeModal) {
    obj.playing = !!prevStatusPlaying;
  } else if (
    progress < 1 &&
    !prevStatusPlaying &&
    !openStartResumeModal &&
    !get(VOD, "parental_rating.require_pin")
  ) {
    obj.playing = true;
  }

  const data = setAdditionalStateParams(
    progress,
    duration,
    watchedVideoCampaigns
  );
  obj = { ...obj, ...data };

  this.setState(
    {
      openVideoCampaign: false,
      ...obj
    },
    () => {
      const {
        watchedVideoCampaigns: { end }
      } = this.state;
      if (end) {
        if (this._onClosePlayer) this._onClosePlayer();
      }
    }
  );
};

const setAdditionalStateParams = (
  progress,
  duration,
  watchedVideoCampaigns
) => {
  const obj = {};

  if (progress > 1 && progress < duration)
    obj.watchedVideoCampaigns = { ...watchedVideoCampaigns, middle: true };
  else if (progress > 1 && Math.trunc(progress) === Math.trunc(duration))
    obj.watchedVideoCampaigns = { ...watchedVideoCampaigns, end: true };
  else if (progress === 0 || progress < 1)
    obj.watchedVideoCampaigns = { ...watchedVideoCampaigns, start: true };

  return obj;
};

export default onCloseVideoCampaign;
