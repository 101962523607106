import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router";
import DefaultButton from "../Buttons/DefaultButton";
import {
  parseFromQueryToObject,
  parseObjectToUrl
} from "functions/logic-functions";
import style from "./style.module.scss";

class VodsPager extends PureComponent {
  constructor(props) {
    super(props);
    this.searchQueryToObject = parseFromQueryToObject(props.location.search);
  }

  goToNextPrev = goTo => {
    const {
      list,
      location: { search }
    } = this.props;
    this.searchQueryToObject = parseFromQueryToObject(search);
    const pageNumber = this.searchQueryToObject.page;

    switch (goTo) {
      case "prev":
        this._goToPrev(pageNumber);
        break;
      case "next":
        this._goToNext(list, pageNumber);
        break;
      default:
        break;
    }
  };

  _goToPrev = pageNumber => {
    const {
      location: { pathname }
    } = this.props;
    this.searchQueryToObject.page = pageNumber - 1;

    if (pageNumber && pageNumber > 1) {
      this.props.history.push({
        path: pathname,
        search: parseObjectToUrl(this.searchQueryToObject)
      });
    }
  };

  _goToNext = (list, pageNumber) => {
    const {
      location: { pathname }
    } = this.props;

    if (get(list, "pager.pageCount") && get(list, "pager.pageCount") > 1) {
      if (!this.searchQueryToObject.page) {
        this.searchQueryToObject.page = 2;

        this.props.history.push({
          path: pathname,
          search: parseObjectToUrl(this.searchQueryToObject)
        });
      } else if (
        pageNumber &&
        parseInt(get(list, "pager.pageCount")) > parseInt(pageNumber)
      ) {
        this.searchQueryToObject.page = parseInt(pageNumber) + 1;

        this.props.history.push({
          path: pathname,
          search: parseObjectToUrl(this.searchQueryToObject)
        });
      }
    }
  };

  _renderCurrentPage = (counterText, limitText, list, pageNumber) => {
    if (list && list.pager && list.pager.pageCount !== 1) {
      counterText = pageNumber ? pageNumber : 1;
      limitText = get(list, "pager.pageCount")
        ? get(list, "pager.pageCount")
        : 1;
    }

    if (counterText && limitText) {
      return (
        <p className={style.pagerNumbers}>
          {counterText}/{limitText}
        </p>
      );
    }
  };

  _renderPrevButton = (counterText, limitText) => {
    const pageNumber = this.searchQueryToObject.page;

    if (counterText && limitText && pageNumber && pageNumber > 1) {
      return (
        <DefaultButton
          title={<FormattedMessage id="common.previous" />}
          onClick={() => this.goToNextPrev("prev")}
        />
      );
    }
  };

  _renderNextButton = (counterText, limitText, showNextButton) => {
    if (counterText && limitText && showNextButton) {
      return (
        <DefaultButton
          title={<FormattedMessage id="common.next" />}
          onClick={() => this.goToNextPrev("next")}
        />
      );
    }
  };

  _renderNextButtonWithoutPager = (
    counterText,
    limitText,
    showNextButtonWithoutPageNumber
  ) => {
    if (counterText && limitText && showNextButtonWithoutPageNumber) {
      return (
        <DefaultButton
          title={<FormattedMessage id="common.next" />}
          onClick={() => this.goToNextPrev("next")}
        />
      );
    }
  };

  _getCounterText = (list, pageNumber) => {
    if (list && get(list, "pager.pageCount") !== 1) {
      return pageNumber ? pageNumber : 1;
    }
    return "";
  };

  _getLimitText = list => {
    if (list && get(list, "pager.pageCount") !== 1) {
      return get(list, "pager.pageCount") ? get(list, "pager.pageCount") : 1;
    }
    return "";
  };

  render() {
    const { list } = this.props;
    const pageNumber = this.searchQueryToObject.page;
    const counterText = this._getCounterText(list, pageNumber);
    const limitText = this._getLimitText(list);

    let showNextButtonWithoutPageNumber =
      !pageNumber &&
      get(list, "pager.pageCount") &&
      get(list, "pager.pageCount") > 1;
    let showNextButton =
      pageNumber &&
      get(list, "pager.pageCount") &&
      parseInt(get(list, "pager.pageCount")) > parseInt(pageNumber);

    return (
      <div className={style.pager}>
        {this._renderCurrentPage(counterText, limitText, list, pageNumber)}
        {this._renderPrevButton(counterText, limitText)}
        {this._renderNextButton(counterText, limitText, showNextButton)}
        {this._renderNextButtonWithoutPager(
          counterText,
          limitText,
          showNextButtonWithoutPageNumber
        )}
      </div>
    );
  }
}

VodsPager.propTypes = {
  list: PropTypes.shape({
    items: PropTypes.array
  })
};

export default withRouter(VodsPager);
