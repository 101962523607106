import React, { memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import style from "./style.module.scss";

const SearchResultCardList = ({
  textSearchVODS,
  hasValue,
  showDropdownOverride,
  hideDropdown,
  onHideNav,
  filteringPanelID,
  redirectToSearchPage,
  history: {
    location: { pathname }
  }
}) => {
  if (
    showDropdownOverride ||
    (pathname.indexOf("search") === -1 &&
      textSearchVODS.all &&
      textSearchVODS.all.length &&
      hasValue)
  ) {
    return (
      <div
        id={filteringPanelID}
        className={classNames(style.resultCardWrapper, "searchPanelBackground")}
      >
        {textSearchVODS.all
          .filter((item, index) => index < 5)
          .map(item => {
            return (
              <Link
                key={item.id}
                className={classNames(style.listItem, "searchPanelBackground")}
                to={{
                  pathname: `${item.urlRoute}/${item.id}`,
                  state: {
                    item: item
                  }
                }}
                onClick={() => {
                  hideDropdown();
                  if (onHideNav) onHideNav();
                }}
              >
                <img
                  className={classNames({
                    channelImg: item.channel_number
                  })}
                  src={
                    (item.covers &&
                      item.covers[0] &&
                      item.covers[0].medium_ar &&
                      item.covers[0].medium_ar) ||
                    (item.cover &&
                      item.cover.medium_ar &&
                      item.cover.medium_ar) ||
                    (item.logo_url && item.logo_url)
                  }
                  alt={""}
                />
                <div>
                  {item.title && (
                    <h4 className={"searchPanelText"}>{item.title}</h4>
                  )}
                  {item.year && (
                    <p className={"searchPanelText"}>{item.year}</p>
                  )}
                </div>
              </Link>
            );
          })}
        {textSearchVODS.all && textSearchVODS.all.length > 5 && (
          <div
            className={classNames(style.viewAllResults)}
            onClick={redirectToSearchPage}
          >
            <FormattedMessage
              id="common.viewAllNumberResults"
              values={{
                resultNumber: textSearchVODS.all.length
              }}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

SearchResultCardList.propTypes = {
  hasValue: PropTypes.bool.isRequired,
  showDropdownOverride: PropTypes.bool,
  redirectToSearchPage: PropTypes.func.isRequired,
  hideDropdown: PropTypes.func.isRequired,
  onHideNav: PropTypes.func
};

export default withRouter(memo(SearchResultCardList));
