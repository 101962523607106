import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import IconHoC from "HOCs/IconHoC";
import SeasonCarousel from "components/Carousels/SeasonCarousel";
import { ReactComponent as ArrowDown } from "./icons/down-arrow.svg";
import style from "./style.module.scss";

const EpisodesSuggestionComponentView = ({
  intl,
  episode,
  episodes,
  isSuggestionsInFocus,
  isResumePlayerOff,
  arrowsColor,
  onChangePlaying,
  onFocus,
  onDisplaySelectProfileToPlayModal,
  onDisplayParentalModal
}) => {
  const ArrowIcon = IconHoC(ArrowDown);

  return (
    <div
      className={classNames(style.bottomSuggestionCarousel, {
        [style.activeSuggestions]: isSuggestionsInFocus
      })}
    >
      <div
        className={classNames(style.arrowWrapper, {
          [style.invertIcon]: isSuggestionsInFocus
        })}
        onClick={onFocus}
      >
        <ArrowIcon className={style.arrowIcon} />
      </div>
      <SeasonCarousel
        intl={intl}
        items={episodes}
        urlRoute="/tv-shows/episode"
        activeEpisodeID={episode.id}
        useWhiteControlArrows={true}
        arrowsColor={arrowsColor}
        isResumePlayerOff={isResumePlayerOff}
        onDisplaySelectProfileToPlayModal={onDisplaySelectProfileToPlayModal}
        onChangePlaying={onChangePlaying}
        onDisplayParentalModal={onDisplayParentalModal}
      />
    </div>
  );
};

EpisodesSuggestionComponentView.propTypes = {
  intl: PropTypes.object.isRequired,
  episode: PropTypes.object,

  episodes: PropTypes.array,

  arrowsColor: PropTypes.string,

  isSuggestionsInFocus: PropTypes.bool,
  isResumePlayerOff: PropTypes.bool,

  onFocus: PropTypes.func.isRequired,
  onChangePlaying: PropTypes.func.isRequired,
  onDisplaySelectProfileToPlayModal: PropTypes.func,
  onDisplayParentalModal: PropTypes.func
};

export default memo(EpisodesSuggestionComponentView);
