import FormData from "form-data";
import axios from "./axios";

const forgotPassword = ({ url, email }) => {
  if (url && email) {
    const bodyFormData = new FormData();

    bodyFormData.append("email", email);
    return axios({
      method: "post",
      url: url,
      data: bodyFormData
    })
      .then(response => {
        return response;
      })
      .catch(err => {
        throw err;
      });
  }
};

export default forgotPassword;
