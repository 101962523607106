import React, { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";
import OutlineButton from "../OutlineButton";

const BottomControlButtons = (props, ref) => {
  const {
    successButtonTitle,
    closeTranslationID,
    closeButtonTitle,
    successTranslationID,
    hideCancelButton,
    onBack,
    onSuccess,
    onClose
  } = props;
  const additionalParams = {};

  if (ref) additionalParams.ref = ref;

  return (
    <div className={style.bottomButtons}>
      <OutlineButton
        titleTranslationID={successTranslationID}
        title={successButtonTitle}
        onClick={onSuccess}
        {...additionalParams}
      />
      {!hideCancelButton && onClose && (
        <OutlineButton
          titleTranslationID={closeTranslationID}
          title={closeButtonTitle}
          onClick={onClose}
        />
      )}
      {onBack && (
        <OutlineButton titleTranslationID={"common.back"} onClick={onBack} />
      )}
    </div>
  );
};

BottomControlButtons.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onBack: PropTypes.func,

  hideCancelButton: PropTypes.bool,

  successButtonTitle: PropTypes.string,
  closeButtonTitle: PropTypes.string,
  successTranslationID: PropTypes.string,
  closeTranslationID: PropTypes.string
};

export default memo(forwardRef(BottomControlButtons));
