import React, { memo } from "react";
import PropTypes from "prop-types";
import MdRadioButtonChecked from "react-icons/lib/md/radio-button-checked";
import MdRadioButtonUnchecked from "react-icons/lib/md/radio-button-unchecked";
import { List, Transition } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import * as CountryLanguage from "country-language";
import { getRenamedArray } from "functions/logic-functions";
import { uniqBy, isEqual, orderBy } from "lodash";
import classNames from "classnames";
import style from "./style.module.scss";

const SettingsPannel = props => {
  const {
    externalMovieSubtitles,
    internalMovieSubtitles,
    internalAudioSubtitles,
    activeMovieSubtitle,
    activeAudioSubtitle
  } = props;
  let offMovieSubtitle = {
    id: "MovieOff",
    locale: "Off",
    url: "#"
  };

  let subtitlesToShow = [];
  let audioSubtitlesToShow = [];

  if (externalMovieSubtitles && externalMovieSubtitles.length) {
    let externalMovies = externalMovieSubtitles.map(item => {
      return {
        id: item.id,
        locale: CountryLanguage.getLanguage(item.locale).name
          ? CountryLanguage.getLanguage(item.locale).nativeName[0]
          : item.locale,
        src: item.src,
        internal: false
      };
    });

    subtitlesToShow.push(...externalMovies);
  }

  if (internalMovieSubtitles && internalMovieSubtitles.length) {
    let internalMovies = internalMovieSubtitles.map(item => {
      return {
        id: item.id,
        locale: CountryLanguage.getLanguage(item.lang).name
          ? CountryLanguage.getLanguage(item.lang).nativeName[0]
          : item.lang,
        url: "#",
        internal: true
      };
    });

    subtitlesToShow.push(...internalMovies);
  }

  if (subtitlesToShow && subtitlesToShow.length) {
    if (!isEqual(uniqBy(subtitlesToShow, "locale"), subtitlesToShow)) {
      const subtitlesToShowOrderByAsc = orderBy(
        subtitlesToShow,
        ["locale"],
        ["asc"]
      );
      subtitlesToShow = [
        offMovieSubtitle,
        ...getRenamedArray(subtitlesToShowOrderByAsc, "locale")
      ];
    } else {
      subtitlesToShow = [offMovieSubtitle, ...subtitlesToShow];
    }
  }

  if (internalAudioSubtitles && internalAudioSubtitles.length) {
    let internalAudios = internalAudioSubtitles.map(item => {
      return {
        id: item.id,
        groupId: item.groupId,
        locale: item.name,
        url: "#",
        internal: true
      };
    });

    audioSubtitlesToShow.push(...internalAudios);
  }

  if (audioSubtitlesToShow && audioSubtitlesToShow.length) {
    audioSubtitlesToShow = [...audioSubtitlesToShow];
  }

  const getRadioButton = itemId => {
    if (activeMovieSubtitle === null && itemId === "MovieOff") {
      return <MdRadioButtonChecked />;
    } else if (itemId === activeMovieSubtitle) {
      return <MdRadioButtonChecked />;
    } else {
      return <MdRadioButtonUnchecked />;
    }
  };

  const getAudioRadioButton = itemId => {
    if (itemId === activeAudioSubtitle) {
      return <MdRadioButtonChecked />;
    } else {
      return <MdRadioButtonUnchecked />;
    }
  };

  return (
    <div id={props.settingPanelID}>
      <Transition.Group
        animation={props.animation}
        duration={props.fadeDuration}
      >
        {props.showSettingsPanel && (
          <div className={`${style.settingsPannelWrapper} themeSettingPanel`}>
            <div className={`${style.settingWrapper} themeSettingWrapper`}>
              <p>
                <FormattedMessage id="common.settings" />
              </p>
            </div>
            <div className={style.subtitle}>
              <p>
                <FormattedMessage id="common.subtitles" />
              </p>
            </div>

            <div className={style.listItemsWrapper}>
              {subtitlesToShow && subtitlesToShow.length > 0 ? (
                <List selection verticalAlign="middle">
                  {subtitlesToShow.map((item, index) => (
                    <List.Item
                      key={index}
                      className={classNames(
                        item.id === props.activeMovieSubtitle
                          ? style.listItemActive
                          : "",
                        style.listItem,
                        "themeListItem"
                      )}
                      onClick={() => {
                        props.onActiveMovieSubtitle(item);
                      }}
                    >
                      <List.Content floated="right">
                        {getRadioButton(item.id)}
                      </List.Content>
                      <List.Content>{item.locale}</List.Content>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <p className={style.noSubtitle}>
                  <FormattedMessage id="common.noSubtitle" />
                </p>
              )}
            </div>
            <div className={style.subtitle}>
              <p>
                <FormattedMessage id="common.audio" />
              </p>
            </div>
            <div className={style.listWrapper}>
              {audioSubtitlesToShow && audioSubtitlesToShow.length > 0 ? (
                <List selection verticalAlign="middle">
                  {audioSubtitlesToShow.map((item, index) => (
                    <List.Item
                      key={index}
                      className={`${style.listItem} themeListItem`}
                      onClick={() => props.onActiveAudioSubtitle(item)}
                    >
                      <List.Content floated="right">
                        {getAudioRadioButton(item.id)}
                      </List.Content>

                      <List.Content>
                        {`${item.locale} ${item.groupId &&
                          "(" + item.groupId + ")"}`}
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <p className={style.noSubtitle}>
                  <FormattedMessage id="common.noAudioSubtitle" />
                </p>
              )}
            </div>
          </div>
        )}
      </Transition.Group>
    </div>
  );
};

SettingsPannel.propTypes = {
  settingPanelID: PropTypes.string,
  showSettingsPanel: PropTypes.bool,
  externalMovieSubtitles: PropTypes.array,
  internalMovieSubtitles: PropTypes.array,
  internalAudioSubtitles: PropTypes.array,
  onActiveMovieSubtitle: PropTypes.func,
  onActiveAudioSubtitle: PropTypes.func,
  activeMovieSubtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  activeAudioSubtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  animation: PropTypes.string,
  fadeDuration: PropTypes.number
};

export default memo(SettingsPannel);
