export const GET_VOD_GENRES = "GET_VOD_GENRES";
export const GET_VOD_GENRES_SUCCESS = "GET_VOD_GENRES_SUCCESS";

const genres = (state = [], action) => {
  switch (action.type) {
    case GET_VOD_GENRES_SUCCESS:
      if (action.payload) return action.payload;
      return state;
    default:
      return state;
  }
};

export default genres;

export const getVodGenres = data => {
  return {
    type: GET_VOD_GENRES,
    payload: {
      url: data.url
    }
  };
};
