export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";

const subscriptions = (state = [], action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_SUCCESS:
      if (action.payload) return action.payload;
      return state;
    default:
      return state;
  }
};

export default subscriptions;

export const getSubscriptions = data => {
  return {
    type: GET_SUBSCRIPTIONS,
    payload: {
      url: data.url
    }
  };
};
