import { get } from "lodash";

const isCensorshipCard = item => {
  const parentalCensorship =
    localStorage.getItem("censorship-allowed") &&
    JSON.parse(localStorage.getItem("censorship-allowed"));
  return parentalCensorship && get(item, "censorship_allowed");
};

export default isCensorshipCard;
