import { get } from "lodash";
export const GET_TEXT_SEARCH_FILTER_VODS = "GET_TEXT_SEARCH_FILTER_VODS";
export const GET_TEXT_SEARCH_FILTER_VODS_SUCCESS =
  "GET_TEXT_SEARCH_FILTER_VODS_SUCCESS";
export const APPEND_TEXT_FILTER_SEARCH_RESULTS =
  "APPEND_TEXT_FILTER_SEARCH_RESULTS";
export const APPEND_TEXT_FILTER_SEARCH_RESULTS_SUCCESS =
  "APPEND_TEXT_FILTER_SEARCH_RESULTS_SUCCESS";
export const RESET_FILTER_SEARCH_RESULTS = "RESET_FILTER_SEARCH_RESULTS";

const initialValue = {
  all: [],
  movies: [],
  tvShows: [],
  channels: []
};

const appendRoutes = item => {
  if (item.type === 1 && item.type_name === "episode")
    item.urlRoute = "/tv-shows/episode";
  else if (item.seasons) item.urlRoute = "/tv-shows";
  else if (item.channel_number) item.urlRoute = "/channels";
  else item.urlRoute = "/movies";
  return item;
};

const textSearchVODS = (state = initialValue, action) => {
  switch (action.type) {
    case GET_TEXT_SEARCH_FILTER_VODS_SUCCESS:
      if (action.payload && action.payload.length) {
        let result = Object.assign({}, initialValue);

        action.payload.forEach(item => {
          let {
            data: {
              data: { items }
            }
          } = item;

          if (items) {
            items = items.map(item => {
              return appendRoutes(item);
            });
            result.all = [...result.all, ...items];
          } else if (Array.isArray(get(item, "data.data"))) {
            item.data.data = item.data.data.map(item => {
              return appendRoutes(item);
            });
            result.all = [...result.all, ...item.data.data];
          }
          if (items && items[0] && items[0].seasons) result.tvShows = items;
          else if (get(item, "data.data.items[0].channel_number")) {
            result.channels = item.data.data.items;
          } else if (
            items &&
            items[0] &&
            items[0].type_name &&
            items[0].type_name === "movie"
          )
            result.movies = items;
        });

        return result;
      } else return state;

    case APPEND_TEXT_FILTER_SEARCH_RESULTS_SUCCESS:
      if (action.payload && action.payload.type && action.payload.results) {
        const appendedData = Object.assign({}, state);

        appendedData[action.payload.type] = action.payload.results.map(item => {
          item.urlRoute = `/${action.payload.type}`;
          return item;
        });
        return Object.assign({}, appendedData);
      }
      return state;

    case RESET_FILTER_SEARCH_RESULTS:
      return initialValue;

    default:
      return state;
  }
};

export default textSearchVODS;

export const getTextSearchFilterVods = data => {
  return {
    type: GET_TEXT_SEARCH_FILTER_VODS,
    payload: {
      urls: data.urls,
      successCallback: data.successCallback
    }
  };
};

export const appendTextFilterSearchResults = data => {
  return {
    type: APPEND_TEXT_FILTER_SEARCH_RESULTS,
    payload: {
      url: data.url,
      type: data.type
    }
  };
};

export const resetTextFilterSearchResults = () => {
  return {
    type: RESET_FILTER_SEARCH_RESULTS
  };
};
