import React, { memo, forwardRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import style from "./style.module.scss";

const OutlineButton = (
  { onClick, titleTranslationID, className, title },
  ref
) => {
  const additionalParams = {};

  if (ref) additionalParams.ref = ref;

  return (
    <button
      className={classNames(style.button, className)}
      onClick={onClick}
      {...additionalParams}
    >
      {title ? (
        title
      ) : titleTranslationID ? (
        <FormattedMessage id={titleTranslationID} />
      ) : null}
    </button>
  );
};

OutlineButton.propTypes = {
  ref: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  titleTranslationID: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.object
};

export default memo(forwardRef(OutlineButton));
