export const GET_CONTINUE_WATCHING_VODS = "GET_CONTINUE_WATCHING";
export const GET_CONTINUE_WATCHING_VODS_SUCCESS =
  "GET_CONTINUE_WATCHING_SUCCESS";

const cachedContinueWatchingList = (state = null, action) => {
  switch (action.type) {
    case GET_CONTINUE_WATCHING_VODS_SUCCESS:
      if (action.payload) return action.payload;
      return state;
    default:
      return state;
  }
};

export default cachedContinueWatchingList;

export const getContinueWatchingVods = data => {
  return {
    type: GET_CONTINUE_WATCHING_VODS,
    payload: {
      url: data.url
    }
  };
};
