import React, { memo } from "react";
import PropTypes from "prop-types";
import RightArrow from "react-icons/lib/md/keyboard-arrow-right";
import LeftArrow from "react-icons/lib/md/keyboard-arrow-left";
import classNames from "classnames";
import { ArrowWrapper } from "./styledComponents";
import style from "./style.module.scss";

const Arrow = ({
  icon,
  hasInfiniteScroll,
  slideCount,
  currentSlide,
  onClick,
  ...props
}) => {
  const checkCurrentSlide =
    currentSlide === 0 && !hasInfiniteScroll ? "slick-disabled" : "";
  const checkSlideCount =
    currentSlide === slideCount - 3 && !hasInfiniteScroll
      ? "slick-disabled"
      : "";
  return (
    <ArrowWrapper
      {...props}
      onClick={onClick}
      className={classNames({
        [style.rightArrow]: icon === "right",
        [style.leftArrow]: icon === "left",

        [checkCurrentSlide]: icon === "left",
        [checkSlideCount]: icon === "right"
      })}
    >
      {icon === "right" ? <RightArrow /> : <LeftArrow />}
    </ArrowWrapper>
  );
};

Arrow.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default memo(Arrow);
