import React, { memo } from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const RoundIconButton = ({ onClick, iconComponent, label }) => {
  return (
    <div onClick={onClick}>
      <div className={style.iconWrapper}>
        <div>{iconComponent && iconComponent}</div>
      </div>
      {label && <p>{label}</p>}
    </div>
  );
};

RoundIconButton.propTypes = {
  iconComponent: PropTypes.node,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default memo(RoundIconButton);
