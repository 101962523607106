const config = {
  hide_registration: false,
  hide_payment_history: false,
  hide_subscriptions: false,
  hide_register_payment_methods: true,
  hide_themes: false,
  POLYNET_API_KEY:
    "eyJoYXNoZWRfa2V5IjoiRXgxMkJSelpJbEhiR1NfcUZ6b1ZCaF96Wnk4Sm1HdjVkZENKbEVGbldKSSIsInNpZ25hbF9zZXJ2ZXJfdXJsIjoid3NzOlwvXC9hbWVyaWNhcy5zNzNjbG91ZC5jb21cL3dzIiwic3R1bl9zZXJ2ZXJfdXJsIjpbInN0dW46c3R1bi1hbWVyaWNhcy0wMS5zNzNjbG91ZC5jb206MzQ3OCIsInN0dW46c3R1bi1hbWVyaWNhcy0wMi5zNzNjbG91ZC5jb206MzQ3OCJdLCJtZXRyaWNfc2lua191cmwiOiJodHRwczpcL1wvYW1lcmljYXMuczczY2xvdWQuY29tXC9tZXRyaWNzXC92MSJ9",
  firebaseConfig: {
    apiKey: "AIzaSyCSy8sHfPQ_vByuJAjN15TIx6IzP83Op90",
    authDomain: "wetek-69133.firebaseapp.com",
    databaseURL: "https://wetek-69133.firebaseio.com",
    projectId: "wetek-69133",
    storageBucket: "wetek-69133.appspot.com",
    messagingSenderId: "615055219290",
    appId: "1:615055219290:web:0923ab981661fb406a1663",
    measurementId: "G-GMMYZ6KD4N"
  }
};

export const appVersion = "2.31.4";

export default config;
