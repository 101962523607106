import { put, call } from "redux-saga/effects";
import { get } from "lodash";
import { UPDATE_CHANNEL_FAVORITES } from "reduxStore/reducers/channels";
import { postData } from "helper-functions/http-functions";

const addOrRemoveFavouriteChannel = function*({ payload }) {
  try {
    const result = yield call(postData, payload);

    if (get(result, "data.data")) {
      yield put({
        type: UPDATE_CHANNEL_FAVORITES,
        payload: result.data.data
      });
    }
  } catch (err) {
    console.info("ERR", err);
  }
};

export default addOrRemoveFavouriteChannel;
