import { put } from "redux-saga/effects";
import { ADD_VOD_PROFILE_SUCCESS } from "reduxStore/reducers/vodProfile";

const addVodProfile = function*({ payload }) {
  try {
    yield put({ type: ADD_VOD_PROFILE_SUCCESS, payload: payload.data });
    if (payload.successCallback) payload.successCallback("success");
  } catch (e) {
    console.info("ERR", e);
  }
};

export default addVodProfile;
