import React, { memo } from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const MiniProgressBar = ({ progress, duration }) => {
  if (!progress || !duration) return null;

  return (
    <div className={style.wrapper}>
      <span
        className={style.progressIndicator}
        style={{ width: `${(progress / duration) * 100}%` }}
      ></span>
    </div>
  );
};

MiniProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired
};

export default memo(MiniProgressBar);
