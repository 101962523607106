import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "antd/lib/slider";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import AnimateHeight from "react-animate-height";
import { parseFromQueryToObject } from "functions/logic-functions";
import style from "./style.module.scss";
import "./style.scss";

const { bool, object, func, array } = PropTypes;

const marks = {
  1900: "1900"
};
marks[new Date().getFullYear()] = new Date().getFullYear();

const SearchFiltersView = ({
  genres,
  children,
  visible,
  year,
  showYearFilter,
  match: {
    params: { year: urlYear }
  },
  location: { search },
  onChange,
  onYearChange,
  onAfterChange,
  resetFilters
}) => {
  const searchQueryToObject = parseFromQueryToObject(search);

  const _renderGenres = () => {
    return genres.map(item => {
      const obj = {};

      if (
        searchQueryToObject.genres &&
        searchQueryToObject.genres
          .split(",")
          .map(Number)
          .includes(item.id)
      ) {
        obj.checked = true;
      } else obj.checked = false;

      return (
        <li key={item.id}>
          <input
            type="checkbox"
            name={item.id}
            id={item.id}
            onChange={onChange}
            {...obj}
          />
          <label htmlFor={item.id}>{item.name}</label>
        </li>
      );
    });
  };

  const _renderYearBlock = () => {
    let properties = {};

    if (!year) {
      properties.value = [
        1900,
        urlYear ? parseInt(urlYear.split("-")[1]) : new Date().getFullYear()
      ];
    } else {
      properties.value = year.map(Number);
    }

    if (showYearFilter) {
      return (
        <div className={style.yearBlock}>
          <h4>
            <FormattedMessage id="common.year" />:
          </h4>
          <div>
            <Slider
              marks={marks}
              range
              defaultValue={[1900, new Date().getFullYear()]}
              min={1900}
              max={new Date().getFullYear()}
              onChange={onYearChange}
              onAfterChange={onAfterChange}
              {...properties}
            />
          </div>
        </div>
      );
    }
  };

  const _renderResetButton = () => {
    return (
      <p className={classNames(style.resetButton)} onClick={resetFilters}>
        <FormattedMessage id="vods.resetAllFilters" />
      </p>
    );
  };

  if (!genres || (genres && !genres.length)) return null;

  return (
    <AnimateHeight duration={500} height={visible ? "auto" : 0}>
      <div className={classNames(style.filterWrapper, "filtersWrapper")}>
        <div>
          <div className={style.genresList}>
            <h4>
              <FormattedMessage id="common.genres" />:
            </h4>
            <ul className={style.filtersList}>{_renderGenres()}</ul>
          </div>
          {_renderYearBlock()}
        </div>
        {_renderResetButton()}
        {children && <div className={style.showTypesWrapper}>{children}</div>}
      </div>
    </AnimateHeight>
  );
};

SearchFiltersView.propTypes = {
  genres: array,
  children: PropTypes.any,
  visible: bool.isRequired,
  year: array,
  showYearFilter: bool,
  match: object.isRequired,
  location: object.isRequired,
  onChange: func.isRequired,
  onYearChange: func.isRequired,
  onAfterChange: func.isRequired,
  resetFilters: func.isRequired
};

export default withRouter(memo(SearchFiltersView));
