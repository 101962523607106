import { call } from "redux-saga/effects";
import { fetchData } from "helper-functions/http-functions";

const getRecommended = function*({ payload }) {
  try {
    const result = yield call(fetchData, payload);

    if (result && result.items && result.items.length)
      payload.successCallback(result.items);
  } catch (err) {
    //yield put({type: AUTHORIZE_FAILED});
    console.info("ERR", err);
  }
};

export default getRecommended;
