import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ScrollTopHOC from "HOCs/ScrollTopHOC";
import { withRouter } from "react-router";
import { Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import GenresNavList from "components/GenresNavList";
import VodsGridList from "components/VodsGridList";
import SearchFilters from "components/SearchFilters";
import VodsPager from "components/VodsPager";
import ActiveBanner from "components/ActiveBanner";
import ShowTypes from "components/ShowTypes";
import { getMvodList } from "reduxStore/actions";
import { getVodGenres } from "reduxStore/reducers/genres";
import {
  GET_VOD_GENRES_URL,
  GET_MVOD_LIST_URL,
  GET_ACTIVE_BANNER
} from "API-routes";
import NoContentComponent from "components/NoContentComponent";
import MdMovie from "react-icons/lib/md/movie";
import { FormattedMessage } from "react-intl";
import {
  parseFromQueryToObject,
  parseObjectToEndpointQuery
} from "functions/logic-functions";
import { injectIntl } from "react-intl";
import style from "./style.module.scss";

class TvShowsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      showFilter: {
        show: false,
        clicked: false
      },
      shows: []
    };
  }

  componentDidMount() {
    const {
      location: { search }
    } = this.props;
    const { getVodGenresDispatch, genres } = this.props;
    const searchQueryObject = parseFromQueryToObject(search);

    if (!genres.length) {
      this.setState(
        {
          showLoader: true
        },
        () => {
          getVodGenresDispatch({
            url: GET_VOD_GENRES_URL
          });
        }
      );
    }

    this.setState(
      {
        showLoader: true
      },
      () => {
        this.fetchShows({
          genres: searchQueryObject.genres,
          page: searchQueryObject.page,
          showTypes: searchQueryObject["show-types"]
        });
      }
    );
  }

  componentWillUnmount() {
    clearTimeout(this.fetchImagesTimer);
    clearTimeout(this.loaderTimeout);
  }

  componentWillReceiveProps(nextProps) {
    const { shows } = this.state;
    const {
      location: { search }
    } = this.props;
    const {
      location: { search: nextSearch }
    } = nextProps;
    const searchQueryObject = parseFromQueryToObject(search);
    const nextSearchQueryObject = parseFromQueryToObject(nextSearch);

    if (
      searchQueryObject.genres !== nextSearchQueryObject.genres ||
      searchQueryObject.year !== nextSearchQueryObject.year ||
      searchQueryObject["show-types"] !== nextSearchQueryObject["show-types"]
    ) {
      this.setState(
        {
          showLoader: true
        },
        () => {
          this.fetchShows({
            genres: nextSearchQueryObject.genres,
            page: nextSearchQueryObject.page,
            year: nextSearchQueryObject.year,
            showTypes: nextSearchQueryObject["show-types"]
          });
        }
      );
    }

    if (
      parseInt(searchQueryObject.page) !== parseInt(nextSearchQueryObject.page)
    ) {
      this.setState(
        {
          showLoader: true
        },
        () => {
          this.fetchShows({
            genres: nextSearchQueryObject.genres || searchQueryObject.genres,
            page: nextSearchQueryObject.page,
            year: nextSearchQueryObject.year,
            showTypes: nextSearchQueryObject["show-types"]
          });

          // FETCH CONTENT FOR THE NEXT PAGE AND DOWNLOAD COVER IMAGES
          if (shows && shows.pager) {
            const {
              pager: { pageCount }
            } = shows;

            if (pageCount >= parseInt(nextSearchQueryObject.page) + 1) {
              this.fetchImagesTimer = setTimeout(() => {
                this.fetchShows({
                  genres:
                    nextSearchQueryObject.genres || searchQueryObject.genres,
                  page: nextSearchQueryObject.page
                    ? parseInt(nextSearchQueryObject.page) + 1
                    : 2,
                  showTypes: nextSearchQueryObject["show-types"],
                  downloadImagesFunction: this.downloadNextPageSmallImages
                });
              }, 2000);
            }
          }
        }
      );
    }

    this.loaderTimeout = setTimeout(() => {
      this.setState({
        showLoader: false
      });
    }, 4000);
  }

  onLoaderShow = showLoader => {
    this.setState({
      showLoader
    });
  };

  downloadNextPageSmallImages = queryString => {
    const { getMvodListDispatch } = this.props;

    getMvodListDispatch({
      url: `${GET_MVOD_LIST_URL}${queryString}`,
      successCallback: this.nextPageImagesSuccessCallback
    });
  };

  nextPageImagesSuccessCallback = response => {
    const { items } = response || {};

    if (items.length) {
      items.forEach(item => {
        const imageSource =
          (item.covers && item.covers[0].medium_ar) ||
          (item.covers && item.covers[0].small_ar) ||
          (item.cover && item.cover.medium_ar) ||
          (item.cover && item.cover.small_ar && item.cover.small_ar) ||
          (item.screenshot_url && item.screenshot_url) ||
          (item.logo_url && item.logo_url);

        if (imageSource) {
          const image = new Image();

          image.src = imageSource;
        }
      });
    }
  };

  fetchShows = ({ genres, page = 1, showTypes, downloadImagesFunction }) => {
    const { getMvodListDispatch } = this.props;
    const queryObject = {};

    queryObject.genres = genres;
    queryObject.page = page;
    queryObject.limit = 24;
    queryObject["show-types"] = showTypes;

    let endpointQueryString = parseObjectToEndpointQuery(queryObject);

    if (downloadImagesFunction) downloadImagesFunction(queryObject);
    else {
      getMvodListDispatch({
        url: `${GET_MVOD_LIST_URL}${endpointQueryString}`,
        successCallback: shows => {
          this.setState({
            showLoader: false,
            shows
          });
        }
      });
    }
  };

  onShowNavs = () => {
    const { showFilter } = this.state;

    this.setState({
      showFilter: {
        show: !showFilter.show,
        clicked: true
      }
    });
  };

  render() {
    const { shows, showFilter, showLoader } = this.state;
    const {
      intl,
      genres,
      composerConfig: {
        web: {
          modules: { advertisements }
        }
      },
      location: { search }
    } = this.props;
    const hasContent = shows && shows.items && shows.items.length;
    const searchQueryObject = parseFromQueryToObject(search);

    return (
      <div
        className={classNames(style.container, "topPadding")}
        style={
          !hasContent
            ? {
                height: "100%"
              }
            : {}
        }
      >
        <GenresNavList
          route="/tv-shows"
          genres={genres}
          selectedGenres={searchQueryObject.genres}
          onShowNavs={this.onShowNavs}
        />
        <SearchFilters
          visible={showFilter.show}
          showYearFilter={false}
          onLoaderShow={this.onLoaderShow}
        >
          <ShowTypes />
        </SearchFilters>
        {hasContent ? (
          <VodsGridList intl={intl} urlRoute="/tv-shows" list={shows}>
            {advertisements && (
              <ActiveBanner
                useParentContainer={true}
                useNestedBanner={true}
                url={`${GET_ACTIVE_BANNER}?filter[box_position]=tv_shows_box&filter[ads_type]=image`}
              />
            )}
          </VodsGridList>
        ) : (
          <NoContentComponent
            noContentIcon={<MdMovie />}
            noContentText={
              <FormattedMessage id="common.moviesContentUnavailable" />
            }
          />
        )}
        {showLoader && (
          <div
            className={classNames(style.loaderWrapper, "theme-loader-wrapper")}
          >
            <Loader active={true} size="big" indeterminate />
          </div>
        )}
        {shows && shows.pager && <VodsPager list={shows} />}
      </div>
    );
  }
}

TvShowsListPage.propTypes = {
  genres: PropTypes.array
};

const mapStateToProps = state => ({
  genres: state.genres,
  composerConfig: state.composerConfig
});

const matchDispatchToProps = dispatch => ({
  getVodGenresDispatch: data => dispatch(getVodGenres(data)),
  getMvodListDispatch: data => dispatch(getMvodList(data))
});

export default ScrollTopHOC(
  withRouter(
    connect(mapStateToProps, matchDispatchToProps)(injectIntl(TvShowsListPage))
  )
);
