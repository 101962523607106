import React, { Component } from "react";
import { connect } from "react-redux";
import ScrollTopHOC from "HOCs/ScrollTopHOC";
import { ALIASES } from "reduxStore/reducers/cachedHorizontalHomepage";
import HeaderSlider from "components/Carousels/HeaderSlider";
import DynamicDataCarousel from "components/Carousels/DynamicDataCarousel";
import ActiveBanner from "components/ActiveBanner";
import CarouselFetchWrapper from "./CarouselFetchWrapper";
import CarouselContinueWatchWrapper from "./CarouselContinueWatchWrapper";
import CarouselFavoritesWrapper from "./CarouselFavoritesWrapper";
import { appendChannelsList } from "reduxStore/reducers/channels";
import { fetchFavoritesChannels } from "helper-functions/http-functions";

import {
  GET_MVOD_LIST_URL,
  RECOMMENDED_VODS_BY_VIEW_URL,
  SINGLE_VOD,
  GET_ACTIVE_BANNER,
  GET_CHANNELS_WITH_PAGER,
  FAVORITES_LIST
} from "API-routes";
import { injectIntl } from "react-intl";

const {
  RECOMMENDED_TV_SHOWS,
  MOVIES_NEW_RELEASES,
  TV_SHOWS_NEW_RELEASES,
  RECOMMENDED_MOVIES
} = ALIASES;

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favoritesChannels: []
    };
  }

  _onAppendChannels = (limit = 100, page) => {
    const { appendChannelsListDispatch } = this.props;

    appendChannelsListDispatch({
      url: `${GET_CHANNELS_WITH_PAGER}?config[enable_favorites]=1&${
        limit ? `limit=${limit}&` : ""
      }${page ? `page=${page}` : ""}`
    });
  };

  componentDidMount() {
    fetchFavoritesChannels({
      url: FAVORITES_LIST,
      body: {}
    }).then(
      response =>
        response &&
        response.length &&
        this.setState({ favoritesChannels: response })
    );
  }

  render() {
    const { favoritesChannels } = this.state;
    const {
      cachedHorizontalHomepage,
      composerConfig: {
        web: {
          modules: { vod, channels: channelsModule, advertisements }
        }
      },
      channels,
      intl
    } = this.props;

    return (
      <div className={"topPadding"}>
        {(vod || channelsModule) && <HeaderSlider intl={intl} />}
        <div style={{ overflowX: "hidden" }}>
          {vod && <CarouselContinueWatchWrapper intl={intl} />}
          {channelsModule && (
            <CarouselFavoritesWrapper
              onAppendChannels={this._onAppendChannels}
              favoritesChannels={favoritesChannels}
            />
          )}
          {channelsModule && (
            <CarouselFetchWrapper
              urlRoute={"/channels"}
              intl={intl}
              onAppendChannels={this._onAppendChannels}
              useCacheFrom={channels.items}
              pager={channels.pager}
              limit={40}
              lazyLoad={true}
            >
              <DynamicDataCarousel
                title={intl.formatMessage({
                  id: "common.liveTv"
                })}
                intl={intl}
                urlRoute={"/channels"}
                items={channels.items}
                showPlayIcon={false}
                useInfiniteScroll={channels.items.length > 8}
              />
            </CarouselFetchWrapper>
          )}
          {vod && (
            <CarouselFetchWrapper
              url={RECOMMENDED_VODS_BY_VIEW_URL}
              alias={RECOMMENDED_MOVIES}
              type={0}
              intl={intl}
              title={intl.formatMessage({
                id: "homepage.recommendedMovies"
              })}
              urlRoute={"/movies"}
              requestMethod="post"
              useCacheFrom={cachedHorizontalHomepage.recommendedMovies.items}
            />
          )}
          {vod && (
            <CarouselFetchWrapper
              url={RECOMMENDED_VODS_BY_VIEW_URL}
              alias={RECOMMENDED_TV_SHOWS}
              type={2}
              intl={intl}
              title={intl.formatMessage({
                id: "homepage.recommendedTvShows"
              })}
              urlRoute={"/tv-shows"}
              requestMethod="post"
              useCacheFrom={cachedHorizontalHomepage.recommendedTvShows.items}
            />
          )}
          {vod && (
            <CarouselFetchWrapper
              url={`${SINGLE_VOD}?page=1&sort[created]=desc`}
              alias={MOVIES_NEW_RELEASES}
              title={intl.formatMessage({
                id: "homepage.moviesNewReleases"
              })}
              intl={intl}
              requestMethod="get"
              urlRoute={"/movies"}
              useCacheFrom={cachedHorizontalHomepage.moviesNewReleases.items}
            />
          )}
          {vod && (
            <CarouselFetchWrapper
              url={`${GET_MVOD_LIST_URL}?sort[created]=desc`}
              alias={TV_SHOWS_NEW_RELEASES}
              title={intl.formatMessage({
                id: "homepage.tvShowsNewReleases"
              })}
              intl={intl}
              requestMethod="get"
              urlRoute={"/tv-shows"}
              useCacheFrom={cachedHorizontalHomepage.tvShowsNewReleases.items}
            />
          )}
          {advertisements && (
            <ActiveBanner
              url={`${GET_ACTIVE_BANNER}?filter[box_position]=home_box&filter[ads_type]=image`}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cachedHorizontalHomepage: state.cachedHorizontalHomepage,
  composerConfig: state.composerConfig,
  channels: state.channels
});

const mapDispatchToProps = dispatch => ({
  appendChannelsListDispatch: data => dispatch(appendChannelsList(data))
});

export default ScrollTopHOC(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(HomePage))
);
