import { differenceWith, isEqual } from "lodash";

//RETURNS TRUE IF COLLECTIONS ARE DIFFERENT
const uniqueCollections = (x, y) => {
  if (!Array.isArray(x) || !Array.isArray(y)) return;
  if (x.length !== y.length) return true;
  return !!differenceWith(x, y, isEqual).length;
};

export default uniqueCollections;
