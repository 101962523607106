import React, { memo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import style from "./style.module.scss";

const NoContentComponent = ({ noContentIcon, noContentText }) => (
  <div className={style.noContentPage}>
    <div className={style.iconStyling}>{noContentIcon}</div>
    <p className={style.noContentFirstText}>
      <FormattedMessage id="common.contentUnavailable" />
    </p>
    <p className={style.noContentTextStyle}>{noContentText}</p>
  </div>
);

NoContentComponent.propTypes = {
  noContentIcon: PropTypes.node.isRequired,
  noContentText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired
};

export default withRouter(memo(NoContentComponent));
