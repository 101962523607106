import styledComponents from "styled-components";

export const SliderWrapper = styledComponents.div`
	position: relative;
	margin: 20px 0;
	width: 100%;
	 
	.slick-slide.slick-active.slick-center.slick-current{    
		border-radius: 15px;
		overflow: hidden;
    	height: 465px;
    	top: 0px;
    	flex-grow: 2;
    	transform: scale(1.1);
    	position: relative;
    	z-index:999;
    	margin-top: 25px;
    	margin-bottom: 25px;
    	
    	img{
    		filter: brightness(1);
    	}
    	
    	@media(max-width: 768px){
    		height: 310px;
    	}
    	
    	& > div {
		    height: 100%;
		    
		    & > div {
		        height: 100%;
		    }
		}
    	 
      }
    .slick-slide.slick-active, .slick-slide{
    	transition: transform 800ms ease, border-radius 800ms ease;
    	border-radius: 0px;
    	padding: 0px;
    	transform: scale(1);
    	height: 460px;
			position: relative;
			top: 25px;
			flex-grow: 1;
		
    	img{
    		width: 100%;
    		height: 100%;
    		object-fit: cover;
    		filter: brightness(0.6);
    	}
    	
    	@media(max-width: 768px){
    		height: 315px;
    	}
    	
    	& > div {
		    height: 100%;
		    
		    & > div {
		        height: 100%;
		    }
		}
    }
    .slick-list > .slick-track{
    	display: flex;
    	margin: auto;
    	${({ isSingleHighlightedContent }) =>
        isSingleHighlightedContent &&
        `
   			@media(max-width: 1599px){
    		width: 1210px !important; 
    	} 
    	@media(max-width: 1550px){
    		width: 1070px !important; 
    	}
    	
    	@media(max-width: 1400px){
    		width: 1050px !important; 
    	}
    	@media(max-width: 1315px){
    		width: 950px !important; 
    	}
    	
    	@media(max-width: 1200px){
    		width: 920px !important; 
    	}
    	@media(max-width: 1120px){
    		width: 820px !important; 
    	}
    	@media(max-width: 1000px){
    		width: 750px !important; 
    	}
    	@media(max-width: 900px){
    		width: 650px !important; 
    	}
    	@media(max-width: 768px){
    		width: 560px !important; 
    	}
    		@media(max-width: 650px){
    		width: 460px !important; 
    	}
    		@media(max-width: 570px){
    		width: 380px !important; 
    	}
    	@media(max-width: 460px){
    		width: 280px !important; 
    	}
    	@media(max-width: 360px){
    		width: 240px !important; 
    	}
    	
    	`}
    }  
    ul.slick-dots{
        li{
			width: 80px;
    		height: 5px;
    		button{
				width: 100%;
				height: 100%;
				padding: 0;
				background: #c4c4c4;
				&::before{
					display: none;
				}
			}
			@media(max-width: 648px){
				width: 60px;
			}
        }
    }
`;
